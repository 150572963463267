import React, { Component } from "react";
import { Redirect, withRouter } from "react-router-dom";
import { Image } from "react-bootstrap";
import { Table } from "react-bootstrap";
// import { Button } from "react-bootstrap";

import Axios from "axios";

import MessageBox from "../components/messagebox/messagebox";
import LoadingBox from "../components/messagebox/loadingbox";
import ConfirmBox from "../components/messagebox/confirmbox";

import MenuAdmin from "../components/menu/menuAdmin";
import MenuAdminSubcon from "../components/menu/menuAdminSubcon";
import MenuReviewer from "../components/menu/menuReviewer";
import MenuTechnician from "../components/menu/menuTechnician";

import auth from "../helper/authHelper";
import utils from "../helper/utilityHelper";

var Config = require("../config/config.json");

class PATAdminReviewerShow extends Component {
  //
  constructor(props) {
    // Base contructor.
    super(props);
    // Ref.
    this.messageboxRef = React.createRef();
    this.loadingboxRef = React.createRef();
    this.confirmboxRef = React.createRef();
    this.designMaster1ImageRef = React.createRef();
    this.designSlaveImageRef = React.createRef();
    // State.
    this.state = {
      redirect: "",
      data: null
    };
  }

  //
  onDelete = path => {
    if (this.loadingboxRef.current) this.loadingboxRef.current.onShow();
    let data = this.state.data;

    if (path === "p271") {
      data.content.p271 = "";
    } else if (path === "p272") {
      data.content.p272 = "";
    } else if (path === "p273") {
      data.content.p273 = "";
    }

    // console.log(path);
    // console.log(data);

    this.setState({ data: data });
    Axios.put(
      Config.service.url + "/pat/update/" + this.state.data._id,
      { data: JSON.stringify(this.state.data) },
      {
        headers: {
          Authorization: localStorage.getItem("token")
        }
      }
    )
      .then(res => {
        if (res.data.success === false) {
          this.messageboxRef.current.onShow("Error", res.data.message);
          if (this.loadingboxRef.current) this.loadingboxRef.current.onHide();
          return;
        }
        if (this.loadingboxRef.current) this.loadingboxRef.current.onHide();
        this.messageboxRef.current.onShow(
          "Success",
          "The data have been updated."
        );
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {});
  };

  //
  requestData = () => {
    if (this.loadingboxRef.current) this.loadingboxRef.current.onShow();
    let params = new URLSearchParams(window.location.search);
    let mode = params.get("mode");
    let ssrId = params.get("ssrId");
    let patId = params.get("id");
    let endPoint = "";

    if (patId !== null) {
      endPoint = Config.service.url + "/pat/get/" + patId;
    } else {
      endPoint = Config.service.url + "/pat/getBySSRId/" + ssrId;
    }

    Axios.get(endPoint, {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    })
      .then(res => {
        if (res.data.success === false) {
          this.messageboxRef.current.onShow("Error", res.data.message);
          return;
        }
        //
        console.log(res.data);
        if (res.data.data !== undefined) {
          this.setState({ data: res.data.data });
          document.title =
            "PAT-Doc " +
            res.data.data.cover.siteCode +
            " No-" +
            res.data.data.documentNumber;
          if (mode === "print") {
            setTimeout(() => {
              if (this.loadingboxRef.current)
                this.loadingboxRef.current.onHide();
              setTimeout(() => {
                window.print();
              }, 1000);
            }, 15 * 1000);
          } else {
            if (this.loadingboxRef.current) this.loadingboxRef.current.onHide();
          }
        } else {
          this.messageboxRef.current.onShow(
            "Error",
            "PAT document does not create yet."
          );
        }
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        if (this.loadingboxRef.current) this.loadingboxRef.current.onHide();
      });
  };

  //
  componentDidMount() {
    if (localStorage.getItem("token") === null) {
      return;
    }
    this.requestData();
  }

  //
  uiSiteDetail() {
    return (
      <table width="100%" border="1">
        <tbody>
          <tr>
            <td>
              <span style={{ fontSize: "10px" }}>Vender/Sub</span>
              <br />
              {this.state.data !== null
                ? this.state.data.cover.surveyAndInstallation
                : ""}
            </td>
          </tr>
          <tr>
            <td>
              <table width="100%">
                <tbody>
                  <tr>
                    <td width="33%">
                      <span style={{ fontSize: "10px" }}>
                        Doc response/Approved
                      </span>
                      <br />
                      AWN
                    </td>
                    <td width="33%">
                      <span style={{ fontSize: "10px" }}>Checked</span>
                      <br />
                      AWN
                    </td>
                    <td width="33%">
                      <span style={{ fontSize: "10px" }}>File</span>
                      <br />
                      PAT Document Smart Site
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td>
              <table width="100%">
                <tbody>
                  <tr>
                    <td width="33%">
                      <span style={{ fontSize: "10px" }}>Project Name</span>
                      <br />
                      {this.state.data !== null
                        ? this.state.data.cover.projectName
                        : ""}
                    </td>
                    <td width="33%">
                      <span style={{ fontSize: "10px" }}>Site Name</span>
                      <br />
                      {this.state.data !== null
                        ? this.state.data.cover.siteNameEn
                        : ""}
                    </td>
                    <td width="33%">
                      <span style={{ fontSize: "10px" }}>Site Code</span>
                      <br />
                      {this.state.data !== null
                        ? this.state.data.cover.siteCode
                        : ""}
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    );
  }

  menu = () => {
    if (auth.allowAdmin()) {
      return <MenuAdmin />;
    } else if (auth.allowAdminSubcon()) {
      return <MenuAdminSubcon />;
    } else if (auth.allowReview()) {
      return <MenuReviewer />;
    } else {
      return <MenuTechnician />;
    }
  };

  //
  view_desktop = () => {
    return (
      <>
        {this.menu()}
        <MessageBox ref={this.messageboxRef} />
        <LoadingBox ref={this.loadingboxRef} />
        <ConfirmBox ref={this.confirmboxRef} />
        <div className="App-body">
          {/* Page 1 : Cover */}
          <div style={{ marginTop: "10px" }}>
            <table width="100%" border="1">
              <tbody>
                <tr>
                  <td align="center">
                    <h3>PROVISIONAL ACCEPTANCE TEST</h3>
                    {this.state.data !== null
                      ? "Doc No: " + this.state.data.documentNumber
                      : ""}
                  </td>
                </tr>
                <tr>
                  <td align="center">
                    <div style={{ margin: "15px" }}>
                      <h4>PAT Document</h4>
                      <h5>
                        {this.state.data !== null &&
                        this.state.data.cover.projectName !== ""
                          ? this.state.data.cover.projectName
                          : ""}
                      </h5>
                      <Image
                        src={
                          this.state.data !== null &&
                          this.state.data.cover.imageCover !== ""
                            ? Config.service.url +
                              "/ssr/image/" +
                              this.state.data.cover.imageCover
                            : "/images/na-vertical.jpg"
                        }
                        height="900"
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div style={{ margin: "15px" }}>
                      <table width="100%">
                        <tbody>
                          <tr>
                            <td colSpan="2">
                              Contact Person :{" "}
                              {this.state.data !== null
                                ? this.state.data.cover.contactPerson
                                : ""}
                            </td>
                          </tr>
                          <tr colSpan="2">
                            <td>
                              Contact Address :{" "}
                              {this.state.data !== null
                                ? this.state.data.cover.address
                                : ""}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Site Name En :{" "}
                              {this.state.data !== null
                                ? this.state.data.cover.siteNameEn
                                : ""}
                            </td>
                            <td>
                              Site Name Th :{" "}
                              {this.state.data !== null
                                ? this.state.data.cover.siteNameTh
                                : ""}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Site Code :{" "}
                              {this.state.data !== null
                                ? this.state.data.cover.siteCode
                                : ""}
                            </td>
                            <td>
                              Region :{" "}
                              {this.state.data !== null
                                ? this.state.data.cover.region
                                : ""}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Zone :{" "}
                              {this.state.data !== null
                                ? this.state.data.cover.zone
                                : ""}
                            </td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>
                              IP :{" "}
                              {this.state.data !== null
                                ? this.state.data.cover.ip
                                : ""}
                            </td>
                            <td>
                              Gateway :{" "}
                              {this.state.data !== null
                                ? this.state.data.cover.gateway
                                : ""}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Subnet Mask :{" "}
                              {this.state.data !== null
                                ? this.state.data.cover.subnetMask
                                : ""}
                            </td>
                            <td>
                              IPRAN :{" "}
                              {this.state.data !== null
                                ? this.state.data.cover.ipRan
                                : ""}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              QR Code Master 1 :{" "}
                              {this.state.data !== null
                                ? this.state.data.cover.qrCodeMaster1
                                : ""}
                            </td>
                            <td>
                              QR Code Slave 2 :{" "}
                              {this.state.data !== null
                                ? this.state.data.cover.qrCodeSlave2
                                : ""}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              QR Code Slave 3 :{" "}
                              {this.state.data !== null
                                ? this.state.data.cover.qrCodeSlave3
                                : ""}
                            </td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>
                              <span
                                style={{ fontWeight: "bold", color: "#ff0000" }}
                              >
                                Remark :
                                {" " +
                                  utils.getValue(
                                    this.state.data?.cover.remark,
                                    "",
                                    ""
                                  )}
                              </span>
                            </td>
                            <td></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div style={{ margin: "15px" }}>
                      <table width="100%">
                        <tbody>
                          <tr>
                            <td colSpan="2">
                              Surver & Installaion by : SSK DECHA ENGINEERING
                              LTD.,PART.
                            </td>
                          </tr>
                          <tr>
                            <td>Telephone No: 092-328-2997</td>
                            <td>
                              Date:{" "}
                              {this.state.data !== null
                                ? this.state.data.cover.date
                                : ""}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Technician Name :{" "}
                              {this.state.data !== null
                                ? this.state.data.cover.technicianName
                                : ""}
                            </td>
                            <td>
                              Telephone No :{" "}
                              {this.state.data !== null
                                ? this.state.data.cover.technicianPhone
                                : ""}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <p style={{ pageBreakBefore: "always" }} />
          {/* Page 3 : 1.1 */}
          <div style={{ marginTop: "10px" }}>
            <table width="100%" border="1">
              <tbody>
                <tr>
                  <td align="center">
                    <h4>1.1 Installation check list</h4>
                  </td>
                </tr>
                <tr>
                  <td>{this.uiSiteDetail()}</td>
                </tr>
                <tr>
                  <td>
                    {/* 
                    
                    */}
                    <div style={{ textAlign: "center" }}>Physical Check</div>
                    <Table striped bordered hover size="sm">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Detail</th>
                          <th>Result</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1</td>
                          <td>
                            ติดตั้ง Master Control ที่บริเวณข้าง Rack
                            และไม่กีดขวางจุดยึดอุปกรณ์อื่น
                          </td>
                          <td>
                            {this.state.data !== null
                              ? this.state.data.content.p111
                              : ""}
                          </td>
                        </tr>
                        <tr>
                          <td>2</td>
                          <td>
                            ติตตั้ง Motion Sensor ที่คานใต้ตู้ Rack , Wiring
                            สายต่อเข้ากล่องและติด Label{" "}
                          </td>
                          <td width="100">
                            {this.state.data !== null
                              ? this.state.data.content.p112
                              : ""}
                          </td>
                        </tr>
                        <tr>
                          <td>3</td>
                          <td>
                            Wiring LAN Cable เสียบเข้า IPRAN เช็ค Port Up
                            พร้อมใช้งานและติด Label
                          </td>
                          <td width="100">
                            {this.state.data !== null
                              ? this.state.data.content.p113
                              : ""}
                          </td>
                        </tr>
                        <tr>
                          <td>4</td>
                          <td>
                            ติตตั้ง Siren ที่ตำแหน่งข้าง Rack , Wiring
                            สายต่อเข้ากล่องและติด Label{" "}
                          </td>
                          <td width="100">
                            {this.state.data !== null
                              ? this.state.data.content.p114
                              : ""}
                          </td>
                        </tr>
                        <tr>
                          <td>5</td>
                          <td>
                            รูปการใช้ JIG ในการติตตั้ง Door lock ตู้ Master ,
                            Wiring สายต่อเข้ากล่องและติด Label{" "}
                          </td>
                          <td width="100">
                            {this.state.data !== null
                              ? this.state.data.content.p115
                              : ""}
                          </td>
                        </tr>
                        <tr>
                          <td>6</td>
                          <td>
                            รูปติดตั้ง Electric Door Lock ที่ขอบประตูตู้ Master
                          </td>
                          <td width="100">
                            {this.state.data !== null
                              ? this.state.data.content.p116
                              : ""}
                          </td>
                        </tr>
                        <tr>
                          <td>7</td>
                          <td>รูปติดตั้งหูช้างที่บานประตู Master</td>
                          <td width="100">
                            {this.state.data !== null
                              ? this.state.data.content.p117
                              : ""}
                          </td>
                        </tr>
                        <tr>
                          <td>8</td>
                          <td>
                            ติดตั้ง DC Power Cable จาก Isolate Batt Wiring
                            มาที่กล่อง Master และติด Label
                          </td>
                          <td width="100">
                            {this.state.data !== null
                              ? this.state.data.content.p118
                              : ""}
                          </td>
                        </tr>
                        <tr>
                          <td>9</td>
                          <td>
                            รูปถ่ายจุดเชื่อมต่อสาย DC Power ที่ CB Batt Isolate
                            และ On Breaker Master
                          </td>
                          <td width="100">
                            {this.state.data !== null
                              ? this.state.data.content.p1110
                              : ""}
                          </td>
                        </tr>
                        <tr>
                          <td>10</td>
                          <td>รูปถ่ายตำแหน่งติดตั้งกล้อง USB</td>
                          <td width="100">
                            {this.state.data !== null
                              ? this.state.data.content.p1111
                              : ""}
                          </td>
                        </tr>
                        <tr>
                          <td>11</td>
                          <td>
                            รูปแสดงจุด Inlet ภายนอกตู้ RS485 Cable (ช่องร้อยสาย
                            RS485 อุดด้วย ซิลิโคน) Master
                          </td>
                          <td width="100">
                            {this.state.data !== null
                              ? this.state.data.content.p1112
                              : ""}
                          </td>
                        </tr>
                        <tr>
                          <td>12</td>
                          <td>
                            ติดตั้ง Slave Control ที่บริเวณข้าง Rack
                            และไม่กีดขวางจุดยึดอุปกรณ์อื่น
                          </td>
                          <td width="100">
                            {this.state.data !== null
                              ? this.state.data.content.p1113
                              : ""}
                          </td>
                        </tr>
                        <tr>
                          <td>13</td>
                          <td>
                            รูปการใช้ JIG ในการติตตั้ง Door lock ตู้ Slave ,
                            Wiring สายต่อเข้ากล่องและติด Label{" "}
                          </td>
                          <td width="100">
                            {this.state.data !== null
                              ? this.state.data.content.p1114
                              : ""}
                          </td>
                        </tr>
                        <tr>
                          <td>14</td>
                          <td>
                            รูปติดตั้ง Electric Door Lock ที่ขอบประตูตู้ Slave
                          </td>
                          <td width="100">
                            {this.state.data !== null
                              ? this.state.data.content.p1115
                              : ""}
                          </td>
                        </tr>
                        <tr>
                          <td>15</td>
                          <td>รูปติดตั้งหูช้างที่บานประตู Slave</td>
                          <td width="100">
                            {this.state.data !== null
                              ? this.state.data.content.p1116
                              : ""}
                          </td>
                        </tr>
                        <tr>
                          <td>16</td>
                          <td>
                            ติดตั้ง DC Power Cable จาก Isolate Batt Wiring
                            มาที่กล่อง Slave และติด Label
                          </td>
                          <td width="100">
                            {this.state.data !== null
                              ? this.state.data.content.p1117
                              : ""}
                          </td>
                        </tr>
                        <tr>
                          <td>17</td>
                          <td>
                            รูปถ่ายจุดเชื่อมต่อสาย DC Power ที่ CB Batt Isolate
                            และ On Breaker Slave
                          </td>
                          <td width="100">
                            {this.state.data !== null
                              ? this.state.data.content.p1118
                              : ""}
                          </td>
                        </tr>
                        <tr>
                          <td>18</td>
                          <td>
                            รูปแสดงจุด Inlet ภายนอกตู้ RS485 Cable (ช่องร้อยสาย
                            RS485 อุดด้วย ซิลิโคน) Slave
                          </td>
                          <td width="100">
                            {this.state.data !== null
                              ? this.state.data.content.p1120
                              : ""}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                    {/* 
                    
                    */}
                    <div style={{ textAlign: "center" }}>Logical Test</div>
                    <Table striped bordered hover size="sm">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Detail</th>
                          <th>Result</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1</td>
                          <td>
                            รูป Capture จาก App Smart Site Setup (Local Test)
                          </td>
                          <td width="100px"></td>
                        </tr>
                        <tr>
                          <td></td>
                          <td>&nbsp;&nbsp;&nbsp;IP Address</td>
                          <td>
                            {this.state.data !== null
                              ? this.state.data.content.p11l11
                              : ""}
                          </td>
                        </tr>
                        <tr>
                          <td></td>
                          <td>&nbsp;&nbsp;&nbsp;การเชื่อมต่อ Sensor</td>
                          <td>
                            {this.state.data !== null
                              ? this.state.data.content.p11l12
                              : ""}
                          </td>
                        </tr>
                        <tr>
                          <td></td>
                          <td>
                            &nbsp;&nbsp;&nbsp;Connection ping to server
                            10.242.15.21 , 10.242.15.22
                          </td>
                          <td>
                            {this.state.data !== null
                              ? this.state.data.content.p11l13
                              : ""}
                          </td>
                        </tr>
                        <tr>
                          <td></td>
                          <td>
                            &nbsp;&nbsp;&nbsp;รูปถ่ายจากกล้อง Smart Site
                            สามารถมองเห็นภาพของคนที่เข้าทำงานได้ชัดเจน
                          </td>
                          <td>
                            {this.state.data !== null
                              ? this.state.data.content.p11l14
                              : ""}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <p style={{ pageBreakBefore: "always" }} />
          {/* Page 5 : 2.1 */}
          <div style={{ marginTop: "10px" }}>
            <table width="100%" border="1">
              <tbody>
                <tr>
                  <td align="center">
                    <h4>2.1 Physical Photo</h4>
                  </td>
                </tr>
                <tr>
                  <td>{this.uiSiteDetail()}</td>
                </tr>
                <tr>
                  <td align="center">
                    <table>
                      <tbody>
                        <tr>
                          <td align="center">
                            <Image
                              src={
                                this.state.data !== null &&
                                this.state.data.content.p211 !== ""
                                  ? Config.service.url +
                                    "/ssr/image/" +
                                    this.state.data.content.p211
                                  : "/images/na-horizontal.jpg"
                              }
                              style={{ width: 400 }}
                              rounded
                              fluid
                            />
                            <br />
                            รูปใช้ JIG ในการเจาะตู้ Master 1
                          </td>
                          <td align="center">
                            <Image
                              src={
                                this.state.data !== null &&
                                this.state.data.content.p212 !== ""
                                  ? Config.service.url +
                                    "/ssr/image/" +
                                    this.state.data.content.p212
                                  : "/images/na-horizontal.jpg"
                              }
                              style={{ width: 400 }}
                              rounded
                              fluid
                            />
                            <br />
                            รูปใช้ JIG ในการเจาะตู้ Slave 2
                          </td>
                          <td align="center">
                            <Image
                              src={utils.getValue(
                                this.state.data?.content.p219,
                                "/images/na-horizontal.jpg",
                                Config.service.url + "/ssr/image/"
                              )}
                              style={{ width: 400 }}
                              rounded
                              fluid
                            />
                            <br />
                            รูปใช้ JIG ในการเจาะตู้ Slave 3
                          </td>
                        </tr>
                        <tr>
                          <td align="center">
                            <Image
                              src={
                                this.state.data !== null &&
                                this.state.data.content.p215 !== ""
                                  ? Config.service.url +
                                    "/ssr/image/" +
                                    this.state.data.content.p215
                                  : "/images/na-horizontal.jpg"
                              }
                              style={{ width: 400 }}
                              rounded
                              fluid
                            />
                            <br />
                            รูป On CB Battery Isolate Master 1
                          </td>
                          <td align="center">
                            <Image
                              src={
                                this.state.data !== null &&
                                this.state.data.content.p216 !== ""
                                  ? Config.service.url +
                                    "/ssr/image/" +
                                    this.state.data.content.p216
                                  : "/images/na-horizontal.jpg"
                              }
                              style={{ width: 400 }}
                              rounded
                              fluid
                            />
                            <br />
                            รูป On CB Battery Isolate Slave 2
                          </td>
                          <td align="center">
                            <Image
                              src={utils.getValue(
                                this.state.data?.content.p2110,
                                "/images/na-horizontal.jpg",
                                Config.service.url + "/ssr/image/"
                              )}
                              style={{ width: 400 }}
                              rounded
                              fluid
                            />
                            <br />
                            รูป On CB Battery Isolate Slave 3
                          </td>
                        </tr>
                        <tr>
                          <td align="center">
                            <Image
                              src={
                                this.state.data !== null &&
                                this.state.data.content.p217 !== ""
                                  ? Config.service.url +
                                    "/ssr/image/" +
                                    this.state.data.content.p217
                                  : "/images/na-horizontal.jpg"
                              }
                              style={{ width: 400 }}
                              rounded
                              fluid
                            />
                            <br />
                            รูป Cable Inlet อุด ซิลิโคน Master 1
                          </td>
                          <td align="center">
                            <Image
                              src={
                                this.state.data !== null &&
                                this.state.data.content.p218 !== ""
                                  ? Config.service.url +
                                    "/ssr/image/" +
                                    this.state.data.content.p218
                                  : "/images/na-horizontal.jpg"
                              }
                              style={{ width: 400 }}
                              rounded
                              fluid
                            />
                            <br />
                            รูป Cable Inlet อุด ซิลิโคน Slave 2
                          </td>
                          <td align="center">
                            <Image
                              src={utils.getValue(
                                this.state.data?.content.p2111,
                                "/images/na-horizontal.jpg",
                                Config.service.url + "/ssr/image/"
                              )}
                              style={{ width: 400 }}
                              rounded
                              fluid
                            />
                            <br />
                            รูป Cable Inlet อุด ซิลิโคน Slave 3
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <p style={{ pageBreakBefore: "always" }} />
          {/* Page 6 : 2.2 page 1 */}
          <div style={{ marginTop: "10px" }}>
            <table width="100%" border="1">
              <tbody>
                <tr>
                  <td align="center">
                    <h4>2.2 Logical Test Photo</h4>
                  </td>
                </tr>
                <tr>
                  <td>{this.uiSiteDetail()}</td>
                </tr>
                <tr>
                  <td align="center">
                    <table>
                      <tbody>
                        <tr>
                          <td align="center">
                            <Image
                              src={
                                this.state.data !== null &&
                                this.state.data.content.p221 !== "" &&
                                this.state.data.content.p221.indexOf(
                                  "images/patExample"
                                ) === -1
                                  ? Config.service.url +
                                    "/ssr/image/" +
                                    this.state.data.content.p221
                                  : "/images/na-vertical.jpg"
                              }
                              style={{ height: 600, width: 400 }}
                              rounded
                              fluid
                            />
                            <br />
                            รูปการตั้งค่า IP Address
                          </td>
                          <td align="center">
                            <Image
                              src={
                                this.state.data !== null &&
                                this.state.data.content.p222 !== "" &&
                                this.state.data.content.p222.indexOf(
                                  "images/patExample"
                                ) === -1
                                  ? Config.service.url +
                                    "/ssr/image/" +
                                    this.state.data.content.p222
                                  : "/images/na-vertical.jpg"
                              }
                              style={{ height: 600, width: 400 }}
                              rounded
                              fluid
                            />
                            <br />
                            รูปถ่ายจากกล้อง Smart Site
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table>
                      <tbody>
                        <tr>
                          <td align="center">
                            <Image
                              src={
                                this.state.data !== null &&
                                this.state.data.content.p225 !== "" &&
                                this.state.data.content.p225.indexOf(
                                  "images/patExample"
                                ) === -1
                                  ? Config.service.url +
                                    "/ssr/image/" +
                                    this.state.data.content.p225
                                  : "/images/na-vertical.jpg"
                              }
                              style={{ height: 600, width: 400 }}
                              rounded
                              fluid
                            />
                            <br />
                            รูป Normal Case : Close
                          </td>
                          <td align="center">
                            <Image
                              src={
                                this.state.data !== null &&
                                this.state.data.content.p226 !== "" &&
                                this.state.data.content.p226.indexOf(
                                  "images/patExample"
                                ) === -1
                                  ? Config.service.url +
                                    "/ssr/image/" +
                                    this.state.data.content.p226
                                  : "/images/na-vertical.jpg"
                              }
                              style={{ height: 600, width: 400 }}
                              rounded
                              fluid
                            />
                            <br />
                            รูป Normal Case : Open
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <p style={{ pageBreakBefore: "always" }} />
          {/* Page 6 : 2.2 page 2 */}
          <div style={{ marginTop: "10px" }}>
            <table width="100%" border="1">
              <tbody>
                <tr>
                  <td align="center">
                    <h4>2.2 Logical Test Photo</h4>
                  </td>
                </tr>
                <tr>
                  <td>{this.uiSiteDetail()}</td>
                </tr>
                <tr>
                  <td align="center">
                    <table>
                      <tbody>
                        <tr>
                          <td align="center">
                            <Image
                              src={utils.getValue(
                                this.state.data?.content.p223,
                                "/images/na-vertical.jpg",
                                Config.service.url + "/ssr/image/"
                              )}
                              style={{ height: 600, width: 400 }}
                              rounded
                              fluid
                            />
                            <br />
                            รูป Emergency Case (เชื่อมต่อ blutooth)
                          </td>
                          <td align="center">
                            <Image
                              src={utils.getValue(
                                this.state.data?.content.p228,
                                "/images/na-vertical.jpg",
                                Config.service.url + "/ssr/image/"
                              )}
                              style={{ height: 600, width: 400 }}
                              rounded
                              fluid
                            />
                            <br />
                            รูป Emer Case : Close
                          </td>
                          <td align="center">
                            <Image
                              src={utils.getValue(
                                this.state.data?.content.p229,
                                "/images/na-vertical.jpg",
                                Config.service.url + "/ssr/image/"
                              )}
                              style={{ height: 600, width: 400 }}
                              rounded
                              fluid
                            />
                            <br />
                            รูป Emer Case : Open
                          </td>
                        </tr>
                        <tr>
                          <td align="center">
                            <Image
                              src={utils.getValue(
                                this.state.data?.content.p2210,
                                "/images/na-vertical.jpg",
                                Config.service.url + "/ssr/image/"
                              )}
                              style={{ height: 600, width: 400 }}
                              rounded
                              fluid
                            />
                            <br />
                            รูปกลอนล็อคตู้ Master 1
                          </td>
                          <td align="center">
                            <Image
                              src={utils.getValue(
                                this.state.data?.content.p2211,
                                "/images/na-vertical.jpg",
                                Config.service.url + "/ssr/image/"
                              )}
                              style={{ height: 600, width: 400 }}
                              rounded
                              fluid
                            />
                            <br />
                            รูปกลอนล็อคตู้ Slave 2
                          </td>
                          <td align="center">
                            <Image
                              src={utils.getValue(
                                this.state.data?.content.p2212,
                                "/images/na-vertical.jpg",
                                Config.service.url + "/ssr/image/"
                              )}
                              style={{ height: 600, width: 400 }}
                              rounded
                              fluid
                            />
                            <br />
                            รูปกลอนล็อคตู้ Slave 3
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <p style={{ pageBreakBefore: "always" }} />
          {/* Page 7 : 2.3 */}
          <div style={{ marginTop: "10px" }}>
            <table width="100%" border="1">
              <tbody>
                <tr>
                  <td align="center">
                    <h4>2.3 Photo Master 1 Before - After</h4>
                  </td>
                </tr>
                <tr>
                  <td>{this.uiSiteDetail()}</td>
                </tr>
                <tr>
                  <td align="center">
                    <table>
                      <tbody>
                        <tr>
                          <td align="center">
                            <Image
                              src={
                                this.state.data !== null &&
                                this.state.data.content.p231 !== ""
                                  ? Config.service.url +
                                    "/ssr/image/" +
                                    this.state.data.content.p231
                                  : "/images/na-horizontal.jpg"
                              }
                              style={{ width: 400, height: 230 }}
                              rounded
                              fluid
                            />
                            <br />
                            Before : จุดติดตั้ง Master Controller
                          </td>
                          <td align="center">
                            <Image
                              src={
                                this.state.data !== null &&
                                this.state.data.content.p232 !== ""
                                  ? Config.service.url +
                                    "/ssr/image/" +
                                    this.state.data.content.p232
                                  : "/images/na-horizontal.jpg"
                              }
                              style={{ width: 400, height: 230 }}
                              rounded
                              fluid
                            />
                            <br />
                            After : ตำแหน่ง Master Controller
                          </td>
                        </tr>
                        <tr>
                          <td align="center">
                            <Image
                              src={
                                this.state.data !== null &&
                                this.state.data.content.p233 !== ""
                                  ? Config.service.url +
                                    "/ssr/image/" +
                                    this.state.data.content.p233
                                  : "/images/na-horizontal.jpg"
                              }
                              style={{ width: 400, height: 230 }}
                              rounded
                              fluid
                            />
                            <br />
                            Before : จุดติดตั้ง Motion Sensor
                          </td>
                          <td align="center">
                            <Image
                              src={
                                this.state.data !== null &&
                                this.state.data.content.p234 !== ""
                                  ? Config.service.url +
                                    "/ssr/image/" +
                                    this.state.data.content.p234
                                  : "/images/na-horizontal.jpg"
                              }
                              style={{ width: 400, height: 230 }}
                              rounded
                              fluid
                            />
                            <br />
                            After : จุดติดตั้ง Motion Sensor
                          </td>
                        </tr>
                        <tr>
                          <td align="center">
                            <Image
                              src={
                                this.state.data !== null &&
                                this.state.data.content.p235 !== ""
                                  ? Config.service.url +
                                    "/ssr/image/" +
                                    this.state.data.content.p235
                                  : "/images/na-horizontal.jpg"
                              }
                              style={{ width: 400, height: 230 }}
                              rounded
                              fluid
                            />
                            <br />
                            Before : ตำแหน่ง IPRAN
                          </td>
                          <td align="center">
                            <Image
                              src={
                                this.state.data !== null &&
                                this.state.data.content.p236 !== ""
                                  ? Config.service.url +
                                    "/ssr/image/" +
                                    this.state.data.content.p236
                                  : "/images/na-horizontal.jpg"
                              }
                              style={{ width: 400, height: 230 }}
                              rounded
                              fluid
                            />
                            <br />
                            After : ตำแหน่ง IPRAN
                          </td>
                        </tr>
                        <tr>
                          <td align="center">
                            <Image
                              src={
                                this.state.data !== null &&
                                this.state.data.content.p237 !== ""
                                  ? Config.service.url +
                                    "/ssr/image/" +
                                    this.state.data.content.p237
                                  : "/images/na-horizontal.jpg"
                              }
                              style={{ width: 400, height: 230 }}
                              rounded
                              fluid
                            />
                            <br />
                            Before : จุดติดตั้ง Siren
                          </td>
                          <td align="center">
                            <Image
                              src={
                                this.state.data !== null &&
                                this.state.data.content.p238 !== ""
                                  ? Config.service.url +
                                    "/ssr/image/" +
                                    this.state.data.content.p238
                                  : "/images/na-horizontal.jpg"
                              }
                              style={{ width: 400, height: 230 }}
                              rounded
                              fluid
                            />
                            <br />
                            After : จุดติดตั้ง Siren
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <p style={{ pageBreakBefore: "always" }} />
          {/* Page 8 : 2.4 */}
          <div style={{ marginTop: "10px" }}>
            <table width="100%" border="1">
              <tbody>
                <tr>
                  <td align="center">
                    <h4>2.4 Photo Master 1 Before - After</h4>
                  </td>
                </tr>
                <tr>
                  <td>{this.uiSiteDetail()}</td>
                </tr>
                <tr>
                  <td align="center">
                    <table>
                      <tbody>
                        <tr>
                          <td align="center">
                            <Image
                              src={
                                this.state.data !== null &&
                                this.state.data.content.p241 !== ""
                                  ? Config.service.url +
                                    "/ssr/image/" +
                                    this.state.data.content.p241
                                  : "/images/na-horizontal.jpg"
                              }
                              style={{ width: 400, height: 230 }}
                              rounded
                              fluid
                            />
                            <br />
                            Before : ตำแหน่ง Master Electric Lock
                          </td>
                          <td align="center">
                            <Image
                              src={
                                this.state.data !== null &&
                                this.state.data.content.p242 !== ""
                                  ? Config.service.url +
                                    "/ssr/image/" +
                                    this.state.data.content.p242
                                  : "/images/na-horizontal.jpg"
                              }
                              style={{ width: 400, height: 230 }}
                              rounded
                              fluid
                            />
                            <br />
                            After : ตำแหน่ง Master Electric Lock
                          </td>
                        </tr>
                        <tr>
                          <td align="center">
                            <Image
                              src={
                                this.state.data !== null &&
                                this.state.data.content.p243 !== ""
                                  ? Config.service.url +
                                    "/ssr/image/" +
                                    this.state.data.content.p243
                                  : "/images/na-horizontal.jpg"
                              }
                              style={{ width: 400, height: 230 }}
                              rounded
                              fluid
                            />
                            <br />
                            Before : ตำแหน่งติดตั้งหูช้าง
                          </td>
                          <td align="center">
                            <Image
                              src={
                                this.state.data !== null &&
                                this.state.data.content.p244 !== ""
                                  ? Config.service.url +
                                    "/ssr/image/" +
                                    this.state.data.content.p244
                                  : "/images/na-horizontal.jpg"
                              }
                              style={{ width: 400, height: 230 }}
                              rounded
                              fluid
                            />
                            <br />
                            After : ตำแหน่งติดตั้งหูช้าง
                          </td>
                        </tr>
                        <tr>
                          <td align="center">
                            <Image
                              src={
                                this.state.data !== null &&
                                this.state.data.content.p245 !== ""
                                  ? Config.service.url +
                                    "/ssr/image/" +
                                    this.state.data.content.p245
                                  : "/images/na-horizontal.jpg"
                              }
                              style={{ width: 400, height: 230 }}
                              rounded
                              fluid
                            />
                            <br />
                            Before : DC Power
                          </td>
                          <td align="center">
                            <Image
                              src={
                                this.state.data !== null &&
                                this.state.data.content.p246 !== ""
                                  ? Config.service.url +
                                    "/ssr/image/" +
                                    this.state.data.content.p246
                                  : "/images/na-horizontal.jpg"
                              }
                              style={{ width: 400, height: 230 }}
                              rounded
                              fluid
                            />
                            <br />
                            After : DC Power
                          </td>
                        </tr>
                        <tr>
                          <td align="center">
                            <Image
                              src={
                                this.state.data !== null &&
                                this.state.data.content.p247 !== ""
                                  ? Config.service.url +
                                    "/ssr/image/" +
                                    this.state.data.content.p247
                                  : "/images/na-horizontal.jpg"
                              }
                              style={{ width: 400, height: 230 }}
                              rounded
                              fluid
                            />
                            <br />
                            Before : USB Camera
                          </td>
                          <td align="center">
                            <Image
                              src={
                                this.state.data !== null &&
                                this.state.data.content.p248 !== ""
                                  ? Config.service.url +
                                    "/ssr/image/" +
                                    this.state.data.content.p248
                                  : "/images/na-horizontal.jpg"
                              }
                              style={{ width: 400, height: 230 }}
                              rounded
                              fluid
                            />
                            <br />
                            After : USB Camera
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <p style={{ pageBreakBefore: "always" }} />
          {/* Page 9 : 2.5 */}
          <div style={{ marginTop: "10px" }}>
            <table width="100%" border="1">
              <tbody>
                <tr>
                  <td align="center">
                    <h4>2.5 Photo Slave 2 Before - After</h4>
                  </td>
                </tr>
                <tr>
                  <td>{this.uiSiteDetail()}</td>
                </tr>
                <tr>
                  <td align="center">
                    <table>
                      <tbody>
                        <tr>
                          <td align="center">
                            <Image
                              src={
                                this.state.data !== null &&
                                this.state.data.content.p251 !== ""
                                  ? Config.service.url +
                                    "/ssr/image/" +
                                    this.state.data.content.p251
                                  : "/images/na-horizontal.jpg"
                              }
                              style={{ width: 400, height: 230 }}
                              rounded
                              fluid
                            />
                            <br />
                            Before : ตำแหน่ง Slave Controller
                          </td>
                          <td align="center">
                            <Image
                              src={
                                this.state.data !== null &&
                                this.state.data.content.p252 !== ""
                                  ? Config.service.url +
                                    "/ssr/image/" +
                                    this.state.data.content.p252
                                  : "/images/na-horizontal.jpg"
                              }
                              style={{ width: 400, height: 230 }}
                              rounded
                              fluid
                            />
                            <br />
                            After : ตำแหน่ง Slave Controller
                          </td>
                        </tr>
                        <tr>
                          <td align="center">
                            <Image
                              src={
                                this.state.data !== null &&
                                this.state.data.content.p253 !== ""
                                  ? Config.service.url +
                                    "/ssr/image/" +
                                    this.state.data.content.p253
                                  : "/images/na-horizontal.jpg"
                              }
                              style={{ width: 400, height: 230 }}
                              rounded
                              fluid
                            />
                            <br />
                            Before : ตำแหน่ง Slave Door Lock
                          </td>
                          <td align="center">
                            <Image
                              src={
                                this.state.data !== null &&
                                this.state.data.content.p254 !== ""
                                  ? Config.service.url +
                                    "/ssr/image/" +
                                    this.state.data.content.p254
                                  : "/images/na-horizontal.jpg"
                              }
                              style={{ width: 400, height: 230 }}
                              rounded
                              fluid
                            />
                            <br />
                            After : ตำแหน่ง Slave Door Lock
                          </td>
                        </tr>
                        <tr>
                          <td align="center">
                            <Image
                              src={
                                this.state.data !== null &&
                                this.state.data.content.p255 !== ""
                                  ? Config.service.url +
                                    "/ssr/image/" +
                                    this.state.data.content.p255
                                  : "/images/na-horizontal.jpg"
                              }
                              style={{ width: 400, height: 230 }}
                              rounded
                              fluid
                            />
                            <br />
                            Before : ตำแหน่งติดตั้งหูช้าง
                          </td>
                          <td align="center">
                            <Image
                              src={
                                this.state.data !== null &&
                                this.state.data.content.p256 !== ""
                                  ? Config.service.url +
                                    "/ssr/image/" +
                                    this.state.data.content.p256
                                  : "/images/na-horizontal.jpg"
                              }
                              style={{ width: 400, height: 230 }}
                              rounded
                              fluid
                            />
                            <br />
                            After : ตำแหน่งติดตั้งหูช้าง
                          </td>
                        </tr>
                        <tr>
                          <td align="center">
                            <Image
                              src={
                                this.state.data !== null &&
                                this.state.data.content.p257 !== ""
                                  ? Config.service.url +
                                    "/ssr/image/" +
                                    this.state.data.content.p257
                                  : "/images/na-horizontal.jpg"
                              }
                              style={{ width: 400, height: 230 }}
                              rounded
                              fluid
                            />
                            <br />
                            Before : DC Power
                          </td>
                          <td align="center">
                            <Image
                              src={
                                this.state.data !== null &&
                                this.state.data.content.p258 !== ""
                                  ? Config.service.url +
                                    "/ssr/image/" +
                                    this.state.data.content.p258
                                  : "/images/na-horizontal.jpg"
                              }
                              style={{ width: 400, height: 230 }}
                              rounded
                              fluid
                            />
                            <br />
                            After : DC Power
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <p style={{ pageBreakBefore: "always" }} />
          {/* Page 10 : 2.6 */}
          <div style={{ marginTop: "10px" }}>
            <table width="100%" border="1">
              <tbody>
                <tr>
                  <td align="center">
                    <h4>2.6 Photo Slave 3 Before - After</h4>
                  </td>
                </tr>
                <tr>
                  <td>{this.uiSiteDetail()}</td>
                </tr>
                <tr>
                  <td align="center">
                    <table>
                      <tbody>
                        <tr>
                          <td align="center">
                            <Image
                              src={
                                this.state.data !== null &&
                                this.state.data.content.p261 !== ""
                                  ? Config.service.url +
                                    "/ssr/image/" +
                                    this.state.data.content.p261
                                  : "/images/na-horizontal.jpg"
                              }
                              style={{ width: 400, height: 230 }}
                              rounded
                              fluid
                            />
                            <br />
                            Before : ตำแหน่ง Slave Controller
                          </td>
                          <td align="center">
                            <Image
                              src={
                                this.state.data !== null &&
                                this.state.data.content.p262 !== ""
                                  ? Config.service.url +
                                    "/ssr/image/" +
                                    this.state.data.content.p262
                                  : "/images/na-horizontal.jpg"
                              }
                              style={{ width: 400, height: 230 }}
                              rounded
                              fluid
                            />
                            <br />
                            After : ตำแหน่ง Slave Controller
                          </td>
                        </tr>
                        <tr>
                          <td align="center">
                            <Image
                              src={
                                this.state.data !== null &&
                                this.state.data.content.p263 !== ""
                                  ? Config.service.url +
                                    "/ssr/image/" +
                                    this.state.data.content.p263
                                  : "/images/na-horizontal.jpg"
                              }
                              style={{ width: 400, height: 230 }}
                              rounded
                              fluid
                            />
                            <br />
                            Before : ตำแหน่ง Slave Door Lock
                          </td>
                          <td align="center">
                            <Image
                              src={
                                this.state.data !== null &&
                                this.state.data.content.p264 !== ""
                                  ? Config.service.url +
                                    "/ssr/image/" +
                                    this.state.data.content.p264
                                  : "/images/na-horizontal.jpg"
                              }
                              style={{ width: 400, height: 230 }}
                              rounded
                              fluid
                            />
                            <br />
                            After : ตำแหน่ง Slave Door Lock
                          </td>
                        </tr>
                        <tr>
                          <td align="center">
                            <Image
                              src={
                                this.state.data !== null &&
                                this.state.data.content.p265 !== ""
                                  ? Config.service.url +
                                    "/ssr/image/" +
                                    this.state.data.content.p265
                                  : "/images/na-horizontal.jpg"
                              }
                              style={{ width: 400, height: 230 }}
                              rounded
                              fluid
                            />
                            <br />
                            Before : ตำแหน่งติดตั้งหูช้าง
                          </td>
                          <td align="center">
                            <Image
                              src={
                                this.state.data !== null &&
                                this.state.data.content.p266 !== ""
                                  ? Config.service.url +
                                    "/ssr/image/" +
                                    this.state.data.content.p266
                                  : "/images/na-horizontal.jpg"
                              }
                              style={{ width: 400, height: 230 }}
                              rounded
                              fluid
                            />
                            <br />
                            After : ตำแหน่งติดตั้งหูช้าง
                          </td>
                        </tr>
                        <tr>
                          <td align="center">
                            <Image
                              src={
                                this.state.data !== null &&
                                this.state.data.content.p267 !== ""
                                  ? Config.service.url +
                                    "/ssr/image/" +
                                    this.state.data.content.p267
                                  : "/images/na-horizontal.jpg"
                              }
                              style={{ width: 400, height: 230 }}
                              rounded
                              fluid
                            />
                            <br />
                            Before : DC Power
                          </td>
                          <td align="center">
                            <Image
                              src={
                                this.state.data !== null &&
                                this.state.data.content.p268 !== ""
                                  ? Config.service.url +
                                    "/ssr/image/" +
                                    this.state.data.content.p268
                                  : "/images/na-horizontal.jpg"
                              }
                              style={{ width: 400, height: 230 }}
                              rounded
                              fluid
                            />
                            <br />
                            After : DC Power
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <p style={{ pageBreakBefore: "always" }} />
          {/* Page 10 : 2.7 */}
          <div style={{ marginTop: "10px" }}>
            <table width="100%" border="1">
              <tbody>
                <tr>
                  <td align="center">
                    <h4>2.7 Photo Master - Slave Install Completed</h4>
                  </td>
                </tr>
                <tr>
                  <td>{this.uiSiteDetail()}</td>
                </tr>
                <tr>
                  <td align="center">
                    <div style={{ marginTop: 5 }}>
                      <table>
                        <tbody>
                          <tr>
                            <td align="center" colSpan="3">
                              <Image
                                src={
                                  this.state.data !== null &&
                                  this.state.data.content.p271 !== undefined &&
                                  this.state.data.content.p271 !== ""
                                    ? Config.service.url +
                                      "/ssr/image/" +
                                      this.state.data.content.p271
                                    : "/images/na-vertical.jpg"
                                }
                                style={{ width: 300 }}
                                rounded
                                fluid
                              />
                            </td>
                          </tr>
                          <tr>
                            <td align="center" colSpan="3">
                              Smart Site Config &amp; On service
                            </td>
                          </tr>
                          {/* <tr>
                                                        <td align="center" colSpan="2">
                                                            <div className="hidden-print" style={{margin: 5}}>
                                                                <Button variant="danger" onClick={() => this.onDelete('p271')}>Delete</Button>
                                                            </div>
                                                        </td>
                                                    </tr> */}
                          <tr>
                            <td align="center">
                              <Image
                                src={
                                  this.state.data !== null &&
                                  this.state.data.content.p272 !== undefined &&
                                  this.state.data.content.p272 !== ""
                                    ? Config.service.url +
                                      "/ssr/image/" +
                                      this.state.data.content.p272
                                    : "/images/na-vertical.jpg"
                                }
                                style={{ width: 300 }}
                                rounded
                                fluid
                              />
                            </td>
                            <td align="center">
                              <Image
                                src={
                                  this.state.data !== null &&
                                  this.state.data.content.p273 !== undefined &&
                                  this.state.data.content.p273 !== ""
                                    ? Config.service.url +
                                      "/ssr/image/" +
                                      this.state.data.content.p273
                                    : "/images/na-vertical.jpg"
                                }
                                style={{ width: 300 }}
                                rounded
                                fluid
                              />
                            </td>
                            <td align="center">
                              <Image
                                src={
                                  this.state.data !== null &&
                                  this.state.data.content.p274 !== undefined &&
                                  this.state.data.content.p274 !== ""
                                    ? Config.service.url +
                                      "/ssr/image/" +
                                      this.state.data.content.p274
                                    : "/images/na-vertical.jpg"
                                }
                                style={{ width: 300 }}
                                rounded
                                fluid
                              />
                            </td>
                          </tr>
                          <tr>
                            <td align="center">Master 1 Installed</td>
                            <td align="center">Slave 2 Installed</td>
                            <td align="center">Slave 3 Installed</td>
                          </tr>
                          {/* <tr>
                            <td align="center">
                              <div className="hidden-print" style={{margin: 5}}>
                                                                <Button variant="danger" onClick={() => this.onDelete('p272')}>Delete</Button>
                                                            </div>
                            </td>
                            <td align="center">
                              <div
                                className="hidden-print"
                                style={{ margin: 5 }}
                              >
                                <Button
                                  variant="danger"
                                  onClick={() => this.onDelete("p273")}
                                >
                                  Delete
                                </Button>
                              </div>
                            </td>
                          </tr> */}
                        </tbody>
                      </table>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <p style={{ pageBreakBefore: "always" }} />
          {/* Page 12 : 2.8 */}
          <div style={{ marginTop: "10px" }}>
            <table width="100%" border="1">
              <tbody>
                <tr>
                  <td align="center">
                    <h4>2.8 Photo Key</h4>
                  </td>
                </tr>
                <tr>
                  <td>{this.uiSiteDetail()}</td>
                </tr>
                <tr>
                  <td align="center">
                    <div style={{ marginTop: 5 }}>
                      <table>
                        <tbody>
                          <tr>
                            <td align="center">
                              <Image
                                src={
                                  this.state.data !== null &&
                                  this.state.data.content.p281 !== undefined &&
                                  this.state.data.content.p281 !== ""
                                    ? Config.service.url +
                                      "/ssr/image/" +
                                      this.state.data.content.p281
                                    : "/images/na-horizontal.jpg"
                                }
                                style={{ width: 400, height: 230 }}
                                rounded
                                fluid
                              />
                            </td>
                            <td align="center">
                              <Image
                                src={
                                  this.state.data !== null &&
                                  this.state.data.content.p282 !== undefined &&
                                  this.state.data.content.p282 !== ""
                                    ? Config.service.url +
                                      "/ssr/image/" +
                                      this.state.data.content.p282
                                    : "/images/na-horizontal.jpg"
                                }
                                style={{ width: 400, height: 230 }}
                                rounded
                                fluid
                              />
                            </td>
                          </tr>
                          <tr>
                            <td align="center">
                              Lock C1
                              <br />
                              {utils.getValue(
                                this.state.data?.content.p281Text,
                                "",
                                ""
                              )}
                            </td>
                            <td align="center">
                              BATT C1
                              <br />
                              {utils.getValue(
                                this.state.data?.content.p282Text,
                                "",
                                ""
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td align="center">
                              <Image
                                src={
                                  this.state.data !== null &&
                                  this.state.data.content.p283 !== undefined &&
                                  this.state.data.content.p283 !== ""
                                    ? Config.service.url +
                                      "/ssr/image/" +
                                      this.state.data.content.p283
                                    : "/images/na-horizontal.jpg"
                                }
                                style={{ width: 400, height: 230 }}
                                rounded
                                fluid
                              />
                            </td>
                            <td align="center">
                              <Image
                                src={
                                  this.state.data !== null &&
                                  this.state.data.content.p284 !== undefined &&
                                  this.state.data.content.p284 !== ""
                                    ? Config.service.url +
                                      "/ssr/image/" +
                                      this.state.data.content.p284
                                    : "/images/na-horizontal.jpg"
                                }
                                style={{ width: 400, height: 230 }}
                                rounded
                                fluid
                              />
                            </td>
                          </tr>
                          <tr>
                            <td align="center">
                              Lock C2
                              <br />
                              {utils.getValue(
                                this.state.data?.content.p283Text,
                                "",
                                ""
                              )}
                            </td>
                            <td align="center">
                              BATT C2
                              <br />
                              {utils.getValue(
                                this.state.data?.content.p284Text,
                                "",
                                ""
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td align="center">
                              <Image
                                src={
                                  this.state.data !== null &&
                                  this.state.data.content.p285 !== undefined &&
                                  this.state.data.content.p285 !== ""
                                    ? Config.service.url +
                                      "/ssr/image/" +
                                      this.state.data.content.p285
                                    : "/images/na-horizontal.jpg"
                                }
                                style={{ width: 400, height: 230 }}
                                rounded
                                fluid
                              />
                            </td>
                            <td align="center">
                              <Image
                                src={
                                  this.state.data !== null &&
                                  this.state.data.content.p286 !== undefined &&
                                  this.state.data.content.p286 !== ""
                                    ? Config.service.url +
                                      "/ssr/image/" +
                                      this.state.data.content.p286
                                    : "/images/na-horizontal.jpg"
                                }
                                style={{ width: 400, height: 230 }}
                                rounded
                                fluid
                              />
                            </td>
                          </tr>
                          <tr>
                            <td align="center">
                              Lock C3
                              <br />
                              {utils.getValue(
                                this.state.data?.content.p285Text,
                                "",
                                ""
                              )}
                            </td>
                            <td align="center">
                              BATT C3
                              <br />
                              {utils.getValue(
                                this.state.data?.content.p286Text,
                                "",
                                ""
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td align="center">
                              <Image
                                src={
                                  this.state.data !== null &&
                                  this.state.data.content.p287 !== undefined &&
                                  this.state.data.content.p287 !== ""
                                    ? Config.service.url +
                                      "/ssr/image/" +
                                      this.state.data.content.p287
                                    : "/images/na-horizontal.jpg"
                                }
                                style={{ width: 400, height: 230 }}
                                rounded
                                fluid
                              />
                            </td>
                          </tr>
                          <tr>
                            <td align="center">
                              รั้ว
                              <br />
                              {utils.getValue(
                                this.state.data?.content.p287Text,
                                "",
                                ""
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
  };

  //
  render() {
    if (localStorage.getItem("token") === null) {
      return <Redirect push to="/login" />;
    }

    if (this.state.redirect) {
      return <Redirect push to={this.state.redirect} />;
    }

    return this.view_desktop();
  }
}

export default withRouter(PATAdminReviewerShow);

import React, { Component } from "react";
import { Redirect, withRouter } from "react-router-dom";
import { Form } from "react-bootstrap";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import { Tabs } from "react-bootstrap";
import { Tab } from "react-bootstrap";
import { Card } from "react-bootstrap";
import { Table } from "react-bootstrap";
import { Button } from "react-bootstrap";

import Axios from "axios";

import MenuTechnician from "../components/menu/menuTechnician";
import MenuAdmin from "../components/menu/menuAdmin";
import MenuAdminSubcon from "../components/menu/menuAdminSubcon";
import MenuReviewer from "../components/menu/menuReviewer";

import SSRDesignMasterImage from "../components/image/SSRDesignMasterImage";
import SSRDesignSlaveImage from "../components/image/SSRDesignSlaveImage";
import ImageUploadPreview from "../components/image/ImageUploadPreview";
import ImageQRUploadPreview from "../components/image/ImageQRUploadPreview";
import ImageUploadPreviewWithRect from "../components/image/ImageUploadPreviewWithRect";

import MessageBox from "../components/messagebox/messagebox";
import LoadingBox from "../components/messagebox/loadingbox";
import ConfirmBox from "../components/messagebox/confirmbox";

import { base64ImageToBlob, getValue } from "../helper/utilityHelper";
import auth from "../helper/authHelper";

var Moment = require("moment");
var Config = require("../config/config.json");

class SSRTechnicianEdit extends Component {
  //
  constructor(props) {
    // Base contructor.
    super(props);
    // Ref.
    this.messageboxRef = React.createRef();
    this.loadingboxRef = React.createRef();
    this.confirmboxRef = React.createRef();
    //
    this.imageCoverRef = React.createRef();
    this.imageQRCodeMaster1Ref = React.createRef();
    this.imageQRCodeSlave2Ref = React.createRef();
    this.imageQRCodeSlave3Ref = React.createRef();
    //
    this.imageVisualCheck11Ref = React.createRef();
    this.imageVisualCheck12Ref = React.createRef();
    this.imageVisualCheck13Ref = React.createRef();
    this.imageVisualCheck14Ref = React.createRef();
    //
    this.imageVisualCheck21Ref = React.createRef();
    this.imageVisualCheck22Ref = React.createRef();
    this.imageVisualCheck23Ref = React.createRef();
    this.imageVisualCheck24Ref = React.createRef();
    //
    this.imageVisualCheck31Ref = React.createRef();
    this.imageVisualCheck32Ref = React.createRef();
    this.imageVisualCheck33Ref = React.createRef();
    this.imageVisualCheck34Ref = React.createRef();
    //
    this.imageDesignMaster1Ref = React.createRef();
    this.imageM11Ref = React.createRef();
    this.imageM12Ref = React.createRef();
    this.imageM13Ref = React.createRef();
    this.imageM14Ref = React.createRef();
    this.imageM15Ref = React.createRef();
    this.imageM16Ref = React.createRef();
    this.imageM17Ref = React.createRef();
    this.imageM18Ref = React.createRef();
    this.imageM19Ref = React.createRef();
    this.imageM110Ref = React.createRef();
    this.imageM111Ref = React.createRef();
    //
    this.imageDesignSlave2Ref = React.createRef();
    this.imageS21Ref = React.createRef();
    this.imageS22Ref = React.createRef();
    this.imageS23Ref = React.createRef();
    this.imageS24Ref = React.createRef();
    this.imageS25Ref = React.createRef();
    this.imageS26Ref = React.createRef();
    this.imageS27Ref = React.createRef();
    //
    this.imageS31Ref = React.createRef();
    this.imageS32Ref = React.createRef();
    this.imageS33Ref = React.createRef();
    this.imageS34Ref = React.createRef();
    this.imageS35Ref = React.createRef();
    this.imageS36Ref = React.createRef();
    this.imageS37Ref = React.createRef();
    // State
    this.state = {
      redirect: "",
      allowSave: true,
      data: null,
      owners: null
    };
  }

  //
  onCoverChange = e => {
    const { name, value } = e.target;
    let data = { ...this.state.data };
    data.cover[name] = value;
    this.setState({ data: data });
  };

  //
  onCheckChange = e => {
    const { name, value } = e.target;
    let data = { ...this.state.data };
    data.check[name] = value;
    this.setState({ data: data });
  };

  //
  onCoverChangeToLocalStorage = e => {
    const { name, value } = e.target;
    localStorage.setItem(name, value);
    let data = { ...this.state.data };
    data.cover[name] = value;
    this.setState({ data: data });
  };

  //
  onCoverSiteCodeLookup = e => {
    let value = "";
    if (e !== null) {
      value = e.target.value;
    } else {
      value = this.state.cover.siteCode;
    }

    if (value !== null && value.length >= 5) {
      Axios.get(Config.service.url + "/baseStation/getBySiteCode/" + value, {
        headers: {
          Authorization: localStorage.getItem("token")
        }
      })
        .then(res => {
          console.log(res.data);
          if (res.data.success === false) {
            this.messageboxRef.current.onShow("Error", res.data.message);
            return;
          }
          //
          if (res.data.data.length === 0) {
            //
            let cover = { ...this.state.cover };
            cover.siteCode = value;
            cover.region = "";
            cover.siteNameEn = "";
            cover.siteNameTh = "";
            cover.zone = "";
            cover.ip = "";
            cover.gateway = "";
            cover.subnetMask = "";
            cover.ipRan = "";
            //
            this.setState({ cover: cover });
            localStorage.setItem("siteCode", value);
            return;
          }
          //
          let data = { ...this.state.data };
          data.cover.siteCode = res.data.data[0].siteCode;
          data.cover.region = res.data.data[0].region;
          data.cover.siteNameEn = res.data.data[0].siteNameEn;
          data.cover.siteNameTh = res.data.data[0].siteNameTh;
          data.cover.zone = res.data.data[0].zone;
          data.cover.ip = res.data.data[0].networkIP;
          data.cover.gateway = res.data.data[0].networkGateway;
          data.cover.subnetMask = res.data.data[0].networkSubnet;
          data.cover.ipRan = res.data.data[0].networkIPRANPort;
          //
          this.setState({ data: data });
          localStorage.setItem("siteCode", res.data.data[0].siteCode);
          // Check siteCode is created in database.
          // Need to check, Does the site code document already created?
          Axios.get(Config.service.url + "/ssr/isCreated/" + value, {
            headers: {
              Authorization: localStorage.getItem("token")
            }
          })
            .then(res => {
              console.log(res.data);
              if (res.data.success === true) {
                if (this.state.data._id !== res.data.data._id) {
                  this.messageboxRef.current.onShow(
                    "Error",
                    "Duplicate site code, you cannot save this document."
                  );
                  this.setState({ allowSave: false });
                } else {
                  this.setState({ allowSave: true });
                }
              } else {
                this.setState({ allowSave: true });
              }
            })
            .catch(err => {
              console.log(err);
            })
            .finally(() => {});
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {});
    }
  };

  //
  onSubmit = () => {
    // Add gurad
    if (
      this.state.data.cover.siteCode.length === 0 &&
      this.state.data.cover.region.length === 0 &&
      this.state.data.cover.siteNameEn.length === 0 &&
      this.state.data.cover.siteNameTh.length === 0 &&
      this.state.data.cover.zone.length === 0
    ) {
      this.messageboxRef.current.onShow(
        "Error",
        "Site code, region, site name, zone does not fill"
      );
      return;
    }

    // Show loading screen
    if (this.loadingboxRef.current) this.loadingboxRef.current.onShow();
    //
    let data = new FormData();
    // QR
    // eslint-disable-next-line react/no-direct-mutation-state
    this.state.data.cover.qrCodeMaster1 =
      this.imageQRCodeMaster1Ref.current.getState().qrCode;
    // eslint-disable-next-line react/no-direct-mutation-state
    this.state.data.cover.qrCodeSlave2 =
      this.imageQRCodeSlave2Ref.current.getState().qrCode;
    // eslint-disable-next-line react/no-direct-mutation-state
    this.state.data.cover.qrCodeSlave3 =
      this.imageQRCodeSlave3Ref.current.getState().qrCode;
    //
    data.append("data", JSON.stringify(this.state.data));
    //
    data.append(
      "designMasterMarkers",
      JSON.stringify(this.imageDesignMaster1Ref.current.getState().markers)
    );
    data.append(
      "designSlaveMarkers",
      JSON.stringify(this.imageDesignSlave2Ref.current.getState().markers)
    );
    //
    data.append(
      "designMasterMarkersM11",
      JSON.stringify(this.imageM11Ref.current.getState().markers)
    );
    data.append(
      "designMasterMarkersM12",
      JSON.stringify(this.imageM12Ref.current.getState().markers)
    );
    data.append(
      "designMasterMarkersM13",
      JSON.stringify(this.imageM13Ref.current.getState().markers)
    );
    data.append(
      "designMasterMarkersM14",
      JSON.stringify(this.imageM14Ref.current.getState().markers)
    );
    data.append(
      "designMasterMarkersM15",
      JSON.stringify(this.imageM15Ref.current.getState().markers)
    );
    data.append(
      "designMasterMarkersM16",
      JSON.stringify(this.imageM16Ref.current.getState().markers)
    );
    data.append(
      "designMasterMarkersM17",
      JSON.stringify(this.imageM17Ref.current.getState().markers)
    );
    data.append(
      "designMasterMarkersM18",
      JSON.stringify(this.imageM18Ref.current.getState().markers)
    );
    data.append(
      "designMasterMarkersM19",
      JSON.stringify(this.imageM19Ref.current.getState().markers)
    );
    data.append(
      "designMasterMarkersM110",
      JSON.stringify(this.imageM110Ref.current.getState().markers)
    );
    data.append(
      "designMasterMarkersM111",
      JSON.stringify(this.imageM111Ref.current.getState().markers)
    );
    //
    data.append(
      "designSlaveMarkersS21",
      JSON.stringify(this.imageS21Ref.current.getState().markers)
    );
    data.append(
      "designSlaveMarkersS22",
      JSON.stringify(this.imageS22Ref.current.getState().markers)
    );
    data.append(
      "designSlaveMarkersS23",
      JSON.stringify(this.imageS23Ref.current.getState().markers)
    );
    data.append(
      "designSlaveMarkersS24",
      JSON.stringify(this.imageS24Ref.current.getState().markers)
    );
    data.append(
      "designSlaveMarkersS25",
      JSON.stringify(this.imageS25Ref.current.getState().markers)
    );
    data.append(
      "designSlaveMarkersS26",
      JSON.stringify(this.imageS26Ref.current.getState().markers)
    );
    data.append(
      "designSlaveMarkersS27",
      JSON.stringify(this.imageS27Ref.current.getState().markers)
    );
    //
    data.append(
      "designSlaveMarkersS31",
      JSON.stringify(this.imageS31Ref.current.getState().markers)
    );
    data.append(
      "designSlaveMarkersS32",
      JSON.stringify(this.imageS32Ref.current.getState().markers)
    );
    data.append(
      "designSlaveMarkersS33",
      JSON.stringify(this.imageS33Ref.current.getState().markers)
    );
    data.append(
      "designSlaveMarkersS34",
      JSON.stringify(this.imageS34Ref.current.getState().markers)
    );
    data.append(
      "designSlaveMarkersS35",
      JSON.stringify(this.imageS35Ref.current.getState().markers)
    );
    data.append(
      "designSlaveMarkersS36",
      JSON.stringify(this.imageS36Ref.current.getState().markers)
    );
    data.append(
      "designSlaveMarkersS37",
      JSON.stringify(this.imageS37Ref.current.getState().markers)
    );
    // Cover
    data.append("imageCover", this.imageCoverRef.current.getState().file);
    // QR
    if (this.imageQRCodeMaster1Ref.current.getState().file.length > 0)
      data.append(
        "imageQRCodeMaster1",
        base64ImageToBlob(this.imageQRCodeMaster1Ref.current.getState().file)
      );
    if (this.imageQRCodeSlave2Ref.current.getState().file.length > 0)
      data.append(
        "imageQRCodeSlave2",
        base64ImageToBlob(this.imageQRCodeSlave2Ref.current.getState().file)
      );
    if (this.imageQRCodeSlave3Ref.current.getState().file.length > 0)
      data.append(
        "imageQRCodeSlave3",
        base64ImageToBlob(this.imageQRCodeSlave3Ref.current.getState().file)
      );
    // Images
    if (this.imageVisualCheck11Ref.current !== null)
      data.append(
        "imageVisualCheck11",
        this.imageVisualCheck11Ref.current.getState().file
      );
    if (this.imageVisualCheck12Ref.current !== null)
      data.append(
        "imageVisualCheck12",
        this.imageVisualCheck12Ref.current.getState().file
      );
    if (this.imageVisualCheck13Ref.current !== null)
      data.append(
        "imageVisualCheck13",
        this.imageVisualCheck13Ref.current.getState().file
      );
    if (this.imageVisualCheck14Ref.current !== null)
      data.append(
        "imageVisualCheck14",
        this.imageVisualCheck14Ref.current.getState().file
      );
    //
    if (this.imageVisualCheck21Ref.current !== null)
      data.append(
        "imageVisualCheck21",
        this.imageVisualCheck21Ref.current.getState().file
      );
    if (this.imageVisualCheck22Ref.current !== null)
      data.append(
        "imageVisualCheck22",
        this.imageVisualCheck22Ref.current.getState().file
      );
    if (this.imageVisualCheck23Ref.current !== null)
      data.append(
        "imageVisualCheck23",
        this.imageVisualCheck23Ref.current.getState().file
      );
    if (this.imageVisualCheck24Ref.current !== null)
      data.append(
        "imageVisualCheck24",
        this.imageVisualCheck24Ref.current.getState().file
      );
    //
    if (this.imageVisualCheck31Ref.current !== null)
      data.append(
        "imageVisualCheck31",
        this.imageVisualCheck31Ref.current.getState().file
      );
    if (this.imageVisualCheck32Ref.current !== null)
      data.append(
        "imageVisualCheck32",
        this.imageVisualCheck32Ref.current.getState().file
      );
    if (this.imageVisualCheck33Ref.current !== null)
      data.append(
        "imageVisualCheck33",
        this.imageVisualCheck33Ref.current.getState().file
      );
    if (this.imageVisualCheck34Ref.current !== null)
      data.append(
        "imageVisualCheck34",
        this.imageVisualCheck34Ref.current.getState().file
      );
    //
    data.append(
      "imageDesignMaster",
      this.imageDesignMaster1Ref.current.getState().file
    );
    data.append(
      "imageDesignMaster2",
      this.imageDesignMaster1Ref.current.getState().file2
    );
    //
    data.append("imageM11", this.imageM11Ref.current.getState().file);
    data.append("imageM12", this.imageM12Ref.current.getState().file);
    data.append("imageM13", this.imageM13Ref.current.getState().file);
    data.append("imageM14", this.imageM14Ref.current.getState().file);
    data.append("imageM15", this.imageM15Ref.current.getState().file);
    data.append("imageM16", this.imageM16Ref.current.getState().file);
    data.append("imageM17", this.imageM17Ref.current.getState().file);
    data.append("imageM18", this.imageM18Ref.current.getState().file);
    data.append("imageM19", this.imageM19Ref.current.getState().file);
    data.append("imageM110", this.imageM110Ref.current.getState().file);
    data.append("imageM111", this.imageM111Ref.current.getState().file);
    //
    data.append(
      "imageDesignSlave",
      this.imageDesignSlave2Ref.current.getState().file
    );
    //
    data.append("imageS21", this.imageS21Ref.current.getState().file);
    data.append("imageS22", this.imageS22Ref.current.getState().file);
    data.append("imageS23", this.imageS23Ref.current.getState().file);
    data.append("imageS24", this.imageS24Ref.current.getState().file);
    data.append("imageS25", this.imageS25Ref.current.getState().file);
    data.append("imageS26", this.imageS26Ref.current.getState().file);
    data.append("imageS27", this.imageS27Ref.current.getState().file);
    //
    data.append("imageS31", this.imageS31Ref.current.getState().file);
    data.append("imageS32", this.imageS32Ref.current.getState().file);
    data.append("imageS33", this.imageS33Ref.current.getState().file);
    data.append("imageS34", this.imageS34Ref.current.getState().file);
    data.append("imageS35", this.imageS35Ref.current.getState().file);
    data.append("imageS36", this.imageS36Ref.current.getState().file);
    data.append("imageS37", this.imageS37Ref.current.getState().file);

    //
    Axios.put(Config.service.url + "/ssr/update/" + this.state.data._id, data, {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    })
      .then(res => {
        if (res.data.success === false) {
          this.messageboxRef.current.onShow("Error", res.data.message);
          if (this.loadingboxRef.current) this.loadingboxRef.current.onHide();
          return;
        }
        if (this.loadingboxRef.current) this.loadingboxRef.current.onHide();
        this.messageboxRef.current.onShow(
          "Success",
          "The data have been updated."
        );
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {});
  };

  //
  onTabSelect = key => {
    if (key === "master1") {
      setTimeout(() => {
        this.imageDesignMaster1Ref.current.refresh(
          this.state.data.master1.imageDesignMaster,
          this.state.data.master1imageDesignMaster2,
          this.state.data.master1.markers
        );
        this.imageM11Ref.current.refresh(
          this.state.data.master1.imageM11,
          this.state.data.master1.markersM11
        );
        this.imageM12Ref.current.refresh(
          this.state.data.master1.imageM12,
          this.state.data.master1.markersM12
        );
        this.imageM13Ref.current.refresh(
          this.state.data.master1.imageM13,
          this.state.data.master1.markersM13
        );
        this.imageM14Ref.current.refresh(
          this.state.data.master1.imageM14,
          this.state.data.master1.markersM14
        );
        this.imageM15Ref.current.refresh(
          this.state.data.master1.imageM15,
          this.state.data.master1.markersM15
        );
        this.imageM16Ref.current.refresh(
          this.state.data.master1.imageM16,
          this.state.data.master1.markersM16
        );
        this.imageM17Ref.current.refresh(
          this.state.data.master1.imageM17,
          this.state.data.master1.markersM17
        );
        this.imageM18Ref.current.refresh(
          this.state.data.master1.imageM18,
          this.state.data.master1.markersM18
        );
        this.imageM19Ref.current.refresh(
          this.state.data.master1.imageM19,
          this.state.data.master1.markersM19
        );
        this.imageM110Ref.current.refresh(
          this.state.data.master1.imageM110,
          this.state.data.master1.markersM110
        );
        this.imageM111Ref.current.refresh(
          this.state.data.master1.imageM111,
          this.state.data.master1.markersM111
        );
      }, 50);
    } else if (key === "slave2") {
      setTimeout(() => {
        this.imageDesignSlave2Ref.current.refresh(
          this.state.data.slave2.imageDesignSlave,
          this.state.data.slave2.markers
        );
        this.imageS21Ref.current.refresh(
          this.state.data.slave2.imageS21,
          this.state.data.slave2.markersS21
        );
        this.imageS22Ref.current.refresh(
          this.state.data.slave2.imageS22,
          this.state.data.slave2.markersS22
        );
        this.imageS23Ref.current.refresh(
          this.state.data.slave2.imageS23,
          this.state.data.slave2.markersS23
        );
        this.imageS24Ref.current.refresh(
          this.state.data.slave2.imageS24,
          this.state.data.slave2.markersS24
        );
        this.imageS25Ref.current.refresh(
          this.state.data.slave2.imageS25,
          this.state.data.slave2.markersS25
        );
        this.imageS26Ref.current.refresh(
          this.state.data.slave2.imageS26,
          this.state.data.slave2.markersS26
        );
        this.imageS27Ref.current.refresh(
          this.state.data.slave2.imageS27,
          this.state.data.slave2.markersS27
        );
      }, 50);
    } else if (key === "slave3") {
      setTimeout(() => {
        this.imageS31Ref.current.refresh(
          this.state.data.slave3.imageS31,
          this.state.data.slave3.markersS31
        );
        this.imageS32Ref.current.refresh(
          this.state.data.slave3.imageS32,
          this.state.data.slave3.markersS32
        );
        this.imageS33Ref.current.refresh(
          this.state.data.slave3.imageS33,
          this.state.data.slave3.markersS33
        );
        this.imageS34Ref.current.refresh(
          this.state.data.slave3.imageS34,
          this.state.data.slave3.markersS34
        );
        this.imageS35Ref.current.refresh(
          this.state.data.slave3.imageS35,
          this.state.data.slave3.markersS35
        );
        this.imageS36Ref.current.refresh(
          this.state.data.slave3.imageS36,
          this.state.data.slave3.markersS36
        );
        this.imageS37Ref.current.refresh(
          this.state.data.slave3.imageS37,
          this.state.data.slave3.markersS37
        );
      }, 50);
    }
  };

  //
  requestData = () => {
    if (this.loadingboxRef.current) this.loadingboxRef.current.onShow();
    //
    Axios.get(Config.service.url + "/user/getOwner", {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    }).then(res => {
      this.setState({ owners: res.data.data });
    });
    //
    let params = new URLSearchParams(window.location.search);
    let id = params.get("id");
    Axios.get(Config.service.url + "/ssr/get/" + id, {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    })
      .then(res => {
        if (res.data.success === false) {
          this.messageboxRef.current.onShow("Error", res.data.message);
          return;
        }

        // Fixed bug, if slave2.markersS27 or slave3.markersS37 is not here then init them
        console.log(
          "res.data.data.slave2.markersS27 = ",
          res.data.data.slave2.markersS27
        );
        console.log(
          "res.data.data.slave3.markersS37 = ",
          res.data.data.slave3.markersS37
        );

        if (res.data.data.slave2.markersS27 === undefined) {
          res.data.data.slave2.markersS27 = {
            r1: {
              x: 50.0,
              y: 50.0
            }
          };
        }

        if (res.data.data.slave3.markersS37 === undefined) {
          res.data.data.slave3.markersS37 = {
            r1: {
              x: 50.0,
              y: 50.0
            }
          };
        }

        console.log("requestData = ", res.data.data);

        //
        this.setState({ data: res.data.data });
        // Update
        this.imageCoverRef.current.refresh(
          this.state.data.cover.imageCover,
          "/images/ssrExample/cover.png"
        );
        //
        this.imageQRCodeMaster1Ref.current.refresh(
          this.state.data.cover.qrCodeMaster1,
          this.state.data.cover.imageQRCodeMaster1
        );
        this.imageQRCodeSlave2Ref.current.refresh(
          this.state.data.cover.qrCodeSlave2,
          this.state.data.cover.imageQRCodeSlave2
        );
        this.imageQRCodeSlave3Ref.current.refresh(
          this.state.data.cover.qrCodeSlave3,
          this.state.data.cover.imageQRCodeSlave3
        );
        // Master 1
        this.imageDesignMaster1Ref.current.refresh(
          this.state.data.master1.imageDesignMaster,
          this.state.data.master1.imageDesignMaster2,
          this.state.data.master1.markers
        );
        this.imageM11Ref.current.refresh(
          this.state.data.master1.imageM11,
          this.state.data.master1.markersM11
        );
        this.imageM12Ref.current.refresh(
          this.state.data.master1.imageM12,
          this.state.data.master1.markersM12
        );
        this.imageM13Ref.current.refresh(
          this.state.data.master1.imageM13,
          this.state.data.master1.markersM13
        );
        this.imageM14Ref.current.refresh(
          this.state.data.master1.imageM14,
          this.state.data.master1.markersM14
        );
        this.imageM15Ref.current.refresh(
          this.state.data.master1.imageM15,
          this.state.data.master1.markersM15
        );
        this.imageM16Ref.current.refresh(
          this.state.data.master1.imageM16,
          this.state.data.master1.markersM16
        );
        this.imageM17Ref.current.refresh(
          this.state.data.master1.imageM17,
          this.state.data.master1.markersM17
        );
        this.imageM18Ref.current.refresh(
          this.state.data.master1.imageM18,
          this.state.data.master1.markersM18
        );
        this.imageM19Ref.current.refresh(
          this.state.data.master1.imageM19,
          this.state.data.master1.markersM19
        );
        this.imageM110Ref.current.refresh(
          this.state.data.master1.imageM110,
          this.state.data.master1.markersM110
        );
        this.imageM111Ref.current.refresh(
          this.state.data.master1.imageM111,
          this.state.data.master1.markersM111
        );
        // Slave 2
        this.imageDesignSlave2Ref.current.refresh(
          this.state.data.slave2.imageDesignSlave,
          this.state.data.slave2.markers
        );
        this.imageS21Ref.current.refresh(
          this.state.data.slave2.imageS21,
          this.state.data.slave2.markersS21
        );
        this.imageS22Ref.current.refresh(
          this.state.data.slave2.imageS22,
          this.state.data.slave2.markersS22
        );
        this.imageS23Ref.current.refresh(
          this.state.data.slave2.imageS23,
          this.state.data.slave2.markersS23
        );
        this.imageS24Ref.current.refresh(
          this.state.data.slave2.imageS24,
          this.state.data.slave2.markersS24
        );
        this.imageS25Ref.current.refresh(
          this.state.data.slave2.imageS25,
          this.state.data.slave2.markersS25
        );
        this.imageS26Ref.current.refresh(
          this.state.data.slave2.imageS26,
          this.state.data.slave2.markersS26
        );
        this.imageS27Ref.current.refresh(
          this.state.data.slave2.imageS27,
          this.state.data.slave2.markersS27
        );
        // Slave 3
        this.imageS31Ref.current.refresh(
          this.state.data.slave3.imageS31,
          this.state.data.slave3.markersS31
        );
        this.imageS32Ref.current.refresh(
          this.state.data.slave3.imageS32,
          this.state.data.slave3.markersS32
        );
        this.imageS33Ref.current.refresh(
          this.state.data.slave3.imageS33,
          this.state.data.slave3.markersS33
        );
        this.imageS34Ref.current.refresh(
          this.state.data.slave3.imageS34,
          this.state.data.slave3.markersS34
        );
        this.imageS35Ref.current.refresh(
          this.state.data.slave3.imageS35,
          this.state.data.slave3.markersS35
        );
        this.imageS36Ref.current.refresh(
          this.state.data.slave3.imageS36,
          this.state.data.slave3.markersS36
        );
        this.imageS37Ref.current.refresh(
          this.state.data.slave3.imageS37,
          this.state.data.slave3.markersS37
        );
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        if (this.loadingboxRef.current) this.loadingboxRef.current.onHide();
      });
  };

  // //
  // onChangeOwner = (e) => {
  //     const { name, value } = e.target;
  //     let data = {...this.state.data};
  //     data[name] = value;
  //     this.setState({ data: data });
  // }

  //
  componentDidMount = () => {
    if (localStorage.getItem("token") === null) {
      return;
    }
    this.requestData();
  };

  menu = () => {
    if (auth.allowAdmin()) {
      return <MenuAdmin />;
    } else if (auth.allowAdminSubcon()) {
      return <MenuAdminSubcon />;
    } else if (auth.allowReview()) {
      return <MenuReviewer />;
    } else {
      return <MenuTechnician />;
    }
  };

  //
  render = () => {
    //
    if (localStorage.getItem("token") === null) {
      return <Redirect push to="/login" />;
    }

    // Redirect
    if (this.state.redirect) {
      return <Redirect push to={this.state.redirect} />;
    }

    let isReadOnly = !(
      localStorage.getItem("role") === "Admin" ||
      localStorage.getItem("role") === "Super Admin"
    );

    return (
      <>
        {this.menu()}
        <MessageBox ref={this.messageboxRef} />
        <LoadingBox ref={this.loadingboxRef} />
        <ConfirmBox ref={this.confirmboxRef} />
        <div className="App-body">
          <Row>
            <Col>
              <div style={{ marginBottom: "10px" }}>
                <table width="100%">
                  <tbody>
                    <tr>
                      <td></td>
                      <td width="100" align="right">
                        {this.state.allowSave === true ? (
                          <Button variant="success" onClick={this.onSubmit}>
                            Update
                          </Button>
                        ) : (
                          <Button
                            variant="secondary"
                            onClick={this.onSubmit}
                            disabled
                          >
                            Update
                          </Button>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div style={{ textAlign: "center", marginBottom: "15px" }}>
                <h3>Technical Site Survey</h3>
                {this.state.data !== null
                  ? "Doc No: " + this.state.data.documentNumber
                  : ""}
              </div>
              <Form>
                <Tabs
                  id={React.createRef()}
                  defaultActiveKey="cover"
                  onSelect={this.onTabSelect}
                >
                  {/*  */}
                  <Tab eventKey="cover" title="Cover">
                    <div style={{ marginTop: "10px" }}>
                      <Card border="primary">
                        <Card.Header>Cover's Detail</Card.Header>
                        <Card.Body>
                          <Form.Group>
                            <Form.Label>Project</Form.Label>
                            <Form.Control
                              type="text"
                              name="projectName"
                              defaultValue={getValue(
                                this.state.data?.cover.projectName,
                                "",
                                ""
                              )}
                              onChange={this.onCoverChangeToLocalStorage}
                            />
                          </Form.Group>
                          <Form.Group>
                            <Form.Label>Site Code</Form.Label>
                            <Form.Control
                              type="text"
                              name="siteCode"
                              defaultValue={getValue(
                                this.state.data?.cover.siteCode,
                                "",
                                ""
                              )}
                              onChange={this.onCoverSiteCodeLookup}
                            />
                          </Form.Group>
                          <Form.Group>
                            <Form.Label>Region</Form.Label>
                            <Form.Control
                              type="text"
                              name="region"
                              defaultValue={getValue(
                                this.state.data?.cover.region,
                                "",
                                ""
                              )}
                              readOnly={isReadOnly}
                              onChange={this.onCoverChange}
                            />
                          </Form.Group>
                          <Form.Group>
                            <Form.Label>Site Name EN</Form.Label>
                            <Form.Control
                              type="text"
                              name="siteNameEn"
                              defaultValue={getValue(
                                this.state.data?.cover.siteNameEn,
                                "",
                                ""
                              )}
                              readOnly={isReadOnly}
                              onChange={this.onCoverChange}
                            />
                          </Form.Group>
                          <Form.Group>
                            <Form.Label>Site Name TH</Form.Label>
                            <Form.Control
                              type="text"
                              name="siteNameTh"
                              defaultValue={getValue(
                                this.state.data?.cover.siteNameTh,
                                "",
                                ""
                              )}
                              readOnly={isReadOnly}
                              onChange={this.onCoverChange}
                            />
                          </Form.Group>
                          <Form.Group>
                            <Form.Label>Zone</Form.Label>
                            <Form.Control
                              type="text"
                              name="zone"
                              defaultValue={getValue(
                                this.state.data?.cover.zone,
                                "",
                                ""
                              )}
                              readOnly={isReadOnly}
                              onChange={this.onCoverChange}
                            />
                          </Form.Group>
                          <Form.Group>
                            <Form.Label>IP</Form.Label>
                            <Form.Control
                              type="text"
                              name="ip"
                              defaultValue={getValue(
                                this.state.data?.cover.ip,
                                "",
                                ""
                              )}
                              onChange={this.onCoverChange}
                            />
                          </Form.Group>
                          <Form.Group>
                            <Form.Label>Gateway</Form.Label>
                            <Form.Control
                              type="text"
                              name="gateway"
                              defaultValue={getValue(
                                this.state.data?.cover.gateway,
                                "",
                                ""
                              )}
                              onChange={this.onCoverChange}
                            />
                          </Form.Group>
                          <Form.Group>
                            <Form.Label>Subnet Mask</Form.Label>
                            <Form.Control
                              type="text"
                              name="subnetMask"
                              defaultValue={getValue(
                                this.state.data?.cover.subnetMask,
                                "",
                                ""
                              )}
                              onChange={this.onCoverChange}
                            />
                          </Form.Group>
                          <Form.Group>
                            <Form.Label>IPRAN</Form.Label>
                            <Form.Control
                              type="text"
                              name="ipRan"
                              defaultValue={getValue(
                                this.state.data?.cover.ipRan,
                                "",
                                ""
                              )}
                              onChange={this.onCoverChange}
                            />
                          </Form.Group>
                          <hr />
                          <Form.Group>
                            <Form.Label>Surver & Installaion by</Form.Label>
                            <Form.Control
                              type="text"
                              name="surveyAndInstallation"
                              defaultValue={getValue(
                                this.state.data?.cover.surveyAndInstallation,
                                "",
                                ""
                              )}
                              onChange={this.onCoverChange}
                              readOnly={isReadOnly}
                            />
                          </Form.Group>
                          <Form.Group>
                            <Form.Label>Surver & Installaion Phone</Form.Label>
                            <Form.Control
                              type="text"
                              name="surveyAndInstallationPhone"
                              defaultValue={getValue(
                                this.state.data?.cover
                                  .surveyAndInstallationPhone,
                                "",
                                ""
                              )}
                              onChange={this.onCoverChange}
                              readOnly={isReadOnly}
                            />
                          </Form.Group>
                          <Form.Group>
                            <Form.Label>Technician Name 1</Form.Label>
                            <Form.Control
                              type="text"
                              name="technicianName"
                              defaultValue={getValue(
                                this.state.data?.cover.technicianName,
                                "",
                                ""
                              )}
                              readOnly={isReadOnly}
                              onChange={this.onCoverChange}
                            />
                          </Form.Group>
                          <Form.Group>
                            <Form.Label>Technician Phone 1</Form.Label>
                            <Form.Control
                              type="text"
                              name="technicianPhone"
                              defaultValue={getValue(
                                this.state.data?.cover.technicianPhone,
                                "",
                                ""
                              )}
                              readOnly={isReadOnly}
                              onChange={this.onCoverChange}
                            />
                          </Form.Group>
                          <Form.Group>
                            <Form.Label>Technician Name 2</Form.Label>
                            <Form.Control
                              type="text"
                              name="technicianName2"
                              defaultValue={getValue(
                                this.state.data?.cover.technicianName2,
                                "",
                                ""
                              )}
                              readOnly={isReadOnly}
                              onChange={this.onCoverChange}
                            />
                          </Form.Group>
                          <Form.Group>
                            <Form.Label>Technician Phone 2</Form.Label>
                            <Form.Control
                              type="text"
                              name="technicianPhone2"
                              defaultValue={getValue(
                                this.state.data?.cover.technicianPhone2,
                                "",
                                ""
                              )}
                              readOnly={isReadOnly}
                              onChange={this.onCoverChange}
                            />
                          </Form.Group>
                          <Form.Group>
                            <Form.Label>Date</Form.Label>
                            <Form.Control
                              type="date"
                              name="date"
                              onChange={this.onCoverChange}
                              defaultValue={getValue(
                                this.state.data?.cover.date,
                                Moment().format("YYYY-MM-DD"),
                                ""
                              )}
                            />
                          </Form.Group>
                          <Form.Group>
                            <Form.Label>Remark</Form.Label>
                            <Form.Control
                              type="text"
                              name="remark"
                              defaultValue={getValue(
                                this.state.data?.cover.remark,
                                "",
                                ""
                              )}
                              onChange={this.onCoverChange}
                            />
                          </Form.Group>
                          {
                            // (localStorage.getItem('role') === 'Reviewer' || localStorage.getItem('role') === 'Admin') ?
                            // <Form.Group>
                            //     <Form.Label>Owner</Form.Label>
                            //     <select name='owner' className="custom-select" onChange={this.onChangeOwner} value={(this.state.data !== null) ? this.state.data.owner : null}>
                            //         <option value="" defaultValue>---</option>
                            //         {
                            //             (this.state.owners !== null) ?
                            //             this.state.owners.map((data, index) => {
                            //                 return <option value={data.name}>{data.name}</option>
                            //             }) : null
                            //         }
                            //     </select>
                            // </Form.Group> : null
                          }
                        </Card.Body>
                      </Card>
                      <Card style={{ marginTop: "10px" }} border="primary">
                        <Card.Header>Cover's Image</Card.Header>
                        <Card.Body>
                          <Form.Group>
                            <ImageUploadPreview
                              ref={this.imageCoverRef}
                              src={
                                this.state.data !== null
                                  ? Config.service.url +
                                    "/ssr/image/" +
                                    this.state.data.cover.imageCover
                                  : "/images/ssrExample/cover.png"
                              }
                              mode="v"
                            />
                          </Form.Group>
                        </Card.Body>
                      </Card>
                      <Card style={{ marginTop: "10px" }} border="primary">
                        <Card.Header>QR Code Master 1</Card.Header>
                        <Card.Body>
                          <Form.Group>
                            <ImageQRUploadPreview
                              ref={this.imageQRCodeMaster1Ref}
                            />
                          </Form.Group>
                        </Card.Body>
                      </Card>
                      <Card style={{ marginTop: "10px" }} border="primary">
                        <Card.Header>QR Code Slave 2</Card.Header>
                        <Card.Body>
                          <Form.Group>
                            <ImageQRUploadPreview
                              ref={this.imageQRCodeSlave2Ref}
                            />
                          </Form.Group>
                        </Card.Body>
                      </Card>
                      <Card style={{ marginTop: "10px" }} border="primary">
                        <Card.Header>QR Code Slave 3</Card.Header>
                        <Card.Body>
                          <Form.Group>
                            <ImageQRUploadPreview
                              ref={this.imageQRCodeSlave3Ref}
                            />
                          </Form.Group>
                        </Card.Body>
                      </Card>
                    </div>
                  </Tab>
                  {/*  */}
                  <Tab eventKey="check" title="Check">
                    <div style={{ padding: "5px" }}>
                      <Table striped bordered hover size="sm">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Detail</th>
                            <th>Result</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>1</td>
                            <td>
                              ตรวจสอบตู้ ได้รับความเสียหายหรือไม่ เช่น บุบ
                              ซีลยางไม่ฉีกขาด
                            </td>
                            <td>
                              <select
                                name="visualCheck1"
                                className="custom-select"
                                onChange={this.onCheckChange}
                                value={
                                  this.state.data !== null
                                    ? this.state.data.check.visualCheck1
                                    : "Pass"
                                }
                              >
                                <option value="Pass" defaultValue>
                                  Pass
                                </option>
                                <option value="Fail">Fail</option>
                                <option value="N/A">N/A</option>
                              </select>
                            </td>
                          </tr>
                          {this.state.data !== null &&
                          this.state.data.check.visualCheck1 === "Fail" ? (
                            <tr>
                              <td colSpan="3">
                                <ImageUploadPreview
                                  ref={this.imageVisualCheck11Ref}
                                  src={
                                    this.state.data !== null &&
                                    this.state.data.check.imageVisualCheck11 !==
                                      ""
                                      ? Config.service.url +
                                        "/ssr/image/" +
                                        this.state.data.check.imageVisualCheck11
                                      : "/images/na-horizontal.jpg"
                                  }
                                  mode="h"
                                />
                                <ImageUploadPreview
                                  ref={this.imageVisualCheck12Ref}
                                  src={
                                    this.state.data !== null &&
                                    this.state.data.check.imageVisualCheck12 !==
                                      ""
                                      ? Config.service.url +
                                        "/ssr/image/" +
                                        this.state.data.check.imageVisualCheck12
                                      : "/images/na-horizontal.jpg"
                                  }
                                  mode="h"
                                />
                                <ImageUploadPreview
                                  ref={this.imageVisualCheck13Ref}
                                  src={
                                    this.state.data !== null &&
                                    this.state.data.check.imageVisualCheck13 !==
                                      ""
                                      ? Config.service.url +
                                        "/ssr/image/" +
                                        this.state.data.check.imageVisualCheck13
                                      : "/images/na-horizontal.jpg"
                                  }
                                  mode="h"
                                />
                                <ImageUploadPreview
                                  ref={this.imageVisualCheck14Ref}
                                  src={
                                    this.state.data !== null &&
                                    this.state.data.check.imageVisualCheck14 !==
                                      ""
                                      ? Config.service.url +
                                        "/ssr/image/" +
                                        this.state.data.check.imageVisualCheck14
                                      : "/images/na-horizontal.jpg"
                                  }
                                  mode="h"
                                />
                              </td>
                            </tr>
                          ) : null}
                          <tr>
                            <td>2</td>
                            <td>
                              ตรวจสอบว่าประตูและตัวล๊อคเดิมของตู้
                              สามารถทำงานได้อย่างสมบรูณ์ไม่ติดขัด
                            </td>
                            <td width="100">
                              <select
                                name="visualCheck2"
                                className="custom-select"
                                onChange={this.onCheckChange}
                                value={
                                  this.state.data !== null
                                    ? this.state.data.check.visualCheck2
                                    : "Pass"
                                }
                              >
                                <option value="Pass" defaultValue>
                                  Pass
                                </option>
                                <option value="Fail">Fail</option>
                                <option value="N/A">N/A</option>
                              </select>
                            </td>
                          </tr>
                          {this.state.data !== null &&
                          this.state.data.check.visualCheck2 === "Fail" ? (
                            <tr>
                              <td colSpan="3">
                                <ImageUploadPreview
                                  ref={this.imageVisualCheck21Ref}
                                  src={
                                    this.state.data !== null &&
                                    this.state.data.check.imageVisualCheck21 !==
                                      ""
                                      ? Config.service.url +
                                        "/ssr/image/" +
                                        this.state.data.check.imageVisualCheck21
                                      : "/images/na-horizontal.jpg"
                                  }
                                  mode="h"
                                />
                                <ImageUploadPreview
                                  ref={this.imageVisualCheck22Ref}
                                  src={
                                    this.state.data !== null &&
                                    this.state.data.check.imageVisualCheck22 !==
                                      ""
                                      ? Config.service.url +
                                        "/ssr/image/" +
                                        this.state.data.check.imageVisualCheck22
                                      : "/images/na-horizontal.jpg"
                                  }
                                  mode="h"
                                />
                                <ImageUploadPreview
                                  ref={this.imageVisualCheck23Ref}
                                  src={
                                    this.state.data !== null &&
                                    this.state.data.check.imageVisualCheck23 !==
                                      ""
                                      ? Config.service.url +
                                        "/ssr/image/" +
                                        this.state.data.check.imageVisualCheck23
                                      : "/images/na-horizontal.jpg"
                                  }
                                  mode="h"
                                />
                                <ImageUploadPreview
                                  ref={this.imageVisualCheck24Ref}
                                  src={
                                    this.state.data !== null &&
                                    this.state.data.check.imageVisualCheck24 !==
                                      ""
                                      ? Config.service.url +
                                        "/ssr/image/" +
                                        this.state.data.check.imageVisualCheck24
                                      : "/images/na-horizontal.jpg"
                                  }
                                  mode="h"
                                />
                              </td>
                            </tr>
                          ) : null}
                          <tr>
                            <td>3</td>
                            <td>
                              ตรวจสอบทรัพย์สินและอุปกรณ์เกิดการสูญหายหรือไม่
                            </td>
                            <td width="100">
                              <select
                                name="visualCheck3"
                                className="custom-select"
                                onChange={this.onCheckChange}
                                value={
                                  this.state.data !== null
                                    ? this.state.data.check.visualCheck3
                                    : "Pass"
                                }
                              >
                                <option value="Pass" defaultValue>
                                  Pass
                                </option>
                                <option value="Fail">Fail</option>
                                <option value="N/A">N/A</option>
                              </select>
                            </td>
                          </tr>
                          {this.state.data !== null &&
                          this.state.data.check.visualCheck3 === "Fail" ? (
                            <tr>
                              <td colSpan="3">
                                <ImageUploadPreview
                                  ref={this.imageVisualCheck31Ref}
                                  src={
                                    this.state.data !== null &&
                                    this.state.data.check.imageVisualCheck31 !==
                                      ""
                                      ? Config.service.url +
                                        "/ssr/image/" +
                                        this.state.data.check.imageVisualCheck31
                                      : "/images/na-horizontal.jpg"
                                  }
                                  mode="h"
                                />
                                <ImageUploadPreview
                                  ref={this.imageVisualCheck32Ref}
                                  src={
                                    this.state.data !== null &&
                                    this.state.data.check.imageVisualCheck32 !==
                                      ""
                                      ? Config.service.url +
                                        "/ssr/image/" +
                                        this.state.data.check.imageVisualCheck32
                                      : "/images/na-horizontal.jpg"
                                  }
                                  mode="h"
                                />
                                <ImageUploadPreview
                                  ref={this.imageVisualCheck33Ref}
                                  src={
                                    this.state.data !== null &&
                                    this.state.data.check.imageVisualCheck33 !==
                                      ""
                                      ? Config.service.url +
                                        "/ssr/image/" +
                                        this.state.data.check.imageVisualCheck33
                                      : "/images/na-horizontal.jpg"
                                  }
                                  mode="h"
                                />
                                <ImageUploadPreview
                                  ref={this.imageVisualCheck34Ref}
                                  src={
                                    this.state.data !== null &&
                                    this.state.data.check.imageVisualCheck34 !==
                                      ""
                                      ? Config.service.url +
                                        "/ssr/image/" +
                                        this.state.data.check.imageVisualCheck34
                                      : "/images/na-horizontal.jpg"
                                  }
                                  mode="h"
                                />
                              </td>
                            </tr>
                          ) : null}
                        </tbody>
                      </Table>
                    </div>
                  </Tab>
                  {/*  */}
                  <Tab eventKey="master1" title="Master 1">
                    <div style={{ marginTop: "10px" }}>
                      <Card border="primary">
                        <Card.Header>Design Master's Image</Card.Header>
                        <Card.Body>
                          <Form.Group>
                            <Form.Label>
                              Design Implement Smart Site Master
                            </Form.Label>
                          </Form.Group>
                          <Form.Group>
                            <SSRDesignMasterImage
                              ref={this.imageDesignMaster1Ref}
                              src={
                                this.state.data !== null
                                  ? Config.service.url +
                                    "/ssr/image/" +
                                    this.state.data.master1.imageDesignMaster
                                  : "/images/ssrExample/designMaster.png"
                              }
                              src2={
                                this.state.data !== null
                                  ? Config.service.url +
                                    "/ssr/image/" +
                                    this.state.data.master1.imageDesignMaster
                                  : "/images/ssrExample/designMaster2.png"
                              }
                              draggable={true}
                            />
                          </Form.Group>
                        </Card.Body>
                      </Card>
                      <Card style={{ marginTop: "10px" }} border="primary">
                        <Card.Header>Master's Image</Card.Header>
                        <Card.Body>
                          <Row>
                            <Col md={6}>
                              <div
                                style={{
                                  borderWidth: "1px",
                                  borderStyle: "solid",
                                  borderColor: "#52BE80",
                                  marginBottom: "5px",
                                  padding: "10px"
                                }}
                              >
                                <Form.Group>
                                  <Form.Label>
                                    CONNECT DC POWER TO SMART SITE
                                  </Form.Label>
                                </Form.Group>
                                <Form.Group>
                                  <ImageUploadPreviewWithRect
                                    ref={this.imageM11Ref}
                                    src={
                                      this.state.data !== null &&
                                      this.state.data.master1.imageM11 !== ""
                                        ? Config.service.url +
                                          "/ssr/image/" +
                                          this.state.data.master1.imageM11
                                        : "/images/ssrExample/m1.png"
                                    }
                                    draggable={true}
                                  />
                                </Form.Group>
                              </div>
                            </Col>
                            <Col md={6}>
                              <div
                                style={{
                                  borderWidth: "1px",
                                  borderStyle: "solid",
                                  borderColor: "#52BE80",
                                  marginBottom: "5px",
                                  padding: "10px"
                                }}
                              >
                                <Form.Group>
                                  <Form.Label>USB CAMERA</Form.Label>
                                </Form.Group>
                                <Form.Group>
                                  <ImageUploadPreviewWithRect
                                    ref={this.imageM12Ref}
                                    src={
                                      this.state.data !== null &&
                                      this.state.data.master1.imageM12 !== ""
                                        ? Config.service.url +
                                          "/ssr/image/" +
                                          this.state.data.master1.imageM12
                                        : "/images/ssrExample/m2.png"
                                    }
                                    draggable={true}
                                  />
                                </Form.Group>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={6}>
                              <div
                                style={{
                                  borderWidth: "1px",
                                  borderStyle: "solid",
                                  borderColor: "#52BE80",
                                  marginBottom: "5px",
                                  padding: "10px"
                                }}
                              >
                                <Form.Group>
                                  <Form.Label>ELECTRIC LOCK</Form.Label>
                                </Form.Group>
                                <Form.Group>
                                  <ImageUploadPreviewWithRect
                                    ref={this.imageM13Ref}
                                    src={
                                      this.state.data !== null &&
                                      this.state.data.master1.imageM13 !== ""
                                        ? Config.service.url +
                                          "/ssr/image/" +
                                          this.state.data.master1.imageM13
                                        : "/images/ssrExample/m3.png"
                                    }
                                    draggable={true}
                                  />
                                </Form.Group>
                              </div>
                            </Col>
                            <Col md={6}>
                              <div
                                style={{
                                  borderWidth: "1px",
                                  borderStyle: "solid",
                                  borderColor: "#52BE80",
                                  marginBottom: "5px",
                                  padding: "10px"
                                }}
                              >
                                <Form.Group>
                                  <Form.Label>DOOR LOCK</Form.Label>
                                </Form.Group>
                                <Form.Group>
                                  <ImageUploadPreviewWithRect
                                    ref={this.imageM14Ref}
                                    src={
                                      this.state.data !== null &&
                                      this.state.data.master1.imageM14 !== ""
                                        ? Config.service.url +
                                          "/ssr/image/" +
                                          this.state.data.master1.imageM14
                                        : "/images/ssrExample/m4.png"
                                    }
                                    draggable={true}
                                  />
                                </Form.Group>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={6}>
                              <div
                                style={{
                                  borderWidth: "1px",
                                  borderStyle: "solid",
                                  borderColor: "#52BE80",
                                  marginBottom: "5px",
                                  padding: "10px"
                                }}
                              >
                                <Form.Group>
                                  <Form.Label>IPRAN PORT ASSIGN</Form.Label>
                                </Form.Group>
                                <Form.Group>
                                  <ImageUploadPreviewWithRect
                                    ref={this.imageM15Ref}
                                    src={
                                      this.state.data !== null &&
                                      this.state.data.master1.imageM15 !== ""
                                        ? Config.service.url +
                                          "/ssr/image/" +
                                          this.state.data.master1.imageM15
                                        : "/images/ssrExample/m5.png"
                                    }
                                    draggable={true}
                                  />
                                </Form.Group>
                              </div>
                            </Col>
                            <Col md={6}>
                              <div
                                style={{
                                  borderWidth: "1px",
                                  borderStyle: "solid",
                                  borderColor: "#52BE80",
                                  marginBottom: "5px",
                                  padding: "10px"
                                }}
                              >
                                <Form.Group>
                                  <Form.Label>MASTER CONTROLLER</Form.Label>
                                </Form.Group>
                                <Form.Group>
                                  <ImageUploadPreviewWithRect
                                    ref={this.imageM16Ref}
                                    src={
                                      this.state.data !== null &&
                                      this.state.data.master1.imageM16 !== ""
                                        ? Config.service.url +
                                          "/ssr/image/" +
                                          this.state.data.master1.imageM16
                                        : "/images/ssrExample/m6.png"
                                    }
                                    draggable={true}
                                  />
                                </Form.Group>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={6}>
                              <div
                                style={{
                                  borderWidth: "1px",
                                  borderStyle: "solid",
                                  borderColor: "#52BE80",
                                  marginBottom: "5px",
                                  padding: "10px"
                                }}
                              >
                                <Form.Group>
                                  <Form.Label>BUZZER</Form.Label>
                                </Form.Group>
                                <Form.Group>
                                  <ImageUploadPreviewWithRect
                                    ref={this.imageM17Ref}
                                    src={
                                      this.state.data !== null &&
                                      this.state.data.master1.imageM17 !== ""
                                        ? Config.service.url +
                                          "/ssr/image/" +
                                          this.state.data.master1.imageM17
                                        : "/images/ssrExample/m7.png"
                                    }
                                    draggable={true}
                                  />
                                </Form.Group>
                              </div>
                            </Col>
                            <Col md={6}>
                              <div
                                style={{
                                  borderWidth: "1px",
                                  borderStyle: "solid",
                                  borderColor: "#52BE80",
                                  marginBottom: "5px",
                                  padding: "10px"
                                }}
                              >
                                <Form.Group>
                                  <Form.Label>MOTION SENSOR</Form.Label>
                                </Form.Group>
                                <Form.Group>
                                  <ImageUploadPreviewWithRect
                                    ref={this.imageM18Ref}
                                    src={
                                      this.state.data !== null &&
                                      this.state.data.master1.imageM18 !== ""
                                        ? Config.service.url +
                                          "/ssr/image/" +
                                          this.state.data.master1.imageM18
                                        : "/images/ssrExample/m8.png"
                                    }
                                    draggable={true}
                                  />
                                </Form.Group>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={6}>
                              <div
                                style={{
                                  borderWidth: "1px",
                                  borderStyle: "solid",
                                  borderColor: "#52BE80",
                                  marginBottom: "5px",
                                  padding: "10px"
                                }}
                              >
                                <Form.Group>
                                  <Form.Label>Cable Inlet</Form.Label>
                                </Form.Group>
                                <Form.Group>
                                  <ImageUploadPreviewWithRect
                                    ref={this.imageM19Ref}
                                    src={
                                      this.state.data !== null &&
                                      this.state.data.master1.imageM19 !== ""
                                        ? Config.service.url +
                                          "/ssr/image/" +
                                          this.state.data.master1.imageM19
                                        : "/images/ssrExample/m9.png"
                                    }
                                    draggable={true}
                                  />
                                </Form.Group>
                              </div>
                            </Col>
                            <Col md={6}>
                              <div
                                style={{
                                  borderWidth: "1px",
                                  borderStyle: "solid",
                                  borderColor: "#52BE80",
                                  marginBottom: "5px",
                                  padding: "10px"
                                }}
                              >
                                <Form.Group>
                                  <Form.Label>Ground Connection</Form.Label>
                                </Form.Group>
                                <Form.Group>
                                  <ImageUploadPreviewWithRect
                                    ref={this.imageM110Ref}
                                    src={
                                      this.state.data !== null &&
                                      this.state.data.master1.imageM110 !== ""
                                        ? Config.service.url +
                                          "/ssr/image/" +
                                          this.state.data.master1.imageM110
                                        : "/images/ssrExample/m10.png"
                                    }
                                    draggable={true}
                                  />
                                </Form.Group>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={6}>
                              <div
                                style={{
                                  borderWidth: "1px",
                                  borderStyle: "solid",
                                  borderColor: "#52BE80",
                                  marginBottom: "5px",
                                  padding: "10px"
                                }}
                              >
                                <Form.Group>
                                  <Form.Label>Ground Bar Connection</Form.Label>
                                </Form.Group>
                                <Form.Group>
                                  <ImageUploadPreviewWithRect
                                    ref={this.imageM111Ref}
                                    src={
                                      this.state.data !== null &&
                                      this.state.data.master1.imageM111 !== ""
                                        ? Config.service.url +
                                          "/ssr/image/" +
                                          this.state.data.master1.imageM111
                                        : "/images/ssrExample/m11.png"
                                    }
                                    draggable={true}
                                  />
                                </Form.Group>
                              </div>
                            </Col>
                            <Col md={6}></Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    </div>
                  </Tab>
                  {/*  */}
                  <Tab eventKey="slave2" title="Slave 2">
                    <div style={{ marginTop: "10px" }}>
                      <Card border="primary">
                        <Card.Header>Design Slave's Image</Card.Header>
                        <Card.Body>
                          <Form.Group>
                            <Form.Label>
                              Design Implement Smart Site Slave
                            </Form.Label>
                          </Form.Group>
                          <Form.Group>
                            <SSRDesignSlaveImage
                              ref={this.imageDesignSlave2Ref}
                              src={
                                this.state.data !== null
                                  ? Config.service.url +
                                    "/ssr/image/" +
                                    this.state.data.slave2.imageDesignSlave
                                  : "/images/ssrExample/designSlave.png"
                              }
                              draggable={true}
                            />
                          </Form.Group>
                        </Card.Body>
                      </Card>
                      <Card style={{ marginTop: "10px" }} border="primary">
                        <Card.Header>Slave's Image</Card.Header>
                        <Card.Body>
                          <Row>
                            <Col md={6}>
                              <div
                                style={{
                                  borderWidth: "1px",
                                  borderStyle: "solid",
                                  borderColor: "#52BE80",
                                  marginBottom: "5px",
                                  padding: "10px"
                                }}
                              >
                                <Form.Group>
                                  <Form.Label>
                                    CONNECT DC POWER TO SMART SITE
                                  </Form.Label>
                                </Form.Group>
                                <Form.Group>
                                  <ImageUploadPreviewWithRect
                                    ref={this.imageS21Ref}
                                    src={
                                      this.state.data !== null &&
                                      this.state.data.slave2.imageS21 !== ""
                                        ? Config.service.url +
                                          "/ssr/image/" +
                                          this.state.data.slave2.imageS21
                                        : "/images/ssrExample/s1.png"
                                    }
                                    draggable={true}
                                  />
                                </Form.Group>
                              </div>
                            </Col>
                            <Col md={6}>
                              <div
                                style={{
                                  borderWidth: "1px",
                                  borderStyle: "solid",
                                  borderColor: "#52BE80",
                                  marginBottom: "5px",
                                  padding: "10px"
                                }}
                              >
                                <Form.Group>
                                  <Form.Label>SLAVE CONTROLLER</Form.Label>
                                </Form.Group>
                                <Form.Group>
                                  <ImageUploadPreviewWithRect
                                    ref={this.imageS22Ref}
                                    src={
                                      this.state.data !== null &&
                                      this.state.data.slave2.imageS22 !== ""
                                        ? Config.service.url +
                                          "/ssr/image/" +
                                          this.state.data.slave2.imageS22
                                        : "/images/ssrExample/s2.png"
                                    }
                                    draggable={true}
                                  />
                                </Form.Group>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={6}>
                              <div
                                style={{
                                  borderWidth: "1px",
                                  borderStyle: "solid",
                                  borderColor: "#52BE80",
                                  marginBottom: "5px",
                                  padding: "10px"
                                }}
                              >
                                <Form.Group>
                                  <Form.Label>ELECTRIC LOCK</Form.Label>
                                </Form.Group>
                                <Form.Group>
                                  <ImageUploadPreviewWithRect
                                    ref={this.imageS23Ref}
                                    src={
                                      this.state.data !== null &&
                                      this.state.data.slave2.imageS23 !== ""
                                        ? Config.service.url +
                                          "/ssr/image/" +
                                          this.state.data.slave2.imageS23
                                        : "/images/ssrExample/s3.png"
                                    }
                                    draggable={true}
                                  />
                                </Form.Group>
                              </div>
                            </Col>
                            <Col md={6}>
                              <div
                                style={{
                                  borderWidth: "1px",
                                  borderStyle: "solid",
                                  borderColor: "#52BE80",
                                  marginBottom: "5px",
                                  padding: "10px"
                                }}
                              >
                                <Form.Group>
                                  <Form.Label>DOOR LOCK</Form.Label>
                                </Form.Group>
                                <Form.Group>
                                  <ImageUploadPreviewWithRect
                                    ref={this.imageS24Ref}
                                    src={
                                      this.state.data !== null &&
                                      this.state.data.slave2.imageS24 !== ""
                                        ? Config.service.url +
                                          "/ssr/image/" +
                                          this.state.data.slave2.imageS24
                                        : "/images/ssrExample/s4.png"
                                    }
                                    draggable={true}
                                  />
                                </Form.Group>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={6}>
                              <div
                                style={{
                                  borderWidth: "1px",
                                  borderStyle: "solid",
                                  borderColor: "#52BE80",
                                  marginBottom: "5px",
                                  padding: "10px"
                                }}
                              >
                                <Form.Group>
                                  <Form.Label>Cable Inlet</Form.Label>
                                </Form.Group>
                                <Form.Group>
                                  <ImageUploadPreviewWithRect
                                    ref={this.imageS25Ref}
                                    src={
                                      this.state.data !== null &&
                                      this.state.data.slave2.imageS25 !== ""
                                        ? Config.service.url +
                                          "/ssr/image/" +
                                          this.state.data.slave2.imageS25
                                        : "/images/ssrExample/s5.png"
                                    }
                                    draggable={true}
                                  />
                                </Form.Group>
                              </div>
                            </Col>
                            <Col md={6}>
                              <div
                                style={{
                                  borderWidth: "1px",
                                  borderStyle: "solid",
                                  borderColor: "#52BE80",
                                  marginBottom: "5px",
                                  padding: "10px"
                                }}
                              >
                                <Form.Group>
                                  <Form.Label>
                                    Ground Bar in Cab. Connection
                                  </Form.Label>
                                </Form.Group>
                                <Form.Group>
                                  <ImageUploadPreviewWithRect
                                    ref={this.imageS26Ref}
                                    src={
                                      this.state.data !== null &&
                                      this.state.data.slave2.imageS26 !== ""
                                        ? Config.service.url +
                                          "/ssr/image/" +
                                          this.state.data.slave2.imageS26
                                        : "/images/ssrExample/s6.png"
                                    }
                                    draggable={true}
                                  />
                                </Form.Group>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={6}>
                              <div
                                style={{
                                  borderWidth: "1px",
                                  borderStyle: "solid",
                                  borderColor: "#52BE80",
                                  marginBottom: "5px",
                                  padding: "10px"
                                }}
                              >
                                <Form.Group>
                                  <Form.Label>Ground Bar Connection</Form.Label>
                                </Form.Group>
                                <Form.Group>
                                  <ImageUploadPreviewWithRect
                                    ref={this.imageS27Ref}
                                    src={
                                      this.state.data !== null &&
                                      this.state.data.slave2.imageS27 !== ""
                                        ? Config.service.url +
                                          "/ssr/image/" +
                                          this.state.data.slave2.imageS27
                                        : "/images/ssrExample/s7.png"
                                    }
                                    draggable={true}
                                  />
                                </Form.Group>
                              </div>
                            </Col>
                            <Col md={6}></Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    </div>
                  </Tab>
                  {/*  */}
                  <Tab eventKey="slave3" title="Slave 3">
                    <div style={{ marginTop: "10px" }}>
                      <Card style={{ marginTop: "10px" }} border="primary">
                        <Card.Header>Slave's Image</Card.Header>
                        <Card.Body>
                          <Row>
                            <Col md={6}>
                              <div
                                style={{
                                  borderWidth: "1px",
                                  borderStyle: "solid",
                                  borderColor: "#52BE80",
                                  marginBottom: "5px",
                                  padding: "10px"
                                }}
                              >
                                <Form.Group>
                                  <Form.Label>
                                    CONNECT DC POWER TO SMART SITE
                                  </Form.Label>
                                </Form.Group>
                                <Form.Group>
                                  <ImageUploadPreviewWithRect
                                    ref={this.imageS31Ref}
                                    src={
                                      this.state.data !== null &&
                                      this.state.data.slave3.imageS31 !== ""
                                        ? Config.service.url +
                                          "/ssr/image/" +
                                          this.state.data.slave3.imageS31
                                        : "/images/ssrExample/s1.png"
                                    }
                                    draggable={true}
                                  />
                                </Form.Group>
                              </div>
                            </Col>
                            <Col md={6}>
                              <div
                                style={{
                                  borderWidth: "1px",
                                  borderStyle: "solid",
                                  borderColor: "#52BE80",
                                  marginBottom: "5px",
                                  padding: "10px"
                                }}
                              >
                                <Form.Group>
                                  <Form.Label>SLAVE CONTROLLER</Form.Label>
                                </Form.Group>
                                <Form.Group>
                                  <ImageUploadPreviewWithRect
                                    ref={this.imageS32Ref}
                                    src={
                                      this.state.data !== null &&
                                      this.state.data.slave3.imageS32 !== ""
                                        ? Config.service.url +
                                          "/ssr/image/" +
                                          this.state.data.slave3.imageS32
                                        : "/images/ssrExample/s2.png"
                                    }
                                    draggable={true}
                                  />
                                </Form.Group>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={6}>
                              <div
                                style={{
                                  borderWidth: "1px",
                                  borderStyle: "solid",
                                  borderColor: "#52BE80",
                                  marginBottom: "5px",
                                  padding: "10px"
                                }}
                              >
                                <Form.Group>
                                  <Form.Label>ELECTRIC LOCK</Form.Label>
                                </Form.Group>
                                <Form.Group>
                                  <ImageUploadPreviewWithRect
                                    ref={this.imageS33Ref}
                                    src={
                                      this.state.data !== null &&
                                      this.state.data.slave3.imageS33 !== ""
                                        ? Config.service.url +
                                          "/ssr/image/" +
                                          this.state.data.slave3.imageS33
                                        : "/images/ssrExample/s3.png"
                                    }
                                    draggable={true}
                                  />
                                </Form.Group>
                              </div>
                            </Col>
                            <Col md={6}>
                              <div
                                style={{
                                  borderWidth: "1px",
                                  borderStyle: "solid",
                                  borderColor: "#52BE80",
                                  marginBottom: "5px",
                                  padding: "10px"
                                }}
                              >
                                <Form.Group>
                                  <Form.Label>DOOR LOCK</Form.Label>
                                </Form.Group>
                                <Form.Group>
                                  <ImageUploadPreviewWithRect
                                    ref={this.imageS34Ref}
                                    src={
                                      this.state.data !== null &&
                                      this.state.data.slave3.imageS34 !== ""
                                        ? Config.service.url +
                                          "/ssr/image/" +
                                          this.state.data.slave3.imageS34
                                        : "/images/ssrExample/s4.png"
                                    }
                                    draggable={true}
                                  />
                                </Form.Group>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={6}>
                              <div
                                style={{
                                  borderWidth: "1px",
                                  borderStyle: "solid",
                                  borderColor: "#52BE80",
                                  marginBottom: "5px",
                                  padding: "10px"
                                }}
                              >
                                <Form.Group>
                                  <Form.Label>Cable Inlet</Form.Label>
                                </Form.Group>
                                <Form.Group>
                                  <ImageUploadPreviewWithRect
                                    ref={this.imageS35Ref}
                                    src={
                                      this.state.data !== null &&
                                      this.state.data.slave3.imageS35 !== ""
                                        ? Config.service.url +
                                          "/ssr/image/" +
                                          this.state.data.slave3.imageS35
                                        : "/images/ssrExample/s5.png"
                                    }
                                    draggable={true}
                                  />
                                </Form.Group>
                              </div>
                            </Col>
                            <Col md={6}>
                              <div
                                style={{
                                  borderWidth: "1px",
                                  borderStyle: "solid",
                                  borderColor: "#52BE80",
                                  marginBottom: "5px",
                                  padding: "10px"
                                }}
                              >
                                <Form.Group>
                                  <Form.Label>
                                    Ground Bar in Cab. Connection
                                  </Form.Label>
                                </Form.Group>
                                <Form.Group>
                                  <ImageUploadPreviewWithRect
                                    ref={this.imageS36Ref}
                                    src={
                                      this.state.data !== null &&
                                      this.state.data.slave3.imageS36 !== ""
                                        ? Config.service.url +
                                          "/ssr/image/" +
                                          this.state.data.slave3.imageS36
                                        : "/images/ssrExample/s6.png"
                                    }
                                    draggable={true}
                                  />
                                </Form.Group>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={6}>
                              <div
                                style={{
                                  borderWidth: "1px",
                                  borderStyle: "solid",
                                  borderColor: "#52BE80",
                                  marginBottom: "5px",
                                  padding: "10px"
                                }}
                              >
                                <Form.Group>
                                  <Form.Label>Ground Bar Connection</Form.Label>
                                </Form.Group>
                                <Form.Group>
                                  <ImageUploadPreviewWithRect
                                    ref={this.imageS37Ref}
                                    src={
                                      this.state.data !== null &&
                                      this.state.data.slave3.imageS37 !== ""
                                        ? Config.service.url +
                                          "/ssr/image/" +
                                          this.state.data.slave3.imageS37
                                        : "/images/ssrExample/s7.png"
                                    }
                                    draggable={true}
                                  />
                                </Form.Group>
                              </div>
                            </Col>
                            <Col md={6}></Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    </div>
                  </Tab>
                </Tabs>
              </Form>
            </Col>
          </Row>
        </div>
      </>
    );
  };
}

export default withRouter(SSRTechnicianEdit);

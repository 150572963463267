import React, { Component } from "react";
import { Redirect, withRouter } from "react-router-dom";
import { Button } from "react-bootstrap";
import { Table } from "react-bootstrap";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { Container } from "react-bootstrap";
import { Dropdown } from "react-bootstrap";
import { DropdownButton } from "react-bootstrap";
import auth from "../helper/authHelper";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

import Axios from "axios";

import MenuAdmin from "../components/menu/menuAdmin";
import MenuAdminSubcon from "../components/menu/menuAdminSubcon";
import MenuReviewer from "../components/menu/menuReviewer";
import MenuTechnician from "../components/menu/menuTechnician";

import MessageBox from "../components/messagebox/messagebox";
import LoadingBox from "../components/messagebox/loadingbox";
import ConfirmBox from "../components/messagebox/confirmbox";

var Moment = require("moment");
var Config = require("../config/config.json");

class ConfigSmartSiteAdminReviewer extends Component {
  //
  constructor(props) {
    // Base contructor.
    super(props);
    // Ref.
    this.messageboxRef = React.createRef();
    this.loadingboxRef = React.createRef();
    this.confirmboxRef = React.createRef();
    this.linkDownloadRef = React.createRef();
    // State.
    this.state = {
      search: {
        documentNumber: "",
        month: Moment().format("YYYY-MM"),
        siteCode: "",
        siteNameEn: "",
        region: "",
        zone: "",
        contractor: "",
        searchAll: "yes"
      },
      redirect: "",
      isShowSearch: false,
      isShowDownload: false,
      searchType: "",
      data: [],
      page: 0,
      pages: 0
    };
  }

  onNextPage = () => {
    let page = this.state.page + 1;
    if (page >= this.state.pages) {
      this.setState({ page: this.state.pages });
    } else {
      this.setState({ page: page });
    }

    if (this.state.searchType === "all") {
      this.requestAllData(page);
    } else {
      this.onSearchSubmit(page);
    }
  };

  onPreviousPage = () => {
    let page = this.state.page - 1;
    if (page <= 0) {
      page = 0;
    }

    this.setState({ page: page });

    if (this.state.searchType === "all") {
      this.requestAllData(page);
    } else {
      this.onSearchSubmit(page);
    }
  };

  //
  requestAllData = page => {
    if (this.loadingboxRef.current) this.loadingboxRef.current.onShow();
    this.setState({ searchType: "all" });

    Axios.post(
      Config.service.url + "/pat/search",
      {
        page: page,
        pagination: Config.share.pagination
      },
      {
        headers: {
          Authorization: localStorage.getItem("token")
        }
      }
    )
      .then(res => {
        if (res.data.success === false) {
          this.messageboxRef.current.onShow("Error", res.data.message);
          return;
        }
        this.setState({ data: res.data.data.data, pages: res.data.data.pages });
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        if (this.loadingboxRef.current) this.loadingboxRef.current.onHide();
      });
  };

  //
  requestData = () => {
    if (this.loadingboxRef.current) this.loadingboxRef.current.onShow();
    Axios.post(
      Config.service.url + "/pat/search",
      {
        month: this.state.search.month
      },
      {
        headers: {
          Authorization: localStorage.getItem("token")
        }
      }
    )
      .then(res => {
        if (res.data.success === false) {
          this.messageboxRef.current.onShow("Error", res.data.message);
          return;
        }
        this.setState({ data: res.data.data.data });
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        if (this.loadingboxRef.current) this.loadingboxRef.current.onHide();
      });
  };

  //
  onChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  //
  onChangeSearch = e => {
    const { name, value } = e.target;
    let search = { ...this.state.search };
    search[name] = value;
    this.setState({ search: search });
  };

  //
  onViewPage27 = data => {
    window.open("patAdminReviewerShowPage27?id=" + data._id, "_blank");
  };

  //
  onHide = () => {
    this.setState({ isShowSearch: false });
  };

  //
  onSearch = () => {
    this.setState({ isShowSearch: true });
  };

  //
  onSearchSubmit = page => {
    this.setState({
      isShowSearch: false,
      searchType: ""
    });

    if (this.loadingboxRef.current) this.loadingboxRef.current.onShow();

    let filter = null;
    if (this.state.search.searchAll === "yes") {
      filter = {
        page: page,
        pagination: Config.share.pagination,
        documentNumber: this.state.search.documentNumber,
        siteCode: this.state.search.siteCode,
        siteNameEn: this.state.search.siteNameEn,
        region: this.state.search.region,
        zone: this.state.search.zone,
        contractor: this.state.search.contractor,
        status: this.state.search.status
      };
    } else {
      filter = {
        page: page,
        pagination: Config.share.pagination,
        month: this.state.search.month,
        documentNumber: this.state.search.documentNumber,
        siteCode: this.state.search.siteCode,
        siteNameEn: this.state.search.siteNameEn,
        region: this.state.search.region,
        zone: this.state.search.zone,
        contractor: this.state.search.contractor,
        status: this.state.search.status
      };
    }

    Axios.post(Config.service.url + "/pat/search", filter, {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    })
      .then(res => {
        if (res.data.success === false) {
          this.messageboxRef.current.onShow("Error", res.data.message);
          return;
        }

        this.setState({ data: res.data.data.data, pages: res.data.data.pages });
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        if (this.loadingboxRef.current) this.loadingboxRef.current.onHide();
      });
  };

  //
  onExportToExcel = () => {
    if (this.loadingboxRef.current) this.loadingboxRef.current.onShow();

    let filter = null;
    if (this.state.search.searchAll === "yes") {
      filter = {
        documentNumber: this.state.search.documentNumber,
        siteCode: this.state.search.siteCode,
        siteNameEn: this.state.search.siteNameEn,
        region: this.state.search.region,
        zone: this.state.search.zone,
        contractor: this.state.search.contractor,
        status: this.state.search.status
      };
    } else {
      filter = {
        month: this.state.search.month,
        documentNumber: this.state.search.documentNumber,
        siteCode: this.state.search.siteCode,
        siteNameEn: this.state.search.siteNameEn,
        region: this.state.search.region,
        zone: this.state.search.zone,
        contractor: this.state.search.contractor,
        status: this.state.search.status
      };
    }

    Axios.post(Config.service.url + "/report/exportConfigSmartSite", filter, {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    })
      .then(res => {
        if (res.data.success === false) {
          this.messageboxRef.current.onShow("Error", res.data.message);
          return;
        }
        console.log(res.data);
        if (this.loadingboxRef.current) this.loadingboxRef.current.onHide();
        this.setState({ isShowDownload: true });
        this.linkDownloadRef.current.href =
          Config.service.url + "/report/download/" + res.data.file;
        console.log(this.linkDownloadRef.current.href);
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {});
  };

  //
  onHideDownload = () => {
    this.setState({ isShowDownload: false });
  };

  //
  componentDidMount() {
    if (localStorage.getItem("token") === null) {
      return;
    }
    this.requestData();
  }

  //
  countImages = data => {
    let count = 0;
    if (data.content.p271 !== undefined && data.content.p271 !== "") {
      count++;
    }
    if (data.content.p272 !== undefined && data.content.p272 !== "") {
      count++;
    }
    if (data.content.p273 !== undefined && data.content.p273 !== "") {
      count++;
    }
    return count;
  };

  menu = () => {
    if (auth.allowAdmin()) {
      return <MenuAdmin />;
    } else if (auth.allowAdminSubcon()) {
      return <MenuAdminSubcon />;
    } else if (auth.allowReview()) {
      return <MenuReviewer />;
    } else {
      return <MenuTechnician />;
    }
  };

  //
  view_desktop = () => {
    return (
      <>
        {this.menu()}
        <MessageBox ref={this.messageboxRef} />
        <LoadingBox ref={this.loadingboxRef} />
        <ConfirmBox ref={this.confirmboxRef} />
        <Modal show={this.state.isShowDownload} onHide={this.onHideDownload}>
          <Modal.Header closeButton>
            <Modal.Title>Download</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <a
              ref={this.linkDownloadRef}
              href="http://localhost"
              target="_blank"
              download
              rel="noopener noreferrer"
            >
              Click here to download
            </a>
          </Modal.Body>
        </Modal>
        <Modal show={this.state.isShowSearch} onHide={this.onHide}>
          <Modal.Header closeButton>
            <Modal.Title>Search</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Container>
                <Form.Group>
                  <Row>
                    <Col md={4}>Search All</Col>
                    <Col md={8}>
                      <select
                        name="searchAll"
                        className="custom-select"
                        onChange={this.onChangeSearch}
                        value={this.state.search.searchAll}
                      >
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                      </select>
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row>
                    <Col md={4}>Doc No</Col>
                    <Col md={8}>
                      <Form.Control
                        type="documentNumber"
                        name="documentNumber"
                        onChange={this.onChangeSearch}
                        defaultValue={this.state.search.documentNumber}
                      />
                    </Col>
                  </Row>
                </Form.Group>
                {this.state.search.searchAll === "no" ? (
                  <Form.Group>
                    <Row>
                      <Col md={4}>Month</Col>
                      <Col md={8}>
                        <Form.Control
                          type="month"
                          name="month"
                          onChange={this.onChangeSearch}
                          defaultValue={this.state.search.month}
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                ) : null}
                <Form.Group>
                  <Row>
                    <Col md={4}>Site Code</Col>
                    <Col md={8}>
                      <Form.Control
                        type="text"
                        name="siteCode"
                        onChange={this.onChangeSearch}
                        value={this.state.search.siteCode}
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row>
                    <Col md={4}>Site Name En</Col>
                    <Col md={8}>
                      <Form.Control
                        type="text"
                        name="siteNameEn"
                        onChange={this.onChangeSearch}
                        value={this.state.search.siteNameEn}
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row>
                    <Col md={4}>Region</Col>
                    <Col md={8}>
                      <Form.Control
                        type="text"
                        name="region"
                        onChange={this.onChangeSearch}
                        value={this.state.search.region}
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row>
                    <Col md={4}>Zone</Col>
                    <Col md={8}>
                      <Form.Control
                        type="text"
                        name="zone"
                        onChange={this.onChangeSearch}
                        value={this.state.search.zone}
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row>
                    <Col md={4}>Contractor</Col>
                    <Col md={8}>
                      <Form.Control
                        type="text"
                        name="contractor"
                        onChange={this.onChangeSearch}
                        value={this.state.search.contractor}
                      />
                    </Col>
                  </Row>
                </Form.Group>
              </Container>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={this.onSearchSubmit}>
              OK
            </Button>
            <Button variant="danger" onClick={this.onHide}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
        <div className="App-body">
          <Row>
            <Col md={12}>
              <table width="100%">
                <tbody>
                  <tr>
                    <td>
                      <h3>
                        Config Smart Site (
                        {this.state.searchType !== "all"
                          ? this.state.search.month
                          : "All"}
                        )
                      </h3>
                    </td>
                    <td align="right">
                      <Button
                        variant="success"
                        onClick={this.onExportToExcel}
                        title="Search"
                      >
                        Export to Excel
                      </Button>
                      &nbsp;
                      <Button
                        variant="warning"
                        onClick={() => {
                          this.requestAllData(0);
                        }}
                        title="Search"
                      >
                        Show All
                      </Button>
                      &nbsp;
                      <Button
                        variant="success"
                        onClick={this.onSearch}
                        title="Search"
                      >
                        <FontAwesomeIcon icon={faSearch} />
                      </Button>
                    </td>
                  </tr>
                </tbody>
              </table>
              {this.state.data.length === 0 ? (
                <center>
                  <div style={{ marginTop: 20 }}>
                    <span>No data</span>
                  </div>
                </center>
              ) : (
                <div style={{ marginTop: 10 }}>
                  <Row className="mb-3">
                    <Col md={12}>
                      <table width="100%">
                        <tbody>
                          <tr>
                            <td>
                              {"page " +
                                (this.state.page + 1) +
                                " of " +
                                this.state.pages +
                                " pages"}
                            </td>
                            <td align="right">
                              <div
                                className="hidden-print"
                                style={{ display: "inline" }}
                              >
                                <Button
                                  variant="secondary"
                                  onClick={this.onPreviousPage}
                                  title="Search"
                                >
                                  <FontAwesomeIcon icon={faArrowLeft} />
                                </Button>
                                &nbsp;
                                <Button
                                  variant="secondary"
                                  onClick={this.onNextPage}
                                  title="Search"
                                >
                                  <FontAwesomeIcon icon={faArrowRight} />
                                </Button>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div style={{ marginTop: "10px" }}>
                        <Table striped hover>
                          <thead>
                            <tr>
                              <th>Region</th>
                              <th>Zone</th>
                              <th>Site Code</th>
                              <th>Site TH</th>
                              <th>Site EN</th>
                              <th>Date</th>
                              <th>Doc No</th>
                              <th>Config Online</th>
                              <th>Master 1 QR</th>
                              <th>Slave 2 QR</th>
                              <th>Slave 3 QR</th>
                              <th>Contractor</th>
                              <th style={{ textAlign: "right" }}>
                                <div className="hidden-print"></div>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.data.map((data, index) => {
                              return (
                                <tr key={index}>
                                  <td>{data.cover.region}</td>
                                  <td>{data.cover.zone}</td>
                                  <td>{data.cover.siteCode}</td>
                                  <td>{data.cover.siteNameTh}</td>
                                  <td>{data.cover.siteNameEn}</td>
                                  <td>{data.cover.date}</td>
                                  <td>{data.documentNumber}</td>
                                  <td align="center">
                                    {this.countImages(data)}
                                  </td>
                                  <td>{data.cover.qrCodeMaster1}</td>
                                  <td>{data.cover.qrCodeSlave2}</td>
                                  <td>{data.cover.qrCodeSlave3}</td>
                                  <td>{data.cover.technicianContractor}</td>
                                  <td style={{ textAlign: "right" }}>
                                    <div className="hidden-print">
                                      <DropdownButton
                                        id="dropdown-basic-button"
                                        title="Action"
                                        variant="secondary"
                                      >
                                        <Dropdown.Item
                                          onClick={() =>
                                            this.onViewPage27(data)
                                          }
                                        >
                                          Show PAT Page 2.7
                                        </Dropdown.Item>
                                      </DropdownButton>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </div>
                    </Col>
                  </Row>
                </div>
              )}
            </Col>
          </Row>
        </div>
      </>
    );
  };

  //
  render() {
    if (localStorage.getItem("token") === null) {
      return <Redirect push to="/login" />;
    }

    if (this.state.redirect) {
      return <Redirect push to={this.state.redirect} />;
    }

    return this.view_desktop();
  }
}

export default withRouter(ConfigSmartSiteAdminReviewer);

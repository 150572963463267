import React, { Component } from "react";
import { Redirect, withRouter } from "react-router-dom";
import { Form } from "react-bootstrap";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import { Tabs } from "react-bootstrap";
import { Tab } from "react-bootstrap";
import { Image } from "react-bootstrap";
import { Card } from "react-bootstrap";
import { Table } from "react-bootstrap";
import { Button } from "react-bootstrap";

import Axios from "axios";

import MenuTechnician from "../components/menu/menuTechnician";
import ImageUploadPreview from "../components/image/ImageUploadPreview";

import MessageBox from "../components/messagebox/messagebox";
import LoadingBox from "../components/messagebox/loadingbox";
import ConfirmBox from "../components/messagebox/confirmbox";

import { getValue } from "../helper/utilityHelper";

var Moment = require("moment");
var Config = require("../config/config.json");

class PATTechnician extends Component {
  //
  constructor(props) {
    // Base contructor.
    super(props);
    // Ref.
    this.messageboxRef = React.createRef();
    this.loadingboxRef = React.createRef();
    this.confirmboxRef = React.createRef();
    //
    this.imageP211Ref = React.createRef();
    this.imageP212Ref = React.createRef();
    // this.imageP213Ref = React.createRef();
    // this.imageP214Ref = React.createRef();
    this.imageP215Ref = React.createRef();
    this.imageP216Ref = React.createRef();
    this.imageP217Ref = React.createRef();
    this.imageP218Ref = React.createRef();
    this.imageP219Ref = React.createRef();
    this.imageP2110Ref = React.createRef();
    this.imageP2111Ref = React.createRef();
    //
    this.imageP221Ref = React.createRef();
    this.imageP222Ref = React.createRef();
    this.imageP223Ref = React.createRef();
    this.imageP225Ref = React.createRef();
    this.imageP226Ref = React.createRef();
    this.imageP228Ref = React.createRef();
    this.imageP229Ref = React.createRef();
    this.imageP2210Ref = React.createRef();
    this.imageP2211Ref = React.createRef();
    this.imageP2212Ref = React.createRef();
    //
    this.imageP232Ref = React.createRef();
    this.imageP234Ref = React.createRef();
    this.imageP236Ref = React.createRef();
    this.imageP238Ref = React.createRef();
    //
    this.imageP242Ref = React.createRef();
    this.imageP244Ref = React.createRef();
    this.imageP246Ref = React.createRef();
    this.imageP248Ref = React.createRef();
    //
    this.imageP252Ref = React.createRef();
    this.imageP254Ref = React.createRef();
    this.imageP256Ref = React.createRef();
    this.imageP258Ref = React.createRef();
    //
    this.imageP262Ref = React.createRef();
    this.imageP264Ref = React.createRef();
    this.imageP266Ref = React.createRef();
    this.imageP268Ref = React.createRef();
    //
    this.imageP271Ref = React.createRef();
    this.imageP272Ref = React.createRef();
    this.imageP273Ref = React.createRef();
    this.imageP274Ref = React.createRef();
    //
    this.imageP281Ref = React.createRef();
    this.imageP282Ref = React.createRef();
    this.imageP283Ref = React.createRef();
    this.imageP284Ref = React.createRef();
    this.imageP285Ref = React.createRef();
    this.imageP286Ref = React.createRef();
    this.imageP287Ref = React.createRef();
    // State
    this.state = {
      redirect: "",
      ssr: null,
      pat: {
        ssrId: "",
        documentNumber: String,
        cover: {
          projectName: "",
          siteNameEn: "",
          siteNameTh: "",
          siteCode: "",
          region: "",
          zone: "",
          date: "",
          //
          imageCover: "",
          imageQRCodeMaster1: "",
          imageQRCodeSlave2: "",
          imageQRCodeSlave3: "",
          //
          qrCodeMaster1: "",
          qrCodeSlave2: "",
          qrCodeSlave3: "",
          //
          technicianName: "",
          technicianPhone: "",
          technicianContractor: "",
          //
          technicianName2: "",
          technicianPhone2: "",
          technicianContractor2: "",
          //
          remark: "",
          //
          contactPerson: localStorage.getItem("contactPerson"),
          address: localStorage.getItem("address"),
          surveyAndInstallation: "SSK DECHA ENGINEERING LTD.,PART.",
          surveyAndInstallationPhone: "092-328-2997"
        },
        content: {
          // Page 1.1
          p111: "Pass",
          p112: "Pass",
          p113: "Pass",
          p114: "Pass",
          p115: "Pass",
          p116: "Pass",
          p117: "Pass",
          p118: "Pass",
          p119: "Pass",
          p1110: "Pass",
          p1111: "Pass",
          p1112: "Pass",
          p1113: "Pass",
          p1114: "Pass",
          p1115: "Pass",
          p1116: "Pass",
          p1117: "Pass",
          p1118: "Pass",
          p1119: "Pass",
          p1120: "Pass",
          p1121: "Pass",
          //
          p11l11: "Pass",
          p11l12: "Pass",
          p11l13: "Pass",
          p11l14: "Pass",
          p11l21: "Pass",
          p11l22: "Pass",
          p11l23: "Pass",
          p11l24: "Pass",
          p11l25: "Pass",
          p11l26: "Pass",
          p11l27: "Pass",
          p11l28: "Pass",
          // Page 1.2
          p121: "Pass",
          p122: "Pass",
          p123: "N/A",
          p124: "Pass",
          p125: "Pass",
          p126: "Pass",
          p127: "Pass",
          p128: "N/A",
          p129: "Pass",
          p1210: "Pass",
          p1211: "Pass",
          // Page 2.1
          p211: "", // รูปใช้ JIG ในการเจาะตู้ Master 1
          p212: "", // รูปใช้ JIG ในการเจาะตู้ Slave 2
          p213: "",
          p214: "",
          p215: "", // รูป On CB Battery Isolate Master 1
          p216: "", // รูป On CB Battery Isolate Slave 2
          p217: "", // รูป Cable Inlet อุด PU Master 1
          p218: "", // รูป Cable Inlet อุด ซิลิโคน Slave 2
          p219: "", // รูปใช้ JIG ในการเจาะตู้ Slave 3
          p2110: "", // รูป On CB Battery Isolate Slave 3
          p2111: "", // รูป Cable Inlet อุด ซิลิโคน Slave 3
          // Page 2.2
          p221: "/images/patExample/221.jpg",
          p222: "/images/patExample/222.jpg",
          p223: "/images/patExample/223.jpg",
          p224: "/images/patExample/224.jpg",
          p225: "/images/patExample/225.jpg",
          p226: "/images/patExample/226.jpg",
          p227: "/images/patExample/227.jpg",
          p228: "/images/patExample/228.jpg",
          p229: "/images/patExample/229.jpg",
          p2210: "/images/na-vertical.jpg",
          p2211: "/images/na-vertical.jpg",
          p2212: "/images/na-vertical.jpg",
          // Page 2.3
          p231: "",
          p232: "",
          p233: "",
          p234: "",
          p235: "",
          p236: "",
          p237: "",
          p238: "",
          // Page 2.4
          p241: "",
          p242: "",
          p243: "",
          p244: "",
          p245: "",
          p246: "",
          p247: "",
          p248: "",
          // Page 2.5
          p251: "",
          p252: "",
          p253: "",
          p254: "",
          p255: "",
          p256: "",
          p257: "",
          p258: "",
          // Page 2.6
          p261: "",
          p262: "",
          // Page 2.7
          p271: "",
          p272: "",
          p273: "",
          // Page 2.8
          p281: "",
          p282: "",
          p283: "",
          p284: "",
          p285: "",
          p286: "",
          p287: "",
          p288: "",
          // Page 2.8 text
          p281Text: "",
          p282Text: "",
          p283Text: "",
          p284Text: "",
          p285Text: "",
          p286Text: "",
          p287Text: "",
          p288Text: ""
        },
        status: "Waiting Review", // Waiting Review, Reject Review, Waiting Approve, Reject Approve, Approved
        createAt: Moment().format("YYYY-MM-DD"),
        updateAt: Moment().format("YYYY-MM-DD")
      }
    };
  }

  //
  requestData = () => {
    if (this.loadingboxRef.current) this.loadingboxRef.current.onShow();
    let params = new URLSearchParams(window.location.search);
    let id = params.get("id");
    Axios.get(Config.service.url + "/ssr/get/" + id, {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    })
      .then(res => {
        console.log("data = ", res.data);
        if (res.data.success === false) {
          this.messageboxRef.current.onShow("Error", res.data.message);
          return;
        }
        //
        this.setState({ ssr: res.data.data });
        //
        this.setState({
          pat: {
            ssrId: res.data.data._id,
            documentNumber: res.data.data.documentNumber,
            cover: {
              projectName: res.data.data.cover.projectName,
              siteNameEn: res.data.data.cover.siteNameEn,
              siteNameTh: res.data.data.cover.siteNameTh,
              siteCode: res.data.data.cover.siteCode,
              region: res.data.data.cover.region,
              zone: res.data.data.cover.zone,
              ip: res.data.data.cover.ip,
              gateway: res.data.data.cover.gateway,
              subnetMask: res.data.data.cover.subnetMask,
              ipRan: res.data.data.cover.ipRan,
              date: Moment().format("YYYY-MM-DD"),
              imageCover: res.data.data.cover.imageCover,
              //
              imageQRCodeMaster1: res.data.data.cover.imageQRCodeMaster1,
              imageQRCodeSlave2: res.data.data.cover.imageQRCodeSlave2,
              imageQRCodeSlave3: res.data.data.cover.imageQRCodeSlave3,
              qrCodeMaster1: res.data.data.cover.qrCodeMaster1,
              qrCodeSlave2: res.data.data.cover.qrCodeSlave2,
              qrCodeSlave3: res.data.data.cover.qrCodeSlave3,
              //
              technicianName: res.data.data.cover.technicianName,
              technicianPhone: res.data.data.cover.technicianPhone,
              technicianContractor: res.data.data.cover.technicianContractor,
              //
              technicianName2: res.data.data.cover.technicianName2,
              technicianPhone2: res.data.data.cover.technicianPhone2,
              technicianContractor2: res.data.data.cover.technicianContractor2,
              //
              remark: getValue(
                res.data.data.cover.technicianContractor2,
                "",
                ""
              ),
              //
              contactPerson: localStorage.getItem("contactPerson"),
              address: localStorage.getItem("address"),
              surveyAndInstallation: "SSK DECHA ENGINEERING LTD.,PART.",
              surveyAndInstallationPhone: "092-328-2997"
            },
            content: {
              // Page 1.1
              p111: "Pass",
              p112: "Pass",
              p113: "Pass",
              p114: "Pass",
              p115: "Pass",
              p116: "Pass",
              p117: "Pass",
              p118: "Pass",
              p119: "Pass",
              p1110: "Pass",
              p1111: "Pass",
              p1112: "Pass",
              p1113: "Pass",
              p1114: "Pass",
              p1115: "Pass",
              p1116: "Pass",
              p1117: "Pass",
              p1118: "Pass",
              p1119: "Pass",
              p1120: "Pass",
              p1121: "Pass",
              //
              p11l11: "Pass",
              p11l12: "Pass",
              p11l13: "Pass",
              p11l14: "Pass",
              p11l21: "Pass",
              p11l22: "Pass",
              p11l23: "Pass",
              p11l24: "Pass",
              p11l25: "Pass",
              p11l26: "Pass",
              p11l27: "Pass",
              p11l28: "Pass",
              // Page 1.2
              p121: "Pass",
              p122: "Pass",
              p123: "N/A",
              p124: "Pass",
              p125: "Pass",
              p126: "Pass",
              p127: "Pass",
              p128: "N/A",
              p129: "Pass",
              p1210: "Pass",
              p1211: "Pass",
              // Page 2.1
              p211: "", // รูปใช้ JIG ในการเจาะตู้ Master 1
              p212: "", // รูปใช้ JIG ในการเจาะตู้ Slave 2
              p213: "",
              p214: "",
              p215: "", // รูป On CB Battery Isolate Master 1
              p216: "", // รูป On CB Battery Isolate Slave 2
              p217: "", // รูป Cable Inlet อุด PU Master 1
              p218: "", // รูป Cable Inlet อุด ซิลิโคน Slave 2
              p219: "", // รูปใช้ JIG ในการเจาะตู้ Slave 3
              p2110: "", // รูป On CB Battery Isolate Slave 3
              p2111: "", // รูป Cable Inlet อุด ซิลิโคน Slave 3
              // Page 2.2
              p221: "/images/patExample/221.jpg",
              p222: "/images/patExample/222.jpg",
              p223: "/images/patExample/223.jpg",
              p224: "/images/patExample/224.jpg",
              p225: "/images/patExample/225.jpg",
              p226: "/images/patExample/226.jpg",
              p227: "/images/patExample/227.jpg",
              p228: "/images/patExample/228.jpg",
              p229: "/images/patExample/229.jpg",
              p2210: "/images/na-vertical.jpg",
              p2211: "/images/na-vertical.jpg",
              p2212: "/images/na-vertical.jpg",
              // Page 2.3
              p231: res.data.data.master1.imageM16,
              p232: "",
              p233: res.data.data.master1.imageM18,
              p234: "",
              p235: res.data.data.master1.imageM15,
              p236: "",
              p237: res.data.data.master1.imageM17,
              p238: "",
              // Page 2.4
              p241: res.data.data.master1.imageM13,
              p242: "",
              p243: res.data.data.master1.imageM14,
              p244: "",
              p245: res.data.data.master1.imageM11,
              p246: "",
              p247: res.data.data.master1.imageM12,
              p248: "",
              // Page 2.5
              p251: res.data.data.slave2.imageS22,
              p252: "",
              p253: res.data.data.slave2.imageS23,
              p254: "",
              p255: res.data.data.slave2.imageS24,
              p256: "",
              p257: res.data.data.slave2.imageS21,
              p258: "",
              // Page 2.6
              p261: res.data.data.slave3.imageS32,
              p262: "",
              p263: res.data.data.slave3.imageS33,
              p264: "",
              p265: res.data.data.slave3.imageS34,
              p266: "",
              p267: res.data.data.slave3.imageS31,
              p268: "",
              // Page 2.7
              p271: "",
              p272: "",
              p273: "",
              p274: "",
              // Page 2.8
              p281: "",
              p282: "",
              p283: "",
              p284: "",
              p285: "",
              p286: "",
              p287: "",
              // Page 2.8 text
              p281Text: "",
              p282Text: "",
              p283Text: "",
              p284Text: "",
              p285Text: "",
              p286Text: "",
              p287Text: "",
              p288Text: ""
            }
          }
        });
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        if (this.loadingboxRef.current) this.loadingboxRef.current.onHide();
      });
  };

  //
  onCoverChange = e => {
    const { name, value } = e.target;
    let pat = { ...this.state.pat };
    pat.cover[name] = value;
    this.setState({ pat: pat });
  };

  //
  onCoverChangeToLocalStorage = e => {
    const { name, value } = e.target;
    localStorage.setItem(name, value);
    let pat = { ...this.state.pat };
    pat.cover[name] = value;
    this.setState({ pat: pat });
  };

  //
  onContentChange = e => {
    const { name, value } = e.target;
    let pat = { ...this.state.pat };
    pat.content[name] = value;
    this.setState({ pat: pat });
  };

  //
  onSubmit = () => {
    if (this.loadingboxRef.current) this.loadingboxRef.current.onShow();
    //
    let data = new FormData();
    data.append("data", JSON.stringify(this.state.pat));
    //
    data.append("p211", this.imageP211Ref.current.getState().file);
    data.append("p212", this.imageP212Ref.current.getState().file);
    // data.append('p213', this.imageP213Ref.current.getState().file);
    // data.append('p214', this.imageP214Ref.current.getState().file);
    data.append("p215", this.imageP215Ref.current.getState().file);
    data.append("p216", this.imageP216Ref.current.getState().file);
    data.append("p217", this.imageP217Ref.current.getState().file);
    data.append("p218", this.imageP218Ref.current.getState().file);
    data.append("p219", this.imageP219Ref.current.getState().file);
    data.append("p2110", this.imageP2110Ref.current.getState().file);
    data.append("p2111", this.imageP2110Ref.current.getState().file);
    //
    data.append("p221", this.imageP221Ref.current.getState().file);
    data.append("p222", this.imageP222Ref.current.getState().file);
    data.append("p223", this.imageP223Ref.current.getState().file);
    data.append("p225", this.imageP225Ref.current.getState().file);
    data.append("p226", this.imageP226Ref.current.getState().file);
    data.append("p228", this.imageP228Ref.current.getState().file);
    data.append("p229", this.imageP229Ref.current.getState().file);
    data.append("p2210", this.imageP2210Ref.current.getState().file);
    data.append("p2211", this.imageP2211Ref.current.getState().file);
    data.append("p2212", this.imageP2212Ref.current.getState().file);
    //
    data.append("p232", this.imageP232Ref.current.getState().file);
    data.append("p234", this.imageP234Ref.current.getState().file);
    data.append("p236", this.imageP236Ref.current.getState().file);
    data.append("p238", this.imageP238Ref.current.getState().file);
    //
    data.append("p242", this.imageP242Ref.current.getState().file);
    data.append("p244", this.imageP244Ref.current.getState().file);
    data.append("p246", this.imageP246Ref.current.getState().file);
    data.append("p248", this.imageP248Ref.current.getState().file);
    //
    data.append("p252", this.imageP252Ref.current.getState().file);
    data.append("p254", this.imageP254Ref.current.getState().file);
    data.append("p256", this.imageP256Ref.current.getState().file);
    data.append("p258", this.imageP258Ref.current.getState().file);
    //
    data.append("p262", this.imageP262Ref.current.getState().file);
    data.append("p264", this.imageP264Ref.current.getState().file);
    data.append("p266", this.imageP266Ref.current.getState().file);
    data.append("p268", this.imageP268Ref.current.getState().file);
    //
    data.append("p271", this.imageP271Ref.current.getState().file);
    data.append("p272", this.imageP272Ref.current.getState().file);
    data.append("p273", this.imageP273Ref.current.getState().file);
    data.append("p274", this.imageP274Ref.current.getState().file);
    //
    data.append("p281", this.imageP281Ref.current.getState().file);
    data.append("p282", this.imageP282Ref.current.getState().file);
    data.append("p283", this.imageP283Ref.current.getState().file);
    data.append("p284", this.imageP284Ref.current.getState().file);
    data.append("p285", this.imageP285Ref.current.getState().file);
    data.append("p286", this.imageP286Ref.current.getState().file);
    data.append("p287", this.imageP287Ref.current.getState().file);
    //
    Axios.post(Config.service.url + "/pat/add", data, {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    })
      .then(res => {
        if (res.data.success === false) {
          this.messageboxRef.current.onShow("Error", res.data.message);
          this.loadingboxRef.current.onHide();
          return;
        }
        if (this.loadingboxRef.current) this.loadingboxRef.current.onHide();
        this.setState({
          redirect: "/patTechnicianEdit?id=" + res.data.data._id
        });
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {});
  };

  //
  componentDidMount = () => {
    if (localStorage.getItem("token") === null) {
      return;
    }
    this.requestData();
  };

  //
  render = () => {
    //
    if (localStorage.getItem("token") === null) {
      return <Redirect push to="/login" />;
    }

    // Redirect
    if (this.state.redirect) {
      return <Redirect push to={this.state.redirect} />;
    }

    return (
      <>
        <MenuTechnician />
        <MessageBox ref={this.messageboxRef} />
        <LoadingBox ref={this.loadingboxRef} />
        <ConfirmBox ref={this.confirmboxRef} />
        <div className="App-body">
          <Row>
            <Col>
              <div>
                <table width="100%">
                  <tbody>
                    <tr>
                      <td>
                        <h5>PAT Document</h5>
                      </td>
                      <td width="100" align="right">
                        <Button variant="success" onClick={this.onSubmit}>
                          Save
                        </Button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <hr />
              <div style={{ textAlign: "center", marginBottom: "10px" }}>
                <h4>{this.state.pat.cover.projectName}</h4>
              </div>
              <Form>
                <Tabs
                  id={React.createRef()}
                  defaultActiveKey="cover"
                  onSelect={this.onTabSelect}
                >
                  {/*  */}
                  <Tab eventKey="cover" title="Cover">
                    <div style={{ marginTop: "10px" }}>
                      <Card border="primary">
                        <Card.Header>Cover's Detail</Card.Header>
                        <Card.Body>
                          <Form.Group>
                            <Form.Label>Contact Person</Form.Label>
                            <Form.Control
                              type="text"
                              name="contactPerson"
                              defaultValue={this.state.pat.cover.contactPerson}
                              onChange={this.onCoverChangeToLocalStorage}
                            />
                          </Form.Group>
                          <Form.Group>
                            <Form.Label>Address</Form.Label>
                            <Form.Control
                              type="text"
                              name="address"
                              defaultValue={this.state.pat.cover.address}
                              onChange={this.onCoverChangeToLocalStorage}
                            />
                          </Form.Group>
                          <Form.Group>
                            <Form.Label>Site Code</Form.Label>
                            <Form.Control
                              type="text"
                              name="siteCode"
                              defaultValue={this.state.pat.cover.siteCode}
                              readOnly
                            />
                          </Form.Group>
                          <Form.Group>
                            <Form.Label>Region</Form.Label>
                            <Form.Control
                              type="text"
                              name="region"
                              defaultValue={this.state.pat.cover.region}
                              readOnly
                            />
                          </Form.Group>
                          <Form.Group>
                            <Form.Label>Site Name EN</Form.Label>
                            <Form.Control
                              type="text"
                              name="siteNameEn"
                              defaultValue={this.state.pat.cover.siteNameEn}
                              readOnly
                            />
                          </Form.Group>
                          <Form.Group>
                            <Form.Label>Site Name TH</Form.Label>
                            <Form.Control
                              type="text"
                              name="siteNameTh"
                              defaultValue={this.state.pat.cover.siteNameTh}
                              readOnly
                            />
                          </Form.Group>
                          <Form.Group>
                            <Form.Label>Zone</Form.Label>
                            <Form.Control
                              type="text"
                              name="zone"
                              defaultValue={this.state.pat.cover.zone}
                              readOnly
                            />
                          </Form.Group>
                          <Form.Group>
                            <Form.Label>IP</Form.Label>
                            <Form.Control
                              type="text"
                              name="ip"
                              defaultValue={this.state.pat.cover.ip}
                              onChange={this.onCoverChange}
                            />
                          </Form.Group>
                          <Form.Group>
                            <Form.Label>Gateway</Form.Label>
                            <Form.Control
                              type="text"
                              name="gateway"
                              defaultValue={this.state.pat.cover.gateway}
                              onChange={this.onCoverChange}
                            />
                          </Form.Group>
                          <Form.Group>
                            <Form.Label>Subnet Mask</Form.Label>
                            <Form.Control
                              type="text"
                              name="subnetMask"
                              defaultValue={this.state.pat.cover.subnetMask}
                              onChange={this.onCoverChange}
                            />
                          </Form.Group>
                          <Form.Group>
                            <Form.Label>IPRAN</Form.Label>
                            <Form.Control
                              type="text"
                              name="ipRan"
                              defaultValue={this.state.pat.cover.ipRan}
                              onChange={this.onCoverChange}
                            />
                          </Form.Group>
                          <hr />
                          <Form.Group>
                            <Form.Label>Technician Name 1</Form.Label>
                            <Form.Control
                              type="text"
                              name="technicianName"
                              defaultValue={this.state.pat.cover.technicianName}
                              readOnly
                            />
                          </Form.Group>
                          <Form.Group>
                            <Form.Label>Technician Phone 1</Form.Label>
                            <Form.Control
                              type="text"
                              name="technicianPhone"
                              defaultValue={
                                this.state.pat.cover.technicianPhone
                              }
                              readOnly
                            />
                          </Form.Group>
                          <Form.Group>
                            <Form.Label>Technician Name 2</Form.Label>
                            <Form.Control
                              type="text"
                              name="technicianName2"
                              defaultValue={
                                this.state.pat.cover.technicianName2
                              }
                              readOnly
                            />
                          </Form.Group>
                          <Form.Group>
                            <Form.Label>Technician Phone 2</Form.Label>
                            <Form.Control
                              type="text"
                              name="technicianPhone2"
                              defaultValue={
                                this.state.pat.cover.technicianPhone2
                              }
                              readOnly
                            />
                          </Form.Group>
                          <Form.Group>
                            <Form.Label>Date</Form.Label>
                            <Form.Control
                              type="date"
                              name="date"
                              onChange={this.onCoverChange}
                              defaultValue={this.state.pat.cover.date}
                            />
                          </Form.Group>
                          <Form.Group>
                            <Form.Label>Remark</Form.Label>
                            <Form.Control
                              type="text"
                              name="remark"
                              defaultValue={this.state.pat.cover.remark}
                            />
                          </Form.Group>
                          <hr />
                          <Form.Group>
                            <Form.Label>Surver & Installaion by</Form.Label>
                            <Form.Control
                              type="text"
                              name="surveyAndInstallation"
                              defaultValue={
                                this.state.pat.cover.surveyAndInstallation
                              }
                              disabled
                            />
                          </Form.Group>
                          <Form.Group>
                            <Form.Label>Surver & Installaion Phone</Form.Label>
                            <Form.Control
                              type="text"
                              name="surveyAndInstallationPhone"
                              defaultValue={
                                this.state.pat.cover.surveyAndInstallationPhone
                              }
                              disabled
                            />
                          </Form.Group>
                        </Card.Body>
                      </Card>
                      <Card style={{ marginTop: "10px" }} border="primary">
                        <Card.Header>Cover's Image</Card.Header>
                        <Card.Body>
                          <Form.Group>
                            <Image
                              src={
                                this.state.pat.cover.imageCover !== ""
                                  ? Config.service.url +
                                    "/ssr/image/" +
                                    this.state.pat.cover.imageCover
                                  : "/images/na-vertical.jpg"
                              }
                              style={{ width: "100%", marginTop: "5px" }}
                              rounded
                              fluid
                            />
                          </Form.Group>
                        </Card.Body>
                      </Card>
                      <div style={{ marginTop: "5px" }}>
                        <Card border="primary">
                          <Card.Header>QR Code Master 1</Card.Header>
                          <Card.Body>
                            QR Code Master :{" "}
                            {this.state.pat.cover.qrCodeMaster1}
                            <br />
                            <br />
                            <Image
                              src={
                                this.state.pat !== null &&
                                this.state.pat.cover.imageQRCodeMaster1 !== ""
                                  ? Config.service.url +
                                    "/ssr/image/" +
                                    this.state.pat.cover.imageQRCodeMaster1
                                  : "/images/na-vertical.jpg"
                              }
                              style={{ width: "100%" }}
                              rounded
                              fluid
                            />
                          </Card.Body>
                        </Card>
                      </div>
                      <div style={{ marginTop: "5px" }}>
                        <Card border="primary">
                          <Card.Header>QR Code Slave 2</Card.Header>
                          <Card.Body>
                            QR Code Slave : {this.state.pat.cover.qrCodeSlave2}
                            <br />
                            <br />
                            <Image
                              src={
                                this.state.pat !== null &&
                                this.state.pat.cover.imageQRCodeSlave2 !== ""
                                  ? Config.service.url +
                                    "/ssr/image/" +
                                    this.state.pat.cover.imageQRCodeSlave2
                                  : "/images/na-vertical.jpg"
                              }
                              style={{ width: "100%" }}
                              rounded
                              fluid
                            />
                          </Card.Body>
                        </Card>
                      </div>
                      <div style={{ marginTop: "5px" }}>
                        <Card border="primary">
                          <Card.Header>QR Code Slave 3</Card.Header>
                          <Card.Body>
                            QR Code Slave : {this.state.pat.cover.qrCodeSlave3}
                            <br />
                            <br />
                            <Image
                              src={
                                this.state.pat !== null &&
                                this.state.pat.cover.imageQRCodeSlave3 !== ""
                                  ? Config.service.url +
                                    "/ssr/image/" +
                                    this.state.pat.cover.imageQRCodeSlave3
                                  : "/images/na-vertical.jpg"
                              }
                              style={{ width: "100%" }}
                              rounded
                              fluid
                            />
                          </Card.Body>
                        </Card>
                      </div>
                    </div>
                  </Tab>
                  {/*  */}
                  <Tab eventKey="content" title="Content">
                    <div style={{ marginTop: "10px" }}>
                      <table width="100%">
                        <tbody>
                          <tr>
                            <td align="center" colSpan="2">
                              <h4>Contents</h4>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan="2">1. Equipment check</td>
                          </tr>
                          <tr>
                            <td width="50"></td>
                            <td>1.1 Installation check list</td>
                          </tr>
                          <tr>
                            <td></td>
                            <td>1.2 Alarm Test Report</td>
                          </tr>
                          <tr>
                            <td colSpan="2">2. Photo</td>
                          </tr>
                          <tr>
                            <td></td>
                            <td>2.1 Physical Photo</td>
                          </tr>
                          <tr>
                            <td></td>
                            <td>2.2 Logical Test Photo</td>
                          </tr>
                          <tr>
                            <td></td>
                            <td>2.3 Photo Master 1 After - Before</td>
                          </tr>
                          <tr>
                            <td></td>
                            <td>2.4 Photo Master 1 After - Before</td>
                          </tr>
                          <tr>
                            <td></td>
                            <td>2.5 Photo Slave 2 After - Before</td>
                          </tr>
                          <tr>
                            <td></td>
                            <td>2.6 Photo Slave 3 After - Before</td>
                          </tr>
                          <tr>
                            <td></td>
                            <td>2.7 Photo Master - Slave Install Completed</td>
                          </tr>
                          <tr>
                            <td></td>
                            <td>2.8 Photo Key</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </Tab>
                  {/*  */}
                  <Tab eventKey="1.1" title="1.1">
                    <div style={{ padding: "5px" }}>
                      <div style={{ textAlign: "center", margin: "10px" }}>
                        <h4>Installation check list</h4>
                      </div>
                      <div style={{ textAlign: "center" }}>
                        <h5>Physical Check</h5>
                      </div>
                      <Table striped bordered hover size="sm">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Detail</th>
                            <th>Result</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>1</td>
                            <td>
                              ติดตั้ง Master Control ที่บริเวณข้าง Rack
                              และไม่กีดขวางจุดยึดอุปกรณ์อื่น
                            </td>
                            <td>
                              <select
                                name="p111"
                                className="custom-select"
                                onChange={this.onContentChange}
                                value={this.state.pat.content.p111}
                              >
                                <option value="Pass" defaultValue>
                                  Pass
                                </option>
                                <option value="Fail">Fail</option>
                                <option value="N/A">N/A</option>
                              </select>
                            </td>
                          </tr>
                          <tr>
                            <td>2</td>
                            <td>
                              ติตตั้ง Motion Sensor ที่คานใต้ตู้ Rack , Wiring
                              สายต่อเข้ากล่องและติด Label{" "}
                            </td>
                            <td width="100">
                              <select
                                name="p112"
                                className="custom-select"
                                onChange={this.onContentChange}
                                value={this.state.pat.content.p112}
                              >
                                <option value="Pass" defaultValue>
                                  Pass
                                </option>
                                <option value="Fail">Fail</option>
                                <option value="N/A">N/A</option>
                              </select>
                            </td>
                          </tr>
                          <tr>
                            <td>3</td>
                            <td>
                              Wiring LAN Cable เสียบเข้า IPRAN เช็ค Port Up
                              พร้อมใช้งานและติด Label
                            </td>
                            <td width="100">
                              <select
                                name="p113"
                                className="custom-select"
                                onChange={this.onContentChange}
                                value={this.state.pat.content.p113}
                              >
                                <option value="Pass" defaultValue>
                                  Pass
                                </option>
                                <option value="Fail">Fail</option>
                                <option value="N/A">N/A</option>
                              </select>
                            </td>
                          </tr>
                          <tr>
                            <td>4</td>
                            <td>
                              ติตตั้ง Siren ที่ตำแหน่งข้าง Rack , Wiring
                              สายต่อเข้ากล่องและติด Label{" "}
                            </td>
                            <td width="100">
                              <select
                                name="p114"
                                className="custom-select"
                                onChange={this.onContentChange}
                                value={this.state.pat.content.p114}
                              >
                                <option value="Pass" defaultValue>
                                  Pass
                                </option>
                                <option value="Fail">Fail</option>
                                <option value="N/A">N/A</option>
                              </select>
                            </td>
                          </tr>
                          <tr>
                            <td>5</td>
                            <td>
                              รูปการใช้ JIG ในการติตตั้ง Door lock ตู้ Master ,
                              Wiring สายต่อเข้ากล่องและติด Label{" "}
                            </td>
                            <td width="100">
                              <select
                                name="p115"
                                className="custom-select"
                                onChange={this.onContentChange}
                                value={this.state.pat.content.p115}
                              >
                                <option value="Pass" defaultValue>
                                  Pass
                                </option>
                                <option value="Fail">Fail</option>
                                <option value="N/A">N/A</option>
                              </select>
                            </td>
                          </tr>
                          <tr>
                            <td>6</td>
                            <td>
                              รูปติดตั้ง Electric Door Lock ที่ขอบประตูตู้
                              Master
                            </td>
                            <td width="100">
                              <select
                                name="p116"
                                className="custom-select"
                                onChange={this.onContentChange}
                                value={this.state.pat.content.p116}
                              >
                                <option value="Pass" defaultValue>
                                  Pass
                                </option>
                                <option value="Fail">Fail</option>
                                <option value="N/A">N/A</option>
                              </select>
                            </td>
                          </tr>
                          <tr>
                            <td>7</td>
                            <td>รูปติดตั้งหูช้างที่บานประตู Master</td>
                            <td width="100">
                              <select
                                name="p117"
                                className="custom-select"
                                onChange={this.onContentChange}
                                value={this.state.pat.content.p117}
                              >
                                <option value="Pass" defaultValue>
                                  Pass
                                </option>
                                <option value="Fail">Fail</option>
                                <option value="N/A">N/A</option>
                              </select>
                            </td>
                          </tr>
                          <tr>
                            <td>8</td>
                            <td>
                              ติดตั้ง DC Power Cable จาก Isolate Batt Wiring
                              มาที่กล่อง Master และติด Label
                            </td>
                            <td width="100">
                              <select
                                name="p118"
                                className="custom-select"
                                onChange={this.onContentChange}
                                value={this.state.pat.content.p118}
                              >
                                <option value="Pass" defaultValue>
                                  Pass
                                </option>
                                <option value="Fail">Fail</option>
                                <option value="N/A">N/A</option>
                              </select>
                            </td>
                          </tr>
                          {/* <tr>
                                                        <td>9</td>
                                                        <td>รูปถ่ายประแจ Torgue ที่ขั้วแบตเตอรี่  (ค่า Torgue = ............Nm )</td>
                                                        <td width="100">
                                                            <select name='p119' className="custom-select" onChange={this.onContentChange} value={this.state.pat.content.p119}>
                                                                <option value="Pass" defaultValue>Pass</option>
                                                                <option value="Fail">Fail</option>
                                                                <option value="N/A">N/A</option>
                                                            </select>
                                                        </td>
                                                    </tr> */}
                          <tr>
                            <td>9</td>
                            <td>
                              รูปถ่ายจุดเชื่อมต่อสาย DC Power ที่ CB Batt
                              Isolate และ On Breaker Master
                            </td>
                            <td width="100">
                              <select
                                name="p1110"
                                className="custom-select"
                                onChange={this.onContentChange}
                                value={this.state.pat.content.p1110}
                              >
                                <option value="Pass" defaultValue>
                                  Pass
                                </option>
                                <option value="Fail">Fail</option>
                                <option value="N/A">N/A</option>
                              </select>
                            </td>
                          </tr>
                          <tr>
                            <td>10</td>
                            <td>รูปถ่ายตำแหน่งติดตั้งกล้อง USB</td>
                            <td width="100">
                              <select
                                name="p1111"
                                className="custom-select"
                                onChange={this.onContentChange}
                                value={this.state.pat.content.p1111}
                              >
                                <option value="Pass" defaultValue>
                                  Pass
                                </option>
                                <option value="Fail">Fail</option>
                                <option value="N/A">N/A</option>
                              </select>
                            </td>
                          </tr>
                          <tr>
                            <td>11</td>
                            <td>
                              รูปแสดงจุด Inlet ภายนอกตู้ RS485 Cable
                              (ช่องร้อยสาย RS485 อุดด้วย PU) Master
                            </td>
                            <td width="100">
                              <select
                                name="p1112"
                                className="custom-select"
                                onChange={this.onContentChange}
                                value={this.state.pat.content.p1112}
                              >
                                <option value="Pass" defaultValue>
                                  Pass
                                </option>
                                <option value="Fail">Fail</option>
                                <option value="N/A">N/A</option>
                              </select>
                            </td>
                          </tr>
                          <tr>
                            <td>12</td>
                            <td>
                              ติดตั้ง Slave Control ที่บริเวณข้าง Rack
                              และไม่กีดขวางจุดยึดอุปกรณ์อื่น
                            </td>
                            <td width="100">
                              <select
                                name="p1113"
                                className="custom-select"
                                onChange={this.onContentChange}
                                value={this.state.pat.content.p1113}
                              >
                                <option value="Pass" defaultValue>
                                  Pass
                                </option>
                                <option value="Fail">Fail</option>
                                <option value="N/A">N/A</option>
                              </select>
                            </td>
                          </tr>
                          <tr>
                            <td>13</td>
                            <td>
                              รูปการใช้ JIG ในการติตตั้ง Door lock ตู้ Slave ,
                              Wiring สายต่อเข้ากล่องและติด Label{" "}
                            </td>
                            <td width="100">
                              <select
                                name="p1114"
                                className="custom-select"
                                onChange={this.onContentChange}
                                value={this.state.pat.content.p1114}
                              >
                                <option value="Pass" defaultValue>
                                  Pass
                                </option>
                                <option value="Fail">Fail</option>
                                <option value="N/A">N/A</option>
                              </select>
                            </td>
                          </tr>
                          <tr>
                            <td>14</td>
                            <td>
                              รูปติดตั้ง Electric Door Lock ที่ขอบประตูตู้ Slave
                            </td>
                            <td width="100">
                              <select
                                name="p1115"
                                className="custom-select"
                                onChange={this.onContentChange}
                                value={this.state.pat.content.p1115}
                              >
                                <option value="Pass" defaultValue>
                                  Pass
                                </option>
                                <option value="Fail">Fail</option>
                                <option value="N/A">N/A</option>
                              </select>
                            </td>
                          </tr>
                          <tr>
                            <td>15</td>
                            <td>รูปติดตั้งหูช้างที่บานประตู Slave</td>
                            <td width="100">
                              <select
                                name="p1116"
                                className="custom-select"
                                onChange={this.onContentChange}
                                value={this.state.pat.content.p1116}
                              >
                                <option value="Pass" defaultValue>
                                  Pass
                                </option>
                                <option value="Fail">Fail</option>
                                <option value="N/A">N/A</option>
                              </select>
                            </td>
                          </tr>
                          <tr>
                            <td>16</td>
                            <td>
                              ติดตั้ง DC Power Cable จาก Isolate Batt Wiring
                              มาที่กล่อง Slave และติด Label
                            </td>
                            <td width="100">
                              <select
                                name="p1117"
                                className="custom-select"
                                onChange={this.onContentChange}
                                value={this.state.pat.content.p1117}
                              >
                                <option value="Pass" defaultValue>
                                  Pass
                                </option>
                                <option value="Fail">Fail</option>
                                <option value="N/A">N/A</option>
                              </select>
                            </td>
                          </tr>
                          <tr>
                            <td>17</td>
                            <td>
                              รูปถ่ายจุดเชื่อมต่อสาย DC Power ที่ CB Batt
                              Isolate และ On Breaker Slave
                            </td>
                            <td width="100">
                              <select
                                name="p1118"
                                className="custom-select"
                                onChange={this.onContentChange}
                                value={this.state.pat.content.p1118}
                              >
                                <option value="Pass" defaultValue>
                                  Pass
                                </option>
                                <option value="Fail">Fail</option>
                                <option value="N/A">N/A</option>
                              </select>
                            </td>
                          </tr>
                          {/* <tr>
                                                        <td>19</td>
                                                        <td>รูปถ่ายประแจ Torgue ที่ขั้วแบตเตอรี่  (ค่า Torgue =    5  Nm? )</td>
                                                        <td width="100">
                                                            <select name='p1119' className="custom-select" onChange={this.onContentChange} value={this.state.pat.content.p1119}>
                                                                <option value="Pass" defaultValue>Pass</option>
                                                                <option value="Fail">Fail</option>
                                                                <option value="N/A">N/A</option>
                                                            </select>
                                                        </td>
                                                    </tr> */}
                          <tr>
                            <td>18</td>
                            <td>
                              รูปแสดงจุด Inlet ภายนอกตู้ RS485 Cable
                              (ช่องร้อยสาย RS485 อุดด้วย PU) Slave
                            </td>
                            <td width="100">
                              <select
                                name="p1120"
                                className="custom-select"
                                onChange={this.onContentChange}
                                value={this.state.pat.content.p1120}
                              >
                                <option value="Pass" defaultValue>
                                  Pass
                                </option>
                                <option value="Fail">Fail</option>
                                <option value="N/A">N/A</option>
                              </select>
                            </td>
                          </tr>
                          <tr>
                            <td>19</td>
                            <td>
                              รูปแนว Ladder เดินสายข้ามตู้ ระหว่าง Master -
                              Slave
                            </td>
                            <td width="100">
                              <select
                                name="p1121"
                                className="custom-select"
                                onChange={this.onContentChange}
                                value={this.state.pat.content.p1121}
                              >
                                <option value="Pass" defaultValue>
                                  Pass
                                </option>
                                <option value="Fail">Fail</option>
                                <option value="N/A">N/A</option>
                              </select>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                      <div style={{ textAlign: "center" }}>
                        <h5>Logical Test</h5>
                      </div>
                      <Table striped bordered hover size="sm">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Detail</th>
                            <th>Result</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>1</td>
                            <td>
                              รูป Capture จาก App Smart Site Setup (Local Test)
                            </td>
                            <td width="100px"></td>
                          </tr>
                          <tr>
                            <td></td>
                            <td>IP Address</td>
                            <td>
                              <select
                                name="p11l11"
                                className="custom-select"
                                onChange={this.onContentChange}
                                value={
                                  this.state.pat !== null
                                    ? this.state.pat.content.p11l11
                                    : "Pass"
                                }
                              >
                                <option value="Pass" defaultValue>
                                  Pass
                                </option>
                                <option value="Fail">Fail</option>
                                <option value="N/A">N/A</option>
                              </select>
                            </td>
                          </tr>
                          <tr>
                            <td></td>
                            <td>การเชื่อมต่อ Sensor</td>
                            <td>
                              <select
                                name="p11l12"
                                className="custom-select"
                                onChange={this.onContentChange}
                                value={
                                  this.state.pat !== null
                                    ? this.state.pat.content.p11l12
                                    : "Pass"
                                }
                              >
                                <option value="Pass" defaultValue>
                                  Pass
                                </option>
                                <option value="Fail">Fail</option>
                                <option value="N/A">N/A</option>
                              </select>
                            </td>
                          </tr>
                          <tr>
                            <td></td>
                            <td>
                              Connection ping to server 10.242.15.21 ,
                              10.242.15.22
                            </td>
                            <td>
                              <select
                                name="p11l13"
                                className="custom-select"
                                onChange={this.onContentChange}
                                value={
                                  this.state.pat !== null
                                    ? this.state.pat.content.p11l13
                                    : "Pass"
                                }
                              >
                                <option value="Pass" defaultValue>
                                  Pass
                                </option>
                                <option value="Fail">Fail</option>
                                <option value="N/A">N/A</option>
                              </select>
                            </td>
                          </tr>
                          <tr>
                            <td></td>
                            <td>
                              รูปถ่ายจากกล้อง Smart Site
                              สามารถมองเห็นภาพของคนที่เข้าทำงานได้ชัดเจน
                            </td>
                            <td>
                              <select
                                name="p11l14"
                                className="custom-select"
                                onChange={this.onContentChange}
                                value={
                                  this.state.pat !== null
                                    ? this.state.pat.content.p11l14
                                    : "Pass"
                                }
                              >
                                <option value="Pass" defaultValue>
                                  Pass
                                </option>
                                <option value="Fail">Fail</option>
                                <option value="N/A">N/A</option>
                              </select>
                            </td>
                          </tr>
                          {/*  */}
                          <tr>
                            <td>2</td>
                            <td>รูป Capture จาก TTSM App</td>
                            <td width="100px"></td>
                          </tr>
                          <tr>
                            <td></td>
                            <td>Normal Case</td>
                            <td>
                              <select
                                name="p11l21"
                                className="custom-select"
                                onChange={this.onContentChange}
                                value={
                                  this.state.pat !== null
                                    ? this.state.pat.content.p11l21
                                    : "Pass"
                                }
                              >
                                <option value="Pass" defaultValue>
                                  Pass
                                </option>
                                <option value="Fail">Fail</option>
                                <option value="N/A">N/A</option>
                              </select>
                            </td>
                          </tr>
                          <tr>
                            <td></td>
                            <td>Lock</td>
                            <td>
                              <select
                                name="p11l22"
                                className="custom-select"
                                onChange={this.onContentChange}
                                value={
                                  this.state.pat !== null
                                    ? this.state.pat.content.p11l22
                                    : "Pass"
                                }
                              >
                                <option value="Pass" defaultValue>
                                  Pass
                                </option>
                                <option value="Fail">Fail</option>
                                <option value="N/A">N/A</option>
                              </select>
                            </td>
                          </tr>
                          <tr>
                            <td></td>
                            <td>Unlock</td>
                            <td>
                              <select
                                name="p11l23"
                                className="custom-select"
                                onChange={this.onContentChange}
                                value={
                                  this.state.pat !== null
                                    ? this.state.pat.content.p11l23
                                    : "Pass"
                                }
                              >
                                <option value="Pass" defaultValue>
                                  Pass
                                </option>
                                <option value="Fail">Fail</option>
                                <option value="N/A">N/A</option>
                              </select>
                            </td>
                          </tr>
                          <tr>
                            <td></td>
                            <td>ผล Alarm Test</td>
                            <td>
                              <select
                                name="p11l24"
                                className="custom-select"
                                onChange={this.onContentChange}
                                value={
                                  this.state.pat !== null
                                    ? this.state.pat.content.p11l24
                                    : "Pass"
                                }
                              >
                                <option value="Pass" defaultValue>
                                  Pass
                                </option>
                                <option value="Fail">Fail</option>
                                <option value="N/A">N/A</option>
                              </select>
                            </td>
                          </tr>
                          <tr>
                            <td></td>
                            <td>Emergency Case</td>
                            <td>
                              <select
                                name="p11l25"
                                className="custom-select"
                                onChange={this.onContentChange}
                                value={
                                  this.state.pat !== null
                                    ? this.state.pat.content.p11l25
                                    : "Pass"
                                }
                              >
                                <option value="Pass" defaultValue>
                                  Pass
                                </option>
                                <option value="Fail">Fail</option>
                                <option value="N/A">N/A</option>
                              </select>
                            </td>
                          </tr>
                          <tr>
                            <td></td>
                            <td>รูป Pair Bluetooth สำเร็จ</td>
                            <td>
                              <select
                                name="p11l26"
                                className="custom-select"
                                onChange={this.onContentChange}
                                value={
                                  this.state.pat !== null
                                    ? this.state.pat.content.p11l26
                                    : "Pass"
                                }
                              >
                                <option value="Pass" defaultValue>
                                  Pass
                                </option>
                                <option value="Fail">Fail</option>
                                <option value="N/A">N/A</option>
                              </select>
                            </td>
                          </tr>
                          <tr>
                            <td></td>
                            <td>Lock</td>
                            <td>
                              <select
                                name="p11l27"
                                className="custom-select"
                                onChange={this.onContentChange}
                                value={
                                  this.state.pat !== null
                                    ? this.state.pat.content.p11l27
                                    : "Pass"
                                }
                              >
                                <option value="Pass" defaultValue>
                                  Pass
                                </option>
                                <option value="Fail">Fail</option>
                                <option value="N/A">N/A</option>
                              </select>
                            </td>
                          </tr>
                          <tr>
                            <td></td>
                            <td>Unlock</td>
                            <td>
                              <select
                                name="p11l28"
                                className="custom-select"
                                onChange={this.onContentChange}
                                value={
                                  this.state.pat !== null
                                    ? this.state.pat.content.p11l28
                                    : "Pass"
                                }
                              >
                                <option value="Pass" defaultValue>
                                  Pass
                                </option>
                                <option value="Fail">Fail</option>
                                <option value="N/A">N/A</option>
                              </select>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </Tab>
                  {/*  */}
                  <Tab eventKey="1.2" title="1.2">
                    <div style={{ padding: "5px" }}>
                      <div style={{ textAlign: "center", margin: "10px" }}>
                        <h4>Alarm Test Report</h4>
                      </div>
                      <Table striped bordered hover size="sm">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Detail</th>
                            <th>Result</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>1</td>
                            <td>Smart Lock Failed</td>
                            <td>
                              <select
                                name="p121"
                                className="custom-select"
                                onChange={this.onContentChange}
                                value={this.state.pat.content.p121}
                              >
                                <option value="Pass" defaultValue>
                                  Pass
                                </option>
                                <option value="Fail">Fail</option>
                                <option value="N/A">N/A</option>
                              </select>
                            </td>
                          </tr>
                          <tr>
                            <td>2</td>
                            <td>Camera Failed</td>
                            <td width="100">
                              <select
                                name="p122"
                                className="custom-select"
                                onChange={this.onContentChange}
                                value={this.state.pat.content.p122}
                              >
                                <option value="Pass" defaultValue>
                                  Pass
                                </option>
                                <option value="Fail">Fail</option>
                                <option value="N/A">N/A</option>
                              </select>
                            </td>
                          </tr>
                          <tr>
                            <td>3</td>
                            <td>Motion Sensor Failed</td>
                            <td width="100">
                              <select
                                name="p123"
                                className="custom-select"
                                onChange={this.onContentChange}
                                value={this.state.pat.content.p123}
                              >
                                <option value="Pass" defaultValue>
                                  Pass
                                </option>
                                <option value="Fail">Fail</option>
                                <option value="N/A">N/A</option>
                              </select>
                            </td>
                          </tr>
                          <tr>
                            <td>4</td>
                            <td>Slave Down</td>
                            <td width="100">
                              <select
                                name="p124"
                                className="custom-select"
                                onChange={this.onContentChange}
                                value={this.state.pat.content.p124}
                              >
                                <option value="Pass" defaultValue>
                                  Pass
                                </option>
                                <option value="Fail">Fail</option>
                                <option value="N/A">N/A</option>
                              </select>
                            </td>
                          </tr>
                          <tr>
                            <td>5</td>
                            <td>Unauthorized Open</td>
                            <td width="100">
                              <select
                                name="p125"
                                className="custom-select"
                                onChange={this.onContentChange}
                                value={this.state.pat.content.p125}
                              >
                                <option value="Pass" defaultValue>
                                  Pass
                                </option>
                                <option value="Fail">Fail</option>
                                <option value="N/A">N/A</option>
                              </select>
                            </td>
                          </tr>
                          <tr>
                            <td>6</td>
                            <td>Vibration Detected</td>
                            <td width="100">
                              <select
                                name="p126"
                                className="custom-select"
                                onChange={this.onContentChange}
                                value={this.state.pat.content.p126}
                              >
                                <option value="Pass" defaultValue>
                                  Pass
                                </option>
                                <option value="Fail">Fail</option>
                                <option value="N/A">N/A</option>
                              </select>
                            </td>
                          </tr>
                          <tr>
                            <td>7</td>
                            <td>Communication Lost</td>
                            <td width="100">
                              <select
                                name="p127"
                                className="custom-select"
                                onChange={this.onContentChange}
                                value={this.state.pat.content.p127}
                              >
                                <option value="Pass" defaultValue>
                                  Pass
                                </option>
                                <option value="Fail">Fail</option>
                                <option value="N/A">N/A</option>
                              </select>
                            </td>
                          </tr>
                          <tr>
                            <td>8</td>
                            <td>Motion Detected</td>
                            <td width="100">
                              <select
                                name="p128"
                                className="custom-select"
                                onChange={this.onContentChange}
                                value={this.state.pat.content.p128}
                              >
                                <option value="Pass" defaultValue>
                                  Pass
                                </option>
                                <option value="Fail">Fail</option>
                                <option value="N/A">N/A</option>
                              </select>
                            </td>
                          </tr>
                          <tr>
                            <td>9</td>
                            <td>Test AUX Input Port1</td>
                            <td width="100">
                              <select
                                name="p129"
                                className="custom-select"
                                onChange={this.onContentChange}
                                value={this.state.pat.content.p129}
                              >
                                <option value="Pass" defaultValue>
                                  Pass
                                </option>
                                <option value="Fail">Fail</option>
                                <option value="N/A">N/A</option>
                              </select>
                            </td>
                          </tr>
                          <tr>
                            <td>10</td>
                            <td>Test AUX Input Port2</td>
                            <td width="100">
                              <select
                                name="p1210"
                                className="custom-select"
                                onChange={this.onContentChange}
                                value={this.state.pat.content.p1210}
                              >
                                <option value="Pass" defaultValue>
                                  Pass
                                </option>
                                <option value="Fail">Fail</option>
                                <option value="N/A">N/A</option>
                              </select>
                            </td>
                          </tr>
                          <tr>
                            <td>11</td>
                            <td>Test AUX Input Port3</td>
                            <td width="100">
                              <select
                                name="p1211"
                                className="custom-select"
                                onChange={this.onContentChange}
                                value={this.state.pat.content.p1211}
                              >
                                <option value="Pass" defaultValue>
                                  Pass
                                </option>
                                <option value="Fail">Fail</option>
                                <option value="N/A">N/A</option>
                              </select>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </Tab>
                  {/*  */}
                  <Tab eventKey="2.1" title="2.1">
                    <div style={{ textAlign: "center", margin: "10px" }}>
                      <h4>Physical Photo</h4>
                    </div>
                    <div style={{ marginTop: "10px" }}>
                      <Card border="primary">
                        <Card.Header>
                          รูปใช้ JIG ในการเจาะตู้ Master 1
                        </Card.Header>
                        <Card.Body>
                          <ImageUploadPreview
                            ref={this.imageP211Ref}
                            src="/images/patExample/211.jpg"
                            mode="h"
                          />
                        </Card.Body>
                      </Card>
                    </div>
                    <div style={{ marginTop: "10px" }}>
                      <Card border="primary">
                        <Card.Header>
                          รูปใช้ JIG ในการเจาะตู้ Slave 2
                        </Card.Header>
                        <Card.Body>
                          <ImageUploadPreview
                            ref={this.imageP212Ref}
                            src="/images/patExample/212.jpg"
                            mode="h"
                          />
                        </Card.Body>
                      </Card>
                    </div>
                    <div style={{ marginTop: "10px" }}>
                      <Card border="primary">
                        <Card.Header>
                          รูปใช้ JIG ในการเจาะตู้ Slave 3
                        </Card.Header>
                        <Card.Body>
                          <ImageUploadPreview
                            ref={this.imageP219Ref}
                            src="/images/patExample/219.jpg"
                            mode="h"
                          />
                        </Card.Body>
                      </Card>
                    </div>
                    <div style={{ marginTop: "10px" }}>
                      <Card border="primary">
                        <Card.Header>
                          รูป On CB Battery Isolate Master 1
                        </Card.Header>
                        <Card.Body>
                          <ImageUploadPreview
                            ref={this.imageP215Ref}
                            src="/images/patExample/215.jpg"
                            mode="h"
                          />
                        </Card.Body>
                      </Card>
                    </div>
                    <div style={{ marginTop: "10px" }}>
                      <Card border="primary">
                        <Card.Header>
                          รูป On CB Battery Isolate Slave 2
                        </Card.Header>
                        <Card.Body>
                          <ImageUploadPreview
                            ref={this.imageP216Ref}
                            src="/images/patExample/216.jpg"
                            mode="h"
                          />
                        </Card.Body>
                      </Card>
                    </div>
                    <div style={{ marginTop: "10px" }}>
                      <Card border="primary">
                        <Card.Header>
                          รูป On CB Battery Isolate Slave 3
                        </Card.Header>
                        <Card.Body>
                          <ImageUploadPreview
                            ref={this.imageP2110Ref}
                            src="/images/patExample/2110.jpg"
                            mode="h"
                          />
                        </Card.Body>
                      </Card>
                    </div>
                    <div style={{ marginTop: "10px" }}>
                      <Card border="primary">
                        <Card.Header>
                          รูป Cable Inlet อุด PU Master 1
                        </Card.Header>
                        <Card.Body>
                          <ImageUploadPreview
                            ref={this.imageP217Ref}
                            src="/images/patExample/217.jpg"
                            mode="h"
                          />
                        </Card.Body>
                      </Card>
                    </div>
                    <div style={{ marginTop: "10px" }}>
                      <Card border="primary">
                        <Card.Header>
                          รูป Cable Inlet อุด ซิลิโคน Slave 2
                        </Card.Header>
                        <Card.Body>
                          <ImageUploadPreview
                            ref={this.imageP218Ref}
                            src="/images/patExample/218.jpg"
                            mode="h"
                          />
                        </Card.Body>
                      </Card>
                    </div>
                    <div style={{ marginTop: "10px" }}>
                      <Card border="primary">
                        <Card.Header>
                          รูป Cable Inlet อุด ซิลิโคน Slave 3
                        </Card.Header>
                        <Card.Body>
                          <ImageUploadPreview
                            ref={this.imageP2111Ref}
                            src="/images/patExample/2111.jpg"
                            mode="h"
                          />
                        </Card.Body>
                      </Card>
                    </div>
                  </Tab>
                  {/*  */}
                  <Tab eventKey="2.2" title="2.2">
                    <div style={{ textAlign: "center", margin: "10px" }}>
                      <h4>Logical Test Photo</h4>
                    </div>
                    <div style={{ marginTop: "10px" }}>
                      <Card border="primary">
                        <Card.Header>รูปการตั้งค่า IP Address</Card.Header>
                        <Card.Body>
                          <ImageUploadPreview
                            ref={this.imageP221Ref}
                            src="/images/patExample/221.jpg"
                            mode="h"
                          />
                        </Card.Body>
                      </Card>
                    </div>
                    <div style={{ marginTop: "10px" }}>
                      <Card border="primary">
                        <Card.Header>รูปถ่ายจากกล้อง Smart Site</Card.Header>
                        <Card.Body>
                          <ImageUploadPreview
                            ref={this.imageP222Ref}
                            src="/images/patExample/222.jpg"
                            mode="h"
                          />
                        </Card.Body>
                      </Card>
                    </div>
                    <div style={{ marginTop: "10px" }}>
                      <Card border="primary">
                        <Card.Header>รูป Normal Case : Close</Card.Header>
                        <Card.Body>
                          <ImageUploadPreview
                            ref={this.imageP225Ref}
                            src="/images/patExample/225.jpg"
                            mode="h"
                          />
                        </Card.Body>
                      </Card>
                    </div>
                    <div style={{ marginTop: "10px" }}>
                      <Card border="primary">
                        <Card.Header>รูป Normal Case : Open</Card.Header>
                        <Card.Body>
                          <ImageUploadPreview
                            ref={this.imageP226Ref}
                            src="/images/patExample/226.jpg"
                            mode="h"
                          />
                        </Card.Body>
                      </Card>
                    </div>
                    <div style={{ marginTop: "10px" }}>
                      <Card border="primary">
                        <Card.Header>
                          รูป Emergency Case (เชื่อมต่อ blutooth)
                        </Card.Header>
                        <Card.Body>
                          <ImageUploadPreview
                            ref={this.imageP223Ref}
                            src="/images/patExample/223.jpg"
                            mode="h"
                          />
                        </Card.Body>
                      </Card>
                    </div>
                    <div style={{ marginTop: "10px" }}>
                      <Card border="primary">
                        <Card.Header>รูป Emer Case : Close</Card.Header>
                        <Card.Body>
                          <ImageUploadPreview
                            ref={this.imageP228Ref}
                            src="/images/patExample/228.jpg"
                            mode="h"
                          />
                        </Card.Body>
                      </Card>
                    </div>
                    <div style={{ marginTop: "10px" }}>
                      <Card border="primary">
                        <Card.Header>รูป Emer Case : Open</Card.Header>
                        <Card.Body>
                          <ImageUploadPreview
                            ref={this.imageP229Ref}
                            src="/images/patExample/229.jpg"
                            mode="h"
                          />
                        </Card.Body>
                      </Card>
                    </div>
                    <div style={{ marginTop: "10px" }}>
                      <Card border="primary">
                        <Card.Header>รูปกลอนล็อคตู้ Master 1</Card.Header>
                        <Card.Body>
                          <ImageUploadPreview
                            ref={this.imageP2210Ref}
                            src="/images/na-vertical.jpg"
                            mode="h"
                          />
                        </Card.Body>
                      </Card>
                    </div>
                    <div style={{ marginTop: "10px" }}>
                      <Card border="primary">
                        <Card.Header>รูปกลอนล็อคตู้ Slave 2</Card.Header>
                        <Card.Body>
                          <ImageUploadPreview
                            ref={this.imageP2211Ref}
                            src="/images/na-vertical.jpg"
                            mode="h"
                          />
                        </Card.Body>
                      </Card>
                    </div>
                    <div style={{ marginTop: "10px" }}>
                      <Card border="primary">
                        <Card.Header>รูปกลอนล็อคตู้ Slave 3</Card.Header>
                        <Card.Body>
                          <ImageUploadPreview
                            ref={this.imageP2212Ref}
                            src="/images/na-vertical.jpg"
                            mode="h"
                          />
                        </Card.Body>
                      </Card>
                    </div>
                  </Tab>
                  {/*  */}
                  <Tab eventKey="2.3" title="2.3">
                    <div style={{ textAlign: "center", margin: "10px" }}>
                      <h4>Photo Master 1 After - Before</h4>
                    </div>
                    <div style={{ marginTop: "10px" }}>
                      <Card border="primary">
                        <Card.Header>
                          Before : จุดติดตั้ง Master Controller
                        </Card.Header>
                        <Card.Body>
                          <Image
                            src={
                              this.state.pat.content.p231 !== ""
                                ? Config.service.url +
                                  "/ssr/image/" +
                                  this.state.pat.content.p231
                                : "/images/na-horizontal.jpg"
                            }
                            style={{ width: "100%", marginTop: "5px" }}
                            rounded
                            fluid
                          />
                        </Card.Body>
                      </Card>
                    </div>
                    <div style={{ marginTop: "10px" }}>
                      <Card border="success">
                        <Card.Header>
                          After : ตำแหน่ง Master Controller
                        </Card.Header>
                        <Card.Body>
                          <ImageUploadPreview
                            ref={this.imageP232Ref}
                            src="/images/patExample/232.jpg"
                            mode="h"
                          />
                        </Card.Body>
                      </Card>
                    </div>
                    <div style={{ marginTop: "10px" }}>
                      <Card border="primary">
                        <Card.Header>
                          Before : จุดติดตั้ง Motion Sensor
                        </Card.Header>
                        <Card.Body>
                          <Image
                            src={
                              this.state.pat.content.p233 !== ""
                                ? Config.service.url +
                                  "/ssr/image/" +
                                  this.state.pat.content.p233
                                : "/images/na-horizontal.jpg"
                            }
                            style={{ width: "100%", marginTop: "5px" }}
                            rounded
                            fluid
                          />
                        </Card.Body>
                      </Card>
                    </div>
                    <div style={{ marginTop: "10px" }}>
                      <Card border="success">
                        <Card.Header>
                          After : จุดติดตั้ง Motion Sensor
                        </Card.Header>
                        <Card.Body>
                          <ImageUploadPreview
                            ref={this.imageP234Ref}
                            src="/images/patExample/234.jpg"
                            mode="h"
                          />
                        </Card.Body>
                      </Card>
                    </div>
                    <div style={{ marginTop: "10px" }}>
                      <Card border="primary">
                        <Card.Header>Before : ตำแหน่ง IPRAN</Card.Header>
                        <Card.Body>
                          <Image
                            src={
                              this.state.pat.content.p235 !== ""
                                ? Config.service.url +
                                  "/ssr/image/" +
                                  this.state.pat.content.p235
                                : "/images/na-horizontal.jpg"
                            }
                            style={{ width: "100%", marginTop: "5px" }}
                            rounded
                            fluid
                          />
                        </Card.Body>
                      </Card>
                    </div>
                    <div style={{ marginTop: "10px" }}>
                      <Card border="success">
                        <Card.Header>After : ตำแหน่ง IPRAN</Card.Header>
                        <Card.Body>
                          <ImageUploadPreview
                            ref={this.imageP236Ref}
                            src="/images/patExample/236.jpg"
                            mode="h"
                          />
                        </Card.Body>
                      </Card>
                    </div>
                    <div style={{ marginTop: "10px" }}>
                      <Card border="primary">
                        <Card.Header>Before : จุดติดตั้ง Siren</Card.Header>
                        <Card.Body>
                          <Image
                            src={
                              this.state.pat.content.p237 !== ""
                                ? Config.service.url +
                                  "/ssr/image/" +
                                  this.state.pat.content.p237
                                : "/images/na-horizontal.jpg"
                            }
                            style={{ width: "100%", marginTop: "5px" }}
                            rounded
                            fluid
                          />
                        </Card.Body>
                      </Card>
                    </div>
                    <div style={{ marginTop: "10px" }}>
                      <Card border="success">
                        <Card.Header>After : จุดติดตั้ง Siren</Card.Header>
                        <Card.Body>
                          <ImageUploadPreview
                            ref={this.imageP238Ref}
                            src="/images/patExample/238.jpg"
                            mode="h"
                          />
                        </Card.Body>
                      </Card>
                    </div>
                  </Tab>
                  {/*  */}
                  <Tab eventKey="2.4" title="2.4">
                    <div style={{ textAlign: "center", margin: "10px" }}>
                      <h4>Photo Master 1 After - Before</h4>
                    </div>
                    <div style={{ marginTop: "10px" }}>
                      <Card border="primary">
                        <Card.Header>
                          Before : ตำแหน่ง Master Electric Lock
                        </Card.Header>
                        <Card.Body>
                          <Image
                            src={
                              this.state.pat.content.p241 !== ""
                                ? Config.service.url +
                                  "/ssr/image/" +
                                  this.state.pat.content.p241
                                : "/images/na-horizontal.jpg"
                            }
                            style={{ width: "100%", marginTop: "5px" }}
                            rounded
                            fluid
                          />
                        </Card.Body>
                      </Card>
                    </div>
                    <div style={{ marginTop: "10px" }}>
                      <Card border="success">
                        <Card.Header>
                          After : ตำแหน่ง Master Electric Lock
                        </Card.Header>
                        <Card.Body>
                          <ImageUploadPreview
                            ref={this.imageP242Ref}
                            src="/images/patExample/242.jpg"
                            mode="h"
                          />
                        </Card.Body>
                      </Card>
                    </div>
                    <div style={{ marginTop: "10px" }}>
                      <Card border="primary">
                        <Card.Header>Before : ตำแหน่งติดตั้งหูช้าง</Card.Header>
                        <Card.Body>
                          <Image
                            src={
                              this.state.pat.content.p243 !== ""
                                ? Config.service.url +
                                  "/ssr/image/" +
                                  this.state.pat.content.p243
                                : "/images/na-horizontal.jpg"
                            }
                            style={{ width: "100%", marginTop: "5px" }}
                            rounded
                            fluid
                          />
                        </Card.Body>
                      </Card>
                    </div>
                    <div style={{ marginTop: "10px" }}>
                      <Card border="success">
                        <Card.Header>After : ตำแหน่งติดตั้งหูช้าง</Card.Header>
                        <Card.Body>
                          <ImageUploadPreview
                            ref={this.imageP244Ref}
                            src="/images/patExample/244.jpg"
                            mode="h"
                          />
                        </Card.Body>
                      </Card>
                    </div>
                    <div style={{ marginTop: "10px" }}>
                      <Card border="primary">
                        <Card.Header>Before : DC Power</Card.Header>
                        <Card.Body>
                          <Image
                            src={
                              this.state.pat.content.p245 !== ""
                                ? Config.service.url +
                                  "/ssr/image/" +
                                  this.state.pat.content.p245
                                : "/images/na-horizontal.jpg"
                            }
                            style={{ width: "100%", marginTop: "5px" }}
                            rounded
                            fluid
                          />
                        </Card.Body>
                      </Card>
                    </div>
                    <div style={{ marginTop: "10px" }}>
                      <Card border="success">
                        <Card.Header>After : DC Power</Card.Header>
                        <Card.Body>
                          <ImageUploadPreview
                            ref={this.imageP246Ref}
                            src="/images/patExample/246.jpg"
                            mode="h"
                          />
                        </Card.Body>
                      </Card>
                    </div>
                    <div style={{ marginTop: "10px" }}>
                      <Card border="primary">
                        <Card.Header>Before : USB Camera</Card.Header>
                        <Card.Body>
                          <Image
                            src={
                              this.state.pat.content.p247 !== ""
                                ? Config.service.url +
                                  "/ssr/image/" +
                                  this.state.pat.content.p247
                                : "/images/na-horizontal.jpg"
                            }
                            style={{ width: "100%", marginTop: "5px" }}
                            rounded
                            fluid
                          />
                        </Card.Body>
                      </Card>
                    </div>
                    <div style={{ marginTop: "10px" }}>
                      <Card border="success">
                        <Card.Header>After : USB Camera</Card.Header>
                        <Card.Body>
                          <ImageUploadPreview
                            ref={this.imageP248Ref}
                            src="/images/patExample/248.jpg"
                            mode="h"
                          />
                        </Card.Body>
                      </Card>
                    </div>
                  </Tab>
                  {/*  */}
                  <Tab eventKey="2.5" title="2.5">
                    <div style={{ textAlign: "center", margin: "10px" }}>
                      <h4>Photo Slave 2 After - Before</h4>
                    </div>
                    <div style={{ marginTop: "10px" }}>
                      <Card border="primary">
                        <Card.Header>
                          Before : ตำแหน่ง Slave Controller
                        </Card.Header>
                        <Card.Body>
                          <Image
                            src={
                              this.state.pat.content.p251 !== ""
                                ? Config.service.url +
                                  "/ssr/image/" +
                                  this.state.pat.content.p251
                                : "/images/na-horizontal.jpg"
                            }
                            style={{ width: "100%", marginTop: "5px" }}
                            rounded
                            fluid
                          />
                        </Card.Body>
                      </Card>
                    </div>
                    <div style={{ marginTop: "10px" }}>
                      <Card border="success">
                        <Card.Header>
                          After : ตำแหน่ง Slave Controller
                        </Card.Header>
                        <Card.Body>
                          <ImageUploadPreview
                            ref={this.imageP252Ref}
                            src="/images/patExample/252.jpg"
                            mode="h"
                          />
                        </Card.Body>
                      </Card>
                    </div>
                    <div style={{ marginTop: "10px" }}>
                      <Card border="primary">
                        <Card.Header>
                          Before : ตำแหน่ง Slave Door Lock
                        </Card.Header>
                        <Card.Body>
                          <Image
                            src={
                              this.state.pat.content.p253 !== ""
                                ? Config.service.url +
                                  "/ssr/image/" +
                                  this.state.pat.content.p253
                                : "/images/na-horizontal.jpg"
                            }
                            style={{ width: "100%", marginTop: "5px" }}
                            rounded
                            fluid
                          />
                        </Card.Body>
                      </Card>
                    </div>
                    <div style={{ marginTop: "10px" }}>
                      <Card border="success">
                        <Card.Header>
                          After : ตำแหน่ง Slave Door Lock
                        </Card.Header>
                        <Card.Body>
                          <ImageUploadPreview
                            ref={this.imageP254Ref}
                            src="/images/patExample/254.jpg"
                            mode="h"
                          />
                        </Card.Body>
                      </Card>
                    </div>
                    <div style={{ marginTop: "10px" }}>
                      <Card border="primary">
                        <Card.Header>Before : ตำแหน่งติดตั้งหูช้าง</Card.Header>
                        <Card.Body>
                          <Image
                            src={
                              this.state.pat.content.p255 !== ""
                                ? Config.service.url +
                                  "/ssr/image/" +
                                  this.state.pat.content.p255
                                : "/images/na-horizontal.jpg"
                            }
                            style={{ width: "100%", marginTop: "5px" }}
                            rounded
                            fluid
                          />
                        </Card.Body>
                      </Card>
                    </div>
                    <div style={{ marginTop: "10px" }}>
                      <Card border="success">
                        <Card.Header>After : ตำแหน่งติดตั้งหูช้าง</Card.Header>
                        <Card.Body>
                          <ImageUploadPreview
                            ref={this.imageP256Ref}
                            src="/images/patExample/256.jpg"
                            mode="h"
                          />
                        </Card.Body>
                      </Card>
                    </div>
                    <div style={{ marginTop: "10px" }}>
                      <Card border="primary">
                        <Card.Header>Before : DC Power</Card.Header>
                        <Card.Body>
                          <Image
                            src={
                              this.state.pat.content.p257 !== ""
                                ? Config.service.url +
                                  "/ssr/image/" +
                                  this.state.pat.content.p257
                                : "/images/na-horizontal.jpg"
                            }
                            style={{ width: "100%", marginTop: "5px" }}
                            rounded
                            fluid
                          />
                        </Card.Body>
                      </Card>
                    </div>
                    <div style={{ marginTop: "10px" }}>
                      <Card border="success">
                        <Card.Header>After : DC Power</Card.Header>
                        <Card.Body>
                          <ImageUploadPreview
                            ref={this.imageP258Ref}
                            src="/images/patExample/258.jpg"
                            mode="h"
                          />
                        </Card.Body>
                      </Card>
                    </div>
                  </Tab>
                  {/*  */}
                  <Tab eventKey="2.6" title="2.6">
                    <div style={{ textAlign: "center", margin: "10px" }}>
                      <h4>Photo Slave 3 After - Before</h4>
                    </div>
                    <div style={{ marginTop: "10px" }}>
                      <Card border="success">
                        <Card.Header>
                          After : ตำแหน่ง Slave Controller
                        </Card.Header>
                        <Card.Body>
                          <ImageUploadPreview
                            ref={this.imageP262Ref}
                            src="/images/patExample/262.jpg"
                            mode="h"
                          />
                        </Card.Body>
                      </Card>
                    </div>
                    <div style={{ marginTop: "10px" }}>
                      <Card border="primary">
                        <Card.Header>
                          Before : ตำแหน่ง Slave Door Lock
                        </Card.Header>
                        <Card.Body>
                          <Image
                            src={
                              this.state.pat.content.p263 !== ""
                                ? Config.service.url +
                                  "/ssr/image/" +
                                  this.state.pat.content.p263
                                : "/images/na-horizontal.jpg"
                            }
                            style={{ width: "100%", marginTop: "5px" }}
                            rounded
                            fluid
                          />
                        </Card.Body>
                      </Card>
                    </div>
                    <div style={{ marginTop: "10px" }}>
                      <Card border="success">
                        <Card.Header>
                          After : ตำแหน่ง Slave Door Lock
                        </Card.Header>
                        <Card.Body>
                          <ImageUploadPreview
                            ref={this.imageP264Ref}
                            src="/images/patExample/264.jpg"
                            mode="h"
                          />
                        </Card.Body>
                      </Card>
                    </div>
                    <div style={{ marginTop: "10px" }}>
                      <Card border="primary">
                        <Card.Header>Before : ตำแหน่งติดตั้งหูช้าง</Card.Header>
                        <Card.Body>
                          <Image
                            src={
                              this.state.pat.content.p265 !== ""
                                ? Config.service.url +
                                  "/ssr/image/" +
                                  this.state.pat.content.p265
                                : "/images/na-horizontal.jpg"
                            }
                            style={{ width: "100%", marginTop: "5px" }}
                            rounded
                            fluid
                          />
                        </Card.Body>
                      </Card>
                    </div>
                    <div style={{ marginTop: "10px" }}>
                      <Card border="success">
                        <Card.Header>After : ตำแหน่งติดตั้งหูช้าง</Card.Header>
                        <Card.Body>
                          <ImageUploadPreview
                            ref={this.imageP266Ref}
                            src="/images/patExample/266.jpg"
                            mode="h"
                          />
                        </Card.Body>
                      </Card>
                    </div>
                    <div style={{ marginTop: "10px" }}>
                      <Card border="primary">
                        <Card.Header>Before : DC Power</Card.Header>
                        <Card.Body>
                          <Image
                            src={
                              this.state.pat.content.p267 !== ""
                                ? Config.service.url +
                                  "/ssr/image/" +
                                  this.state.pat.content.p267
                                : "/images/na-horizontal.jpg"
                            }
                            style={{ width: "100%", marginTop: "5px" }}
                            rounded
                            fluid
                          />
                        </Card.Body>
                      </Card>
                    </div>
                    <div style={{ marginTop: "10px" }}>
                      <Card border="success">
                        <Card.Header>After : DC Power</Card.Header>
                        <Card.Body>
                          <ImageUploadPreview
                            ref={this.imageP268Ref}
                            src="/images/patExample/268.jpg"
                            mode="h"
                          />
                        </Card.Body>
                      </Card>
                    </div>
                  </Tab>
                  {/*  */}
                  <Tab eventKey="2.7" title="2.7">
                    <div style={{ textAlign: "center", margin: "10px" }}>
                      <h4>Photo Master - Slave Install Completed</h4>
                    </div>
                    <div style={{ marginTop: "10px" }}>
                      <Card border="primary">
                        <Card.Header>
                          Smart Site Config &amp; On service
                        </Card.Header>
                        <Card.Body>
                          <ImageUploadPreview
                            ref={this.imageP271Ref}
                            src="/images/patExample/271.jpg"
                            mode="v"
                          />
                        </Card.Body>
                      </Card>
                    </div>
                    <div style={{ marginTop: "10px" }}>
                      <Card border="success">
                        <Card.Header>Master 1 Installed</Card.Header>
                        <Card.Body>
                          <ImageUploadPreview
                            ref={this.imageP272Ref}
                            src="/images/patExample/272.jpg"
                            mode="v"
                          />
                        </Card.Body>
                      </Card>
                    </div>
                    <div style={{ marginTop: "10px" }}>
                      <Card border="primary">
                        <Card.Header>Slave 2 Installed</Card.Header>
                        <Card.Body>
                          <ImageUploadPreview
                            ref={this.imageP273Ref}
                            src="/images/patExample/273.jpg"
                            mode="v"
                          />
                        </Card.Body>
                      </Card>
                    </div>
                    <div style={{ marginTop: "10px" }}>
                      <Card border="primary">
                        <Card.Header>Slave 3 Installed</Card.Header>
                        <Card.Body>
                          <ImageUploadPreview
                            ref={this.imageP274Ref}
                            src="/images/patExample/274.jpg"
                            mode="v"
                          />
                        </Card.Body>
                      </Card>
                    </div>
                  </Tab>
                  {/*  */}
                  <Tab eventKey="2.8" title="2.8">
                    <div style={{ textAlign: "center", margin: "10px" }}>
                      <h4>Photo Key</h4>
                    </div>
                    <div style={{ marginTop: "10px" }}>
                      <Card border="primary">
                        <Card.Header>Lock C1</Card.Header>
                        <Card.Body>
                          <Form.Group>
                            <Form.Label>Lock C1 No.</Form.Label>
                            <Form.Control
                              type="text"
                              name="p281Text"
                              onChange={this.onContentChange}
                            />
                          </Form.Group>
                          <ImageUploadPreview
                            ref={this.imageP281Ref}
                            src="/images/na-horizontal.jpg"
                            mode="v"
                          />
                        </Card.Body>
                      </Card>
                    </div>
                    <div style={{ marginTop: "10px" }}>
                      <Card border="success">
                        <Card.Header>BATT C1</Card.Header>
                        <Card.Body>
                          <Form.Group>
                            <Form.Label>BATT C1 No.</Form.Label>
                            <Form.Control
                              type="text"
                              name="p282Text"
                              onChange={this.onContentChange}
                            />
                          </Form.Group>
                          <ImageUploadPreview
                            ref={this.imageP282Ref}
                            src="/images/na-horizontal.jpg"
                            mode="v"
                          />
                        </Card.Body>
                      </Card>
                    </div>
                    <div style={{ marginTop: "10px" }}>
                      <Card border="primary">
                        <Card.Header>Lock C2</Card.Header>
                        <Card.Body>
                          <Form.Group>
                            <Form.Label>Lock C2 No.</Form.Label>
                            <Form.Control
                              type="text"
                              name="p283Text"
                              onChange={this.onContentChange}
                            />
                          </Form.Group>
                          <ImageUploadPreview
                            ref={this.imageP283Ref}
                            src="/images/na-horizontal.jpg"
                            mode="v"
                          />
                        </Card.Body>
                      </Card>
                    </div>
                    <div style={{ marginTop: "10px" }}>
                      <Card border="primary">
                        <Card.Header>BATT C2</Card.Header>
                        <Card.Body>
                          <Form.Group>
                            <Form.Label>BATT C2 No.</Form.Label>
                            <Form.Control
                              type="text"
                              name="p284Text"
                              onChange={this.onContentChange}
                            />
                          </Form.Group>
                          <ImageUploadPreview
                            ref={this.imageP284Ref}
                            src="/images/na-horizontal.jpg"
                            mode="v"
                          />
                        </Card.Body>
                      </Card>
                    </div>
                    <div style={{ marginTop: "10px" }}>
                      <Card border="primary">
                        <Card.Header>Lock C3</Card.Header>
                        <Card.Body>
                          <Form.Group>
                            <Form.Label>Lock C3 No.</Form.Label>
                            <Form.Control
                              type="text"
                              name="p285Text"
                              onChange={this.onContentChange}
                            />
                          </Form.Group>
                          <ImageUploadPreview
                            ref={this.imageP285Ref}
                            src="/images/na-horizontal.jpg"
                            mode="v"
                          />
                        </Card.Body>
                      </Card>
                    </div>
                    <div style={{ marginTop: "10px" }}>
                      <Card border="primary">
                        <Card.Header>BATT C3</Card.Header>
                        <Card.Body>
                          <Form.Group>
                            <Form.Label>BATT C3 No.</Form.Label>
                            <Form.Control
                              type="text"
                              name="p286Text"
                              onChange={this.onContentChange}
                            />
                          </Form.Group>
                          <ImageUploadPreview
                            ref={this.imageP286Ref}
                            src="/images/na-horizontal.jpg"
                            mode="v"
                          />
                        </Card.Body>
                      </Card>
                    </div>
                    <div style={{ marginTop: "10px" }}>
                      <Card border="primary">
                        <Card.Header>รั้ว</Card.Header>
                        <Card.Body>
                          <Form.Group>
                            <Form.Label>รั้ว No.</Form.Label>
                            <Form.Control
                              type="text"
                              name="p287Text"
                              onChange={this.onContentChange}
                            />
                          </Form.Group>
                          <ImageUploadPreview
                            ref={this.imageP287Ref}
                            src="/images/na-horizontal.jpg"
                            mode="v"
                          />
                        </Card.Body>
                      </Card>
                    </div>
                  </Tab>
                </Tabs>
              </Form>
            </Col>
          </Row>
        </div>
      </>
    );
  };
}

export default withRouter(PATTechnician);

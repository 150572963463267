import React, { Component } from "react";
import { Redirect, withRouter } from "react-router-dom";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";

import MessageBox from "../components/messagebox/messagebox";
import LoadingBox from "../components/messagebox/loadingbox";
import MenuAdmin from "../components/menu/menuAdmin";
import MenuAdminSubcon from "../components/menu/menuAdminSubcon";
import MenuReviewer from "../components/menu/menuReviewer";
import MenuTechnician from "../components/menu/menuTechnician";

import auth from "../helper/authHelper";

import Axios from "axios";

var Config = require("../config/config.json");

class DashboardBurnDown extends Component {
  //
  constructor(props) {
    // Base contructor.
    super(props);
    //
    this.state = {
      progressBurnDown: null,
      options: {}
    };
  }

  buildOption = (title, items) => {
    return {
      chart: {},
      title: {
        text: title,
        align: "left"
      },
      xAxis: {
        categories: items.map(i => {
          return i.name;
        })
      },
      yAxis: [
        {
          min: 0,
          title: {
            text: "Sites"
          }
        }
      ],
      colors: ["#3498DB", "#BA4A00"],
      legend: {
        align: "center",
        x: 70,
        verticalAlign: "top",
        y: 70,
        floating: true,
        backgroundColor:
          Highcharts.defaultOptions.legend.backgroundColor || "white",
        borderColor: "#CCC",
        borderWidth: 1,
        shadow: false
      },
      // plotOptions: {
      //   column: {
      //     stacking: "normal",
      //     dataLabels: {
      //       enabled: true
      //     }
      //   }
      // },
      series: [
        {
          name: "Plan Burn Down",
          data: items.map(i => {
            return i.planBurnDown;
          })
        },
        {
          name: "Installed Burn Down",
          data: items.map(i => {
            return i.installedBurnDown;
          })
        }
      ]
    };
  };

  //
  //
  requestData = () => {
    // Prevent access empty object.
    if (localStorage.getItem("token") !== null) {
    }

    Axios.get(Config.service.url + "/dashboard/burnDown2/all", {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    })
      .then(res => {
        console.log("burn down = ", res.data);
        this.setState({ progressBurnDown: res.data });

        // All Region
        const option = this.buildOption("", res.data.all.items);
        this.setState({ allOption: option });

        // Region
        const bkkOption = this.buildOption("", res.data.regions[0].items);
        const crOption = this.buildOption("", res.data.regions[1].items);
        const erOption = this.buildOption("", res.data.regions[2].items);
        const nerOption = this.buildOption("", res.data.regions[3].items);
        const nrOption = this.buildOption("", res.data.regions[4].items);
        const srOption = this.buildOption("", res.data.regions[5].items);

        let hash = {};
        hash["BKK"] = bkkOption;
        hash["CR"] = crOption;
        hash["ER"] = erOption;
        hash["NER"] = nerOption;
        hash["NR"] = nrOption;
        hash["SR"] = srOption;

        this.setState({ options: hash });
      })
      .catch(err => {
        console.log(err);
      });
  };

  //
  componentDidMount() {
    if (localStorage.getItem("token") === null) {
      return;
    }
    this.requestData();
  }

  menu = () => {
    if (auth.allowAdmin()) {
      return <MenuAdmin />;
    } else if (auth.allowAdminSubcon()) {
      return <MenuAdminSubcon />;
    } else if (auth.allowReview()) {
      return <MenuReviewer />;
    } else {
      return <MenuTechnician />;
    }
  };

  //
  render() {
    //
    if (localStorage.getItem("token") === null) {
      return <Redirect push to="/login" />;
    }

    if (auth.allowAdminReview() === false) {
      return <Redirect push to="/noPermission" />;
    }

    return (
      <>
        <MessageBox ref={this.messageboxRef} />
        <LoadingBox ref={this.loadingboxRef} />
        {this.menu()}
        <div className="App-body">
          {/* Top */}
          <Row>
            <Col md={12}>
              <center>
                <h2>Burn Down Dashboard</h2>
              </center>
            </Col>
          </Row>
          {/* HR */}
          <Row>
            <Col md={12}>
              <hr />
            </Col>
          </Row>
          {/* Burn down all */}
          <Row>
            <Col md={12}>
              <h4>All Region</h4>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              {this.state.progressBurnDown !== null ? (
                <table width="100%" border="1">
                  <tbody>
                    <tr>
                      <td align="center" rowSpan="2">
                        All Region
                      </td>
                      <td align="center" colspan="5">
                        Nov (2023)
                      </td>
                      <td align="center" colspan="4">
                        Dec (2023)
                      </td>
                      <td align="center" colspan="5">
                        Jan (2024)
                      </td>
                      <td align="center" colspan="4">
                        Feb (2024)
                      </td>
                    </tr>
                    <tr>
                      {this.state.progressBurnDown.all.items.map(
                        (data, index) => {
                          return <td align="center">{data.name}</td>;
                        }
                      )}
                    </tr>
                    <tr>
                      <td align="center">Plan</td>
                      {this.state.progressBurnDown.all.items.map(
                        (data, index) => {
                          return <td align="center">{data.plan}</td>;
                        }
                      )}
                    </tr>
                    <tr>
                      <td align="center">Installed</td>
                      {this.state.progressBurnDown.all.items.map(
                        (data, index) => {
                          return <td align="center">{data.installed}</td>;
                        }
                      )}
                    </tr>
                    <tr style={{ color: "#BA4A00" }}>
                      <td align="center">Plan Burn Down</td>
                      {this.state.progressBurnDown.all.items.map(
                        (data, index) => {
                          return <td align="center">{data.planBurnDown}</td>;
                        }
                      )}
                    </tr>
                    <tr style={{ color: "#3498DB" }}>
                      <td align="center">Installed Burn Down</td>
                      {this.state.progressBurnDown.all.items.map(
                        (data, index) => {
                          return (
                            <td align="center">{data.installedBurnDown}</td>
                          );
                        }
                      )}
                    </tr>
                  </tbody>
                </table>
              ) : (
                ""
              )}
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div style={{ marginTop: 30 }}>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={this.state.allOption}
                />
              </div>
            </Col>
          </Row>
          {this.state.progressBurnDown !== null
            ? this.state.progressBurnDown.regions.map((data, index) => {
                return (
                  <>
                    <Row>
                      <Col md={12}>
                        <hr />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <h4>{data.region}</h4>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <table width="100%" border="1">
                          <tbody>
                            <tr>
                              <td align="center" rowSpan="2">
                                {data.region}
                              </td>
                              <td align="center" colspan="5">
                                Nov (2023)
                              </td>
                              <td align="center" colspan="4">
                                Dec (2023)
                              </td>
                              <td align="center" colspan="5">
                                Jan (2024)
                              </td>
                              <td align="center" colspan="4">
                                Feb (2024)
                              </td>
                            </tr>
                            <tr>
                              {data.items.map((data, index) => {
                                return <td align="center">{data.name}</td>;
                              })}
                            </tr>
                            <tr>
                              <td align="center">Plan</td>
                              {data.items.map((data, index) => {
                                return <td align="center">{data.plan}</td>;
                              })}
                            </tr>
                            <tr>
                              <td align="center">Installed</td>
                              {data.items.map((data, index) => {
                                return <td align="center">{data.installed}</td>;
                              })}
                            </tr>
                            <tr style={{ color: "#BA4A00" }}>
                              <td align="center">Plan Burn Down</td>
                              {data.items.map((data, index) => {
                                return (
                                  <td align="center">{data.planBurnDown}</td>
                                );
                              })}
                            </tr>
                            <tr style={{ color: "#3498DB" }}>
                              <td align="center">Installed Burn Down</td>
                              {data.items.map((data, index) => {
                                return (
                                  <td align="center">
                                    {data.installedBurnDown}
                                  </td>
                                );
                              })}
                            </tr>
                          </tbody>
                        </table>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <div style={{ marginTop: 30 }}>
                          <HighchartsReact
                            highcharts={Highcharts}
                            options={this.state.options[data.region]}
                          />
                        </div>
                      </Col>
                    </Row>
                  </>
                );
              })
            : ""}
        </div>
      </>
    );
  }
}

export default withRouter(DashboardBurnDown);

import React, { Component } from "react";
import { Redirect, withRouter } from "react-router-dom";
import { Form } from "react-bootstrap";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import { Tabs } from "react-bootstrap";
import { Tab } from "react-bootstrap";
import { Card } from "react-bootstrap";
import { Table } from "react-bootstrap";
import { Button } from "react-bootstrap";

import Axios from "axios";

import MenuTechnician from "../components/menu/menuTechnician";
import SSRDesignMasterImage from "../components/image/SSRDesignMasterImage";
import SSRDesignSlaveImage from "../components/image/SSRDesignSlaveImage";
import ImageUploadPreview from "../components/image/ImageUploadPreview";
import ImageQRUploadPreview from "../components/image/ImageQRUploadPreview";
import ImageUploadPreviewWithRect from "../components/image/ImageUploadPreviewWithRect";

import MessageBox from "../components/messagebox/messagebox";
import LoadingBox from "../components/messagebox/loadingbox";
import ConfirmBox from "../components/messagebox/confirmbox";

import { base64ImageToBlob } from "../helper/utilityHelper";

import { getValue } from "../helper/utilityHelper";

var Moment = require("moment");
var Config = require("../config/config.json");

class SSRTechnician extends Component {
  //
  constructor(props) {
    // Base contructor.
    super(props);
    // Ref.
    this.messageboxRef = React.createRef();
    this.loadingboxRef = React.createRef();
    this.confirmboxRef = React.createRef();
    //
    this.imageCoverRef = React.createRef();
    this.imageQRCodeMaster1Ref = React.createRef();
    this.imageQRCodeSlave2Ref = React.createRef();
    this.imageQRCodeSlave3Ref = React.createRef();
    //
    this.imageVisualCheck11Ref = React.createRef();
    this.imageVisualCheck12Ref = React.createRef();
    this.imageVisualCheck13Ref = React.createRef();
    this.imageVisualCheck14Ref = React.createRef();
    //
    this.imageVisualCheck21Ref = React.createRef();
    this.imageVisualCheck22Ref = React.createRef();
    this.imageVisualCheck23Ref = React.createRef();
    this.imageVisualCheck24Ref = React.createRef();
    //
    this.imageVisualCheck31Ref = React.createRef();
    this.imageVisualCheck32Ref = React.createRef();
    this.imageVisualCheck33Ref = React.createRef();
    this.imageVisualCheck34Ref = React.createRef();
    //
    this.imageDesignMaster1Ref = React.createRef();
    this.imageM11Ref = React.createRef();
    this.imageM12Ref = React.createRef();
    this.imageM13Ref = React.createRef();
    this.imageM14Ref = React.createRef();
    this.imageM15Ref = React.createRef();
    this.imageM16Ref = React.createRef();
    this.imageM17Ref = React.createRef();
    this.imageM18Ref = React.createRef();
    this.imageM19Ref = React.createRef();
    this.imageM110Ref = React.createRef();
    this.imageM111Ref = React.createRef();
    //
    this.imageDesignSlave2Ref = React.createRef();
    this.imageS21Ref = React.createRef();
    this.imageS22Ref = React.createRef();
    this.imageS23Ref = React.createRef();
    this.imageS24Ref = React.createRef();
    this.imageS25Ref = React.createRef();
    this.imageS26Ref = React.createRef();
    this.imageS27Ref = React.createRef();
    //
    // this.imageDesignSlave3Ref = React.createRef();
    this.imageS31Ref = React.createRef();
    this.imageS32Ref = React.createRef();
    this.imageS33Ref = React.createRef();
    this.imageS34Ref = React.createRef();
    this.imageS35Ref = React.createRef();
    this.imageS36Ref = React.createRef();
    this.imageS37Ref = React.createRef();
    // State
    this.state = {
      redirect: "",
      allowSave: true,
      documentNumber: "",
      cover: {
        projectName: "Smart Site Ph3",
        //siteCode: localStorage.getItem('siteCode'),
        siteCode: "",
        region: "",
        siteNameEn: "",
        siteNameTh: "",
        zone: "",
        ip: "",
        gateway: "",
        subnetMask: "",
        ipRan: "",
        surveyAndInstallation: "SSK DECHA ENGINEERING LTD.,PART.",
        surveyAndInstallationPhone: "092-328-2997",
        technicianName: localStorage.getItem("name"),
        technicianPhone: localStorage.getItem("phone"),
        technicianContractor: localStorage.getItem("contractor"),
        technicianName2: "",
        technicianPhone2: "",
        technicianContractor2: "",
        date: Moment().format("YYYY-MM-DD"),
        imageCover: "",
        qrCodeMaster1: "",
        imageQRCodeMaster1: "",
        qrCodeSlave2: "",
        imageQRCodeSlave2: "",
        qrCodeSlave3: "",
        imageQRCodeSlave3: ""
      },
      check: {
        visualCheck1: "Pass",
        visualCheck2: "Pass",
        visualCheck3: "Pass",
        //
        imageVisualCheck1: "",
        imageVisualCheck2: "",
        imageVisualCheck3: ""
      },
      // Don't remove master
      master1: {
        imageDesignMaster: "",
        imageDesignMaster2: "",
        markers: {
          m1: { text: "DC Power", x: 75, y: 75, xT: 95, yT: 70 },
          m2: { text: "Camera", x: 180, y: 75, xT: 200, yT: 70 },
          m3: { text: "Electric Lock", x: 75, y: 200, xT: 95, yT: 195 },
          m4: { text: "Door Lock", x: 180, y: 200, xT: 200, yT: 195 },
          m5: { text: "IPRAN", x: 180, y: 150, xT: 200, yT: 145 },
          m6: { text: "Master Controller", x: 180, y: 250, xT: 200, yT: 245 },
          m7: { text: "Siren", x: 180, y: 110, xT: 200, yT: 105 },
          m8: { text: "Motion Sensor", x: 180, y: 110, xT: 200, yT: 105 },
          m9: { text: "Cable Inlet", x: 180, y: 170, xT: 200, yT: 165 }
        },
        //
        imageM11: "",
        imageM12: "",
        imageM13: "",
        imageM14: "",
        imageM15: "",
        imageM16: "",
        imageM17: "",
        imageM18: "",
        imageM19: "",
        imageM110: "",
        //
        markersM11: { r1: { x: 50, y: 50 } },
        markersM12: { r1: { x: 50, y: 50 } },
        markersM13: { r1: { x: 50, y: 50 } },
        markersM14: { r1: { x: 50, y: 50 } },
        markersM15: { r1: { x: 50, y: 50 } },
        markersM16: { r1: { x: 50, y: 50 } },
        markersM17: { r1: { x: 50, y: 50 } },
        markersM18: { r1: { x: 50, y: 50 } },
        markersM19: { r1: { x: 50, y: 50 } },
        markersM110: { r1: { x: 50, y: 50 } }
      },
      slave2: {
        imageDesignSlave: "",
        markers: {
          s1: { text: "DC Power", x: 50, y: 110, xT: 68, yT: 105 },
          s2: { text: "Electric Lock", x: 50, y: 220, xT: 68, yT: 215 },
          s3: { text: "Slave Controller", x: 170, y: 270, xT: 188, yT: 265 },
          s4: { text: "Door Lock", x: 170, y: 220, xT: 188, yT: 215 }
        },
        imageS21: "",
        imageS22: "",
        imageS23: "",
        imageS24: "",
        imageS25: "",
        imageS26: "",
        //
        markersS21: { r1: { x: 50, y: 50 } },
        markersS22: { r1: { x: 50, y: 50 } },
        markersS23: { r1: { x: 50, y: 50 } },
        markersS24: { r1: { x: 50, y: 50 } },
        markersS25: { r1: { x: 50, y: 50 } },
        markersS26: { r1: { x: 50, y: 50 } }
      },
      slave3: {
        imageS31: "",
        imageS32: "",
        imageS33: "",
        imageS34: "",
        imageS35: "",
        imageS36: "",
        //
        markersS31: { r1: { x: 50, y: 50 } },
        markersS32: { r1: { x: 50, y: 50 } },
        markersS33: { r1: { x: 50, y: 50 } },
        markersS34: { r1: { x: 50, y: 50 } },
        markersS35: { r1: { x: 50, y: 50 } },
        markersS36: { r1: { x: 50, y: 50 } }
      }
    };

    this.onCoverSiteCodeLookup(null);
  }

  //
  onCoverChange = e => {
    const { name, value } = e.target;
    let cover = { ...this.state.cover };
    cover[name] = value;
    this.setState({ cover: cover });
  };

  //
  onCheckChange = e => {
    const { name, value } = e.target;
    let check = { ...this.state.check };
    check[name] = value;
    this.setState({ check: check });
  };

  //
  onCoverChangeToLocalStorage = e => {
    const { name, value } = e.target;
    localStorage.setItem(name, value);
    let cover = { ...this.state.cover };
    cover[name] = value;
    this.setState({ cover: cover });
  };

  //
  onCoverSiteCodeLookup = e => {
    let value = "";
    if (e !== null) {
      value = e.target.value;
    } else {
      value = this.state.cover.siteCode;
    }
    //
    if (value !== null && value.length >= 5) {
      Axios.get(Config.service.url + "/baseStation/getBySiteCode/" + value, {
        headers: {
          Authorization: localStorage.getItem("token")
        }
      })
        .then(res => {
          console.log(res.data);
          if (res.data.success === false) {
            this.messageboxRef.current.onShow("Error", res.data.message);
            return;
          }
          //
          if (res.data.data.length === 0) {
            //
            let cover = { ...this.state.cover };
            cover.siteCode = value;
            cover.region = "";
            cover.siteNameEn = "";
            cover.siteNameTh = "";
            cover.zone = "";
            cover.ip = "";
            cover.gateway = "";
            cover.subnetMask = "";
            cover.ipRan = "";
            //
            this.setState({ cover: cover });
            localStorage.setItem("siteCode", value);
            return;
          }
          //
          let cover = { ...this.state.cover };
          cover.siteCode = res.data.data[0].siteCode;
          cover.region = res.data.data[0].region;
          cover.siteNameEn = res.data.data[0].siteNameEn;
          cover.siteNameTh = res.data.data[0].siteNameTh;
          cover.zone = res.data.data[0].zone;
          cover.ip = res.data.data[0].networkIP;
          cover.gateway = res.data.data[0].networkGateway;
          cover.subnetMask = res.data.data[0].networkSubnet;
          cover.ipRan = res.data.data[0].networkIPRANPort;
          //
          this.setState({ cover: cover });
          localStorage.setItem("siteCode", res.data.data[0].siteCode);
          // Check siteCode is created in database.
          // Need to check, Does the site code document already created?
          Axios.get(Config.service.url + "/ssr/isCreated/" + value, {
            headers: {
              Authorization: localStorage.getItem("token")
            }
          })
            .then(res => {
              console.log(res.data);
              if (res.data.success === true) {
                this.messageboxRef.current.onShow(
                  "Error",
                  "Duplicate site code, you cannot save this document."
                );
                this.setState({ allowSave: false });
              } else {
                this.setState({ allowSave: true });
              }
            })
            .catch(err => {
              console.log(err);
            })
            .finally(() => {});
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {});
    }
  };

  //
  onSubmit = () => {
    // Add gurad
    if (
      this.state.cover.siteCode.length === 0 &&
      this.state.cover.region.length === 0 &&
      this.state.cover.siteNameEn.length === 0 &&
      this.state.cover.siteNameTh.length === 0 &&
      this.state.cover.zone.length === 0
    ) {
      this.messageboxRef.current.onShow(
        "Error",
        "Site code, region, site name, zone does not fill"
      );
      return;
    }

    // Show loading screen
    if (this.loadingboxRef.current) this.loadingboxRef.current.onShow();
    //
    let data = new FormData();
    // QR
    // eslint-disable-next-line react/no-direct-mutation-state
    this.state.cover.qrCodeMaster1 =
      this.imageQRCodeMaster1Ref.current.getState().qrCode;
    // eslint-disable-next-line react/no-direct-mutation-state
    this.state.cover.qrCodeSlave2 =
      this.imageQRCodeSlave2Ref.current.getState().qrCode;
    // eslint-disable-next-line react/no-direct-mutation-state
    this.state.cover.qrCodeSlave3 =
      this.imageQRCodeSlave3Ref.current.getState().qrCode;
    //
    data.append("data", JSON.stringify(this.state));
    // Markers
    data.append(
      "designMasterMarkers",
      JSON.stringify(this.imageDesignMaster1Ref.current.getState().markers)
    );
    data.append(
      "designSlaveMarkers",
      JSON.stringify(this.imageDesignSlave2Ref.current.getState().markers)
    );
    //
    data.append(
      "designMasterMarkersM11",
      JSON.stringify(this.imageM11Ref.current.getState().markers)
    );
    data.append(
      "designMasterMarkersM12",
      JSON.stringify(this.imageM12Ref.current.getState().markers)
    );
    data.append(
      "designMasterMarkersM13",
      JSON.stringify(this.imageM13Ref.current.getState().markers)
    );
    data.append(
      "designMasterMarkersM14",
      JSON.stringify(this.imageM14Ref.current.getState().markers)
    );
    data.append(
      "designMasterMarkersM15",
      JSON.stringify(this.imageM15Ref.current.getState().markers)
    );
    data.append(
      "designMasterMarkersM16",
      JSON.stringify(this.imageM16Ref.current.getState().markers)
    );
    data.append(
      "designMasterMarkersM17",
      JSON.stringify(this.imageM17Ref.current.getState().markers)
    );
    data.append(
      "designMasterMarkersM18",
      JSON.stringify(this.imageM18Ref.current.getState().markers)
    );
    data.append(
      "designMasterMarkersM19",
      JSON.stringify(this.imageM19Ref.current.getState().markers)
    );
    data.append(
      "designMasterMarkersM110",
      JSON.stringify(this.imageM110Ref.current.getState().markers)
    );
    data.append(
      "designMasterMarkersM111",
      JSON.stringify(this.imageM111Ref.current.getState().markers)
    );
    //
    data.append(
      "designSlaveMarkersS21",
      JSON.stringify(this.imageS21Ref.current.getState().markers)
    );
    data.append(
      "designSlaveMarkersS22",
      JSON.stringify(this.imageS22Ref.current.getState().markers)
    );
    data.append(
      "designSlaveMarkersS23",
      JSON.stringify(this.imageS23Ref.current.getState().markers)
    );
    data.append(
      "designSlaveMarkersS24",
      JSON.stringify(this.imageS24Ref.current.getState().markers)
    );
    data.append(
      "designSlaveMarkersS25",
      JSON.stringify(this.imageS25Ref.current.getState().markers)
    );
    data.append(
      "designSlaveMarkersS26",
      JSON.stringify(this.imageS26Ref.current.getState().markers)
    );
    data.append(
      "designSlaveMarkersS27",
      JSON.stringify(this.imageS27Ref.current.getState().markers)
    );
    //
    data.append(
      "designSlaveMarkersS31",
      JSON.stringify(this.imageS31Ref.current.getState().markers)
    );
    data.append(
      "designSlaveMarkersS32",
      JSON.stringify(this.imageS32Ref.current.getState().markers)
    );
    data.append(
      "designSlaveMarkersS33",
      JSON.stringify(this.imageS33Ref.current.getState().markers)
    );
    data.append(
      "designSlaveMarkersS34",
      JSON.stringify(this.imageS34Ref.current.getState().markers)
    );
    data.append(
      "designSlaveMarkersS35",
      JSON.stringify(this.imageS35Ref.current.getState().markers)
    );
    data.append(
      "designSlaveMarkersS36",
      JSON.stringify(this.imageS36Ref.current.getState().markers)
    );
    data.append(
      "designSlaveMarkersS37",
      JSON.stringify(this.imageS37Ref.current.getState().markers)
    );
    // Cover
    data.append("imageCover", this.imageCoverRef.current.getState().file);
    // QR
    if (this.imageQRCodeMaster1Ref.current.getState().file.length > 0)
      data.append(
        "imageQRCodeMaster1",
        base64ImageToBlob(this.imageQRCodeMaster1Ref.current.getState().file)
      );
    if (this.imageQRCodeSlave2Ref.current.getState().file.length > 0)
      data.append(
        "imageQRCodeSlave2",
        base64ImageToBlob(this.imageQRCodeSlave2Ref.current.getState().file)
      );
    if (this.imageQRCodeSlave3Ref.current.getState().file.length > 0)
      data.append(
        "imageQRCodeSlave3",
        base64ImageToBlob(this.imageQRCodeSlave3Ref.current.getState().file)
      );
    // Images
    if (this.imageVisualCheck11Ref.current !== null)
      data.append(
        "imageVisualCheck11",
        this.imageVisualCheck11Ref.current.getState().file
      );
    if (this.imageVisualCheck12Ref.current !== null)
      data.append(
        "imageVisualCheck12",
        this.imageVisualCheck12Ref.current.getState().file
      );
    if (this.imageVisualCheck13Ref.current !== null)
      data.append(
        "imageVisualCheck13",
        this.imageVisualCheck13Ref.current.getState().file
      );
    if (this.imageVisualCheck14Ref.current !== null)
      data.append(
        "imageVisualCheck14",
        this.imageVisualCheck14Ref.current.getState().file
      );
    //
    if (this.imageVisualCheck21Ref.current !== null)
      data.append(
        "imageVisualCheck21",
        this.imageVisualCheck21Ref.current.getState().file
      );
    if (this.imageVisualCheck22Ref.current !== null)
      data.append(
        "imageVisualCheck22",
        this.imageVisualCheck22Ref.current.getState().file
      );
    if (this.imageVisualCheck23Ref.current !== null)
      data.append(
        "imageVisualCheck23",
        this.imageVisualCheck23Ref.current.getState().file
      );
    if (this.imageVisualCheck24Ref.current !== null)
      data.append(
        "imageVisualCheck24",
        this.imageVisualCheck24Ref.current.getState().file
      );
    //
    if (this.imageVisualCheck31Ref.current !== null)
      data.append(
        "imageVisualCheck31",
        this.imageVisualCheck31Ref.current.getState().file
      );
    if (this.imageVisualCheck32Ref.current !== null)
      data.append(
        "imageVisualCheck32",
        this.imageVisualCheck32Ref.current.getState().file
      );
    if (this.imageVisualCheck33Ref.current !== null)
      data.append(
        "imageVisualCheck33",
        this.imageVisualCheck33Ref.current.getState().file
      );
    if (this.imageVisualCheck34Ref.current !== null)
      data.append(
        "imageVisualCheck34",
        this.imageVisualCheck34Ref.current.getState().file
      );
    // Master 1
    data.append(
      "imageDesignMaster",
      this.imageDesignMaster1Ref.current.getState().file
    );
    data.append(
      "imageDesignMaster2",
      this.imageDesignMaster1Ref.current.getState().file2
    );
    //
    data.append("imageM11", this.imageM11Ref.current.getState().file);
    data.append("imageM12", this.imageM12Ref.current.getState().file);
    data.append("imageM13", this.imageM13Ref.current.getState().file);
    data.append("imageM14", this.imageM14Ref.current.getState().file);
    data.append("imageM15", this.imageM15Ref.current.getState().file);
    data.append("imageM16", this.imageM16Ref.current.getState().file);
    data.append("imageM17", this.imageM17Ref.current.getState().file);
    data.append("imageM18", this.imageM18Ref.current.getState().file);
    data.append("imageM19", this.imageM19Ref.current.getState().file);
    data.append("imageM110", this.imageM110Ref.current.getState().file);
    data.append("imageM111", this.imageM111Ref.current.getState().file);
    // Slave 2
    data.append(
      "imageDesignSlave",
      this.imageDesignSlave2Ref.current.getState().file
    );
    //
    data.append("imageS21", this.imageS21Ref.current.getState().file);
    data.append("imageS22", this.imageS22Ref.current.getState().file);
    data.append("imageS23", this.imageS23Ref.current.getState().file);
    data.append("imageS24", this.imageS24Ref.current.getState().file);
    data.append("imageS25", this.imageS25Ref.current.getState().file);
    data.append("imageS26", this.imageS26Ref.current.getState().file);
    data.append("imageS27", this.imageS27Ref.current.getState().file);
    // Slave 3
    data.append("imageS31", this.imageS31Ref.current.getState().file);
    data.append("imageS32", this.imageS32Ref.current.getState().file);
    data.append("imageS33", this.imageS33Ref.current.getState().file);
    data.append("imageS34", this.imageS34Ref.current.getState().file);
    data.append("imageS35", this.imageS35Ref.current.getState().file);
    data.append("imageS36", this.imageS36Ref.current.getState().file);
    data.append("imageS37", this.imageS37Ref.current.getState().file);
    //
    Axios.post(Config.service.url + "/ssr/add", data, {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    })
      .then(res => {
        if (res.data.success === false) {
          this.messageboxRef.current.onShow("Error", res.data.message);
          if (this.loadingboxRef.current) this.loadingboxRef.current.onHide();
          return;
        }
        if (this.loadingboxRef.current) this.loadingboxRef.current.onHide();
        this.setState({
          redirect: "/ssrTechnicianEdit?id=" + res.data.data._id
        });
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {});
  };

  //
  onTabSelect = key => {
    if (key === "master1") {
      setTimeout(() => {
        this.imageDesignMaster1Ref.current.refresh();
        this.imageM11Ref.current.refresh();
        this.imageM12Ref.current.refresh();
        this.imageM13Ref.current.refresh();
        this.imageM14Ref.current.refresh();
        this.imageM15Ref.current.refresh();
        this.imageM16Ref.current.refresh();
        this.imageM17Ref.current.refresh();
        this.imageM18Ref.current.refresh();
        this.imageM19Ref.current.refresh();
        this.imageM110Ref.current.refresh();
        this.imageM111Ref.current.refresh();
      }, 50);
    } else if (key === "slave2") {
      setTimeout(() => {
        this.imageDesignSlave2Ref.current.refresh();
        this.imageS21Ref.current.refresh();
        this.imageS22Ref.current.refresh();
        this.imageS23Ref.current.refresh();
        this.imageS24Ref.current.refresh();
        this.imageS25Ref.current.refresh();
        this.imageS26Ref.current.refresh();
        this.imageS27Ref.current.refresh();
      }, 50);
    } else if (key === "slave3") {
      setTimeout(() => {
        this.imageS31Ref.current.refresh();
        this.imageS32Ref.current.refresh();
        this.imageS33Ref.current.refresh();
        this.imageS34Ref.current.refresh();
        this.imageS35Ref.current.refresh();
        this.imageS36Ref.current.refresh();
        this.imageS37Ref.current.refresh();
      }, 50);
    }
  };

  //
  componentDidMount = () => {};

  //
  render = () => {
    //
    if (localStorage.getItem("token") === null) {
      return <Redirect push to="/login" />;
    }

    // Redirect
    if (this.state.redirect) {
      return <Redirect push to={this.state.redirect} />;
    }

    return (
      <>
        <MenuTechnician />
        <MessageBox ref={this.messageboxRef} />
        <LoadingBox ref={this.loadingboxRef} />
        <ConfirmBox ref={this.confirmboxRef} />
        <div className="App-body">
          <Row>
            <Col>
              <div>
                <table width="100%">
                  <tbody>
                    <tr>
                      <td>
                        <h4>Technical Site Survey</h4>
                      </td>
                      <td width="100" align="right">
                        {this.state.allowSave === true ? (
                          <Button variant="success" onClick={this.onSubmit}>
                            Save
                          </Button>
                        ) : (
                          <Button
                            variant="secondary"
                            onClick={this.onSubmit}
                            disabled
                          >
                            Save
                          </Button>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <hr />
              <Form>
                <Tabs
                  id={React.createRef()}
                  defaultActiveKey="cover"
                  onSelect={this.onTabSelect}
                >
                  {/*  */}
                  <Tab eventKey="cover" title="Cover">
                    <div style={{ marginTop: "10px" }}>
                      <Card border="primary">
                        <Card.Header>Cover's Detail</Card.Header>
                        <Card.Body>
                          <Form.Group>
                            <Form.Label>Project</Form.Label>
                            <Form.Control
                              type="text"
                              name="projectName"
                              defaultValue={getValue(
                                this.state.cover.projectName,
                                "",
                                ""
                              )}
                              disabled={true}
                              onChange={this.onCoverChangeToLocalStorage}
                            />
                          </Form.Group>
                          <Form.Group>
                            <Form.Label>Site Code</Form.Label>
                            <Form.Control
                              type="text"
                              name="siteCode"
                              defaultValue={getValue(
                                this.state.cover.siteCode,
                                "",
                                ""
                              )}
                              onChange={this.onCoverSiteCodeLookup}
                            />
                          </Form.Group>
                          <Form.Group>
                            <Form.Label>Region</Form.Label>
                            <Form.Control
                              type="text"
                              name="region"
                              defaultValue={getValue(
                                this.state.cover.region,
                                "",
                                ""
                              )}
                              readOnly
                            />
                          </Form.Group>
                          <Form.Group>
                            <Form.Label>Site Name EN</Form.Label>
                            <Form.Control
                              type="text"
                              name="siteNameEn"
                              defaultValue={getValue(
                                this.state.cover.siteNameEn,
                                "",
                                ""
                              )}
                              readOnly
                            />
                          </Form.Group>
                          <Form.Group>
                            <Form.Label>Site Name TH</Form.Label>
                            <Form.Control
                              type="text"
                              name="siteNameTh"
                              defaultValue={getValue(
                                this.state.cover.siteNameTh,
                                "",
                                ""
                              )}
                              readOnly
                            />
                          </Form.Group>
                          <Form.Group>
                            <Form.Label>Zone</Form.Label>
                            <Form.Control
                              type="text"
                              name="zone"
                              defaultValue={getValue(
                                this.state.cover.zone,
                                "",
                                ""
                              )}
                              readOnly
                            />
                          </Form.Group>
                          <Form.Group>
                            <Form.Label>IP</Form.Label>
                            <Form.Control
                              type="text"
                              name="ip"
                              defaultValue={getValue(
                                this.state.cover.ip,
                                "",
                                ""
                              )}
                              onChange={this.onCoverChange}
                            />
                          </Form.Group>
                          <Form.Group>
                            <Form.Label>Gateway</Form.Label>
                            <Form.Control
                              type="text"
                              name="gateway"
                              defaultValue={getValue(
                                this.state.cover.gateway,
                                "",
                                ""
                              )}
                              onChange={this.onCoverChange}
                            />
                          </Form.Group>
                          <Form.Group>
                            <Form.Label>Subnet Mask</Form.Label>
                            <Form.Control
                              type="text"
                              name="subnetMask"
                              defaultValue={getValue(
                                this.state.cover.subnetMask,
                                "",
                                ""
                              )}
                              onChange={this.onCoverChange}
                            />
                          </Form.Group>
                          <Form.Group>
                            <Form.Label>IPRAN</Form.Label>
                            <Form.Control
                              type="text"
                              name="ipRan"
                              defaultValue={getValue(
                                this.state.cover.ipRan,
                                "",
                                ""
                              )}
                              onChange={this.onCoverChange}
                            />
                          </Form.Group>
                          <hr />
                          <Form.Group>
                            <Form.Label>Surver & Installaion by</Form.Label>
                            <Form.Control
                              type="text"
                              name="surveyAndInstallation"
                              defaultValue={getValue(
                                this.state.cover.surveyAndInstallation,
                                "",
                                ""
                              )}
                              disabled
                            />
                          </Form.Group>
                          <Form.Group>
                            <Form.Label>Surver & Installaion Phone</Form.Label>
                            <Form.Control
                              type="text"
                              name="surveyAndInstallationPhone"
                              defaultValue={getValue(
                                this.state.cover.surveyAndInstallationPhone,
                                "",
                                ""
                              )}
                              disabled
                            />
                          </Form.Group>
                          <Form.Group>
                            <Form.Label>Technician Name</Form.Label>
                            <Form.Control
                              type="text"
                              name="technicianName"
                              defaultValue={getValue(
                                this.state.cover.technicianName,
                                "",
                                ""
                              )}
                              readOnly
                            />
                          </Form.Group>
                          <Form.Group>
                            <Form.Label>Technician Phone</Form.Label>
                            <Form.Control
                              type="text"
                              name="technicianPhone"
                              defaultValue={getValue(
                                this.state.cover.technicianPhone,
                                "",
                                ""
                              )}
                              readOnly
                            />
                          </Form.Group>
                          <Form.Group>
                            <Form.Label>Date</Form.Label>
                            <Form.Control
                              type="date"
                              name="date"
                              onChange={this.onCoverChange}
                              defaultValue={getValue(
                                this.state.cover.date,
                                "",
                                ""
                              )}
                            />
                          </Form.Group>
                          <Form.Group>
                            <Form.Label>Remark</Form.Label>
                            <Form.Control
                              type="text"
                              name="remark"
                              defaultValue={getValue(
                                this.state.cover.remark,
                                "",
                                ""
                              )}
                            />
                          </Form.Group>
                        </Card.Body>
                      </Card>
                      <Card style={{ marginTop: "10px" }} border="primary">
                        <Card.Header>Cover's Image</Card.Header>
                        <Card.Body>
                          <Form.Group>
                            <ImageUploadPreview
                              ref={this.imageCoverRef}
                              src="/images/ssrExample/cover.png"
                              mode="v"
                            />
                          </Form.Group>
                        </Card.Body>
                      </Card>
                      <Card style={{ marginTop: "10px" }} border="primary">
                        <Card.Header>QR Code Master 1</Card.Header>
                        <Card.Body>
                          <Form.Group>
                            <ImageQRUploadPreview
                              ref={this.imageQRCodeMaster1Ref}
                              src="/images/ssrExample/qrcode.png"
                            />
                          </Form.Group>
                        </Card.Body>
                      </Card>
                      <Card style={{ marginTop: "10px" }} border="primary">
                        <Card.Header>QR Code Slave 2</Card.Header>
                        <Card.Body>
                          <Form.Group>
                            <ImageQRUploadPreview
                              ref={this.imageQRCodeSlave2Ref}
                              src="/images/ssrExample/qrcode.png"
                            />
                          </Form.Group>
                        </Card.Body>
                      </Card>
                      <Card style={{ marginTop: "10px" }} border="primary">
                        <Card.Header>QR Code Slave 3</Card.Header>
                        <Card.Body>
                          <Form.Group>
                            <ImageQRUploadPreview
                              ref={this.imageQRCodeSlave3Ref}
                              src="/images/ssrExample/qrcode.png"
                            />
                          </Form.Group>
                        </Card.Body>
                      </Card>
                    </div>
                  </Tab>
                  {/*  */}
                  <Tab eventKey="check" title="Check">
                    <div style={{ padding: "5px" }}>
                      <Table striped bordered hover size="sm">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Detail</th>
                            <th>Result</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>1</td>
                            <td>
                              ตรวจสอบตู้ ได้รับความเสียหายหรือไม่ เช่น บุบ
                              ซีลยางไม่ฉีกขาด
                            </td>
                            <td>
                              <select
                                name="visualCheck1"
                                className="custom-select"
                                onChange={this.onCheckChange}
                                value={this.state.cover.visualCheck1}
                              >
                                <option value="Pass" defaultValue>
                                  Pass
                                </option>
                                <option value="Fail">Fail</option>
                                <option value="N/A">N/A</option>
                              </select>
                            </td>
                          </tr>
                          {this.state.check.visualCheck1 === "Fail" ? (
                            <tr>
                              <td colSpan="3">
                                <ImageUploadPreview
                                  ref={this.imageVisualCheck11Ref}
                                  src="/images/na-horizontal.jpg"
                                  mode="h"
                                />
                                <ImageUploadPreview
                                  ref={this.imageVisualCheck12Ref}
                                  src="/images/na-horizontal.jpg"
                                  mode="h"
                                />
                                <ImageUploadPreview
                                  ref={this.imageVisualCheck13Ref}
                                  src="/images/na-horizontal.jpg"
                                  mode="h"
                                />
                                <ImageUploadPreview
                                  ref={this.imageVisualCheck14Ref}
                                  src="/images/na-horizontal.jpg"
                                  mode="h"
                                />
                              </td>
                            </tr>
                          ) : null}
                          <tr>
                            <td>2</td>
                            <td>
                              ตรวจสอบว่าประตูและตัวล๊อคเดิมของตู้
                              สามารถทำงานได้อย่างสมบรูณ์ไม่ติดขัด
                            </td>
                            <td width="100">
                              <select
                                name="visualCheck2"
                                className="custom-select"
                                onChange={this.onCheckChange}
                                value={this.state.cover.visualCheck2}
                              >
                                <option value="Pass" defaultValue>
                                  Pass
                                </option>
                                <option value="Fail">Fail</option>
                                <option value="N/A">N/A</option>
                              </select>
                            </td>
                          </tr>
                          {this.state.check.visualCheck2 === "Fail" ? (
                            <tr>
                              <td colSpan="3">
                                <ImageUploadPreview
                                  ref={this.imageVisualCheck21Ref}
                                  src="/images/na-horizontal.jpg"
                                  mode="h"
                                />
                                <ImageUploadPreview
                                  ref={this.imageVisualCheck22Ref}
                                  src="/images/na-horizontal.jpg"
                                  mode="h"
                                />
                                <ImageUploadPreview
                                  ref={this.imageVisualCheck23Ref}
                                  src="/images/na-horizontal.jpg"
                                  mode="h"
                                />
                                <ImageUploadPreview
                                  ref={this.imageVisualCheck24Ref}
                                  src="/images/na-horizontal.jpg"
                                  mode="h"
                                />
                              </td>
                            </tr>
                          ) : null}
                          <tr>
                            <td>3</td>
                            <td>
                              ตรวจสอบทรัพย์สินและอุปกรณ์เกิดการสูญหายหรือไม่
                            </td>
                            <td width="100">
                              <select
                                name="visualCheck3"
                                className="custom-select"
                                onChange={this.onCheckChange}
                                value={this.state.cover.visualCheck3}
                              >
                                <option value="Pass" defaultValue>
                                  Pass
                                </option>
                                <option value="Fail">Fail</option>
                                <option value="N/A">N/A</option>
                              </select>
                            </td>
                          </tr>
                          {this.state.check.visualCheck3 === "Fail" ? (
                            <tr>
                              <td colSpan="3">
                                <ImageUploadPreview
                                  ref={this.imageVisualCheck31Ref}
                                  src="/images/na-horizontal.jpg"
                                  mode="h"
                                />
                                <ImageUploadPreview
                                  ref={this.imageVisualCheck32Ref}
                                  src="/images/na-horizontal.jpg"
                                  mode="h"
                                />
                                <ImageUploadPreview
                                  ref={this.imageVisualCheck33Ref}
                                  src="/images/na-horizontal.jpg"
                                  mode="h"
                                />
                                <ImageUploadPreview
                                  ref={this.imageVisualCheck34Ref}
                                  src="/images/na-horizontal.jpg"
                                  mode="h"
                                />
                              </td>
                            </tr>
                          ) : null}
                        </tbody>
                      </Table>
                    </div>
                  </Tab>
                  {/* Master 1 */}
                  <Tab eventKey="master1" title="Master 1">
                    <div style={{ marginTop: "10px" }}>
                      <Card border="primary">
                        <Card.Header>Design Master's Image</Card.Header>
                        <Card.Body>
                          <Form.Group>
                            <Form.Label>
                              Design Implement Smart Site Master
                            </Form.Label>
                            <SSRDesignMasterImage
                              ref={this.imageDesignMaster1Ref}
                              src="/images/ssrExample/designMaster.png"
                              src2="/images/ssrExample/designMaster2.png"
                              draggable={true}
                            />
                          </Form.Group>
                        </Card.Body>
                      </Card>
                      <Card style={{ marginTop: "10px" }} border="primary">
                        <Card.Header>Master's Image</Card.Header>
                        <Card.Body>
                          <Row>
                            <Col md={6}>
                              <div
                                style={{
                                  borderWidth: "1px",
                                  borderStyle: "solid",
                                  borderColor: "#52BE80",
                                  marginBottom: "5px",
                                  padding: "10px"
                                }}
                              >
                                <Form.Group>
                                  <Form.Label>
                                    CONNECT DC POWER TO SMART SITE
                                  </Form.Label>
                                </Form.Group>
                                <Form.Group>
                                  <ImageUploadPreviewWithRect
                                    ref={this.imageM11Ref}
                                    src="/images/ssrExample/m1.png"
                                    draggable={true}
                                  />
                                </Form.Group>
                              </div>
                            </Col>
                            <Col md={6}>
                              <div
                                style={{
                                  borderWidth: "1px",
                                  borderStyle: "solid",
                                  borderColor: "#52BE80",
                                  marginBottom: "5px",
                                  padding: "10px"
                                }}
                              >
                                <Form.Group>
                                  <Form.Label>USB CAMERA</Form.Label>
                                </Form.Group>
                                <Form.Group>
                                  <ImageUploadPreviewWithRect
                                    ref={this.imageM12Ref}
                                    src="/images/ssrExample/m2.png"
                                    draggable={true}
                                  />
                                </Form.Group>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={6}>
                              <div
                                style={{
                                  borderWidth: "1px",
                                  borderStyle: "solid",
                                  borderColor: "#52BE80",
                                  marginBottom: "5px",
                                  padding: "10px"
                                }}
                              >
                                <Form.Group>
                                  <Form.Label>ELECTRIC LOCK</Form.Label>
                                </Form.Group>
                                <Form.Group>
                                  <ImageUploadPreviewWithRect
                                    ref={this.imageM13Ref}
                                    src="/images/ssrExample/m3.png"
                                    draggable={true}
                                  />
                                </Form.Group>
                              </div>
                            </Col>
                            <Col md={6}>
                              <div
                                style={{
                                  borderWidth: "1px",
                                  borderStyle: "solid",
                                  borderColor: "#52BE80",
                                  marginBottom: "5px",
                                  padding: "10px"
                                }}
                              >
                                <Form.Group>
                                  <Form.Label>DOOR LOCK</Form.Label>
                                </Form.Group>
                                <Form.Group>
                                  <ImageUploadPreviewWithRect
                                    ref={this.imageM14Ref}
                                    src="/images/ssrExample/m4.png"
                                    draggable={true}
                                  />
                                </Form.Group>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={6}>
                              <div
                                style={{
                                  borderWidth: "1px",
                                  borderStyle: "solid",
                                  borderColor: "#52BE80",
                                  marginBottom: "5px",
                                  padding: "10px"
                                }}
                              >
                                <Form.Group>
                                  <Form.Label>IPRAN PORT ASSIGN</Form.Label>
                                </Form.Group>
                                <Form.Group>
                                  <ImageUploadPreviewWithRect
                                    ref={this.imageM15Ref}
                                    src="/images/ssrExample/m5.png"
                                    draggable={true}
                                  />
                                </Form.Group>
                              </div>
                            </Col>
                            <Col md={6}>
                              <div
                                style={{
                                  borderWidth: "1px",
                                  borderStyle: "solid",
                                  borderColor: "#52BE80",
                                  marginBottom: "5px",
                                  padding: "10px"
                                }}
                              >
                                <Form.Group>
                                  <Form.Label>MASTER CONTROLLER</Form.Label>
                                </Form.Group>
                                <Form.Group>
                                  <ImageUploadPreviewWithRect
                                    ref={this.imageM16Ref}
                                    src="/images/ssrExample/m6.png"
                                    draggable={true}
                                  />
                                </Form.Group>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={6}>
                              <div
                                style={{
                                  borderWidth: "1px",
                                  borderStyle: "solid",
                                  borderColor: "#52BE80",
                                  marginBottom: "5px",
                                  padding: "10px"
                                }}
                              >
                                <Form.Group>
                                  <Form.Label>BUZZER</Form.Label>
                                </Form.Group>
                                <Form.Group>
                                  <ImageUploadPreviewWithRect
                                    ref={this.imageM17Ref}
                                    src="/images/ssrExample/m7.png"
                                    draggable={true}
                                  />
                                </Form.Group>
                              </div>
                            </Col>
                            <Col md={6}>
                              <div
                                style={{
                                  borderWidth: "1px",
                                  borderStyle: "solid",
                                  borderColor: "#52BE80",
                                  marginBottom: "5px",
                                  padding: "10px"
                                }}
                              >
                                <Form.Group>
                                  <Form.Label>MOTION SENSOR</Form.Label>
                                </Form.Group>
                                <Form.Group>
                                  <ImageUploadPreviewWithRect
                                    ref={this.imageM18Ref}
                                    src="/images/ssrExample/m8.png"
                                    draggable={true}
                                  />
                                </Form.Group>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={6}>
                              <div
                                style={{
                                  borderWidth: "1px",
                                  borderStyle: "solid",
                                  borderColor: "#52BE80",
                                  marginBottom: "5px",
                                  padding: "10px"
                                }}
                              >
                                <Form.Group>
                                  <Form.Label>Cable Inlet</Form.Label>
                                </Form.Group>
                                <Form.Group>
                                  <ImageUploadPreviewWithRect
                                    ref={this.imageM19Ref}
                                    src="/images/ssrExample/m9.png"
                                    draggable={true}
                                  />
                                </Form.Group>
                              </div>
                            </Col>
                            <Col md={6}>
                              <div
                                style={{
                                  borderWidth: "1px",
                                  borderStyle: "solid",
                                  borderColor: "#52BE80",
                                  marginBottom: "5px",
                                  padding: "10px"
                                }}
                              >
                                <Form.Group>
                                  <Form.Label>Ground Connection</Form.Label>
                                </Form.Group>
                                <Form.Group>
                                  <ImageUploadPreviewWithRect
                                    ref={this.imageM110Ref}
                                    src="/images/ssrExample/m10.png"
                                    draggable={true}
                                  />
                                </Form.Group>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={6}>
                              <div
                                style={{
                                  borderWidth: "1px",
                                  borderStyle: "solid",
                                  borderColor: "#52BE80",
                                  marginBottom: "5px",
                                  padding: "10px"
                                }}
                              >
                                <Form.Group>
                                  <Form.Label>Ground Bar Connection</Form.Label>
                                </Form.Group>
                                <Form.Group>
                                  <ImageUploadPreviewWithRect
                                    ref={this.imageM111Ref}
                                    src="/images/ssrExample/m11.png"
                                    draggable={true}
                                  />
                                </Form.Group>
                              </div>
                            </Col>
                            <Col md={6}></Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    </div>
                  </Tab>
                  {/* Slave 2 */}
                  <Tab eventKey="slave2" title="Slave 2">
                    <div style={{ marginTop: "10px" }}>
                      <Card border="primary">
                        <Card.Header>Design Slave's Image</Card.Header>
                        <Card.Body>
                          <Form.Group>
                            <Form.Label>
                              Design Implement Smart Site Slave
                            </Form.Label>
                          </Form.Group>
                          <Form.Group>
                            <SSRDesignSlaveImage
                              ref={this.imageDesignSlave2Ref}
                              src="/images/ssrExample/designSlave.png"
                              draggable={true}
                            />
                          </Form.Group>
                        </Card.Body>
                      </Card>
                      <Card style={{ marginTop: "10px" }} border="primary">
                        <Card.Header>Slave's Image</Card.Header>
                        <Card.Body>
                          <Row>
                            <Col md={6}>
                              <div
                                style={{
                                  borderWidth: "1px",
                                  borderStyle: "solid",
                                  borderColor: "#52BE80",
                                  marginBottom: "5px",
                                  padding: "10px"
                                }}
                              >
                                <Form.Group>
                                  <Form.Label>
                                    CONNECT DC POWER TO SMART SITE
                                  </Form.Label>
                                </Form.Group>
                                <Form.Group>
                                  <ImageUploadPreviewWithRect
                                    ref={this.imageS21Ref}
                                    src="/images/ssrExample/s1.png"
                                    draggable={true}
                                  />
                                </Form.Group>
                              </div>
                            </Col>
                            <Col md={6}>
                              <div
                                style={{
                                  borderWidth: "1px",
                                  borderStyle: "solid",
                                  borderColor: "#52BE80",
                                  marginBottom: "5px",
                                  padding: "10px"
                                }}
                              >
                                <Form.Group>
                                  <Form.Label>SLAVE CONTROLLER</Form.Label>
                                </Form.Group>
                                <Form.Group>
                                  <ImageUploadPreviewWithRect
                                    ref={this.imageS22Ref}
                                    src="/images/ssrExample/s2.png"
                                    draggable={true}
                                  />
                                </Form.Group>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={6}>
                              <div
                                style={{
                                  borderWidth: "1px",
                                  borderStyle: "solid",
                                  borderColor: "#52BE80",
                                  marginBottom: "5px",
                                  padding: "10px"
                                }}
                              >
                                <Form.Group>
                                  <Form.Label>ELECTRIC LOCK</Form.Label>
                                </Form.Group>
                                <Form.Group>
                                  <ImageUploadPreviewWithRect
                                    ref={this.imageS23Ref}
                                    src="/images/ssrExample/s3.png"
                                    draggable={true}
                                  />
                                </Form.Group>
                              </div>
                            </Col>
                            <Col md={6}>
                              <div
                                style={{
                                  borderWidth: "1px",
                                  borderStyle: "solid",
                                  borderColor: "#52BE80",
                                  marginBottom: "5px",
                                  padding: "10px"
                                }}
                              >
                                <Form.Group>
                                  <Form.Label>DOOR LOCK</Form.Label>
                                </Form.Group>
                                <Form.Group>
                                  <ImageUploadPreviewWithRect
                                    ref={this.imageS24Ref}
                                    src="/images/ssrExample/s4.png"
                                    draggable={true}
                                  />
                                </Form.Group>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={6}>
                              <div
                                style={{
                                  borderWidth: "1px",
                                  borderStyle: "solid",
                                  borderColor: "#52BE80",
                                  marginBottom: "5px",
                                  padding: "10px"
                                }}
                              >
                                <Form.Group>
                                  <Form.Label>Cable Inlet</Form.Label>
                                </Form.Group>
                                <Form.Group>
                                  <ImageUploadPreviewWithRect
                                    ref={this.imageS25Ref}
                                    src="/images/ssrExample/s5.png"
                                    draggable={true}
                                  />
                                </Form.Group>
                              </div>
                            </Col>
                            <Col md={6}>
                              <div
                                style={{
                                  borderWidth: "1px",
                                  borderStyle: "solid",
                                  borderColor: "#52BE80",
                                  marginBottom: "5px",
                                  padding: "10px"
                                }}
                              >
                                <Form.Group>
                                  <Form.Label>
                                    Ground Bar in Cab. Connection
                                  </Form.Label>
                                </Form.Group>
                                <Form.Group>
                                  <ImageUploadPreviewWithRect
                                    ref={this.imageS26Ref}
                                    src="/images/ssrExample/s6.png"
                                    draggable={true}
                                  />
                                </Form.Group>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={6}>
                              <div
                                style={{
                                  borderWidth: "1px",
                                  borderStyle: "solid",
                                  borderColor: "#52BE80",
                                  marginBottom: "5px",
                                  padding: "10px"
                                }}
                              >
                                <Form.Group>
                                  <Form.Label>Ground Bar Connection</Form.Label>
                                </Form.Group>
                                <Form.Group>
                                  <ImageUploadPreviewWithRect
                                    ref={this.imageS27Ref}
                                    src="/images/ssrExample/s7.png"
                                    draggable={true}
                                  />
                                </Form.Group>
                              </div>
                            </Col>
                            <Col md={6}></Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    </div>
                  </Tab>
                  {/* Slave 3 */}
                  <Tab eventKey="slave3" title="Slave 3">
                    <div style={{ marginTop: "10px" }}>
                      <Card style={{ marginTop: "10px" }} border="primary">
                        <Card.Header>Slave's Image</Card.Header>
                        <Card.Body>
                          <Row>
                            <Col md={6}>
                              <div
                                style={{
                                  borderWidth: "1px",
                                  borderStyle: "solid",
                                  borderColor: "#52BE80",
                                  marginBottom: "5px",
                                  padding: "10px"
                                }}
                              >
                                <Form.Group>
                                  <Form.Label>
                                    CONNECT DC POWER TO SMART SITE
                                  </Form.Label>
                                </Form.Group>
                                <Form.Group>
                                  <ImageUploadPreviewWithRect
                                    ref={this.imageS31Ref}
                                    src="/images/ssrExample/s1.png"
                                    draggable={true}
                                  />
                                </Form.Group>
                              </div>
                            </Col>
                            <Col md={6}>
                              <div
                                style={{
                                  borderWidth: "1px",
                                  borderStyle: "solid",
                                  borderColor: "#52BE80",
                                  marginBottom: "5px",
                                  padding: "10px"
                                }}
                              >
                                <Form.Group>
                                  <Form.Label>SLAVE CONTROLLER</Form.Label>
                                </Form.Group>
                                <Form.Group>
                                  <ImageUploadPreviewWithRect
                                    ref={this.imageS32Ref}
                                    src="/images/ssrExample/s2.png"
                                    draggable={true}
                                  />
                                </Form.Group>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={6}>
                              <div
                                style={{
                                  borderWidth: "1px",
                                  borderStyle: "solid",
                                  borderColor: "#52BE80",
                                  marginBottom: "5px",
                                  padding: "10px"
                                }}
                              >
                                <Form.Group>
                                  <Form.Label>ELECTRIC LOCK</Form.Label>
                                </Form.Group>
                                <Form.Group>
                                  <ImageUploadPreviewWithRect
                                    ref={this.imageS33Ref}
                                    src="/images/ssrExample/s3.png"
                                    draggable={true}
                                  />
                                </Form.Group>
                              </div>
                            </Col>
                            <Col md={6}>
                              <div
                                style={{
                                  borderWidth: "1px",
                                  borderStyle: "solid",
                                  borderColor: "#52BE80",
                                  marginBottom: "5px",
                                  padding: "10px"
                                }}
                              >
                                <Form.Group>
                                  <Form.Label>DOOR LOCK</Form.Label>
                                </Form.Group>
                                <Form.Group>
                                  <ImageUploadPreviewWithRect
                                    ref={this.imageS34Ref}
                                    src="/images/ssrExample/s4.png"
                                    draggable={true}
                                  />
                                </Form.Group>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={6}>
                              <div
                                style={{
                                  borderWidth: "1px",
                                  borderStyle: "solid",
                                  borderColor: "#52BE80",
                                  marginBottom: "5px",
                                  padding: "10px"
                                }}
                              >
                                <Form.Group>
                                  <Form.Label>Cable Inlet</Form.Label>
                                </Form.Group>
                                <Form.Group>
                                  <ImageUploadPreviewWithRect
                                    ref={this.imageS35Ref}
                                    src="/images/ssrExample/s5.png"
                                    draggable={true}
                                  />
                                </Form.Group>
                              </div>
                            </Col>
                            <Col md={6}>
                              <div
                                style={{
                                  borderWidth: "1px",
                                  borderStyle: "solid",
                                  borderColor: "#52BE80",
                                  marginBottom: "5px",
                                  padding: "10px"
                                }}
                              >
                                <Form.Group>
                                  <Form.Label>
                                    Ground Bar in Cab. Connection
                                  </Form.Label>
                                </Form.Group>
                                <Form.Group>
                                  <ImageUploadPreviewWithRect
                                    ref={this.imageS36Ref}
                                    src="/images/ssrExample/s6.png"
                                    draggable={true}
                                  />
                                </Form.Group>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={6}>
                              <div
                                style={{
                                  borderWidth: "1px",
                                  borderStyle: "solid",
                                  borderColor: "#52BE80",
                                  marginBottom: "5px",
                                  padding: "10px"
                                }}
                              >
                                <Form.Group>
                                  <Form.Label>Ground Bar Connection</Form.Label>
                                </Form.Group>
                                <Form.Group>
                                  <ImageUploadPreviewWithRect
                                    ref={this.imageS37Ref}
                                    src="/images/ssrExample/s7.png"
                                    draggable={true}
                                  />
                                </Form.Group>
                              </div>
                            </Col>
                            <Col md={6}></Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    </div>
                  </Tab>
                </Tabs>
              </Form>
            </Col>
          </Row>
        </div>
      </>
    );
  };
}

export default withRouter(SSRTechnician);

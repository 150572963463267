import React, { Component } from "react";
import { Redirect, withRouter } from "react-router-dom";
import { Card } from "react-bootstrap";
import { Image } from "react-bootstrap";
import { Tabs } from "react-bootstrap";
import { Tab } from "react-bootstrap";
import { Table } from "react-bootstrap";

import Axios from "axios";

import MessageBox from "../components/messagebox/messagebox";
import LoadingBox from "../components/messagebox/loadingbox";
import ConfirmBox from "../components/messagebox/confirmbox";
import MenuTechnician from "../components/menu/menuTechnician";
import SSRDesignMasterImage from "../components/image/SSRDesignMasterImage";
import SSRDesignSlaveImage from "../components/image/SSRDesignSlaveImage";
import ImageUploadPreviewWithRect from "../components/image/ImageUploadPreviewWithRect";

import { getValue } from "../helper/utilityHelper";

var Config = require("../config/config.json");

class SSRTechnicianShow extends Component {
  //
  constructor(props) {
    // Base contructor.
    super(props);
    // Ref.
    this.messageboxRef = React.createRef();
    this.loadingboxRef = React.createRef();
    this.confirmboxRef = React.createRef();
    //
    this.imageDesignMaster1Ref = React.createRef();
    this.imageM11Ref = React.createRef();
    this.imageM12Ref = React.createRef();
    this.imageM13Ref = React.createRef();
    this.imageM14Ref = React.createRef();
    this.imageM15Ref = React.createRef();
    this.imageM16Ref = React.createRef();
    this.imageM17Ref = React.createRef();
    this.imageM18Ref = React.createRef();
    this.imageM19Ref = React.createRef();
    this.imageM110Ref = React.createRef();
    this.imageM111Ref = React.createRef();
    //
    this.imageDesignSlave2Ref = React.createRef();
    this.imageS21Ref = React.createRef();
    this.imageS22Ref = React.createRef();
    this.imageS23Ref = React.createRef();
    this.imageS24Ref = React.createRef();
    this.imageS25Ref = React.createRef();
    this.imageS26Ref = React.createRef();
    this.imageS27Ref = React.createRef();
    //
    // this.imageDesignSlave2Ref = React.createRef();
    this.imageS31Ref = React.createRef();
    this.imageS32Ref = React.createRef();
    this.imageS33Ref = React.createRef();
    this.imageS34Ref = React.createRef();
    this.imageS35Ref = React.createRef();
    this.imageS36Ref = React.createRef();
    this.imageS37Ref = React.createRef();
    // State.
    this.state = {
      redirect: "",
      data: null
    };
  }

  //
  requestData = () => {
    if (this.loadingboxRef.current) this.loadingboxRef.current.onShow();
    let params = new URLSearchParams(window.location.search);
    let id = params.get("id");
    Axios.get(Config.service.url + "/ssr/get/" + id, {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    })
      .then(res => {
        if (res.data.success === false) {
          this.messageboxRef.current.onShow("Error", res.data.message);
          return;
        }
        this.setState({ data: res.data.data });
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        if (this.loadingboxRef.current) this.loadingboxRef.current.onHide();
      });
  };

  //
  onTabSelect = key => {
    if (key === "master1") {
      setTimeout(() => {
        this.imageDesignMaster1Ref.current.refresh(
          this.state.data.master1.imageDesignMaster,
          this.state.data.master1imageDesignMaster2,
          this.state.data.master1.markers
        );
        this.imageM11Ref.current.refresh(
          this.state.data.master1.imageM11,
          this.state.data.master1.markersM11
        );
        this.imageM12Ref.current.refresh(
          this.state.data.master1.imageM12,
          this.state.data.master1.markersM12
        );
        this.imageM13Ref.current.refresh(
          this.state.data.master1.imageM13,
          this.state.data.master1.markersM13
        );
        this.imageM14Ref.current.refresh(
          this.state.data.master1.imageM14,
          this.state.data.master1.markersM14
        );
        this.imageM15Ref.current.refresh(
          this.state.data.master1.imageM15,
          this.state.data.master1.markersM15
        );
        this.imageM16Ref.current.refresh(
          this.state.data.master1.imageM16,
          this.state.data.master1.markersM16
        );
        this.imageM17Ref.current.refresh(
          this.state.data.master1.imageM17,
          this.state.data.master1.markersM17
        );
        this.imageM18Ref.current.refresh(
          this.state.data.master1.imageM18,
          this.state.data.master1.markersM18
        );
        this.imageM19Ref.current.refresh(
          this.state.data.master1.imageM19,
          this.state.data.master1.markersM19
        );
        this.imageM110Ref.current.refresh(
          this.state.data.master1.imageM110,
          this.state.data.master1.markersM110
        );
        this.imageM111Ref.current.refresh(
          this.state.data.master1.imageM111,
          this.state.data.master1.markersM111
        );
      }, 50);
    } else if (key === "slave2") {
      setTimeout(() => {
        this.imageDesignSlave2Ref.current.refresh(
          this.state.data.slave2.imageDesignSlave,
          this.state.data.slave2.markers
        );
        this.imageS21Ref.current.refresh(
          this.state.data.slave2.imageS21,
          this.state.data.slave2.markersS21
        );
        this.imageS22Ref.current.refresh(
          this.state.data.slave2.imageS22,
          this.state.data.slave2.markersS22
        );
        this.imageS23Ref.current.refresh(
          this.state.data.slave2.imageS23,
          this.state.data.slave2.markersS23
        );
        this.imageS24Ref.current.refresh(
          this.state.data.slave2.imageS24,
          this.state.data.slave2.markersS24
        );
        this.imageS25Ref.current.refresh(
          this.state.data.slave2.imageS25,
          this.state.data.slave2.markersS25
        );
        this.imageS26Ref.current.refresh(
          this.state.data.slave2.imageS26,
          this.state.data.slave2.markersS26
        );
        this.imageS27Ref.current.refresh(
          this.state.data.slave2.imageS27,
          this.state.data.slave2.markersS27
        );
      }, 50);
    } else if (key === "slave3") {
      setTimeout(() => {
        this.imageS31Ref.current.refresh(
          this.state.data.slave3.imageS31,
          this.state.data.slave3.markersS31
        );
        this.imageS32Ref.current.refresh(
          this.state.data.slave3.imageS32,
          this.state.data.slave3.markersS32
        );
        this.imageS33Ref.current.refresh(
          this.state.data.slave3.imageS33,
          this.state.data.slave3.markersS33
        );
        this.imageS34Ref.current.refresh(
          this.state.data.slave3.imageS34,
          this.state.data.slave3.markersS34
        );
        this.imageS35Ref.current.refresh(
          this.state.data.slave3.imageS35,
          this.state.data.slave3.markersS35
        );
        this.imageS36Ref.current.refresh(
          this.state.data.slave3.imageS36,
          this.state.data.slave3.markersS36
        );
        this.imageS37Ref.current.refresh(
          this.state.data.slave3.imageS37,
          this.state.data.slave3.markersS37
        );
      }, 50);
    }
  };

  //
  componentDidMount() {
    if (localStorage.getItem("token") === null) {
      return;
    }
    this.requestData();
  }

  //
  render() {
    if (localStorage.getItem("token") === null) {
      return <Redirect push to="/login" />;
    }

    // Redirect
    if (this.state.redirect) {
      return <Redirect push to={this.state.redirect} />;
    }

    return (
      <>
        <MenuTechnician />
        <MessageBox ref={this.messageboxRef} />
        <LoadingBox ref={this.loadingboxRef} />
        <ConfirmBox ref={this.confirmboxRef} />
        <div className="App-body">
          <div style={{ textAlign: "center", marginBottom: "15px" }}>
            <h3>Technical Site Survey</h3>
            {this.state.data !== null
              ? "Doc No: " + this.state.data.documentNumber
              : ""}
          </div>
          {/*  */}
          <Tabs
            id={React.createRef()}
            defaultActiveKey="cover"
            onSelect={this.onTabSelect}
          >
            <Tab eventKey="cover" title="Cover">
              <div style={{ marginTop: "5px" }}>
                <Card border="primary">
                  <Card.Header>
                    {getValue(this.state.data?.cover.projectName, "", "")}
                  </Card.Header>
                  <Card.Body>
                    <table width="100%">
                      <tbody>
                        <tr>
                          <td width="120">Status</td>
                          <td>{getValue(this.state.data?.status, "", "")}</td>
                        </tr>
                        <tr>
                          <td>Date</td>
                          <td>
                            {getValue(this.state.data?.cover.date, "", "")}
                          </td>
                        </tr>
                        <tr>
                          <td>Site</td>
                          <td>
                            {getValue(this.state.data?.cover.siteCode, "", "")}
                          </td>
                        </tr>
                        <tr>
                          <td>Site En</td>
                          <td>
                            {getValue(
                              this.state.data?.cover.siteNameEn,
                              "",
                              ""
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>Site Th</td>
                          <td>
                            {getValue(
                              this.state.data?.cover.siteNameTh,
                              "",
                              ""
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>Region</td>
                          <td>
                            {getValue(this.state.data?.cover.region, "", "")}
                          </td>
                        </tr>
                        <tr>
                          <td>Zone</td>
                          <td>
                            {getValue(this.state.data?.cover.zone, "", "")}
                          </td>
                        </tr>
                        <tr>
                          <td>IP</td>
                          <td>{getValue(this.state.data?.cover.ip, "", "")}</td>
                        </tr>
                        <tr>
                          <td>Gateway</td>
                          <td>
                            {getValue(this.state.data?.cover.gateway, "", "")}
                          </td>
                        </tr>
                        <tr>
                          <td>Subnet Mask</td>
                          <td>
                            {getValue(
                              this.state.data?.cover.subnetMask,
                              "",
                              ""
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>IPRAN</td>
                          <td>
                            {getValue(this.state.data?.cover.ipRan, "", "")}
                          </td>
                        </tr>
                        <tr>
                          <td>Tech Name 1</td>
                          <td>
                            {getValue(
                              this.state.data?.cover.technicianName,
                              "",
                              ""
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>Tech Phone 1</td>
                          <td>
                            {getValue(
                              this.state.data?.cover.technicianPhone,
                              "",
                              ""
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>Tech Name 2</td>
                          <td>
                            {getValue(
                              this.state.data?.cover.technicianName2,
                              "",
                              ""
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>Tech Phone 2</td>
                          <td>
                            {getValue(
                              this.state.data?.cover.technicianPhone2,
                              "",
                              ""
                            )}
                          </td>
                        </tr>
                        <tr style={{ color: "#ff0000", fontWeight: "bold" }}>
                          <td>Remark</td>
                          <td>
                            {getValue(this.state.data?.cover.remark, "", "")}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Card.Body>
                </Card>
              </div>
              <div style={{ marginTop: "5px" }}>
                <Card border="primary">
                  <Card.Header>Cover's Image</Card.Header>
                  <Card.Body>
                    <Image
                      src={
                        this.state.data !== null &&
                        this.state.data.cover.imageCover !== ""
                          ? Config.service.url +
                            "/ssr/image/" +
                            this.state.data.cover.imageCover
                          : "/images/na-vertical.jpg"
                      }
                      style={{ width: "100%" }}
                      rounded
                      fluid
                    />
                  </Card.Body>
                </Card>
              </div>
              <div style={{ marginTop: "5px" }}>
                <Card border="primary">
                  <Card.Header>QR Code Master 1</Card.Header>
                  <Card.Body>
                    QR Code Master :{" "}
                    {this.state.data !== null
                      ? this.state.data.cover.qrCodeMaster1
                      : null}
                    <br />
                    <br />
                    <Image
                      src={
                        this.state.data !== null &&
                        this.state.data.cover.imageQRCodeMaster1 !== ""
                          ? Config.service.url +
                            "/ssr/image/" +
                            this.state.data.cover.imageQRCodeMaster1
                          : "/images/na-vertical.jpg"
                      }
                      style={{ width: "100%" }}
                      rounded
                      fluid
                    />
                  </Card.Body>
                </Card>
              </div>
              <div style={{ marginTop: "5px" }}>
                <Card border="primary">
                  <Card.Header>QR Code Slave 2</Card.Header>
                  <Card.Body>
                    QR Code Slave :{" "}
                    {this.state.data !== null
                      ? this.state.data.cover.qrCodeSlave2
                      : null}
                    <br />
                    <br />
                    <Image
                      src={
                        this.state.data !== null &&
                        this.state.data.cover.imageQRCodeSlave2 !== ""
                          ? Config.service.url +
                            "/ssr/image/" +
                            this.state.data.cover.imageQRCodeSlave2
                          : "/images/na-vertical.jpg"
                      }
                      style={{ width: "100%" }}
                      rounded
                      fluid
                    />
                  </Card.Body>
                </Card>
              </div>
              <div style={{ marginTop: "5px" }}>
                <Card border="primary">
                  <Card.Header>QR Code Slave 3</Card.Header>
                  <Card.Body>
                    QR Code Slave :{" "}
                    {this.state.data !== null
                      ? this.state.data.cover.qrCodeSlave3
                      : null}
                    <br />
                    <br />
                    <Image
                      src={
                        this.state.data !== null &&
                        this.state.data.cover.imageQRCodeSlave3 !== ""
                          ? Config.service.url +
                            "/ssr/image/" +
                            this.state.data.cover.imageQRCodeSlave3
                          : "/images/na-vertical.jpg"
                      }
                      style={{ width: "100%" }}
                      rounded
                      fluid
                    />
                  </Card.Body>
                </Card>
              </div>
            </Tab>
            {/*  */}
            <Tab eventKey="check" title="Check">
              <div style={{ padding: "5px" }}>
                <Table striped bordered hover size="sm">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Detail</th>
                      <th>Result</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>
                        ตรวจสอบตู้ ได้รับความเสียหายหรือไม่ เช่น บุบ
                        ซีลยางไม่ฉีกขาด
                      </td>
                      <td>
                        {this.state.data !== null
                          ? this.state.data.check.visualCheck1
                          : ""}
                      </td>
                    </tr>
                    {this.state.data !== null &&
                    this.state.data.check.visualCheck1 === "Fail" ? (
                      <tr>
                        <td colSpan="3" align="center">
                          <Image
                            src={
                              this.state.data !== null &&
                              this.state.data.check.imageVisualCheck11 !== ""
                                ? Config.service.url +
                                  "/ssr/image/" +
                                  this.state.data.check.imageVisualCheck11
                                : "/images/na-horizontal.jpg"
                            }
                            style={{ width: "100%", marginBottom: "5px" }}
                            rounded
                            fluid
                          />
                          <Image
                            src={
                              this.state.data !== null &&
                              this.state.data.check.imageVisualCheck12 !== ""
                                ? Config.service.url +
                                  "/ssr/image/" +
                                  this.state.data.check.imageVisualCheck12
                                : "/images/na-horizontal.jpg"
                            }
                            style={{ width: "100%", marginBottom: "5px" }}
                            rounded
                            fluid
                          />
                          <Image
                            src={
                              this.state.data !== null &&
                              this.state.data.check.imageVisualCheck13 !== ""
                                ? Config.service.url +
                                  "/ssr/image/" +
                                  this.state.data.check.imageVisualCheck13
                                : "/images/na-horizontal.jpg"
                            }
                            style={{ width: "100%", marginBottom: "5px" }}
                            rounded
                            fluid
                          />
                          <Image
                            src={
                              this.state.data !== null &&
                              this.state.data.check.imageVisualCheck14 !== ""
                                ? Config.service.url +
                                  "/ssr/image/" +
                                  this.state.data.check.imageVisualCheck14
                                : "/images/na-horizontal.jpg"
                            }
                            style={{ width: "100%", marginBottom: "5px" }}
                            rounded
                            fluid
                          />
                        </td>
                      </tr>
                    ) : null}
                    <tr>
                      <td>2</td>
                      <td>
                        ตรวจสอบว่าประตูและตัวล๊อคเดิมของตู้
                        สามารถทำงานได้อย่างสมบรูณ์ไม่ติดขัด
                      </td>
                      <td>
                        {this.state.data !== null
                          ? this.state.data.check.visualCheck2
                          : ""}
                      </td>
                    </tr>
                    {this.state.data !== null &&
                    this.state.data.check.visualCheck2 === "Fail" ? (
                      <tr>
                        <td colSpan="3" align="center">
                          <Image
                            src={
                              this.state.data !== null &&
                              this.state.data.check.imageVisualCheck21 !== ""
                                ? Config.service.url +
                                  "/ssr/image/" +
                                  this.state.data.check.imageVisualCheck21
                                : "/images/na-horizontal.jpg"
                            }
                            style={{ width: "100%", marginBottom: "5px" }}
                            rounded
                            fluid
                          />
                          <Image
                            src={
                              this.state.data !== null &&
                              this.state.data.check.imageVisualCheck22 !== ""
                                ? Config.service.url +
                                  "/ssr/image/" +
                                  this.state.data.check.imageVisualCheck22
                                : "/images/na-horizontal.jpg"
                            }
                            style={{ width: "100%", marginBottom: "5px" }}
                            rounded
                            fluid
                          />
                          <Image
                            src={
                              this.state.data !== null &&
                              this.state.data.check.imageVisualCheck23 !== ""
                                ? Config.service.url +
                                  "/ssr/image/" +
                                  this.state.data.check.imageVisualCheck23
                                : "/images/na-horizontal.jpg"
                            }
                            style={{ width: "100%", marginBottom: "5px" }}
                            rounded
                            fluid
                          />
                          <Image
                            src={
                              this.state.data !== null &&
                              this.state.data.check.imageVisualCheck24 !== ""
                                ? Config.service.url +
                                  "/ssr/image/" +
                                  this.state.data.check.imageVisualCheck24
                                : "/images/na-horizontal.jpg"
                            }
                            style={{ width: "100%", marginBottom: "5px" }}
                            rounded
                            fluid
                          />
                        </td>
                      </tr>
                    ) : null}
                    <tr>
                      <td>3</td>
                      <td>ตรวจสอบทรัพย์สินและอุปกรณ์เกิดการสูญหายหรือไม่</td>
                      <td>
                        {this.state.data !== null
                          ? this.state.data.check.visualCheck3
                          : ""}
                      </td>
                    </tr>
                    {this.state.data !== null &&
                    this.state.data.check.visualCheck3 === "Fail" ? (
                      <tr>
                        <td colSpan="3" align="center">
                          <Image
                            src={
                              this.state.data !== null &&
                              this.state.data.check.imageVisualCheck31 !== ""
                                ? Config.service.url +
                                  "/ssr/image/" +
                                  this.state.data.check.imageVisualCheck31
                                : "/images/na-horizontal.jpg"
                            }
                            style={{ width: "100%", marginBottom: "5px" }}
                            rounded
                            fluid
                          />
                          <Image
                            src={
                              this.state.data !== null &&
                              this.state.data.check.imageVisualCheck32 !== ""
                                ? Config.service.url +
                                  "/ssr/image/" +
                                  this.state.data.check.imageVisualCheck32
                                : "/images/na-horizontal.jpg"
                            }
                            style={{ width: "100%", marginBottom: "5px" }}
                            rounded
                            fluid
                          />
                          <Image
                            src={
                              this.state.data !== null &&
                              this.state.data.check.imageVisualCheck33 !== ""
                                ? Config.service.url +
                                  "/ssr/image/" +
                                  this.state.data.check.imageVisualCheck33
                                : "/images/na-horizontal.jpg"
                            }
                            style={{ width: "100%", marginBottom: "5px" }}
                            rounded
                            fluid
                          />
                          <Image
                            src={
                              this.state.data !== null &&
                              this.state.data.check.imageVisualCheck34 !== ""
                                ? Config.service.url +
                                  "/ssr/image/" +
                                  this.state.data.check.imageVisualCheck34
                                : "/images/na-horizontal.jpg"
                            }
                            style={{ width: "100%", marginBottom: "5px" }}
                            rounded
                            fluid
                          />
                        </td>
                      </tr>
                    ) : null}
                  </tbody>
                </Table>
              </div>
            </Tab>
            {/*  */}
            <Tab eventKey="master1" title="Master 1">
              <div style={{ marginTop: "5px" }}>
                <Card border="primary">
                  <Card.Header>Design Implement Smart Site Master</Card.Header>
                  <Card.Body>
                    <SSRDesignMasterImage
                      ref={this.imageDesignMaster1Ref}
                      src="/images/na-vertical.jpg"
                      src2="/images/na-horizontal.jpg"
                      draggable={false}
                      readOnly={true}
                    />
                  </Card.Body>
                </Card>
              </div>
              <div style={{ marginTop: "5px" }}>
                <Card border="primary">
                  <Card.Header>CONNECT DC POWER TO SMART SITE</Card.Header>
                  <Card.Body>
                    <ImageUploadPreviewWithRect
                      ref={this.imageM11Ref}
                      src={
                        this.state.data !== null &&
                        this.state.data.master1.imageM11 !== ""
                          ? Config.service.url +
                            "/ssr/image/" +
                            this.state.data.master1.imageM11
                          : "/images/na-horizontal.jpg"
                      }
                      draggable={false}
                      readOnly={true}
                    />
                  </Card.Body>
                </Card>
              </div>
              <div style={{ marginTop: "5px" }}>
                <Card border="primary">
                  <Card.Header>USB CAMERA</Card.Header>
                  <Card.Body>
                    <ImageUploadPreviewWithRect
                      ref={this.imageM12Ref}
                      src={
                        this.state.data !== null &&
                        this.state.data.master1.imageM12 !== ""
                          ? Config.service.url +
                            "/ssr/image/" +
                            this.state.data.master1.imageM12
                          : "/images/na-horizontal.jpg"
                      }
                      draggable={false}
                      readOnly={true}
                    />
                  </Card.Body>
                </Card>
              </div>
              <div style={{ marginTop: "5px" }}>
                <Card border="primary">
                  <Card.Header>ELECTRIC LOCK</Card.Header>
                  <Card.Body>
                    <ImageUploadPreviewWithRect
                      ref={this.imageM13Ref}
                      src={
                        this.state.data !== null &&
                        this.state.data.master1.imageM13 !== ""
                          ? Config.service.url +
                            "/ssr/image/" +
                            this.state.data.master1.imageM13
                          : "/images/na-horizontal.jpg"
                      }
                      draggable={false}
                      readOnly={true}
                    />
                  </Card.Body>
                </Card>
              </div>
              <div style={{ marginTop: "5px" }}>
                <Card border="primary">
                  <Card.Header>DOOR LOCK</Card.Header>
                  <Card.Body>
                    <ImageUploadPreviewWithRect
                      ref={this.imageM14Ref}
                      src={
                        this.state.data !== null &&
                        this.state.data.master1.imageM14 !== ""
                          ? Config.service.url +
                            "/ssr/image/" +
                            this.state.data.master1.imageM14
                          : "/images/na-horizontal.jpg"
                      }
                      draggable={false}
                      readOnly={true}
                    />
                  </Card.Body>
                </Card>
              </div>
              <div style={{ marginTop: "5px" }}>
                <Card border="primary">
                  <Card.Header>IPRAN PORT ASSIGN</Card.Header>
                  <Card.Body>
                    <ImageUploadPreviewWithRect
                      ref={this.imageM15Ref}
                      src={
                        this.state.data !== null &&
                        this.state.data.master1.imageM15 !== ""
                          ? Config.service.url +
                            "/ssr/image/" +
                            this.state.data.master1.imageM15
                          : "/images/na-horizontal.jpg"
                      }
                      draggable={false}
                      readOnly={true}
                    />
                  </Card.Body>
                </Card>
              </div>
              <div style={{ marginTop: "5px" }}>
                <Card border="primary">
                  <Card.Header>MASTER CONTROLLER</Card.Header>
                  <Card.Body>
                    <ImageUploadPreviewWithRect
                      ref={this.imageM16Ref}
                      src={
                        this.state.data !== null &&
                        this.state.data.master1.imageM16 !== ""
                          ? Config.service.url +
                            "/ssr/image/" +
                            this.state.data.master1.imageM16
                          : "/images/na-horizontal.jpg"
                      }
                      draggable={false}
                      readOnly={true}
                    />
                  </Card.Body>
                </Card>
              </div>
              <div style={{ marginTop: "5px" }}>
                <Card border="primary">
                  <Card.Header>BUZZER</Card.Header>
                  <Card.Body>
                    <ImageUploadPreviewWithRect
                      ref={this.imageM17Ref}
                      src={
                        this.state.data !== null &&
                        this.state.data.master1.imageM17 !== ""
                          ? Config.service.url +
                            "/ssr/image/" +
                            this.state.data.master1.imageM17
                          : "/images/na-horizontal.jpg"
                      }
                      draggable={false}
                      readOnly={true}
                    />
                  </Card.Body>
                </Card>
              </div>
              <div style={{ marginTop: "5px" }}>
                <Card border="primary">
                  <Card.Header>MOTION SENSOR</Card.Header>
                  <Card.Body>
                    <ImageUploadPreviewWithRect
                      ref={this.imageM18Ref}
                      src={
                        this.state.data !== null &&
                        this.state.data.master1.imageM18 !== ""
                          ? Config.service.url +
                            "/ssr/image/" +
                            this.state.data.master1.imageM18
                          : "/images/na-horizontal.jpg"
                      }
                      draggable={false}
                      readOnly={true}
                    />
                  </Card.Body>
                </Card>
              </div>
              <div style={{ marginTop: "5px" }}>
                <Card border="primary">
                  <Card.Header>Cable Inlet</Card.Header>
                  <Card.Body>
                    <ImageUploadPreviewWithRect
                      ref={this.imageM19Ref}
                      src={
                        this.state.data !== null &&
                        this.state.data.master1.imageM19 !== ""
                          ? Config.service.url +
                            "/ssr/image/" +
                            this.state.data.master1.imageM19
                          : "/images/na-horizontal.jpg"
                      }
                      draggable={false}
                      readOnly={true}
                    />
                  </Card.Body>
                </Card>
              </div>
              <div style={{ marginTop: "5px" }}>
                <Card border="primary">
                  <Card.Header>Ground Connection</Card.Header>
                  <Card.Body>
                    <ImageUploadPreviewWithRect
                      ref={this.imageM110Ref}
                      src={
                        this.state.data !== null &&
                        this.state.data.master1.imageM110 !== ""
                          ? Config.service.url +
                            "/ssr/image/" +
                            this.state.data.master1.imageM110
                          : "/images/na-horizontal.jpg"
                      }
                      draggable={false}
                      readOnly={true}
                    />
                  </Card.Body>
                </Card>
              </div>
              <div style={{ marginTop: "5px" }}>
                <Card border="primary">
                  <Card.Header>Ground Bar Connection</Card.Header>
                  <Card.Body>
                    <ImageUploadPreviewWithRect
                      ref={this.imageM111Ref}
                      src={
                        this.state.data !== null &&
                        this.state.data.master1.imageM111 !== ""
                          ? Config.service.url +
                            "/ssr/image/" +
                            this.state.data.master1.imageM111
                          : "/images/na-horizontal.jpg"
                      }
                      draggable={false}
                      readOnly={true}
                    />
                  </Card.Body>
                </Card>
              </div>
            </Tab>
            {/*  */}
            <Tab eventKey="slave2" title="Slave 2">
              <div style={{ marginTop: "5px" }}>
                <Card border="primary">
                  <Card.Header>Design Implement Smart Site Slave</Card.Header>
                  <Card.Body>
                    <SSRDesignSlaveImage
                      ref={this.imageDesignSlave2Ref}
                      src="/images/na-vertical.jpg"
                      draggable={false}
                      readOnly={true}
                    />
                  </Card.Body>
                </Card>
              </div>
              <div style={{ marginTop: "5px" }}>
                <Card border="primary">
                  <Card.Header>CONNECT DC POWER TO SMART SITE</Card.Header>
                  <Card.Body>
                    <ImageUploadPreviewWithRect
                      ref={this.imageS21Ref}
                      src={
                        this.state.data !== null &&
                        this.state.data.slave2.imageS21 !== ""
                          ? Config.service.url +
                            "/ssr/image/" +
                            this.state.data.slave2.imageS21
                          : "/images/na-horizontal.jpg"
                      }
                      draggable={false}
                      readOnly={true}
                    />
                  </Card.Body>
                </Card>
              </div>
              <div style={{ marginTop: "5px" }}>
                <Card border="primary">
                  <Card.Header>SLAVE CONTROLLER</Card.Header>
                  <Card.Body>
                    <ImageUploadPreviewWithRect
                      ref={this.imageS22Ref}
                      src={
                        this.state.data !== null &&
                        this.state.data.slave2.imageS22 !== ""
                          ? Config.service.url +
                            "/ssr/image/" +
                            this.state.data.slave2.imageS22
                          : "/images/na-horizontal.jpg"
                      }
                      draggable={false}
                      readOnly={true}
                    />
                  </Card.Body>
                </Card>
              </div>
              <div style={{ marginTop: "5px" }}>
                <Card border="primary">
                  <Card.Header>ELECTRIC LOCK</Card.Header>
                  <Card.Body>
                    <ImageUploadPreviewWithRect
                      ref={this.imageS23Ref}
                      src={
                        this.state.data !== null &&
                        this.state.data.slave2.imageS23 !== ""
                          ? Config.service.url +
                            "/ssr/image/" +
                            this.state.data.slave2.imageS23
                          : "/images/na-horizontal.jpg"
                      }
                      draggable={false}
                      readOnly={true}
                    />
                  </Card.Body>
                </Card>
              </div>
              <div style={{ marginTop: "5px" }}>
                <Card border="primary">
                  <Card.Header>DOOR LOCK</Card.Header>
                  <Card.Body>
                    <ImageUploadPreviewWithRect
                      ref={this.imageS24Ref}
                      src={
                        this.state.data !== null &&
                        this.state.data.slave2.imageS24 !== ""
                          ? Config.service.url +
                            "/ssr/image/" +
                            this.state.data.slave2.imageS24
                          : "/images/na-horizontal.jpg"
                      }
                      draggable={false}
                      readOnly={true}
                    />
                  </Card.Body>
                </Card>
              </div>
              <div style={{ marginTop: "5px" }}>
                <Card border="primary">
                  <Card.Header>Cable Inlet</Card.Header>
                  <Card.Body>
                    <ImageUploadPreviewWithRect
                      ref={this.imageS25Ref}
                      src={
                        this.state.data !== null &&
                        this.state.data.slave2.imageS25 !== ""
                          ? Config.service.url +
                            "/ssr/image/" +
                            this.state.data.slave2.imageS25
                          : "/images/na-horizontal.jpg"
                      }
                      draggable={false}
                      readOnly={true}
                    />
                  </Card.Body>
                </Card>
              </div>
              <div style={{ marginTop: "5px" }}>
                <Card border="primary">
                  <Card.Header>Ground Bar in Cab. Connection</Card.Header>
                  <Card.Body>
                    <ImageUploadPreviewWithRect
                      ref={this.imageS26Ref}
                      src={
                        this.state.data !== null &&
                        this.state.data.slave2.imageS26 !== ""
                          ? Config.service.url +
                            "/ssr/image/" +
                            this.state.data.slave2.imageS26
                          : "/images/na-horizontal.jpg"
                      }
                      draggable={false}
                      readOnly={true}
                    />
                  </Card.Body>
                </Card>
              </div>
              <div style={{ marginTop: "5px" }}>
                <Card border="primary">
                  <Card.Header>Ground Bar Connection</Card.Header>
                  <Card.Body>
                    <ImageUploadPreviewWithRect
                      ref={this.imageS27Ref}
                      src={
                        this.state.data !== null &&
                        this.state.data.slave2.imageS27 !== ""
                          ? Config.service.url +
                            "/ssr/image/" +
                            this.state.data.slave2.imageS27
                          : "/images/na-horizontal.jpg"
                      }
                      draggable={false}
                      readOnly={true}
                    />
                  </Card.Body>
                </Card>
              </div>
            </Tab>
            <Tab eventKey="slave3" title="Slave 3">
              <div style={{ marginTop: "5px" }}>
                <Card border="primary">
                  <Card.Header>CONNECT DC POWER TO SMART SITE</Card.Header>
                  <Card.Body>
                    <ImageUploadPreviewWithRect
                      ref={this.imageS31Ref}
                      src={
                        this.state.data !== null &&
                        this.state.data.slave3.imageS31 !== ""
                          ? Config.service.url +
                            "/ssr/image/" +
                            this.state.data.slave3.imageS31
                          : "/images/na-horizontal.jpg"
                      }
                      draggable={false}
                      readOnly={true}
                    />
                  </Card.Body>
                </Card>
              </div>
              <div style={{ marginTop: "5px" }}>
                <Card border="primary">
                  <Card.Header>SLAVE CONTROLLER</Card.Header>
                  <Card.Body>
                    <ImageUploadPreviewWithRect
                      ref={this.imageS32Ref}
                      src={
                        this.state.data !== null &&
                        this.state.data.slave3.imageS32 !== ""
                          ? Config.service.url +
                            "/ssr/image/" +
                            this.state.data.slave3.imageS32
                          : "/images/na-horizontal.jpg"
                      }
                      draggable={false}
                      readOnly={true}
                    />
                  </Card.Body>
                </Card>
              </div>
              <div style={{ marginTop: "5px" }}>
                <Card border="primary">
                  <Card.Header>ELECTRIC LOCK</Card.Header>
                  <Card.Body>
                    <ImageUploadPreviewWithRect
                      ref={this.imageS33Ref}
                      src={
                        this.state.data !== null &&
                        this.state.data.slave3.imageS33 !== ""
                          ? Config.service.url +
                            "/ssr/image/" +
                            this.state.data.slave3.imageS33
                          : "/images/na-horizontal.jpg"
                      }
                      draggable={false}
                      readOnly={true}
                    />
                  </Card.Body>
                </Card>
              </div>
              <div style={{ marginTop: "5px" }}>
                <Card border="primary">
                  <Card.Header>DOOR LOCK</Card.Header>
                  <Card.Body>
                    <ImageUploadPreviewWithRect
                      ref={this.imageS34Ref}
                      src={
                        this.state.data !== null &&
                        this.state.data.slave3.imageS34 !== ""
                          ? Config.service.url +
                            "/ssr/image/" +
                            this.state.data.slave3.imageS34
                          : "/images/na-horizontal.jpg"
                      }
                      draggable={false}
                      readOnly={true}
                    />
                  </Card.Body>
                </Card>
              </div>
              <div style={{ marginTop: "5px" }}>
                <Card border="primary">
                  <Card.Header>Cable Inlet</Card.Header>
                  <Card.Body>
                    <ImageUploadPreviewWithRect
                      ref={this.imageS35Ref}
                      src={
                        this.state.data !== null &&
                        this.state.data.slave3.imageS35 !== ""
                          ? Config.service.url +
                            "/ssr/image/" +
                            this.state.data.slave3.imageS35
                          : "/images/na-horizontal.jpg"
                      }
                      draggable={false}
                      readOnly={true}
                    />
                  </Card.Body>
                </Card>
              </div>
              <div style={{ marginTop: "5px" }}>
                <Card border="primary">
                  <Card.Header>Ground Bar in Cab. Connection</Card.Header>
                  <Card.Body>
                    <ImageUploadPreviewWithRect
                      ref={this.imageS36Ref}
                      src={
                        this.state.data !== null &&
                        this.state.data.slave3.imageS36 !== ""
                          ? Config.service.url +
                            "/ssr/image/" +
                            this.state.data.slave3.imageS36
                          : "/images/na-horizontal.jpg"
                      }
                      draggable={false}
                      readOnly={true}
                    />
                  </Card.Body>
                </Card>
              </div>
              <div style={{ marginTop: "5px" }}>
                <Card border="primary">
                  <Card.Header>Ground Bar Connection</Card.Header>
                  <Card.Body>
                    <ImageUploadPreviewWithRect
                      ref={this.imageS37Ref}
                      src={
                        this.state.data !== null &&
                        this.state.data.slave3.imageS37 !== ""
                          ? Config.service.url +
                            "/ssr/image/" +
                            this.state.data.slave3.imageS37
                          : "/images/na-horizontal.jpg"
                      }
                      draggable={false}
                      readOnly={true}
                    />
                  </Card.Body>
                </Card>
              </div>
            </Tab>
          </Tabs>
        </div>
      </>
    );
  }
}

export default withRouter(SSRTechnicianShow);

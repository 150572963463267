import React, { Component } from "react";
import { Redirect, withRouter } from "react-router-dom";
import { Button } from "react-bootstrap";
import { Table } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { Container } from "react-bootstrap";
import { FormControl } from "react-bootstrap";
import { InputGroup } from "react-bootstrap";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import { Card } from "react-bootstrap";
import auth from "../helper/authHelper";
// import { isMobile } from "react-device-detect";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

import Axios from "axios";

import MenuAdmin from "../components/menu/menuAdmin";
import MessageBox from "../components/messagebox/messagebox";
import LoadingBox from "../components/messagebox/loadingbox";
import ConfirmBox from "../components/messagebox/confirmbox";

var Config = require("../config/config.json");

class BaseStation extends Component {
  //
  constructor(props) {
    // Base contructor.
    super(props);
    // Ref.
    this.messageboxRef = React.createRef();
    this.loadingboxRef = React.createRef();
    this.confirmboxRef = React.createRef();
    // State.
    this.state = {
      isShow: false,
      isShowImport: false,
      data: [],
      page: 0,
      pages: 0,
      title: "New",
      excelFileLabel: "Select a file",
      summary: {
        all: 0
      }
    };
  }

  //
  requestData = page => {
    if (this.loadingboxRef.current) this.loadingboxRef.current.onShow();
    Axios.post(
      Config.service.url + "/baseStation/search",
      {
        page: page,
        pagination: Config.share.pagination
      },
      {
        headers: {
          Authorization: localStorage.getItem("token")
        }
      }
    )
      .then(res => {
        if (res.data.success === false) {
          this.messageboxRef.current.onShow("Error", res.data.message);
          return;
        }
        this.setState({ data: res.data.data.data, pages: res.data.data.pages });
        // Find summary.
        this.setState({
          summary: {
            all: res.data.data.length
          }
        });
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        if (this.loadingboxRef.current) this.loadingboxRef.current.onHide();
      });
  };

  //
  onChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  //
  onFileChange = e => {
    if (e.target.files[0] === undefined) return;
    this.setState({
      excelFileLabel: e.target.files[0].name,
      excelFile: e.target.files[0]
    });
  };

  //
  onNew = () => {
    this.setState({
      isShow: true,
      title: "New",
      //
      id: "",
      region: "",
      siteCode: "",
      siteNameEn: "",
      siteNameTh: "",
      zone: ""
    });
  };

  //
  onHide = () => {
    this.setState({ isShow: false });
  };

  //
  onShowImport = () => {
    this.setState({ isShowImport: true });
  };

  //
  onHideImport = () => {
    this.setState({ isShowImport: false });
  };

  //
  onShowClearData = () => {
    this.confirmboxRef.current.onShow(
      "Clear all data",
      "Do you want to clear all data?",
      () => {
        if (this.loadingboxRef.current) this.loadingboxRef.current.onShow();
        Axios.get(Config.service.url + "/baseStation/clear", {
          headers: {
            Authorization: localStorage.getItem("token")
          }
        })
          .then(res => {
            if (res.data.success === false) {
              this.messageboxRef.current.onShow("Error", res.data.message);
              return;
            }
            if (this.loadingboxRef.current) this.loadingboxRef.current.onHide();
            this.requestData(this.state.page);
          })
          .catch(err => {
            console.log(err);
          });
      }
    );
  };

  //
  onImport = () => {
    this.setState({ isShowImport: false });

    if (this.loadingboxRef.current) this.loadingboxRef.current.onShow();

    let data = new FormData();
    data.append("excelFile", this.state.excelFile);

    Axios.post(Config.service.url + "/baseStation/import", data, {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    })
      .then(res => {
        if (res.data.success === false) {
          this.messageboxRef.onShow("Error", res.data.message);
          return;
        }

        if (this.loadingboxRef.current) this.loadingboxRef.current.onHide();

        setTimeout(() => {
          this.requestData(0);
        }, 1000);
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        this.setState({
          id: "",
          image: null
        });
      });
  };

  onNextPage = () => {
    let page = this.state.page + 1;
    if (page >= this.state.pages) {
      this.setState({ page: this.state.pages });
    } else {
      this.setState({ page: page });
    }

    this.onSearchSubmit(page);
  };

  onPreviousPage = () => {
    let page = this.state.page - 1;
    if (page <= 0) {
      page = 0;
    }

    this.setState({ page: page });
    this.onSearchSubmit(page);
  };

  //
  onSubmit = () => {
    this.setState({ isShow: false });

    if (this.loadingboxRef.current) this.loadingboxRef.current.onShow();

    if (this.state.title === "New") {
      Axios.post(
        Config.service.url + "/baseStation/add",
        {
          region: this.state.region,
          siteCode: this.state.siteCode,
          siteNameEn: this.state.siteNameEn,
          siteNameTh: this.state.siteNameTh,
          zone: this.state.zone,
          totalCabinet: this.state.totalCabinet,
          latlong: this.state.latlong,
          networkIP: this.state.networkIP,
          networkGateway: this.state.networkGateway,
          networkSubnet: this.state.networkSubnet,
          networkIPRANPort: this.state.networkIPRANPort
        },
        {
          headers: {
            Authorization: localStorage.getItem("token")
          }
        }
      )
        .then(res => {
          if (res.data.success === false) {
            this.messageboxRef.current.onShow("Error", res.data.message);
            this.loadingboxRef.current.onHide();
            return;
          }

          if (this.loadingboxRef.current) this.loadingboxRef.current.onHide();

          this.requestData(this.state.page);
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.setState({
            id: "",
            region: "",
            siteCode: "",
            siteNameEn: "",
            siteNameTh: "",
            zone: ""
          });
        });
    } else if (this.state.title === "Edit") {
      //
      let data = null;
      data = {
        region: this.state.region,
        siteCode: this.state.siteCode,
        siteNameEn: this.state.siteNameEn,
        siteNameTh: this.state.siteNameTh,
        zone: this.state.zone,
        totalCabinet: this.state.totalCabinet,
        latlong: this.state.latlong,
        networkIP: this.state.networkIP,
        networkGateway: this.state.networkGateway,
        networkSubnet: this.state.networkSubnet,
        networkIPRANPort: this.state.networkIPRANPort
      };

      //
      Axios.put(
        Config.service.url + "/baseStation/update/" + this.state.id,
        data,
        {
          headers: {
            Authorization: localStorage.getItem("token")
          }
        }
      )
        .then(res => {
          if (res.data.success === false) {
            this.messageboxRef.current.onShow("Error", res.data.message);
            return;
          }

          if (this.loadingboxRef.current) this.loadingboxRef.current.onHide();

          this.requestData(this.state.page);
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.setState({
            id: "",
            region: "",
            siteCode: "",
            siteNameEn: "",
            siteNameTh: "",
            zone: ""
          });
        });
    }
  };

  //
  onEdit = data => {
    if (this.loadingboxRef.current) this.loadingboxRef.current.onShow();
    Axios.get(Config.service.url + "/baseStation/get/" + data._id, {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    })
      .then(res => {
        if (res.data.success === false) {
          this.messageboxRef.current.onShow("Error", res.data.message);
          return;
        }
        if (this.loadingboxRef.current) this.loadingboxRef.current.onHide();
        this.setState({
          isShow: true,
          title: "Edit",
          //
          id: res.data.data._id,
          region: res.data.data.region,
          siteCode: res.data.data.siteCode,
          siteNameEn: res.data.data.siteNameEn,
          siteNameTh: res.data.data.siteNameTh,
          zone: res.data.data.zone,
          totalCabinet: res.data.data.totalCabinet,
          latlong: res.data.data.latlong,
          networkIP: res.data.data.networkIP,
          networkGateway: res.data.data.networkGateway,
          networkSubnet: res.data.data.networkSubnet,
          networkIPRANPort: res.data.data.networkIPRANPort
        });
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {});
  };

  //
  onDelete = data => {
    this.confirmboxRef.current.onShow(
      "Delete",
      "Do you want to delete this record?",
      () => {
        if (this.loadingboxRef.current) this.loadingboxRef.current.onShow();
        Axios.delete(Config.service.url + "/baseStation/delete/" + data._id, {
          headers: {
            Authorization: localStorage.getItem("token")
          }
        })
          .then(res => {
            if (res.data.success === false) {
              this.messageboxRef.current.onShow("Error", res.data.message);
              return;
            }
            if (this.loadingboxRef.current) this.loadingboxRef.current.onHide();
            this.requestData(this.state.page);
          })
          .catch(err => {
            console.log(err);
          });
      }
    );
  };

  //
  onSearchSubmit = page => {
    if (this.loadingboxRef.current) this.loadingboxRef.current.onShow();
    Axios.post(
      Config.service.url + "/baseStation/search",
      {
        page: page,
        pagination: Config.share.pagination,
        q: this.state.q
      },
      {
        headers: {
          Authorization: localStorage.getItem("token")
        }
      }
    )
      .then(res => {
        if (res.data.success === false) {
          this.messageboxRef.current.onShow("Error", res.data.message);
          return;
        }
        this.setState({ data: res.data.data.data, pages: res.data.data.pages });
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        if (this.loadingboxRef.current) this.loadingboxRef.current.onHide();
        this.setState({
          q: ""
        });
      });
  };

  //
  componentDidMount() {
    if (localStorage.getItem("token") === null) {
      return;
    }
    this.requestData(0);
  }

  //
  view_desktop = () => {
    return (
      <>
        <MenuAdmin />
        <MessageBox ref={this.messageboxRef} />
        <LoadingBox ref={this.loadingboxRef} />
        <ConfirmBox ref={this.confirmboxRef} />
        {/*  */}
        <Modal show={this.state.isShow} onHide={this.onHide}>
          <Modal.Header closeButton>
            <Modal.Title>Base Station</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Container>
                <Form.Group>
                  <Row>
                    <Col md={4}>Region</Col>
                    <Col md={8}>
                      <Form.Control
                        type="text"
                        name="region"
                        onChange={this.onChange}
                        value={this.state.region}
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row>
                    <Col md={4}>Site Code</Col>
                    <Col md={8}>
                      <Form.Control
                        type="text"
                        name="siteCode"
                        onChange={this.onChange}
                        value={this.state.siteCode}
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row>
                    <Col md={4}>Site Name EN</Col>
                    <Col md={8}>
                      <Form.Control
                        type="text"
                        name="siteNameEn"
                        onChange={this.onChange}
                        value={this.state.siteNameEn}
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row>
                    <Col md={4}>Site Name TH</Col>
                    <Col md={8}>
                      <Form.Control
                        type="text"
                        name="siteNameTh"
                        onChange={this.onChange}
                        value={this.state.siteNameTh}
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row>
                    <Col md={4}>Zone</Col>
                    <Col md={8}>
                      <Form.Control
                        type="text"
                        name="zone"
                        onChange={this.onChange}
                        value={this.state.zone}
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row>
                    <Col md={4}>Total Cabinet</Col>
                    <Col md={8}>
                      <Form.Control
                        type="text"
                        name="totalCabinet"
                        onChange={this.onChange}
                        value={this.state.totalCabinet}
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row>
                    <Col md={4}>Location</Col>
                    <Col md={8}>
                      <Form.Control
                        type="text"
                        name="latlong"
                        onChange={this.onChange}
                        value={this.state.latlong}
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row>
                    <Col md={4}>IP</Col>
                    <Col md={8}>
                      <Form.Control
                        type="text"
                        name="networkIP"
                        onChange={this.onChange}
                        value={this.state.networkIP}
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row>
                    <Col md={4}>Gateway</Col>
                    <Col md={8}>
                      <Form.Control
                        type="text"
                        name="networkGateway"
                        onChange={this.onChange}
                        value={this.state.networkGateway}
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row>
                    <Col md={4}>Subnet</Col>
                    <Col md={8}>
                      <Form.Control
                        type="text"
                        name="networkSubnet"
                        onChange={this.onChange}
                        value={this.state.networkSubnet}
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row>
                    <Col md={4}>IPRAN</Col>
                    <Col md={8}>
                      <Form.Control
                        type="text"
                        name="networkIPRANPort"
                        onChange={this.onChange}
                        value={this.state.networkIPRANPort}
                      />
                    </Col>
                  </Row>
                </Form.Group>
              </Container>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={this.onSubmit}>
              OK
            </Button>
            <Button variant="danger" onClick={this.onHide}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
        {/*  */}
        <Modal show={this.state.isShowImport} onHide={this.onHideImport}>
          <Modal.Header closeButton>
            <Modal.Title>Upload excel file</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Container>
                <Form.Group>
                  <Row>
                    <Col md={12}>
                      <Form.File
                        name="excelFile"
                        onChange={this.onFileChange}
                        label={this.state.excelFileLabel}
                        data-browse="Select"
                        custom
                      />
                      <a
                        href={
                          Config.service.url +
                          "/baseStation/download/BaseStationSmartSitePhase3.xlsx"
                        }
                        target="_blank"
                        download
                        rel="noopener noreferrer"
                      >
                        Download template file
                      </a>
                    </Col>
                  </Row>
                </Form.Group>
              </Container>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={this.onImport}>
              OK
            </Button>
            <Button variant="danger" onClick={this.onHideImport}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
        <div className="App-body">
          <Row className="mb-3">
            <Col md={9}>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <Button variant="success" onClick={this.onShowImport}>
                        {" "}
                        Import Data
                      </Button>
                    </td>
                    {/* <td>
                                            <Button variant="danger" onClick={this.onShowClearData}> Clear All Data</Button>
                                        </td> */}
                  </tr>
                </tbody>
              </table>
            </Col>
            <Col md={3}>
              <div style={{ float: "right" }}>
                <InputGroup className="mb-3">
                  <FormControl
                    placeholder="คำที่จะค้น"
                    name="q"
                    onChange={this.onChange}
                    value={this.state.q}
                  />
                  <InputGroup.Append>
                    <Button
                      variant="secondary"
                      onClick={this.onSearchSubmit}
                      title="Search"
                    >
                      <FontAwesomeIcon icon={faSearch} />
                    </Button>
                  </InputGroup.Append>
                </InputGroup>
              </div>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={12}>
              <table width="100%">
                <tbody>
                  <tr>
                    <td>
                      {"page " +
                        (this.state.page + 1) +
                        " of " +
                        this.state.pages +
                        " pages"}
                    </td>
                    <td align="right">
                      <div
                        className="hidden-print"
                        style={{ display: "inline" }}
                      >
                        <Button
                          variant="secondary"
                          onClick={this.onPreviousPage}
                          title="Search"
                        >
                          <FontAwesomeIcon icon={faArrowLeft} />
                        </Button>
                        &nbsp;
                        <Button
                          variant="secondary"
                          onClick={this.onNextPage}
                          title="Search"
                        >
                          <FontAwesomeIcon icon={faArrowRight} />
                        </Button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Card>
                <Card.Header>
                  <h4>Base Station ({this.state.summary.all})</h4>
                </Card.Header>
                <Card.Body>
                  <Table striped hover>
                    <thead>
                      <tr>
                        <th>Region</th>
                        <th>Code</th>
                        <th>Site Name</th>
                        <th>Zone</th>
                        <th>Location</th>
                        <th>IP</th>
                        <th>Gateway</th>
                        <th>Subnet</th>
                        <th>IPRAN</th>
                        <th width={150} style={{ textAlign: "right" }}>
                          <div className="hidden-print">
                            <Button
                              variant="success"
                              onClick={this.onNew}
                              title="Add"
                            >
                              <FontAwesomeIcon icon={faPlusCircle} />
                            </Button>
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.data.map((data, index) => {
                        return (
                          <tr key={index}>
                            <td>{data.region}</td>
                            <td>{data.siteCode}</td>
                            <td>
                              {data.siteNameTh}
                              <br />
                              {data.siteNameEn}
                            </td>
                            <td>{data.zone}</td>
                            <td>{data.latlong}</td>
                            <td>{data.networkIP}</td>
                            <td>{data.networkGateway}</td>
                            <td>{data.networkSubnet}</td>
                            <td>{data.networkIPRANPort}</td>
                            <td style={{ textAlign: "right" }}>
                              <div className="hidden-print">
                                <Button
                                  variant="primary"
                                  onClick={() => this.onEdit(data)}
                                  title="แก้ไข"
                                >
                                  <FontAwesomeIcon icon={faEdit} />
                                </Button>{" "}
                                <Button
                                  variant="danger"
                                  onClick={() => this.onDelete(data)}
                                  title="ลบ"
                                >
                                  <FontAwesomeIcon icon={faTrash} />
                                </Button>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  };

  //
  render() {
    if (localStorage.getItem("token") === null) {
      return <Redirect push to="/login" />;
    }

    if (auth.allowAdmin() === false) {
      return <Redirect push to="/noPermission" />;
    }

    return this.view_desktop();
  }
}

export default withRouter(BaseStation);

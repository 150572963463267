//
//
//
export const processStatusText = status => {
  if (status === "Waiting-Review") {
    return "Waiting-SSK-Review";
  } else if (status === "Waiting-Approve") {
    return "Waiting-AIS-Approve";
  } else {
    return status;
  }
};

//
//
//
export const processStatus = status => {
  if (status === "Reject-From-SSK" || status === "Reject-From-AIS") {
    return "danger";
  } else if (status === "Waiting-Review") {
    return "warning";
  } else if (status === "Waiting-Approve") {
    return "info";
  } else if (status === "Approved") {
    return "success";
  } else {
    return "primary";
  }
};

//
//
//
export const base64ImageToBlob = str => {
  // extract content type and base64 payload from original string
  var pos = str.indexOf(";base64,");
  var type = str.substring(5, pos);
  var b64 = str.substr(pos + 8);

  // decode base64
  var imageContent = atob(b64);

  // create an ArrayBuffer and a view (as unsigned 8-bit)
  var buffer = new ArrayBuffer(imageContent.length);
  var view = new Uint8Array(buffer);

  // fill the view, using the decoded base64
  for (var n = 0; n < imageContent.length; n++) {
    view[n] = imageContent.charCodeAt(n);
  }

  // convert ArrayBuffer to Blob
  var blob = new Blob([buffer], { type: type });
  return blob;
};

//
//
//
export const getValue = (item, defaultValue, prefix) => {
  if (item === undefined || item === null || item === "") {
    return defaultValue;
  } else if (item.indexOf("images") !== -1) {
    return defaultValue;
  } else {
    return prefix + item;
  }
};

//
//
//
export const getValueImage = (item, defaultValue, prefix) => {
  if (item === undefined || item === null || item === "") {
    return defaultValue;
  } else if (item.indexOf("images") !== -1) {
    return defaultValue;
  } else {
    return prefix + item;
  }
};

const utils = {
  processStatusText,
  processStatus,
  base64ImageToBlob,
  getValue,
  getValueImage
};

export default utils;

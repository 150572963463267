import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import QrReader from "react-qr-reader";
import { Button } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import { Image } from 'react-bootstrap';

import MessageBox from '../../components/messagebox/messagebox';

var Config = require('../../config/config.json');

class ImageQRUploadPreview extends Component {

    //
    constructor(props) {
        // Base contructor.
        super(props);
        // Ref
        this.qrCodeRef = React.createRef();
        this.messageboxRef = React.createRef();
        // State
        this.state = {
            mode: this.props.mode,
            src: this.props.src,
            file: '',
            qrCode: '',
        }
    }

    //
    getState = () => {
        return this.state;
    }

    //
    onQRDataReceived = (data) => {
        if (data) {
            this.setState({
                qrCode: data
            })
        } else {
            this.setState({
                qrCode: ''
            });
        }
    }

    //
    onQRError = (err) => {
        console.error(err)
    }

    //
    openImageDialog = () => {
        this.qrCodeRef.current.openImageDialog();
    }

    //
    onImageLoaded = async (event) => {
        this.setState({ 
            file: event.target.src
        });
    }

    //
    refresh = (qrCode, src) => {
        console.log(src);
        if(src === '') {
            this.setState({ 
                src: '/images/ssrExample/qrcode.png',
                qrCode: qrCode
            });
        } else {
            this.setState({ 
                src: Config.service.url + "/ssr/image/" + src,
                qrCode: qrCode
            });
        }
    }

    //
    onTextChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    //
    render = () => {
        const previewStyle = {
            width: '100%',
        }
        //
        return (<>
            <MessageBox ref={this.messageboxRef} />
            <Form.Group>
                <Row>
                    <Col md={12}>
                        <Button  block onClick={this.openImageDialog} >Browse</Button>
                    </Col>
                    <Col md={12}>
                        <div style={{paddingTop: 5}}>
                            <Form.Control type="text" name="qrCode" defaultValue={this.state.qrCode} placeholder="QR Code" onChange={this.onTextChange} />
                        </div>
                    </Col>
                    <Col>
                        <div style={{paddingTop: 5, display: this.state.file.length > 0 ? 'block' : 'none'}}>
                            <QrReader ref={this.qrCodeRef}
                                delay={this.state.delay}
                                previewStyle={previewStyle}
                                onError={this.onQRError}
                                onScan={this.onQRDataReceived}
                                onImageLoad={this.onImageLoaded}
                                legacyMode={true}
                            />
                        </div>
                        <Image src={this.state.src} style={{width: '100%', marginTop: '5px', display: this.state.file.length > 0 ? 'none' : 'block'}}  />
                    </Col>
                </Row>
            </Form.Group>
        </>);
    }
}

export default ImageQRUploadPreview
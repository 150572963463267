import React, { Component } from "react";
import { Redirect, withRouter } from "react-router-dom";
import { Button } from "react-bootstrap";
import { Table } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { Container } from "react-bootstrap";
import { FormControl } from "react-bootstrap";
import { InputGroup } from "react-bootstrap";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import { Card } from "react-bootstrap";
import auth from "../helper/authHelper";
// import { isMobile } from "react-device-detect";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

import Axios from "axios";
import Hash from "hash.js";

import MenuAdmin from "../components/menu/menuAdmin";

import MessageBox from "../components/messagebox/messagebox";
import LoadingBox from "../components/messagebox/loadingbox";
import ConfirmBox from "../components/messagebox/confirmbox";

var Config = require("../config/config.json");

class User extends Component {
  //
  constructor(props) {
    // Base contructor.
    super(props);
    // Ref.
    this.messageboxRef = React.createRef();
    this.loadingboxRef = React.createRef();
    this.confirmboxRef = React.createRef();
    // State.
    this.state = {
      isShow: false,
      isShowImport: false,
      data: [],
      title: "New",
      excelFileLabel: "Select a file",
      page: 0,
      pages: 0
    };
  }

  //
  requestData = page => {
    if (this.loadingboxRef.current) this.loadingboxRef.current.onShow();
    Axios.post(
      Config.service.url + "/user/search",
      {
        page: page,
        pagination: Config.share.pagination
      },
      {
        headers: {
          Authorization: localStorage.getItem("token")
        }
      }
    )
      .then(res => {
        if (res.data.success === false) {
          this.messageboxRef.current.onShow("Error", res.data.message);
          return;
        }
        this.setState({ data: res.data.data.data, pages: res.data.data.pages });
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        if (this.loadingboxRef.current) this.loadingboxRef.current.onHide();
      });
  };

  //
  onChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  //
  onFileChange = e => {
    if (e.target.files[0] === undefined) return;
    this.setState({
      excelFileLabel: e.target.files[0].name,
      excelFile: e.target.files[0]
    });
  };

  //
  onNew = () => {
    this.setState({
      isShow: true,
      title: "New",
      //
      id: "",
      name: "",
      username: "",
      password: "",
      phone: "",
      contractor: "",
      role: "Reviewer",
      status: "Active"
    });
  };

  //
  onHide = () => {
    this.setState({ isShow: false });
  };

  //
  onShowImport = () => {
    this.setState({ isShowImport: true });
  };

  //
  onHideImport = () => {
    this.setState({ isShowImport: false });
  };

  //
  onSubmit = () => {
    this.setState({ isShow: false });
    if (this.loadingboxRef.current) this.loadingboxRef.current.onShow();

    if (this.state.title === "New") {
      Axios.post(
        Config.service.url + "/user/add",
        {
          name: this.state.name,
          phone: this.state.phone,
          contractor: this.state.contractor,
          username: this.state.username,
          password: Hash.sha256().update(this.state.password).digest("hex"),
          role: this.state.role,
          status: this.state.status
        },
        {
          headers: {
            Authorization: localStorage.getItem("token")
          }
        }
      )
        .then(res => {
          if (res.data.success === false) {
            this.messageboxRef.current.onShow("Error", res.data.message);
            if (this.loadingboxRef.current) this.loadingboxRef.current.onHide();
            return;
          }
          if (this.loadingboxRef.current) this.loadingboxRef.current.onHide();
          this.requestData(this.state.page);
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.setState({
            id: "",
            name: "",
            phone: "",
            contractor: "",
            username: "",
            password: "",
            role: "Reviewer",
            status: "Active"
          });
        });
    } else if (this.state.title === "Edit") {
      //
      let data = null;
      if (
        this.state.password === undefined ||
        this.state.password.length === 0
      ) {
        data = {
          name: this.state.name,
          phone: this.state.phone,
          contractor: this.state.contractor,
          username: this.state.username,
          role: this.state.role,
          status: this.state.status
        };
      } else {
        data = {
          name: this.state.name,
          phone: this.state.phone,
          contractor: this.state.contractor,
          username: this.state.username,
          password: Hash.sha256().update(this.state.password).digest("hex"),
          role: this.state.role,
          status: this.state.status
        };
      }

      //
      Axios.put(Config.service.url + "/user/update/" + this.state.id, data, {
        headers: {
          Authorization: localStorage.getItem("token")
        }
      })
        .then(res => {
          if (res.data.success === false) {
            this.messageboxRef.current.onShow("Error", res.data.message);
            return;
          }
          if (this.loadingboxRef.current) this.loadingboxRef.current.onHide();
          this.requestData(this.state.page);
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.setState({
            id: "",
            name: "",
            username: "",
            password: "",
            phone: "",
            contractor: "",
            role: "Reviewer",
            status: "Active"
          });
        });
    }
  };

  //
  onEdit = data => {
    if (this.loadingboxRef.current) this.loadingboxRef.current.onShow();
    Axios.get(Config.service.url + "/user/get/" + data._id, {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    })
      .then(res => {
        if (res.data.success === false) {
          this.messageboxRef.current.onShow("Error", res.data.message);
          return;
        }
        if (this.loadingboxRef.current) this.loadingboxRef.current.onHide();
        this.setState({
          isShow: true,
          title: "Edit",
          //
          id: res.data.data._id,
          name: res.data.data.name,
          phone: res.data.data.phone,
          contractor: res.data.contractor,
          username: res.data.data.username,
          role: res.data.data.role,
          status: res.data.data.status
        });
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {});
  };

  //
  onDelete = data => {
    this.confirmboxRef.current.onShow(
      "Delete",
      "Do you want to delete this record?",
      () => {
        if (this.loadingboxRef.current) this.loadingboxRef.current.onShow();
        Axios.delete(Config.service.url + "/user/delete/" + data._id, {
          headers: {
            Authorization: localStorage.getItem("token")
          }
        })
          .then(res => {
            if (res.data.success === false) {
              this.messageboxRef.current.onShow("Error", res.data.message);
              return;
            }
            if (this.loadingboxRef.current) this.loadingboxRef.current.onHide();
            this.requestData(this.state.page);
          })
          .catch(err => {
            console.log(err);
          });
      }
    );
  };

  //
  onImport = () => {
    this.setState({ isShowImport: false });

    if (this.loadingboxRef.current) this.loadingboxRef.current.onShow();

    let data = new FormData();
    data.append("excelFile", this.state.excelFile);

    Axios.post(Config.service.url + "/user/import", data, {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    })
      .then(res => {
        if (res.data.success === false) {
          this.messageboxRef.onShow("Error", res.data.message);
          return;
        }

        if (this.loadingboxRef.current) this.loadingboxRef.current.onHide();

        setTimeout(() => {
          this.requestData(0);
        }, 1000);
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        this.setState({
          id: "",
          image: null
        });
      });
  };

  onNextPage = () => {
    let page = this.state.page + 1;
    if (page >= this.state.pages) {
      this.setState({ page: this.state.pages });
    } else {
      this.setState({ page: page });
    }

    this.onSearchSubmit(page);
  };

  onPreviousPage = () => {
    let page = this.state.page - 1;
    if (page <= 0) {
      page = 0;
    }

    this.setState({ page: page });
    this.onSearchSubmit(page);
  };

  //
  onSearchSubmit = page => {
    if (this.loadingboxRef.current) this.loadingboxRef.current.onShow();
    Axios.post(
      Config.service.url + "/user/search",
      {
        page: page,
        pagination: Config.share.pagination,
        q: this.state.q
      },
      {
        headers: {
          Authorization: localStorage.getItem("token")
        }
      }
    )
      .then(res => {
        if (res.data.success === false) {
          this.messageboxRef.current.onShow("Error", res.data.message);
          return;
        }
        this.setState({ data: res.data.data.data, pages: res.data.data.pages });
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        if (this.loadingboxRef.current) this.loadingboxRef.current.onHide();
        this.setState({
          q: ""
        });
      });
  };

  //
  onShowClearData = () => {
    this.confirmboxRef.current.onShow(
      "Clear all data",
      "Do you want to clear all data?",
      () => {
        if (this.loadingboxRef.current) this.loadingboxRef.current.onShow();
        Axios.get(Config.service.url + "/user/clear", {
          headers: {
            Authorization: localStorage.getItem("token")
          }
        })
          .then(res => {
            if (res.data.success === false) {
              this.messageboxRef.current.onShow("Error", res.data.message);
              return;
            }
            if (this.loadingboxRef.current) this.loadingboxRef.current.onHide();
            this.requestData(this.state.page);
          })
          .catch(err => {
            console.log(err);
          });
      }
    );
  };

  //
  componentDidMount() {
    if (localStorage.getItem("token") === null) {
      return;
    }
    this.requestData(this.state.page);
  }

  //
  view_desktop = () => {
    return (
      <>
        <MenuAdmin />
        <MessageBox ref={this.messageboxRef} />
        <LoadingBox ref={this.loadingboxRef} />
        <ConfirmBox ref={this.confirmboxRef} />
        {/*  */}
        <Modal show={this.state.isShow} onHide={this.onHide}>
          <Modal.Header closeButton>
            <Modal.Title>User</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Container>
                <Form.Group>
                  <Row>
                    <Col md={3}>Name</Col>
                    <Col md={9}>
                      <Form.Control
                        type="text"
                        name="name"
                        onChange={this.onChange}
                        value={this.state.name}
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row>
                    <Col md={3}>Phone</Col>
                    <Col md={9}>
                      <Form.Control
                        type="text"
                        name="phone"
                        onChange={this.onChange}
                        value={this.state.phone}
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row>
                    <Col md={3}>Contractor</Col>
                    <Col md={9}>
                      <Form.Control
                        type="text"
                        name="contractor"
                        onChange={this.onChange}
                        value={this.state.contractor}
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row>
                    <Col md={3}>Username</Col>
                    <Col md={9}>
                      <Form.Control
                        type="text"
                        name="username"
                        onChange={this.onChange}
                        value={this.state.username}
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row>
                    <Col md={3}>Password</Col>
                    <Col md={9}>
                      <Form.Control
                        type="password"
                        name="password"
                        onChange={this.onChange}
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row>
                    <Col md={3}>Role</Col>
                    <Col md={9}>
                      <select
                        name="role"
                        className="custom-select"
                        onChange={this.onChange}
                        value={this.state.role}
                      >
                        <option value="Admin" defaultValue>
                          Admin
                        </option>
                        <option value="Reviewer">Reviewer</option>
                        <option value="Technician">Technician</option>
                        <option value="Admin SSK">Admin SSK</option>
                        <option value="Admin Subcon">Admin Subcon</option>
                        <option value="Super Admin">Super Admin</option>
                      </select>
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row>
                    <Col md={3}>Status</Col>
                    <Col md={9}>
                      <select
                        name="status"
                        className="custom-select"
                        onChange={this.onChange}
                        value={this.state.status}
                      >
                        <option value="Active" defaultValue>
                          Active
                        </option>
                        <option value="Cancel">Cancel</option>
                      </select>
                    </Col>
                  </Row>
                </Form.Group>
              </Container>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={this.onSubmit}>
              OK
            </Button>
            <Button variant="danger" onClick={this.onHide}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
        {/*  */}
        <Modal show={this.state.isShowImport} onHide={this.onHideImport}>
          <Modal.Header closeButton>
            <Modal.Title>Upload excel file</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Container>
                <Form.Group>
                  <Row>
                    <Col md={12}>
                      <Form.File
                        name="excelFile"
                        onChange={this.onFileChange}
                        label={this.state.excelFileLabel}
                        data-browse="Select"
                        custom
                      />
                      <a
                        href={Config.service.url + "/user/download/User.xlsx"}
                        target="_blank"
                        download
                        rel="noopener noreferrer"
                      >
                        Download template file
                      </a>
                    </Col>
                  </Row>
                </Form.Group>
              </Container>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={this.onImport}>
              OK
            </Button>
            <Button variant="danger" onClick={this.onHideImport}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
        <div className="App-body">
          <div className="hidden-print">
            <Row>
              <Col md={9}>
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <Button variant="success" onClick={this.onShowImport}>
                          {" "}
                          Import Data
                        </Button>
                      </td>
                      {/* <td>
                        <Button variant="danger" onClick={this.onShowClearData}>
                          {" "}
                          Clear All Data
                        </Button>
                      </td> */}
                    </tr>
                  </tbody>
                </table>
              </Col>
              <Col md={3}>
                <InputGroup className="mb-3">
                  <FormControl
                    placeholder="คำที่จะค้น"
                    name="q"
                    onChange={this.onChange}
                    value={this.state.q}
                  />
                  <InputGroup.Append>
                    <Button
                      variant="secondary"
                      onClick={this.onSearchSubmit}
                      title="ค้นหา"
                    >
                      <FontAwesomeIcon icon={faSearch} />
                    </Button>
                  </InputGroup.Append>
                </InputGroup>
              </Col>
            </Row>
          </div>
          <Row className="mb-3">
            <Col md={12}>
              <table width="100%">
                <tbody>
                  <tr>
                    <td>
                      {"page " +
                        (this.state.page + 1) +
                        " of " +
                        this.state.pages +
                        " pages"}
                    </td>
                    <td align="right">
                      <div
                        className="hidden-print"
                        style={{ display: "inline" }}
                      >
                        <Button
                          variant="secondary"
                          onClick={this.onPreviousPage}
                          title="Search"
                        >
                          <FontAwesomeIcon icon={faArrowLeft} />
                        </Button>
                        &nbsp;
                        <Button
                          variant="secondary"
                          onClick={this.onNextPage}
                          title="Search"
                        >
                          <FontAwesomeIcon icon={faArrowRight} />
                        </Button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Card>
                <Card.Header>
                  <h4>User</h4>
                </Card.Header>
                <Card.Body>
                  <Table striped hover>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Phone</th>
                        <th>Contractor</th>
                        <th>Username</th>
                        <th>Role</th>
                        <th>Status</th>
                        <th width={150} style={{ textAlign: "right" }}>
                          <div className="hidden-print">
                            <Button
                              variant="success"
                              onClick={this.onNew}
                              title="เพิ่ม"
                            >
                              <FontAwesomeIcon icon={faPlusCircle} />
                            </Button>
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.data.map((data, index) => {
                        return (
                          <tr key={index}>
                            <td>{data.name}</td>
                            <td>{data.phone}</td>
                            <td>{data.contractor}</td>
                            <td>{data.username}</td>
                            <td>{data.role}</td>
                            <td>{data.status}</td>
                            <td style={{ textAlign: "right" }}>
                              <div className="hidden-print">
                                <Button
                                  variant="primary"
                                  onClick={() => this.onEdit(data)}
                                  title="แก้ไข"
                                >
                                  <FontAwesomeIcon icon={faEdit} />
                                </Button>{" "}
                                <Button
                                  variant="danger"
                                  onClick={() => this.onDelete(data)}
                                  title="ลบ"
                                >
                                  <FontAwesomeIcon icon={faTrash} />
                                </Button>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  };

  //
  render() {
    if (localStorage.getItem("token") === null) {
      return <Redirect push to="/login" />;
    }

    if (auth.allowAdmin() === false) {
      return <Redirect push to="/noPermission" />;
    }

    return this.view_desktop();
  }
}

export default withRouter(User);

import React, { Component } from "react";
import { Container } from "react-bootstrap";
import { Card } from "react-bootstrap";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";

class MA extends Component {
  //
  render() {
    return (
      <>
        <div style={{ marginTop: 50 }}>
          <Container>
            <Row>
              <Col md={3}></Col>
              <Col md={6}>
                <Card>
                  <Card.Header>System Maintenace</Card.Header>
                  <Card.Body>
                    <Card.Text>The System will be back in soon</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={3}></Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

export default MA;

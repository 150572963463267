import React, { Component } from "react";
import { Redirect, withRouter } from "react-router-dom";
import { Button } from "react-bootstrap";
import { Table } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { Container } from "react-bootstrap";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import { Card } from "react-bootstrap";
import { Tabs } from "react-bootstrap";
import { Tab } from "react-bootstrap";
import auth from "../helper/authHelper";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { faTrash } from "@fortawesome/free-solid-svg-icons";

import Axios from "axios";

import MenuAdmin from "../components/menu/menuAdmin";

import MessageBox from "../components/messagebox/messagebox";
import LoadingBox from "../components/messagebox/loadingbox";
import ConfirmBox from "../components/messagebox/confirmbox";

var Moment = require("moment");
var Config = require("../config/config.json");

class Store extends Component {
  //
  constructor(props) {
    // Base contructor.
    super(props);
    // Ref.
    this.messageboxRef = React.createRef();
    this.loadingboxRef = React.createRef();
    this.confirmboxRef = React.createRef();
    this.linkDownloadRef = React.createRef();
    // State.
    this.state = {
      search: {
        timeIn: Moment().format("YYYY-MM"),
        qrCode: "",
        company: "",
        siteInstall: "",
        searchAll: "yes"
      },
      isShowSearch: false,
      isShowDownload: false,
      isShowQRCodeIn: false,
      isShowQRCodeOut: false,
      //
      searchType: "",
      data: {
        master: null,
        slave: null,
        summary: null
      },
      //
      qrCodeMaster1: "",
      qrCodeSlave2: "",
      qrCodeSlave3: "",
      //
      reportSummary: {
        masterAvailable: 0,
        masterUsed: 0,
        slaveAvailable: 0,
        slaveUsed: 0
      },
      //
      dataCheckIn: {
        master: [],
        slave: []
      },
      //
      dataCheckOut: {
        date: Moment().format("YYYY-MM-DD"),
        name: "",
        company: "",
        phone: "",
        section: "",
        receiver: "",
        sender: "",
        master: [],
        slave: []
      }
    };
  }

  //
  requestData = () => {
    if (this.loadingboxRef.current) this.loadingboxRef.current.onShow();

    this.requestReportSummary();

    //
    let promiseMaster = new Promise((resolve, reject) => {
      Axios.post(
        Config.service.url + "/store/searchMaster",
        {
          // month: this.state.search.timeIn
        },
        {
          headers: {
            Authorization: localStorage.getItem("token")
          }
        }
      )
        .then(res => {
          if (res.data.success === false) {
            this.messageboxRef.current.onShow("Error", res.data.message);
            return;
          }
          this.setState({
            data: { ...this.state.data, master: res.data.data }
          });
          resolve();
        })
        .catch(err => {
          console.log(err);
          reject();
        })
        .finally(() => {});
    });

    //
    let promiseSlave = new Promise((resolve, reject) => {
      Axios.post(
        Config.service.url + "/store/searchSlave",
        {
          // month: this.state.search.timeIn
        },
        {
          headers: {
            Authorization: localStorage.getItem("token")
          }
        }
      )
        .then(res => {
          if (res.data.success === false) {
            this.messageboxRef.current.onShow("Error", res.data.message);
            return;
          }
          this.setState({ data: { ...this.state.data, slave: res.data.data } });
          resolve();
        })
        .catch(err => {
          console.log(err);
          reject();
        })
        .finally(() => {});
    });

    //
    let promiseSummary = new Promise((resolve, reject) => {
      Axios.post(
        Config.service.url + "/store/searchSummary",
        {
          // month: this.state.search.timeIn
        },
        {
          headers: {
            Authorization: localStorage.getItem("token")
          }
        }
      )
        .then(res => {
          if (res.data.success === false) {
            this.messageboxRef.current.onShow("Error", res.data.message);
            return;
          }
          this.setState({
            data: { ...this.state.data, summary: res.data.data }
          });
          resolve();
        })
        .catch(err => {
          console.log(err);
          reject();
        })
        .finally(() => {});
    });

    //
    Promise.all([promiseMaster, promiseSlave, promiseSummary]).then(() => {
      if (this.loadingboxRef.current) this.loadingboxRef.current.onHide();
    });
  };

  //
  requestReportSummary = () => {
    Axios.get(Config.service.url + "/store/reportSummary", {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    })
      .then(res => {
        if (res.data.success === false) {
          console.log(res.data.data);
          return;
        }
        this.setState({ reportSummary: res.data.data });
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {});
  };

  //
  onChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  //
  onChange2 = e => {
    const { name, value } = e.target;
    this.setState({
      dataCheckOut: {
        ...this.state.dataCheckOut,
        [name]: value
      }
    });
  };

  //
  onQRCodeIn = () => {
    this.setState({
      isShowQRCodeIn: true,
      //
      qrCodeMaster1: "",
      qrCodeSlave2: "",
      qrCodeSlave3: "",
      //
      dataCheckIn: {
        master: [],
        slave: []
      }
    });
  };

  //
  onQRCodeOut = () => {
    this.setState({
      isShowQRCodeOut: true,
      //
      qrCodeMaster1: "",
      qrCodeSlave2: "",
      qrCodeSlave3: "",
      //
      dataCheckOut: {
        date: Moment().format("YYYY-MM-DD"),
        name: "",
        company: "",
        phone: "",
        section: "",
        receiver: "",
        sender: "",
        master: [],
        slave: []
      }
    });
  };

  //
  onHideQrCodeIn = () => {
    this.setState({ isShowQRCodeIn: false });
  };

  //
  onHideQrCodeOut = () => {
    this.setState({ isShowQRCodeOut: false });
  };

  //
  onHide = () => {
    this.setState({ isShow: false });
  };

  //
  onSubmitCodeIn = () => {
    //
    if (
      this.state.dataCheckIn.master.length === 0 &&
      this.state.dataCheckIn.slave.length === 0
    ) {
      this.messageboxRef.current.onShow("Error", "ไม่มีข้อมูลที่จะบันทึก");
      return;
    }
    //
    this.setState({ isShowQRCodeIn: false });
    if (this.loadingboxRef.current) this.loadingboxRef.current.onShow();
    let promiseList = [];
    // Master
    this.state.dataCheckIn.master.forEach((item, index) => {
      promiseList.push(
        new Promise((resolve, reject) => {
          Axios.post(
            Config.service.url + "/store/checkin",
            {
              type: "master",
              qrCode: item
            },
            {
              headers: {
                Authorization: localStorage.getItem("token")
              }
            }
          )
            .then(res => {
              if (res.data.success === false) {
                this.messageboxRef.current.onShow("Error", res.data.message);
              }
            })
            .catch(err => {
              console.log(err);
            })
            .finally(() => {
              resolve();
            });
        })
      );
    });
    // Slave
    this.state.dataCheckIn.slave.forEach((item, index) => {
      promiseList.push(
        new Promise((resolve, reject) => {
          Axios.post(
            Config.service.url + "/store/checkin",
            {
              type: "slave",
              qrCode: item
            },
            {
              headers: {
                Authorization: localStorage.getItem("token")
              }
            }
          )
            .then(res => {
              if (res.data.success === false) {
                this.messageboxRef.current.onShow("Error", res.data.message);
              }
            })
            .catch(err => {
              console.log(err);
            })
            .finally(() => {
              resolve();
            });
        })
      );
    });
    //
    Promise.all(promiseList).then(() => {
      if (this.loadingboxRef.current) this.loadingboxRef.current.onHide();
      this.requestData();
      this.setState({
        qrCodeMaster1: "",
        qrCodeSlave2: "",
        qrCodeSlave3: "",
        dataCheckIn: {
          master: [],
          slave: []
        }
      });
    });
  };

  //
  onSubmitCodeOut = () => {
    //
    if (
      this.state.dataCheckOut.master.length === 0 &&
      this.state.dataCheckOut.slave.length === 0
    ) {
      this.messageboxRef.current.onShow("Error", "ไม่มีข้อมูลที่จะบันทึก");
      return;
    }
    if (
      this.state.dataCheckOut.name.length === 0 ||
      this.state.dataCheckOut.company.length === 0 ||
      this.state.dataCheckOut.phone.length === 0 ||
      this.state.dataCheckOut.section.length === 0 ||
      this.state.dataCheckOut.receiver.length === 0 ||
      this.state.dataCheckOut.sender.length === 0
    ) {
      this.messageboxRef.current.onShow("Error", "ใส่ข้อมูลไม่ครบ");
      return;
    }
    //
    this.setState({ isShowQRCodeOut: false });
    if (this.loadingboxRef.current) this.loadingboxRef.current.onShow();
    let promiseList = [];
    // Master
    this.state.dataCheckOut.master.forEach((item, index) => {
      promiseList.push(
        new Promise((resolve, reject) => {
          Axios.put(
            Config.service.url + "/store/checkout",
            {
              type: "master",
              qrCode: item,
              //
              name: this.state.dataCheckOut.name,
              company: this.state.dataCheckOut.company,
              phone: this.state.dataCheckOut.phone,
              section: this.state.dataCheckOut.section,
              //
              receiver: this.state.dataCheckOut.receiver,
              sender: this.state.dataCheckOut.sender,
              //
              patDocument: "",
              ssrDocument: ""
            },
            {
              headers: {
                Authorization: localStorage.getItem("token")
              }
            }
          )
            .then(res => {})
            .catch(err => {
              console.log(err);
            })
            .finally(() => {
              resolve();
            });
        })
      );
    });
    // Slave
    this.state.dataCheckOut.slave.forEach((item, index) => {
      promiseList.push(
        new Promise((resolve, reject) => {
          Axios.put(
            Config.service.url + "/store/checkout",
            {
              type: "slave",
              qrCode: item
            },
            {
              headers: {
                Authorization: localStorage.getItem("token")
              }
            }
          )
            .then(res => {})
            .catch(err => {
              console.log(err);
            })
            .finally(() => {
              resolve();
            });
        })
      );
    });
    //
    Promise.all(promiseList).then(() => {
      if (this.loadingboxRef.current) this.loadingboxRef.current.onHide();
      this.requestData();
      this.setState({
        qrCodeMaster1: "",
        qrCodeSlave2: "",
        qrCodeSlave3: "",
        dataCheckOut: {
          date: Moment().format("YYYY-MM-DD"),
          name: "",
          company: "",
          phone: "",
          section: "",
          receiver: "",
          sender: "",
          master: [],
          slave: []
        }
      });
    });
  };

  //
  onDelete = data => {
    this.confirmboxRef.current.onShow(
      "Delete",
      "Do you want to delete this record?",
      () => {
        if (this.loadingboxRef.current) this.loadingboxRef.current.onShow();
        Axios.delete(Config.service.url + "/store/delete/" + data._id, {
          headers: {
            Authorization: localStorage.getItem("token")
          }
        })
          .then(res => {
            if (res.data.success === false) {
              this.messageboxRef.current.onShow("Error", res.data.message);
              return;
            }
            if (this.loadingboxRef.current) this.loadingboxRef.current.onHide();
            this.requestData();
          })
          .catch(err => {
            console.log(err);
          });
      }
    );
  };

  //
  onSearch = () => {
    this.setState({ isShowSearch: true });
  };

  //
  onHideSearch = () => {
    this.setState({ isShowSearch: false });
  };

  //
  onChangeSearch = e => {
    const { name, value } = e.target;
    let search = { ...this.state.search };
    search[name] = value;
    this.setState({ search: search });
  };

  //
  onSearchSubmit = () => {
    this.setState({
      isShowSearch: false,
      searchType: ""
    });
    if (this.loadingboxRef.current) this.loadingboxRef.current.onShow();
    //
    Axios.post(
      Config.service.url + "/store/search",
      {
        month: this.state.search.timeIn,
        qrCode: this.state.search.qrCode,
        company: this.state.search.company,
        siteInstall: this.state.search.siteInstall
      },
      {
        headers: {
          Authorization: localStorage.getItem("token")
        }
      }
    )
      .then(res => {
        if (res.data.success === false) {
          this.messageboxRef.current.onShow("Error", res.data.message);
          return;
        }
        this.setState({ data: res.data.data });
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        if (this.loadingboxRef.current) this.loadingboxRef.current.onHide();
      });
  };

  //
  componentDidMount = () => {
    if (localStorage.getItem("token") === null) {
      return;
    }
    this.requestData();
  };

  //
  onQRCodeMaster1CheckInAdd = () => {
    if (this.state.qrCodeMaster1 === "") {
      this.messageboxRef.current.onShow("Error", "ต้องใส่ข้อมูล QR Code ด้วย");
      return;
    }
    // Check QRCode duplicate.
    Axios.post(
      Config.service.url + "/store/searchMaster",
      {
        qrCode: this.state.qrCodeMaster1
      },
      {
        headers: {
          Authorization: localStorage.getItem("token")
        }
      }
    )
      .then(res => {
        if (res.data.success === false) {
          this.messageboxRef.current.onShow("Error", res.data.message);
          return;
        }
        if (res.data.data.length === 0) {
          // Check existing in array.
          let result = this.state.dataCheckIn.master.includes(
            this.state.qrCodeMaster1
          );
          if (result) {
            this.messageboxRef.current.onShow("Error", "QR Code ซ้ำ");
          } else {
            this.state.dataCheckIn.master.push(this.state.qrCodeMaster1);
            this.setState({
              qrCodeMaster1: "",
              dataCheckIn: { ...this.state.dataCheckIn }
            });
          }
        } else {
          this.messageboxRef.current.onShow(
            "Error",
            "QR Code " + this.state.qrCodeMaster1 + " นี้มีในระบบแล้ว"
          );
        }
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {});
  };

  //
  onQRCodeSlave2CheckInAdd = () => {
    if (this.state.qrCodeSlave2 === "") {
      this.messageboxRef.current.onShow("Error", "ต้องใส่ข้อมูล QR Code ด้วย");
      return;
    }
    // Check QRCode duplicate
    Axios.post(
      Config.service.url + "/store/searchSlave",
      {
        qrCode: this.state.qrCodeSlave2
      },
      {
        headers: {
          Authorization: localStorage.getItem("token")
        }
      }
    )
      .then(res => {
        if (res.data.success === false) {
          this.messageboxRef.current.onShow("Error", res.data.message);
          return;
        }
        if (res.data.data.length === 0) {
          // Check existing in array.
          let result = this.state.dataCheckIn.slave.includes(
            this.state.qrCodeSlave2
          );
          if (result) {
            this.messageboxRef.current.onShow("Error", "QR Code ซ้ำ");
          } else {
            this.state.dataCheckIn.slave.push(this.state.qrCodeSlave2);
            this.setState({
              qrCodeSlave2: "",
              dataCheckIn: { ...this.state.dataCheckIn }
            });
          }
        } else {
          this.messageboxRef.current.onShow(
            "Error",
            "QR Code " + this.state.qrCodeSlave2 + " นี้มีในระบบแล้ว"
          );
        }
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {});
  };

  //
  onQRCodeMaster1CheckOutAdd = () => {
    if (this.state.qrCodeMaster1 === "") {
      this.messageboxRef.current.onShow("Error", "ต้องใส่ข้อมูล QR Code ด้วย");
      return;
    }
    // Check QRCode must is existing in database and must not used.
    Axios.post(
      Config.service.url + "/store/searchMaster",
      {
        qrCode: this.state.qrCodeMaster1
      },
      {
        headers: {
          Authorization: localStorage.getItem("token")
        }
      }
    )
      .then(res => {
        if (res.data.success === false) {
          this.messageboxRef.current.onShow("Error", res.data.message);
          return;
        }
        // QRCode must is in database.
        if (res.data.data.length > 0) {
          // Check QRCode must not installed at site.
          if (res.data.data[0].siteInstall.length > 0) {
            this.messageboxRef.current.onShow(
              "Error",
              "QR Code นี้ถูกติดตั้งที่ไซท์อื่นเรียบร้อยแล้ว"
            );
            return;
          }
          // Check existing in array.
          let result = this.state.dataCheckOut.master.includes(
            this.state.qrCodeMaster1
          );
          if (result) {
            this.messageboxRef.current.onShow("Error", "QR Code ซ้ำ");
            return;
          } else {
            this.state.dataCheckOut.master.push(this.state.qrCodeMaster1);
            this.setState({
              qrCodeMaster1: "",
              dataCheckOut: { ...this.state.dataCheckOut }
            });
          }
        } else {
          this.messageboxRef.current.onShow(
            "Error",
            "QR Code " + this.state.qrCodeMaster1 + " นี้ไม่มีในระบบ"
          );
        }
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {});
  };

  //
  onQRCodeSlave2CheckOutAdd = () => {
    if (this.state.qrCodeSlave2 === "") {
      this.messageboxRef.current.onShow("Error", "ต้องใส่ข้อมูล QR Code ด้วย");
      return;
    }
    // Check QRCode must is existing in database and must not used.
    Axios.post(
      Config.service.url + "/store/searchSlave",
      {
        qrCode: this.state.qrCodeSlave2
      },
      {
        headers: {
          Authorization: localStorage.getItem("token")
        }
      }
    )
      .then(res => {
        if (res.data.success === false) {
          this.messageboxRef.current.onShow("Error", res.data.message);
          return;
        }
        // QRCode must is in database.
        if (res.data.data.length > 0) {
          // Check QRCode must not installed at site.
          if (res.data.data[0].siteInstall.length > 0) {
            this.messageboxRef.current.onShow(
              "Error",
              "QR Code นี้ถูกติดตั้งที่ไซท์อื่นเรียบร้อยแล้ว"
            );
            return;
          }
          // Check existing in array.
          let result = this.state.dataCheckOut.slave.includes(
            this.state.qrCodeSlave2
          );
          if (result) {
            this.messageboxRef.current.onShow("Error", "QR Code ซ้ำ");
            return;
          } else {
            this.state.dataCheckOut.slave.push(this.state.qrCodeSlave2);
            this.setState({
              qrCodeSlave2: "",
              dataCheckOut: { ...this.state.dataCheckOut }
            });
          }
        } else {
          this.messageboxRef.current.onShow(
            "Error",
            "QR Code " + this.state.qrCodeSlave2 + " นี้ไม่มีในระบบ"
          );
        }
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {});
  };

  //
  removeItemFromList = (arr, value) => {
    return arr.filter(ele => {
      return ele !== value;
    });
  };

  //
  onQRCodeMaster1CheckOutDel = code => {
    this.setState({
      dataCheckOut: {
        ...this.state.dataCheckOut,
        master: this.removeItemFromList(this.state.dataCheckOut.master, code)
      }
    });
  };

  //
  onQRCodeSlave2CheckOutDel = code => {
    this.setState({
      dataCheckOut: {
        ...this.state.dataCheckOut,
        slave: this.removeItemFromList(this.state.dataCheckOut.slave, code)
      }
    });
  };

  //
  onHideDownload = () => {
    this.setState({ isShowDownload: false });
  };

  //
  onExportToExcel = () => {
    if (this.loadingboxRef.current) this.loadingboxRef.current.onShow();
    Axios.get(Config.service.url + "/report/exportStore", {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    })
      .then(res => {
        if (res.data.success === false) {
          this.messageboxRef.current.onShow("Error", res.data.message);
          return;
        }
        console.log(res.data);
        if (this.loadingboxRef.current) this.loadingboxRef.current.onHide();
        this.setState({ isShowDownload: true });
        this.linkDownloadRef.current.href =
          Config.service.url + "/report/download/" + res.data.file;
        console.log(this.linkDownloadRef.current.href);
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {});
  };

  //
  render = () => {
    if (localStorage.getItem("token") === null) {
      return <Redirect push to="/login" />;
    }

    if (auth.allowAdmin() === false) {
      return <Redirect push to="/noPermission" />;
    }

    return (
      <>
        <MenuAdmin />
        <MessageBox ref={this.messageboxRef} />
        <LoadingBox ref={this.loadingboxRef} />
        <ConfirmBox ref={this.confirmboxRef} />
        {/* Download */}
        <Modal show={this.state.isShowDownload} onHide={this.onHideDownload}>
          <Modal.Header closeButton>
            <Modal.Title>Download</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <a
              ref={this.linkDownloadRef}
              href="http://localhost"
              target="_blank"
              download
              rel="noopener noreferrer"
            >
              Click here to download
            </a>
          </Modal.Body>
        </Modal>
        {/* Search */}
        <Modal show={this.state.isShowSearch} onHide={this.onHideSearch}>
          <Modal.Header closeButton>
            <Modal.Title>ค้นหา</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Container>
                {/* <Form.Group>
                                    <Row>
                                        <Col md={3}>Search All</Col>
                                        <Col md={9}>
                                            <select name='searchAll' className="custom-select" onChange={this.onChangeSearch} value={this.state.search.searchAll}>
                                                <option value="yes">Yes</option>
                                                <option value="no">No</option>
                                            </select>
                                        </Col>
                                    </Row>
                                </Form.Group>
                                {
                                    this.state.search.searchAll === 'no' ? 
                                    <Form.Group>
                                        <Row>
                                            <Col md={3}>Month</Col>
                                            <Col md={9}><Form.Control type="month" name="month" onChange={this.onChangeSearch} defaultValue={this.state.search.timeIn} /></Col>
                                        </Row>
                                    </Form.Group> : null
                                } */}
                <Form.Group>
                  <Row>
                    <Col md={3}>QR Code</Col>
                    <Col md={9}>
                      <Form.Control
                        type="text"
                        name="qrCode"
                        onChange={this.onChange}
                        value={this.state.qrCode}
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row>
                    <Col md={3}>Company</Col>
                    <Col md={9}>
                      <Form.Control
                        type="text"
                        name="company"
                        onChange={this.onChange}
                        value={this.state.company}
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row>
                    <Col md={3}>Site Install</Col>
                    <Col md={9}>
                      <Form.Control
                        type="text"
                        name="siteInstall"
                        onChange={this.onChange}
                        value={this.state.siteInstall}
                      />
                    </Col>
                  </Row>
                </Form.Group>
              </Container>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={this.onSearchSubmit}>
              OK
            </Button>
            <Button variant="danger" onClick={this.onHideSearch}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
        {/* QR Code In */}
        <Modal
          show={this.state.isShowQRCodeIn}
          onHide={this.onHideQrCodeIn}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>QR Code-In</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Container>
                <Form.Group>
                  <Row>
                    <Col md={3}>Master QR Code</Col>
                    <Col md={7}>
                      <Form.Control
                        type="text"
                        name="qrCodeMaster1"
                        onChange={this.onChange}
                        value={this.state.qrCodeMaster1}
                      />
                    </Col>
                    <Col md={1}>
                      <Button
                        variant="success"
                        onClick={this.onQRCodeMaster1CheckInAdd}
                      >
                        Add
                      </Button>
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row>
                    <Col md={3}>Slave QR Code</Col>
                    <Col md={7}>
                      <Form.Control
                        type="text"
                        name="qrCodeSlave2"
                        onChange={this.onChange}
                        value={this.state.qrCodeSlave2}
                      />
                    </Col>
                    <Col md={1}>
                      <Button
                        variant="success"
                        onClick={this.onQRCodeSlave2CheckInAdd}
                      >
                        Add
                      </Button>
                    </Col>
                  </Row>
                </Form.Group>
                <Row>
                  <Col md={6}>
                    <Table>
                      <thead>
                        <tr>
                          <th>Master</th>
                          <th align="right">
                            {this.state.dataCheckIn.master.length}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.dataCheckIn.master.length > 0 ? (
                          this.state.dataCheckIn.master.map((item, index) => {
                            return (
                              <tr key={index}>
                                <td colSpan={2}>{item}</td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan={2}></td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </Col>
                  <Col md={6}>
                    <Table>
                      <thead>
                        <tr>
                          <th>Slave</th>
                          <th align="right">
                            {this.state.dataCheckIn.slave.length}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.dataCheckIn.slave.length > 0 ? (
                          this.state.dataCheckIn.slave.map((item, index) => {
                            return (
                              <tr key={index}>
                                <td colSpan={2}>{item}</td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan={2}></td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </Container>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={this.onSubmitCodeIn}>
              Save
            </Button>
            <Button variant="danger" onClick={this.onHideQrCodeIn}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* QR Code Out */}
        <Modal
          show={this.state.isShowQRCodeOut}
          onHide={this.onHideQrCodeOut}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>QR Code-Out</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Container>
                {/* <Form.Group>
                                    <Row>
                                        <Col md={4}>Master QR Code</Col>
                                        <Col md={8}><Form.Control type="text" name="qrCodeMaster1" onChange={this.onChange} value={this.state.qrCodeMaster1} /></Col>
                                    </Row>
                                </Form.Group>
                                <Form.Group>
                                    <Row>
                                        <Col md={4}>Slave QR Code</Col>
                                        <Col md={8}><Form.Control type="text" name="qrCodeSlave2" onChange={this.onChange} value={this.state.qrCodeSlave2} /></Col>
                                    </Row>
                                </Form.Group> */}
                <table width="100%">
                  <tbody>
                    <tr>
                      <td>
                        <table width="100%">
                          <tbody>
                            <tr>
                              <td>
                                <table width="100%">
                                  <tr>
                                    <Row>
                                      <Col md={3}>Name</Col>
                                      <Col md={9}>
                                        <Form.Control
                                          type="text"
                                          name="name"
                                          onChange={this.onChange2}
                                          value={this.state.dataCheckOut.name}
                                        />
                                      </Col>
                                    </Row>
                                  </tr>
                                  <tr>
                                    <Row>
                                      <Col md={3}>Company</Col>
                                      <Col md={9}>
                                        <Form.Control
                                          type="text"
                                          name="company"
                                          onChange={this.onChange2}
                                          value={
                                            this.state.dataCheckOut.company
                                          }
                                        />
                                      </Col>
                                    </Row>
                                  </tr>
                                  <tr>
                                    <Row>
                                      <Col md={3}>Phone</Col>
                                      <Col md={9}>
                                        <Form.Control
                                          type="text"
                                          name="phone"
                                          onChange={this.onChange2}
                                          value={this.state.dataCheckOut.phone}
                                        />
                                      </Col>
                                    </Row>
                                  </tr>
                                  <tr>
                                    <Row>
                                      <Col md={3}>Section</Col>
                                      <Col md={9}>
                                        <Form.Control
                                          type="text"
                                          name="section"
                                          onChange={this.onChange2}
                                          value={
                                            this.state.dataCheckOut.section
                                          }
                                        />
                                      </Col>
                                    </Row>
                                  </tr>
                                </table>
                              </td>
                              <td rowSpan={4} style={{ verticalAlign: "top" }}>
                                <Row>
                                  <Col md={2}>Date</Col>
                                  <Col md={10}>
                                    <Form.Control
                                      type="date"
                                      name="date"
                                      onChange={this.onCoverChange}
                                      defaultValue={
                                        this.state.dataCheckOut.date
                                      }
                                    />
                                  </Col>
                                </Row>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div style={{ marginTop: 10 }}>
                          <Table>
                            <thead>
                              <tr>
                                <th width="100">#</th>
                                <th>Detail</th>
                                <th width="50">Amount</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>Master</td>
                                <td>
                                  <Row>
                                    <Col md={10}>
                                      <Form.Control
                                        type="text"
                                        name="qrCodeMaster1"
                                        onChange={this.onChange}
                                        value={this.state.qrCodeMaster1}
                                      />
                                    </Col>
                                    <Col md={2}>
                                      <Button
                                        variant="success"
                                        onClick={this.onQRCodeMasterCheckOutAdd}
                                      >
                                        Add
                                      </Button>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col md={12}>
                                      <div style={{ marginTop: 10 }}>
                                        <table width="100%">
                                          {this.state.dataCheckOut.master.map(
                                            (item, index) => {
                                              return (
                                                <tr>
                                                  <td>{item}</td>
                                                  <td width="50">
                                                    <Button
                                                      variant="danger"
                                                      onClick={() => {
                                                        this.onQRCodeMaster1CheckOutDel(
                                                          item
                                                        );
                                                      }}
                                                    >
                                                      Del
                                                    </Button>
                                                  </td>
                                                </tr>
                                              );
                                            }
                                          )}
                                        </table>
                                      </div>
                                    </Col>
                                  </Row>
                                </td>
                                <td align="center">
                                  {this.state.dataCheckOut.master.length}
                                </td>
                              </tr>
                              <tr>
                                <td>Slave</td>
                                <td>
                                  <Row>
                                    <Col md={10}>
                                      <Form.Control
                                        type="text"
                                        name="qrCodeSlave2"
                                        onChange={this.onChange}
                                        value={this.state.qrCodeSlave2}
                                      />
                                    </Col>
                                    <Col md={2}>
                                      <Button
                                        variant="success"
                                        onClick={this.onQRCodeSlave2CheckOutAdd}
                                      >
                                        Add
                                      </Button>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col md={12}>
                                      <div style={{ marginTop: 10 }}>
                                        <table width="100%">
                                          {this.state.dataCheckOut.slave.map(
                                            (item, index) => {
                                              return (
                                                <tr>
                                                  <td>{item}</td>
                                                  <td width="50">
                                                    <Button
                                                      variant="danger"
                                                      onClick={() => {
                                                        this.onQRCodeSlave2CheckOutDel(
                                                          item
                                                        );
                                                      }}
                                                    >
                                                      Del
                                                    </Button>
                                                  </td>
                                                </tr>
                                              );
                                            }
                                          )}
                                        </table>
                                      </div>
                                    </Col>
                                  </Row>
                                </td>
                                <td align="center">
                                  {this.state.dataCheckOut.slave.length}
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                        <hr />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <table width="100%">
                          <tbody>
                            <tr>
                              <td>
                                <Row>
                                  <Col md={2}></Col>
                                  <Col md={3}>
                                    <Form.Control
                                      type="text"
                                      name="receiver"
                                      onChange={this.onChange2}
                                      value={this.state.dataCheckOut.receiver}
                                    />
                                    <center>ผู้รับของ</center>
                                  </Col>
                                  <Col md={2}></Col>
                                  <Col md={3}>
                                    <Form.Control
                                      type="text"
                                      name="sender"
                                      onChange={this.onChange2}
                                      value={this.state.dataCheckOut.sender}
                                    />
                                    <center>ผู้จ่ายของ</center>
                                  </Col>
                                  <Col md={2}></Col>
                                </Row>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Container>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={this.onSubmitCodeOut}>
              Save
            </Button>
            <Button variant="danger" onClick={this.onHideQrCodeOut}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Body */}
        <div className="App-body">
          <Row>
            <Col md={12}>
              <table width="100%">
                <tbody>
                  <tr>
                    <td>
                      <h3>
                        Store (
                        {this.state.searchType !== "all"
                          ? this.state.search.timeIn
                          : "All"}
                        )
                      </h3>
                    </td>
                    <td align="right">
                      <div
                        className="hidden-print"
                        style={{ display: "inline" }}
                      >
                        <Button
                          variant="success"
                          onClick={this.onExportToExcel}
                          title="Export to excel"
                        >
                          Export to Excel
                        </Button>
                        &nbsp;
                        <Button
                          variant="primary"
                          onClick={this.onQRCodeIn}
                          title="​New QR Code-In"
                        >
                          New QR Code-In
                        </Button>
                        &nbsp;
                        <Button
                          variant="success"
                          onClick={this.onQRCodeOut}
                          title="New QR Code-Out"
                        >
                          New QR Code-Out
                        </Button>
                        &nbsp;
                        {/* <Button variant="success" onClick={this.onSearch} title="Search"><FontAwesomeIcon icon={faSearch} /></Button> */}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Col>
          </Row>
          <Row className="mb-3 mt-2">
            <Col md={3}>
              <Card className="text-center" border="primary">
                <Card.Header>
                  <h5>Master Available</h5>
                </Card.Header>
                <Card.Body>
                  <Card.Text>
                    <h4>{this.state.reportSummary.masterAvailable}</h4>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={3}>
              <Card className="text-center" border="warning">
                <Card.Header>
                  <h5>Master Used</h5>
                </Card.Header>
                <Card.Body>
                  <Card.Text>
                    <h4>{this.state.reportSummary.masterUsed}</h4>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={3}>
              <Card className="text-center" border="info">
                <Card.Header>
                  <h5>Slave Available</h5>
                </Card.Header>
                <Card.Body>
                  <Card.Text>
                    <h4>{this.state.reportSummary.slaveAvailable}</h4>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={3}>
              <Card className="text-center" border="danger">
                <Card.Header>
                  <h5>Slave Used</h5>
                </Card.Header>
                <Card.Body>
                  <Card.Text>
                    <h4>{this.state.reportSummary.slaveUsed}</h4>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          {this.state.data === null ? (
            <center>
              <span>No data</span>
            </center>
          ) : (
            <div style={{ marginTop: 10 }}>
              <Row>
                <Col md={12}>
                  <Card>
                    <Card.Header>
                      <h4>รายการเบิกจ่ายวัสดุอุปกรณ์</h4>
                    </Card.Header>
                    <Card.Body>
                      <Tabs
                        defaultActiveKey="master"
                        transition={false}
                        id="noanim-tab-example"
                      >
                        <Tab eventKey="master" title="Master">
                          <div style={{ marginTop: 30 }}>
                            <Table>
                              <thead>
                                <tr>
                                  <th>Type</th>
                                  <th>QR Code</th>
                                  <th>Time-in</th>
                                  <th>Time-out</th>
                                  <th>Company</th>
                                  <th>Site Install</th>
                                  <th
                                    width={150}
                                    style={{ textAlign: "right" }}
                                  ></th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.data.master1 !== undefined &&
                                this.state.data.master1 !== null &&
                                this.state.data.master1.length > 0 ? (
                                  this.state.data.master1.map((data, index) => {
                                    return (
                                      <tr key={index}>
                                        <td>{data.type}</td>
                                        <td>{data.qrCode}</td>
                                        <td>{data.timeIn}</td>
                                        <td>{data.timeOut}</td>
                                        <td>{data.company}</td>
                                        <td>{data.siteInstall}</td>
                                        <td style={{ textAlign: "right" }}>
                                          <div className="hidden-print">
                                            <Button
                                              variant="danger"
                                              onClick={() =>
                                                this.onDelete(data)
                                              }
                                              title="ลบ"
                                            >
                                              <FontAwesomeIcon icon={faTrash} />
                                            </Button>
                                          </div>
                                        </td>
                                      </tr>
                                    );
                                  })
                                ) : (
                                  <tr>
                                    <td colSpan={7} align="center">
                                      No data
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </Table>
                          </div>
                        </Tab>
                        <Tab eventKey="slave" title="Slave">
                          <div style={{ marginTop: 30 }}>
                            <Table>
                              <thead>
                                <tr>
                                  <th>Type</th>
                                  <th>QR Code</th>
                                  <th>Time-in</th>
                                  <th>Time-out</th>
                                  <th>Company</th>
                                  <th>Site Install</th>
                                  <th
                                    width={150}
                                    style={{ textAlign: "right" }}
                                  ></th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.data.slave2 !== undefined &&
                                this.state.data.slave2 !== null &&
                                this.state.data.slave2.length > 0 ? (
                                  this.state.data.slave2.map((data, index) => {
                                    return (
                                      <tr key={index}>
                                        <td>{data.type}</td>
                                        <td>{data.qrCode}</td>
                                        <td>{data.timeIn}</td>
                                        <td>{data.timeOut}</td>
                                        <td>{data.company}</td>
                                        <td>{data.siteInstall}</td>
                                        <td style={{ textAlign: "right" }}>
                                          <div className="hidden-print">
                                            <Button
                                              variant="danger"
                                              onClick={() =>
                                                this.onDelete(data)
                                              }
                                              title="ลบ"
                                            >
                                              <FontAwesomeIcon icon={faTrash} />
                                            </Button>
                                          </div>
                                        </td>
                                      </tr>
                                    );
                                  })
                                ) : (
                                  <tr>
                                    <td colSpan={7} align="center">
                                      No data
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </Table>
                          </div>
                        </Tab>
                        <Tab eventKey="summary" title="Summary">
                          <div style={{ marginTop: 30 }}>
                            <Table>
                              <thead>
                                <tr>
                                  <th colSpan={5} bgcolor="#50C8FB">
                                    Master
                                  </th>
                                  <th colSpan={5} bgcolor="#95FD90">
                                    Slave
                                  </th>
                                </tr>
                                <tr>
                                  <th bgcolor="#50C8FB">QR Code</th>
                                  <th bgcolor="#50C8FB">Time-In</th>
                                  <th bgcolor="#50C8FB">Time-Out</th>
                                  <th bgcolor="#50C8FB">Company</th>
                                  <th bgcolor="#FFF1C3">Site Install</th>
                                  <th bgcolor="#95FD90">QR Code</th>
                                  <th bgcolor="#95FD90">Time-In</th>
                                  <th bgcolor="#95FD90">Time-Out</th>
                                  <th bgcolor="#95FD90">Company</th>
                                  <th bgcolor="#FFF1C3">Site Install</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.data.summary !== undefined &&
                                this.state.data.summary !== null &&
                                this.state.data.summary.length > 0 ? (
                                  this.state.data.summary.map((data, index) => {
                                    return (
                                      <tr key={index}>
                                        <td bgcolor="#C3E8FF">
                                          {data.master1.qrCode}
                                        </td>
                                        <td bgcolor="#C3E8FF">
                                          {data.master1.timeIn}
                                        </td>
                                        <td bgcolor="#C3E8FF">
                                          {data.master1.timeOut}
                                        </td>
                                        <td bgcolor="#C3E8FF">
                                          {data.master1.company}
                                        </td>
                                        <td bgcolor="#FFF1C3">
                                          {data.master1.siteInstall}
                                        </td>
                                        <td bgcolor="#C6FFC3">
                                          {data.slave2 !== null
                                            ? data.slave2.qrCode
                                            : ""}
                                        </td>
                                        <td bgcolor="#C6FFC3">
                                          {data.slave2 !== null
                                            ? data.slave2.timeIn
                                            : ""}
                                        </td>
                                        <td bgcolor="#C6FFC3">
                                          {data.slave2 !== null
                                            ? data.slave2.timeOut
                                            : ""}
                                        </td>
                                        <td bgcolor="#C6FFC3">
                                          {data.slave2 !== null
                                            ? data.slave2.company
                                            : ""}
                                        </td>
                                        <td bgcolor="#FFF1C3">
                                          {data.slave2 !== null
                                            ? data.slave2.siteInstall
                                            : ""}
                                        </td>
                                      </tr>
                                    );
                                  })
                                ) : (
                                  <tr>
                                    <td colSpan={10} align="center">
                                      No data
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </Table>
                          </div>
                        </Tab>
                      </Tabs>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </div>
          )}
        </div>
      </>
    );
  };
}

export default withRouter(Store);

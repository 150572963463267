import React, { Component } from "react";
import { Redirect, withRouter } from "react-router-dom";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import { Table } from "react-bootstrap";
import { Tabs } from "react-bootstrap";
import { Tab } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import auth from "../helper/authHelper";
import { processStatusText } from "../helper/utilityHelper";

import Axios from "axios";

import MenuAdmin from "../components/menu/menuAdmin";

import MessageBox from "../components/messagebox/messagebox";
import LoadingBox from "../components/messagebox/loadingbox";
import ConfirmBox from "../components/messagebox/confirmbox";

var Config = require("../config/config.json");

class ReportByDetail extends Component {
  //
  constructor(props) {
    // Base contructor.
    super(props);
    // Ref.
    this.messageboxRef = React.createRef();
    this.loadingboxRef = React.createRef();
    this.confirmboxRef = React.createRef();
    this.linkDownloadRef = React.createRef();
    // State.
    this.state = {
      isShow: false,
      data: null
    };
  }

  //
  requestData = () => {
    if (this.loadingboxRef.current) this.loadingboxRef.current.onShow();
    Axios.get(Config.service.url + "/report/byDetail", {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    })
      .then(res => {
        if (res.data.success === false) {
          this.messageboxRef.current.onShow("Error", res.data.message);
          return;
        }
        console.log(res.data.data);
        this.setState({ data: res.data.data });
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        if (this.loadingboxRef.current) this.loadingboxRef.current.onHide();
      });
  };

  //
  onExportToExcel = () => {
    if (this.loadingboxRef.current) this.loadingboxRef.current.onShow();
    Axios.get(Config.service.url + "/report/exportDetailToExcel", {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    })
      .then(res => {
        if (res.data.success === false) {
          this.messageboxRef.current.onShow("Error", res.data.message);
          return;
        }
        console.log(res.data);
        if (this.loadingboxRef.current) this.loadingboxRef.current.onHide();
        this.setState({ isShow: true });
        this.linkDownloadRef.current.href =
          Config.service.url + "/report/download/" + res.data.file;
        console.log(this.linkDownloadRef.current.href);
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {});
  };

  //
  onHide = () => {
    this.setState({ isShow: false });
  };

  //
  componentDidMount() {
    if (localStorage.getItem("token") === null) {
      return;
    }
    this.requestData();
  }

  //
  render() {
    if (localStorage.getItem("token") === null) {
      return <Redirect push to="/login" />;
    }

    if (auth.allowAdminReview() === false) {
      return <Redirect push to="/noPermission" />;
    }

    return (
      <>
        <MenuAdmin />
        <MessageBox ref={this.messageboxRef} />
        <LoadingBox ref={this.loadingboxRef} />
        <ConfirmBox ref={this.confirmboxRef} />
        <Modal show={this.state.isShow} onHide={this.onHide}>
          <Modal.Header closeButton>
            <Modal.Title>Download</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <a
              ref={this.linkDownloadRef}
              href="http://localhost"
              target="_blank"
              download
              rel="noopener noreferrer"
            >
              Click here to download
            </a>
          </Modal.Body>
        </Modal>
        <div className="App-body">
          <Row>
            <Col md={12}>
              <table width="100%">
                <tbody>
                  <tr>
                    <td>
                      <h3>Report by Detail</h3>
                    </td>
                    <td align="right">
                      <Button
                        variant="success"
                        onClick={this.onExportToExcel}
                        title="Search"
                      >
                        Export to Excel
                      </Button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Col>
          </Row>
          <Row>
            <Col>
              <Tabs
                id={React.createRef()}
                defaultActiveKey="ssr"
                onSelect={this.onTabSelect}
              >
                <Tab eventKey="ssr" title="SSR">
                  <Table striped hover>
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Doc No</th>
                        <th>Project</th>
                        <th>Site</th>
                        <th>Site Code</th>
                        <th>Region</th>
                        <th>Zone</th>
                        <th>Technician</th>
                        <th>Contractor</th>
                        <th>Owner</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.data !== null
                        ? this.state.data.ssr.map((data, index) => {
                            return (
                              <tr key={index}>
                                <td>{data.cover.date}</td>
                                <td>{data.documentNumber}</td>
                                <td>{data.cover.projectName}</td>
                                <td>
                                  {data.cover.siteNameEn}
                                  <br />
                                  {data.cover.siteNameTh}
                                </td>
                                <td>{data.cover.siteCode}</td>
                                <td>{data.cover.region}</td>
                                <td>{data.cover.zone}</td>
                                <td>{data.cover.technicianName}</td>
                                <td>{data.cover.technicianContractor}</td>
                                <td>{data.owner}</td>
                                <td>
                                  <b>{processStatusText(data.status)}</b>
                                  <br />
                                  New : {data.dateStatusNew}
                                  <br />
                                  Waiting-SSK-Review :{" "}
                                  {data.dateStatusWaitingReview}
                                  <br />
                                  Reject-From-SSK :{" "}
                                  {data.dateStatusRejectFromSSK}
                                  <br />
                                  Waiting-AIS-Approve :{" "}
                                  {data.dateStatusWaitingApprove}
                                  <br />
                                  Reject-From-AIS :{" "}
                                  {data.dateStatusRejectFromAIS}
                                  <br />
                                  Approve : {data.dateStatusApproved}
                                  <br />
                                </td>
                              </tr>
                            );
                          })
                        : null}
                    </tbody>
                  </Table>
                </Tab>
                <Tab eventKey="pat" title="PAT">
                  <Table striped hover>
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Doc No</th>
                        <th>Project</th>
                        <th>Site</th>
                        <th>Site Code</th>
                        <th>Region</th>
                        <th>Zone</th>
                        <th>Technician</th>
                        <th>Contractor</th>
                        <th>Owner</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.data !== null
                        ? this.state.data.pat.map((data, index) => {
                            return (
                              <tr key={index}>
                                <td>{data.cover.date}</td>
                                <td>{data.documentNumber}</td>
                                <td>{data.cover.projectName}</td>
                                <td>
                                  {data.cover.siteNameEn}
                                  <br />
                                  {data.cover.siteNameTh}
                                </td>
                                <td>{data.cover.siteCode}</td>
                                <td>{data.cover.region}</td>
                                <td>{data.cover.zone}</td>
                                <td>{data.cover.technicianName}</td>
                                <td>{data.cover.technicianContractor}</td>
                                <td>{data.owner}</td>
                                <td>
                                  <b>{processStatusText(data.status)}</b>
                                  <br />
                                  New : {data.dateStatusNew}
                                  <br />
                                  Waiting-SSK-Review :{" "}
                                  {data.dateStatusWaitingReview}
                                  <br />
                                  Reject-From-SSK :{" "}
                                  {data.dateStatusRejectFromSSK}
                                  <br />
                                  Waiting-AIS-Approve :{" "}
                                  {data.dateStatusWaitingApprove}
                                  <br />
                                  Reject-From-AIS :{" "}
                                  {data.dateStatusRejectFromAIS}
                                  <br />
                                  Approve : {data.dateStatusApproved}
                                  <br />
                                </td>
                              </tr>
                            );
                          })
                        : null}
                    </tbody>
                  </Table>
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default withRouter(ReportByDetail);

import React, { Component } from "react";
import { Redirect, withRouter } from "react-router-dom";
import { Button } from "react-bootstrap";
import { Table } from "react-bootstrap";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { Container } from "react-bootstrap";
import { Badge } from "react-bootstrap";
import { Card } from "react-bootstrap";
import { Dropdown } from "react-bootstrap";
import { DropdownButton } from "react-bootstrap";
import { processStatusText, processStatus } from "../helper/utilityHelper";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

import Axios from "axios";

import MessageBox from "../components/messagebox/messagebox";
import LoadingBox from "../components/messagebox/loadingbox";
import ConfirmBox from "../components/messagebox/confirmbox";
import RemarkBox from "../components/messagebox/remarkbox";

import MenuAdmin from "../components/menu/menuAdmin";
import MenuAdminSubcon from "../components/menu/menuAdminSubcon";
import MenuReviewer from "../components/menu/menuReviewer";
import MenuTechnician from "../components/menu/menuTechnician";

import auth from "../helper/authHelper";
import utils from "../helper/utilityHelper";

var Moment = require("moment");
var Config = require("../config/config.json");

class PATsAdminReviewer extends Component {
  //
  constructor(props) {
    // Base contructor.
    super(props);
    // Ref.
    this.messageboxRef = React.createRef();
    this.loadingboxRef = React.createRef();
    this.confirmboxRef = React.createRef();
    this.remarkboxRef = React.createRef();
    this.linkDownloadRef = React.createRef();
    // State.
    this.state = {
      search: {
        documentNumber: "",
        month: Moment().format("YYYY-MM"),
        siteCode: "",
        siteNameEn: "",
        region: "",
        zone: "",
        contractor: "",
        status: "-",
        searchAll: "yes"
      },
      stats: {
        new: 0,
        waitingReview: 0,
        waitingApprove: 0,
        rejectFromSSK: 0,
        rejectFromAIS: 0,
        approved: 0
      },
      redirect: "",
      isShowSearch: false,
      isShowDownload: false,
      searchType: "",
      data: [],
      page: 0,
      pages: 0
    };
  }

  requestSummary = () => {
    // Request summary
    Axios.post(
      Config.service.url + "/pat/searchSummary",
      this.buildFilter("custom", 0),
      {
        headers: {
          Authorization: localStorage.getItem("token")
        }
      }
    )
      .then(res => {
        if (res.data.success === false) {
          this.messageboxRef.current.onShow("Error", res.data.message);
          return;
        }

        const data = res.data.data;

        this.setState({
          stats: {
            new: data.summary.new,
            waitingReview: data.summary.waitingSSKReview,
            waitingApprove: data.summary.waitingAISApprove,
            rejectFromSSK: data.summary.rejectFromSSK,
            rejectFromAIS: data.summary.rejectFromAIS,
            approved: data.summary.approved
          }
        });
      })
      .catch(err => {
        console.log(err);
      });
  };

  //
  requestAllData = page => {
    if (this.loadingboxRef.current) this.loadingboxRef.current.onShow();
    this.setState({ searchType: "all" });

    // Request content
    Axios.post(
      Config.service.url + "/pat/search",
      {
        page: page,
        pagination: Config.share.pagination
      },
      {
        headers: {
          Authorization: localStorage.getItem("token")
        }
      }
    )
      .then(res => {
        if (res.data.success === false) {
          this.messageboxRef.current.onShow("Error", res.data.message);
          return;
        }

        const data = res.data.data.data;
        this.setState({ data: data, pages: res.data.data.pages });
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        if (this.loadingboxRef.current) this.loadingboxRef.current.onHide();
      });

    // Request summary
    this.requestSummary("all");
  };

  //
  requestData = () => {
    if (this.loadingboxRef.current) this.loadingboxRef.current.onShow();

    // Request content
    Axios.post(
      Config.service.url + "/pat/search",
      {
        page: this.state.page,
        pagination: Config.share.pagination,
        month: this.state.search.month
      },
      {
        headers: {
          Authorization: localStorage.getItem("token")
        }
      }
    )
      .then(res => {
        if (res.data.success === false) {
          this.messageboxRef.current.onShow("Error", res.data.message);
          return;
        }

        const data = res.data.data.data;
        this.setState({ data: data, pages: res.data.data.pages });
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        if (this.loadingboxRef.current) this.loadingboxRef.current.onHide();
      });

    // Request summary
    this.requestSummary("custom");
  };

  //
  onChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  //
  onDelete = data => {
    if (localStorage.getItem("role") !== "Super Admin") {
      this.messageboxRef.current.onShow(
        "Error",
        "Super admin only can delete this record."
      );
      return;
    }

    this.confirmboxRef.current.onShow(
      "Delete",
      "Do you want to delete this record?",
      () => {
        if (this.loadingboxRef.current) this.loadingboxRef.current.onShow();
        Axios.delete(Config.service.url + "/pat/delete/" + data._id, {
          headers: {
            Authorization: localStorage.getItem("token")
          }
        })
          .then(res => {
            if (res.data.success === false) {
              this.messageboxRef.current.onShow("Error", res.data.message);
              return;
            }
            if (this.loadingboxRef.current) this.loadingboxRef.current.onHide();
            this.requestData();
          })
          .catch(err => {
            console.log(err);
          });
      }
    );
  };

  //
  onClearTech2 = data => {
    this.confirmboxRef.current.onShow(
      "Delete",
      "Do you want to clear technician 2 from this record?",
      () => {
        if (this.loadingboxRef.current) this.loadingboxRef.current.onShow();
        Axios.get(
          Config.service.url + "/ssr/clearSiteSecondRound/" + data.ssrId,
          {
            headers: {
              Authorization: localStorage.getItem("token")
            }
          }
        )
          .then(res => {
            if (res.data.success === false) {
              this.messageboxRef.current.onShow("Error", res.data.message);
              return;
            }
            if (this.loadingboxRef.current) this.loadingboxRef.current.onHide();
            this.requestData();
          })
          .catch(err => {
            console.log(err);
          });
      }
    );
  };

  //
  onRemark = data => {
    this.remarkboxRef.current.onShow(
      utils.getValue(data.remark, "", ""),
      () => {
        if (this.loadingboxRef.current) this.loadingboxRef.current.onShow();
        Axios.post(
          Config.service.url + "/pat/remark/" + data._id,
          {
            remark: this.remarkboxRef.current.getState().remark
          },
          {
            headers: {
              Authorization: localStorage.getItem("token")
            }
          }
        )
          .then(res => {
            if (res.data.success === false) {
              this.messageboxRef.current.onShow("Error", res.data.message);
              return;
            }
            this.onSearchSubmit(this.state.page);
          })
          .catch(err => {
            console.log(err);
          })
          .finally(() => {
            if (this.loadingboxRef.current) this.loadingboxRef.current.onHide();
          });
      }
    );
  };

  //
  onChangeSearch = e => {
    const { name, value } = e.target;
    let search = { ...this.state.search };
    search[name] = value;
    this.setState({ search: search });
  };

  //
  onHide = () => {
    this.setState({ isShowSearch: false });
  };

  //
  onSearch = () => {
    this.setState({ isShowSearch: true });
  };

  buildFilter = (type, page) => {
    let filter = null;
    if (type === "all") {
      filter = {};
    } else if (this.state.search.searchAll === "yes") {
      filter = {
        page: page,
        pagination: Config.share.pagination,
        documentNumber: this.state.search.documentNumber,
        siteCode: this.state.search.siteCode,
        siteNameEn: this.state.search.siteNameEn,
        region: this.state.search.region,
        zone: this.state.search.zone,
        contractor: this.state.search.contractor,
        status: this.state.search.status
      };
    } else {
      filter = {
        page: page,
        pagination: Config.share.pagination,
        month: this.state.search.month,
        documentNumber: this.state.search.documentNumber,
        siteCode: this.state.search.siteCode,
        siteNameEn: this.state.search.siteNameEn,
        region: this.state.search.region,
        zone: this.state.search.zone,
        contractor: this.state.search.contractor,
        status: this.state.search.status
      };
    }
    console.debug(filter);
    return filter;
  };

  //
  onSearchSubmit = page => {
    this.setState({
      isShowSearch: false,
      searchType: ""
    });

    if (this.loadingboxRef.current) this.loadingboxRef.current.onShow();

    let filter = this.buildFilter("custom", page);

    // Request content
    Axios.post(Config.service.url + "/pat/search", filter, {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    })
      .then(res => {
        if (res.data.success === false) {
          this.messageboxRef.current.onShow("Error", res.data.message);
          return;
        }

        const data = res.data.data.data;
        this.setState({ data: data, pages: res.data.data.pages });
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        if (this.loadingboxRef.current) this.loadingboxRef.current.onHide();
      });

    // Request summary
    this.requestSummary("custom");
  };

  //
  onEdit = data => {
    window.open("/patTechnicianEdit?id=" + data._id, "_blank");
  };

  //
  onRefresh = data => {
    this.loadingboxRef.current.onShow();
    Axios.get(Config.service.url + "/pat/refresh/" + data._id, {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    })
      .then(res => {
        if (res.data.success === false) {
          this.messageboxRef.current.onShow("Error", res.data.message);
          return;
        }
        this.onSearchSubmit(0);
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        if (this.loadingboxRef.current) this.loadingboxRef.current.onHide();
      });
  };

  onNextPage = () => {
    let page = this.state.page + 1;
    if (page >= this.state.pages) {
      this.setState({ page: this.state.pages });
    } else {
      this.setState({ page: page });
    }

    if (this.state.searchType === "all") {
      this.requestAllData(page);
    } else {
      this.onSearchSubmit(page);
    }
  };

  onPreviousPage = () => {
    let page = this.state.page - 1;
    if (page <= 0) {
      page = 0;
    }

    this.setState({ page: page });

    if (this.state.searchType === "all") {
      this.requestAllData(page);
    } else {
      this.onSearchSubmit(page);
    }
  };

  //
  onView = data => {
    window.open("patAdminReviewerShow?id=" + data._id, "_blank");
  };

  //
  onViewPage27 = data => {
    window.open("patAdminReviewerShowPage27?id=" + data._id, "_blank");
  };

  //
  onViewPage27All = data => {
    if (this.state.searchType === "all") {
      window.open("patAdminReviewerShowPage27All?type=all", "_blank");
    } else {
      window.open(
        "patAdminReviewerShowPage27All?type=custom&month=" +
          this.state.search.month +
          "&siteCode=" +
          this.state.search.siteCode +
          "&region=" +
          this.state.search.region +
          "&zone=" +
          this.state.search.zone +
          "&contractor=" +
          this.state.search.contractor +
          "&status=" +
          this.state.search.status,
        "_blank"
      );
    }
  };

  //
  onPrint = data => {
    window.open(
      "patAdminReviewerShow?id=" + data._id + "&mode=print",
      "_blank"
    );
  };

  //
  onViewSSR = data => {
    window.open("ssrAdminReviewerShow?id=" + data.ssrId, "_blank");
  };

  //
  onChangeStatus = (data, status) => {
    if (this.loadingboxRef.current) this.loadingboxRef.current.onShow();
    Axios.get(
      Config.service.url + "/pat/changeStatus/" + data._id + "/" + status,
      {
        headers: {
          Authorization: localStorage.getItem("token")
        }
      }
    )
      .then(res => {
        if (res.data.success === false) {
          this.messageboxRef.current.onShow("Error", res.data.message);
          return;
        }
        this.onSearchSubmit(this.state.page);
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        if (this.loadingboxRef.current) this.loadingboxRef.current.onHide();
      });
  };

  //
  onExportToExcel = () => {
    if (this.loadingboxRef.current) this.loadingboxRef.current.onShow();

    let filter = null;
    if (this.state.search.searchAll === "yes") {
      filter = {
        documentNumber: this.state.search.documentNumber,
        siteCode: this.state.search.siteCode,
        siteNameEn: this.state.search.siteNameEn,
        region: this.state.search.region,
        zone: this.state.search.zone,
        contractor: this.state.search.contractor,
        status: this.state.search.status
      };
    } else {
      filter = {
        month: this.state.search.month,
        documentNumber: this.state.search.documentNumber,
        siteCode: this.state.search.siteCode,
        siteNameEn: this.state.search.siteNameEn,
        region: this.state.search.region,
        zone: this.state.search.zone,
        contractor: this.state.search.contractor,
        status: this.state.search.status
      };
    }

    Axios.post(Config.service.url + "/report/exportPAT", filter, {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    })
      .then(res => {
        if (res.data.success === false) {
          this.messageboxRef.current.onShow("Error", res.data.message);
          return;
        }
        if (this.loadingboxRef.current) this.loadingboxRef.current.onHide();
        this.setState({ isShowDownload: true });
        this.linkDownloadRef.current.href =
          Config.service.url + "/report/download/" + res.data.file;
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {});
  };

  //
  onHideDownload = () => {
    this.setState({ isShowDownload: false });
  };

  //
  componentDidMount() {
    if (localStorage.getItem("token") === null) {
      return;
    }
    this.requestData();
  }

  //
  actionForReviewr(data) {
    return (
      <DropdownButton
        id="dropdown-basic-button"
        title="Action"
        variant="secondary"
      >
        <Dropdown.Item onClick={() => this.onViewSSR(data)}>
          Open SSR
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item onClick={() => this.onPrint(data)}>Print</Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item onClick={() => this.onView(data)}>
          Show PAT
        </Dropdown.Item>
        <Dropdown.Item onClick={() => this.onViewPage27(data)}>
          Show PAT Page 2.7
        </Dropdown.Item>
      </DropdownButton>
    );
  }

  //
  actionForAdmin(data) {
    return (
      <div className="hidden-print">
        <DropdownButton
          id="dropdown-basic-button"
          title="Action"
          variant="secondary"
        >
          <Dropdown.Item onClick={() => this.onViewSSR(data)}>
            Open SSR
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item onClick={() => this.onPrint(data)}>
            Print
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item onClick={() => this.onView(data)}>
            Show PAT
          </Dropdown.Item>
          <Dropdown.Item onClick={() => this.onViewPage27(data)}>
            Show PAT Page 2.7
          </Dropdown.Item>
          <Dropdown.Item onClick={() => this.onRefresh(data)}>
            Refresh
          </Dropdown.Item>
          <Dropdown.Item onClick={() => this.onEdit(data)}>Edit</Dropdown.Item>
          <Dropdown.Item onClick={() => this.onClearTech2(data)}>
            Clear Technician 2
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item onClick={() => this.onRemark(data)}>
            Remark
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item
            onClick={() => this.onChangeStatus(data, "Waiting-Review")}
          >
            Waiting SSK Review
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => this.onChangeStatus(data, "Reject-From-SSK")}
          >
            Reject From SSK
          </Dropdown.Item>
          <Dropdown.Item onClick={() => this.onChangeStatus(data, "Approved")}>
            Approved
          </Dropdown.Item>
        </DropdownButton>
      </div>
    );
  }

  //
  actionForAdminSuper(data) {
    return (
      <div className="hidden-print">
        <DropdownButton
          id="dropdown-basic-button"
          title="Action"
          variant="secondary"
        >
          <Dropdown.Item onClick={() => this.onViewSSR(data)}>
            Open SSR
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item onClick={() => this.onPrint(data)}>
            Print
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item onClick={() => this.onView(data)}>
            Show PAT
          </Dropdown.Item>
          <Dropdown.Item onClick={() => this.onViewPage27(data)}>
            Show PAT Page 2.7
          </Dropdown.Item>
          <Dropdown.Item onClick={() => this.onRefresh(data)}>
            Refresh
          </Dropdown.Item>
          <Dropdown.Item onClick={() => this.onEdit(data)}>Edit</Dropdown.Item>
          <Dropdown.Item onClick={() => this.onDelete(data)}>
            Delete
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item onClick={() => this.onClearTech2(data)}>
            Clear Technician 2
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item onClick={() => this.onRemark(data)}>
            Remark
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item
            onClick={() => this.onChangeStatus(data, "Waiting-Review")}
          >
            Waiting SSK Review
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => this.onChangeStatus(data, "Waiting-Approve")}
          >
            Waiting AIS Approve
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => this.onChangeStatus(data, "Reject-From-SSK")}
          >
            Reject From SSK
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => this.onChangeStatus(data, "Reject-From-AIS")}
          >
            Reject From AIS
          </Dropdown.Item>
          <Dropdown.Item onClick={() => this.onChangeStatus(data, "Approved")}>
            Approved
          </Dropdown.Item>
        </DropdownButton>
      </div>
    );
  }

  //
  actionForAdminSubcon(data) {
    return (
      <div className="hidden-print">
        <DropdownButton
          id="dropdown-basic-button"
          title="Action"
          variant="secondary"
        >
          <Dropdown.Item onClick={() => this.onViewSSR(data)}>
            Open SSR
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item onClick={() => this.onPrint(data)}>
            Print
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item onClick={() => this.onView(data)}>
            Show PAT
          </Dropdown.Item>
          <Dropdown.Item onClick={() => this.onViewPage27(data)}>
            Show PAT Page 2.7
          </Dropdown.Item>
          <Dropdown.Item onClick={() => this.onRefresh(data)}>
            Refresh
          </Dropdown.Item>
          <Dropdown.Item onClick={() => this.onEdit(data)}>Edit</Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item
            onClick={() => this.onChangeStatus(data, "Waiting-Review")}
          >
            Waiting SSK Review
          </Dropdown.Item>
        </DropdownButton>
      </div>
    );
  }

  action = data => {
    if (auth.allowActionForAdmin()) {
      return this.actionForAdmin(data);
    } else if (auth.allowActionForAdminSuper()) {
      return this.actionForAdminSuper(data);
    } else if (auth.allowActionForAdminSubcon()) {
      return this.actionForAdminSubcon(data);
    } else {
      return this.actionForReviewr(data);
    }
  };

  menu = () => {
    if (auth.allowAdmin()) {
      return <MenuAdmin />;
    } else if (auth.allowAdminSubcon()) {
      return <MenuAdminSubcon />;
    } else if (auth.allowReview()) {
      return <MenuReviewer />;
    } else {
      return <MenuTechnician />;
    }
  };

  //
  view_desktop = () => {
    return (
      <>
        {this.menu()}
        <MessageBox ref={this.messageboxRef} />
        <LoadingBox ref={this.loadingboxRef} />
        <ConfirmBox ref={this.confirmboxRef} />
        <RemarkBox ref={this.remarkboxRef} />
        <Modal show={this.state.isShowDownload} onHide={this.onHideDownload}>
          <Modal.Header closeButton>
            <Modal.Title>Download</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <a
              ref={this.linkDownloadRef}
              href="http://localhost"
              target="_blank"
              download
              rel="noopener noreferrer"
            >
              Click here to download
            </a>
          </Modal.Body>
        </Modal>
        <Modal show={this.state.isShowSearch} onHide={this.onHide}>
          <Modal.Header closeButton>
            <Modal.Title>Search</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Container>
                <Form.Group>
                  <Row>
                    <Col md={4}>Search All</Col>
                    <Col md={8}>
                      <select
                        name="searchAll"
                        className="custom-select"
                        onChange={this.onChangeSearch}
                        value={this.state.search.searchAll}
                      >
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                      </select>
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row>
                    <Col md={4}>Doc No</Col>
                    <Col md={8}>
                      <Form.Control
                        type="documentNumber"
                        name="documentNumber"
                        onChange={this.onChangeSearch}
                        defaultValue={this.state.search.documentNumber}
                      />
                    </Col>
                  </Row>
                </Form.Group>
                {this.state.search.searchAll === "no" ? (
                  <Form.Group>
                    <Row>
                      <Col md={4}>Month</Col>
                      <Col md={8}>
                        <Form.Control
                          type="month"
                          name="month"
                          onChange={this.onChangeSearch}
                          defaultValue={this.state.search.month}
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                ) : null}
                <Form.Group>
                  <Row>
                    <Col md={4}>Site Code</Col>
                    <Col md={8}>
                      <Form.Control
                        type="text"
                        name="siteCode"
                        onChange={this.onChangeSearch}
                        value={this.state.search.siteCode}
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row>
                    <Col md={4}>Site Name En</Col>
                    <Col md={8}>
                      <Form.Control
                        type="text"
                        name="siteNameEn"
                        onChange={this.onChangeSearch}
                        value={this.state.search.siteNameEn}
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row>
                    <Col md={4}>Region</Col>
                    <Col md={8}>
                      <Form.Control
                        type="text"
                        name="region"
                        onChange={this.onChangeSearch}
                        value={this.state.search.region}
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row>
                    <Col md={4}>Zone</Col>
                    <Col md={8}>
                      <Form.Control
                        type="text"
                        name="zone"
                        onChange={this.onChangeSearch}
                        value={this.state.search.zone}
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row>
                    <Col md={4}>Contractor</Col>
                    <Col md={8}>
                      <Form.Control
                        type="text"
                        name="contractor"
                        onChange={this.onChangeSearch}
                        value={this.state.search.contractor}
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row>
                    <Col md={4}>Status</Col>
                    <Col md={8}>
                      <select
                        name="status"
                        className="custom-select"
                        onChange={this.onChangeSearch}
                        value={this.state.search.status}
                      >
                        <option value="-" defaultValue>
                          ---
                        </option>
                        <option value="New">New</option>
                        <option value="Waiting-Review">
                          Waiting-SSK-Review
                        </option>
                        <option value="Reject-From-SSK">Reject-From-SSK</option>
                        <option value="Waiting-Approve">
                          Waiting-AIS-Approve
                        </option>
                        <option value="Reject-From-AIS">Reject-From-AIS</option>
                        <option value="Approved">Approved</option>
                      </select>
                    </Col>
                  </Row>
                </Form.Group>
              </Container>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="success"
              onClick={() => {
                this.setState({ page: 0 });
                this.onSearchSubmit(0);
              }}
            >
              OK
            </Button>
            <Button variant="danger" onClick={this.onHide}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
        <div className="App-body">
          <Row>
            <Col md={12}>
              <table width="100%">
                <tbody>
                  <tr>
                    <td>
                      <h3>
                        PAT Document (
                        {this.state.searchType !== "all"
                          ? this.state.search.month
                          : "All"}
                        )
                      </h3>
                    </td>
                    <td align="right">
                      <div
                        className="hidden-print"
                        style={{ display: "inline" }}
                      >
                        <Button
                          variant="success"
                          onClick={this.onExportToExcel}
                          title="Search"
                        >
                          Export to Excel
                        </Button>
                        &nbsp;
                        <Button
                          variant="secondary"
                          onClick={this.onViewPage27All}
                          title="Search"
                        >
                          Download PAT 2.7 (Only)
                        </Button>
                        &nbsp;
                        <Button
                          variant="warning"
                          onClick={() => {
                            this.setState({ page: 0 });
                            this.requestAllData(0);
                          }}
                          title="Search"
                        >
                          Show All
                        </Button>
                        &nbsp;
                        <Button
                          variant="success"
                          onClick={this.onSearch}
                          title="Search"
                        >
                          <FontAwesomeIcon icon={faSearch} />
                        </Button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              {this.state.data.length === 0 ? (
                <center>
                  <div style={{ marginTop: 20 }}>
                    <span>No data</span>
                  </div>
                </center>
              ) : (
                <div style={{ marginTop: 10 }}>
                  <Row className="mb-3">
                    <Col md={2}>
                      <Card className="text-center" border="primary">
                        <Card.Header>
                          <h6>New</h6>
                        </Card.Header>
                        <Card.Body>
                          <Card.Text>
                            <h4>{this.state.stats.new}</h4>
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col md={2}>
                      <Card className="text-center" border="warning">
                        <Card.Header>
                          <h6>Waiting-SSK-Review</h6>
                        </Card.Header>
                        <Card.Body>
                          <Card.Text>
                            <h4>{this.state.stats.waitingReview}</h4>
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col md={2}>
                      <Card className="text-center" border="info">
                        <Card.Header>
                          <h6>Waiting-AIS-Approve</h6>
                        </Card.Header>
                        <Card.Body>
                          <Card.Text>
                            <h4>{this.state.stats.waitingApprove}</h4>
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col md={2}>
                      <Card className="text-center" border="danger">
                        <Card.Header>
                          <h6>Reject-From-SSK</h6>
                        </Card.Header>
                        <Card.Body>
                          <Card.Text>
                            <h4>{this.state.stats.rejectFromSSK}</h4>
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col md={2}>
                      <Card className="text-center" border="danger">
                        <Card.Header>
                          <h6>Reject-From-AIS</h6>
                        </Card.Header>
                        <Card.Body>
                          <Card.Text>
                            <h4>{this.state.stats.rejectFromAIS}</h4>
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col md={2}>
                      <Card className="text-center" border="success">
                        <Card.Header>
                          <h6>Approved</h6>
                        </Card.Header>
                        <Card.Body>
                          <Card.Text>
                            <h4>{this.state.stats.approved}</h4>
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col md={12}>
                      <table width="100%">
                        <tbody>
                          <tr>
                            <td>
                              {"page " +
                                (this.state.page + 1) +
                                " of " +
                                this.state.pages +
                                " pages"}
                            </td>
                            <td align="right">
                              <div
                                className="hidden-print"
                                style={{ display: "inline" }}
                              >
                                <Button
                                  variant="secondary"
                                  onClick={this.onPreviousPage}
                                  title="Search"
                                >
                                  <FontAwesomeIcon icon={faArrowLeft} />
                                </Button>
                                &nbsp;
                                <Button
                                  variant="secondary"
                                  onClick={this.onNextPage}
                                  title="Search"
                                >
                                  <FontAwesomeIcon icon={faArrowRight} />
                                </Button>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div style={{ marginTop: "10px" }}>
                        <Table striped hover>
                          <thead>
                            <tr>
                              <th>Date</th>
                              <th>Doc No</th>
                              <th>Project</th>
                              <th>Site</th>
                              <th>Site Code</th>
                              <th>Region</th>
                              <th>Zone</th>
                              <th>Tech & Con & Owner</th>
                              <th>QR Code</th>
                              <th>Master 1</th>
                              <th>Slave 2</th>
                              <th>Slave 3</th>
                              <th width={340}>Status</th>
                              <th width={200}>Remark</th>
                              <th style={{ textAlign: "right" }}>
                                <div className="hidden-print"></div>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.data.map((data, index) => {
                              return (
                                <tr key={index}>
                                  <td>{data.cover.date}</td>
                                  <td>{data.documentNumber}</td>
                                  <td>{data.cover.projectName}</td>
                                  <td>
                                    {data.cover.siteNameEn}
                                    <br />
                                    {data.cover.siteNameTh}
                                  </td>
                                  <td>{data.cover.siteCode}</td>
                                  <td>{data.cover.region}</td>
                                  <td>{data.cover.zone}</td>
                                  <td>
                                    Tech 1: {data.cover.technicianName}
                                    <br />
                                    Con 1: {data.cover.technicianContractor}
                                    <br />
                                    Tech 2: {data.cover.technicianName2}
                                    <br />
                                    Con 2: {data.cover.technicianContractor2}
                                    <br />
                                    Owner: {data.owner}
                                    <br />
                                  </td>
                                  <td>
                                    {"M1: " +
                                      (data.cover.qrCodeMaster1 !== undefined
                                        ? data.cover.qrCodeMaster1
                                        : "")}
                                    <br />
                                    {"S2: " +
                                      (data.cover.qrCodeSlave2 !== undefined
                                        ? data.cover.qrCodeSlave2
                                        : "")}
                                    <br />
                                    {"S3: " +
                                      (data.cover.qrCodeSlave3 !== undefined
                                        ? data.cover.qrCodeSlave3
                                        : "")}
                                  </td>
                                  <td align="center">
                                    {data.content.p232 !== "" ? "1" : ""}
                                  </td>
                                  <td align="center">
                                    {data.content.p252 !== "" ? "1" : ""}
                                  </td>
                                  <td align="center">
                                    {data.content.p262 !== "" ? "1" : ""}
                                  </td>
                                  <td>
                                    <Badge variant={processStatus(data.status)}>
                                      {processStatusText(data.status)}
                                    </Badge>
                                    <br />
                                    New : {data.dateStatusNew}
                                    <br />
                                    Waiting-SSK-Review :{" "}
                                    {data.dateStatusWaitingReview}
                                    <br />
                                    Reject-From-SSK :{" "}
                                    {data.dateStatusRejectFromSSK}
                                    <br />
                                    Waiting-AIS-Approve :{" "}
                                    {data.dateStatusWaitingApprove}
                                    <br />
                                    Reject-From-AIS :{" "}
                                    {data.dateStatusRejectFromAIS}
                                    <br />
                                    Approve : {data.dateStatusApproved}
                                    <br />
                                  </td>
                                  <td>
                                    <pre>{data.remark}</pre>
                                  </td>
                                  <td style={{ textAlign: "right" }}>
                                    <div className="hidden-print">
                                      {this.action(data)}
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </div>
                    </Col>
                  </Row>
                </div>
              )}
            </Col>
          </Row>
        </div>
      </>
    );
  };

  //
  render() {
    if (localStorage.getItem("token") === null) {
      return <Redirect push to="/login" />;
    }

    if (this.state.redirect) {
      return <Redirect push to={this.state.redirect} />;
    }

    return this.view_desktop();
  }
}

export default withRouter(PATsAdminReviewer);

import React, { Component } from "react";
import { Redirect, withRouter } from "react-router-dom";

import MenuAdmin from "../components/menu/menuAdmin";
import auth from "../helper/authHelper";

class DashboardAdmin extends Component {
  //
  constructor(props) {
    // Base contructor.
    super(props);
    //
    this.state = {};
  }

  //
  //
  requestData = () => {
    // Prevent access empty object.
    if (localStorage.getItem("token") !== null) {
    }
  };

  //
  componentDidMount() {
    if (localStorage.getItem("token") === null) {
      return;
    }
    this.requestData();
  }

  //
  render() {
    //
    if (localStorage.getItem("token") === null) {
      return <Redirect push to="/login" />;
    }

    if (auth.allowAdmin() === false) {
      return <Redirect push to="/noPermission" />;
    }

    return (
      <>
        <MenuAdmin />
      </>
    );
  }
}

export default withRouter(DashboardAdmin);

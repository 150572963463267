import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import QrReader from 'react-qr-reader'

class QR extends Component {
    //
    constructor(props) {
        // Base contructor.
        super(props);
        // State.
        this.state = {
            result: 'Data : XXXXXXX',
        };
    }

    handleScan = data => {
        if (data) {
          this.setState({
            result: data
          })
        }
    }

    handleError = err => {
        console.error(err)
    }

    //
    render() {
        return <center><div style={{width: 800}}>
            <h1>{this.state.result}</h1>
            <QrReader
            delay={300}
            onError={this.handleError}
            onScan={this.handleScan}
            style={{ width: '100%' }}
            // facingMode="environment"
            />
        </div></center>;
    }
}

export default withRouter(QR);
import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';

class MessageBox extends Component {
    //
    constructor(props) {
        super(props);
        this.state = {
            title: '',
            message: '',
            isShow: false,
        };
    }
    
    //
    onShow = (title, message) => {
        this.setState({ isShow: true, title: title, message: message });
    }

    //
    onHide = () => {
        this.setState({ isShow: false });
    }

    //
    render() {
        return (
            <>
                <Modal show={this.state.isShow} onHide={this.onHide} dialogClassName="modal-50w">
                    <Modal.Header closeButton>
                        <Modal.Title>{this.state.title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.message}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.onHide}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

export default MessageBox
import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { Stage } from 'react-konva';
import { Layer } from 'react-konva';
import { Image } from 'react-konva';
import { Circle } from 'react-konva';
import { Text } from 'react-konva';
import { Rect } from 'react-konva';
import Resizer from 'react-image-file-resizer';

var Config = require('../../config/config.json');

class SSRDesignSlaveImage extends Component {
    //
    constructor(props) {
        // Base contructor.
        super(props);
        // DC Power
        this.refS1Circle = React.createRef();
        this.refS1Rect = React.createRef();
        this.refS1Text = React.createRef();
        // Electric Lock
        this.refS2Circle = React.createRef();
        this.refS2Rect = React.createRef();
        this.refS2Text = React.createRef();
        // Slave Controller
        this.refS3Circle = React.createRef();
        this.refS3Rect = React.createRef();
        this.refS3Text = React.createRef();
        // Door Lock
        this.refS4Circle = React.createRef();
        this.refS4Rect = React.createRef();
        this.refS4Text = React.createRef();
        // State
        this.state = {
            config: {
                radius: 10,
                circleColor: "#FF0000",
                opacity: 1.0,
                fontSize: 12,
                fontColor: "#FF0000",
                backgroundColor: "#FFFFFF",
            },
            blob: null,
            file: null,
            path: this.props.src,
            markers: {
                s1: { text: 'DC Power', x: 50, y: 110, xT: 68, yT: 105 },
                s2: { text: 'Electric Lock', x: 50, y: 220, xT: 68, yT: 215 },
                s3: { text: 'Slave Controller', x: 170, y: 270, xT: 188, yT: 265 },
                s4: { text: 'Door Lock', x: 170, y: 220, xT: 188, yT: 215 },
            },
            draggable: this.props.draggable,
            width: 306,
            height: 408,
        }
    }

    getState = () => {
        return this.state;
    }

    //
    loadImage = (path, file = null) => {
        this.image = new window.Image();
        this.image.src = path;
        this.image.addEventListener('load', () => {
            this.setState({
                blob: this.image,
                path: this.image.src,
            });
            if(file !== null) {
                this.setState({ file: file });
            }
        });
    }

    //
    refresh = (path = '', markers = null) => {
        if(path === '') {
            this.loadImage(this.state.path);
        } else {
            this.loadImage(Config.service.url + "/ssr/image/" + path);
        }
        //
        if(markers === null) {

        } else {
            this.setState({
                markers: markers
            });
        }
    }

    //
    componentDidMount = () => {
        
    }

    //
    onDragMoveCircle = (e) => {
        let m = {...this.state.markers};
        m[e.target.attrs.name].x = e.target.attrs.x;
        m[e.target.attrs.name].y = e.target.attrs.y;
        this.setState({
            markers: m
        });
    }

    //
    onDragMoveText = (e) => {
        let m = {...this.state.markers};
        m[e.target.attrs.name].xT = e.target.attrs.x;
        m[e.target.attrs.name].yT = e.target.attrs.y;
        this.setState({
            markers: m
        });
    }

    //
    resizeFile = (file) => new Promise(resolve => {
        Resizer.imageFileResizer(file, 306, 408, 'JPEG', 100, 0, uri => { resolve(uri) }, 'blob');
    });

    //
    onFileChange = async (e) => {
        // Check in case user doesn't input file.
        if(e.target.files[0] === undefined)
            return;
        // Resize.
        let fileName = e.target.files[0].name;
        let fileResize = new File([await this.resizeFile(e.target.files[0])], fileName);
        this.loadImage(URL.createObjectURL(fileResize), fileResize);
    }

    //
    onResetMarkers = () => {
        console.log('test');
        this.setState({
            markers: {
                s1: { text: 'DC Power', x: 50, y: 110, xT: 68, yT: 105 },
                s2: { text: 'Electric Lock', x: 50, y: 220, xT: 68, yT: 215 },
                s3: { text: 'Slave Controller', x: 170, y: 270, xT: 188, yT: 265 },
                s4: { text: 'Door Lock', x: 170, y: 220, xT: 188, yT: 215 },
            }
        });
    }

    //
    render = () => {
        return (<>
            <div style={{width: '100%', display: 'block'}}>
                {(this.props.readOnly === undefined || this.props.readOnly === false) ? <Form.File label="Select an image" custom onChange={this.onFileChange} /> : null }
                <center>
                    <div style={{marginTop: '10px', width: this.state.width, height: this.state.height, display: 'block'}}>
                        <Stage width={this.state.width} height={this.state.height}>
                            <Layer>
                                <Image image={this.state.blob} width={this.state.width} />
                                {/* DC Power */}
                                <Circle 
                                    ref={this.refS1Circle} 
                                    name="s1"
                                    x={this.state.markers.s1.x} y={this.state.markers.s1.y} 
                                    radius={this.state.config.radius} fill={this.state.config.circleColor} opacity={this.state.config.opacity} 
                                    draggable={this.state.draggable} 
                                    onDragMove={this.onDragMoveCircle} 
                                    />
                                <Rect 
                                    ref={this.refS1Rect} 
                                    name="s1"
                                    width={65} height={16} fill={this.state.config.backgroundColor} 
                                    x={this.state.markers.s1.xT - 3} y={this.state.markers.s1.yT - 3} 
                                    />
                                <Text 
                                    ref={this.refS1Text} 
                                    name="s1"
                                    text={this.state.markers.s1.text} 
                                    x={this.state.markers.s1.xT} y={this.state.markers.s1.yT} 
                                    draggable={this.state.draggable} onDragMove={this.onDragMoveText} 
                                    fontSize={this.state.config.fontSize} fill={this.state.config.fontColor} 
                                    />
                                {/* Electric Lock */}
                                <Circle 
                                    ref={this.refS2Circle} 
                                    name="s2"
                                    x={this.state.markers.s2.x} y={this.state.markers.s2.y} 
                                    radius={this.state.config.radius} fill={this.state.config.circleColor} opacity={this.state.config.opacity} 
                                    draggable={this.state.draggable} 
                                    onDragMove={this.onDragMoveCircle} 
                                    />
                                <Rect 
                                    ref={this.refS2Rect} 
                                    name="s2"
                                    width={75} height={16} fill={this.state.config.backgroundColor} 
                                    x={this.state.markers.s2.xT - 3} y={this.state.markers.s2.yT - 3} 
                                    />
                                <Text 
                                    ref={this.refS2Text} 
                                    name="s2"
                                    text={this.state.markers.s2.text} 
                                    x={this.state.markers.s2.xT} y={this.state.markers.s2.yT} 
                                    draggable={this.state.draggable} onDragMove={this.onDragMoveText} 
                                    fontSize={this.state.config.fontSize} fill={this.state.config.fontColor} 
                                    />
                                {/* Slave Controller */}
                                <Circle 
                                    ref={this.refS3Circle} 
                                    name="s3"
                                    x={this.state.markers.s3.x} y={this.state.markers.s3.y} 
                                    radius={this.state.config.radius} fill={this.state.config.circleColor} opacity={this.state.config.opacity} 
                                    draggable={this.state.draggable} 
                                    onDragMove={this.onDragMoveCircle} 
                                    />
                                <Rect 
                                    ref={this.refS3Rect} 
                                    name="s3"
                                    width={95} height={16} fill={this.state.config.backgroundColor} 
                                    x={this.state.markers.s3.xT - 3} y={this.state.markers.s3.yT - 3} 
                                    />
                                <Text 
                                    ref={this.refS3Text} 
                                    name="s3"
                                    text={this.state.markers.s3.text} 
                                    x={this.state.markers.s3.xT} y={this.state.markers.s3.yT} 
                                    draggable={this.state.draggable} onDragMove={this.onDragMoveText} 
                                    fontSize={this.state.config.fontSize} fill={this.state.config.fontColor} 
                                    />
                                {/* Door Lock */}
                                <Circle 
                                    ref={this.refS4Circle} 
                                    name="s4"
                                    x={this.state.markers.s4.x} y={this.state.markers.s4.y} 
                                    radius={this.state.config.radius} fill={this.state.config.circleColor} opacity={this.state.config.opacity} 
                                    draggable={this.state.draggable} 
                                    onDragMove={this.onDragMoveCircle} 
                                    />
                                <Rect 
                                    ref={this.refS4Rect} 
                                    name="s4"
                                    width={95} height={16} fill={this.state.config.backgroundColor} 
                                    x={this.state.markers.s4.xT - 3} y={this.state.markers.s4.yT - 3} 
                                    />
                                <Text 
                                    ref={this.refS4Text} 
                                    name="s4"
                                    text={this.state.markers.s4.text} 
                                    x={this.state.markers.s4.xT} y={this.state.markers.s4.yT} 
                                    draggable={this.state.draggable} onDragMove={this.onDragMoveText} 
                                    fontSize={this.state.config.fontSize} fill={this.state.config.fontColor} 
                                    />
                            </Layer>
                        </Stage>
                    </div>
                </center>
                <br/>
                <center>
                    {(this.props.readOnly === undefined || this.props.readOnly === false) ? <Button variant="secondary" onClick={() => this.onResetMarkers()} title="Reset all markers">Reset All Marker</Button> : null }
                </center>
            </div>
        </>);
    }
}

export default SSRDesignSlaveImage
//
//
//
export const allowRole = (role, roles) => {
  return roles.includes(role);
};

export const allowAdmin = () => {
  if (
    allowRole(localStorage.getItem("role"), [
      "Admin",
      "Admin SSK",
      "Super Admin"
    ])
  ) {
    return true;
  }
  return false;
};

export const allowAdminReview = () => {
  if (
    allowRole(localStorage.getItem("role"), [
      "Admin",
      "Admin SSK",
      "Super Admin",
      "Reviewer"
    ])
  ) {
    return true;
  }
  return false;
};

export const allowReview = () => {
  if (allowRole(localStorage.getItem("role"), ["Reviewer"])) {
    return true;
  }
  return false;
};

export const allowAdminSubcon = () => {
  if (allowRole(localStorage.getItem("role"), ["Admin Subcon"])) {
    return true;
  }
  return false;
};

export const allowActionForAdmin = () => {
  if (allowRole(localStorage.getItem("role"), ["Admin", "Admin SSK"])) {
    return true;
  }
  return false;
};

export const allowActionForAdminSuper = () => {
  if (allowRole(localStorage.getItem("role"), ["Super Admin"])) {
    return true;
  }
  return false;
};

export const allowActionForAdminSubcon = () => {
  if (allowRole(localStorage.getItem("role"), ["Admin Subcon"])) {
    return true;
  }
  return false;
};

const auth = {
  allowRole,
  allowAdmin,
  allowAdminReview,
  allowReview,
  allowAdminSubcon,
  allowActionForAdmin,
  allowActionForAdminSuper,
  allowActionForAdminSubcon
};

export default auth;

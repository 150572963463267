import React, { Component } from "react";
import { Redirect, withRouter } from "react-router-dom";
import { Image } from "react-bootstrap";
// import { Button } from "react-bootstrap";

import Axios from "axios";

import MessageBox from "../components/messagebox/messagebox";
import LoadingBox from "../components/messagebox/loadingbox";
import ConfirmBox from "../components/messagebox/confirmbox";

import MenuAdmin from "../components/menu/menuAdmin";
import MenuAdminSubcon from "../components/menu/menuAdminSubcon";
import MenuReviewer from "../components/menu/menuReviewer";
import MenuTechnician from "../components/menu/menuTechnician";

import auth from "../helper/authHelper";

var Config = require("../config/config.json");

class PATAdminReviewerShowPage27 extends Component {
  //
  constructor(props) {
    // Base contructor.
    super(props);
    // Ref.
    this.messageboxRef = React.createRef();
    this.loadingboxRef = React.createRef();
    this.confirmboxRef = React.createRef();
    this.designMaster1ImageRef = React.createRef();
    this.designSlaveImageRef = React.createRef();
    // State.
    this.state = {
      redirect: "",
      data: null
    };
  }

  //
  requestData = () => {
    if (this.loadingboxRef.current) this.loadingboxRef.current.onShow();
    let params = new URLSearchParams(window.location.search);
    let mode = params.get("mode");
    let ssrId = params.get("ssrId");
    let patId = params.get("id");
    let endPoint = "";

    if (patId !== null) {
      endPoint = Config.service.url + "/pat/get/" + patId;
    } else {
      endPoint = Config.service.url + "/pat/getBySSRId/" + ssrId;
    }

    Axios.get(endPoint, {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    })
      .then(res => {
        if (res.data.success === false) {
          this.messageboxRef.current.onShow("Error", res.data.message);
          return;
        }
        //
        //console.log(res.data);
        if (res.data.data !== undefined) {
          this.setState({ data: res.data.data });
          document.title =
            "PAT-Doc " +
            res.data.data.cover.siteCode +
            " No-" +
            res.data.data.documentNumber;
          if (mode === "print") {
            setTimeout(() => {
              if (this.loadingboxRef.current)
                this.loadingboxRef.current.onHide();
              setTimeout(() => {
                window.print();
              }, 1000);
            }, 15 * 1000);
          } else {
            if (this.loadingboxRef.current) this.loadingboxRef.current.onHide();
          }
        } else {
          this.messageboxRef.current.onShow(
            "Error",
            "PAT document does not create yet."
          );
        }
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        if (this.loadingboxRef.current) this.loadingboxRef.current.onHide();
      });
  };

  //
  onDelete = path => {
    if (this.loadingboxRef.current) this.loadingboxRef.current.onShow();
    let data = this.state.data;

    if (path === "p271") {
      data.content.p271 = "";
    } else if (path === "p272") {
      data.content.p272 = "";
    } else if (path === "p273") {
      data.content.p273 = "";
    }

    // console.log(path);
    // console.log(data);

    this.setState({ data: data });
    Axios.put(
      Config.service.url + "/pat/update/" + this.state.data._id,
      { data: JSON.stringify(this.state.data) },
      {
        headers: {
          Authorization: localStorage.getItem("token")
        }
      }
    )
      .then(res => {
        if (res.data.success === false) {
          this.messageboxRef.current.onShow("Error", res.data.message);
          if (this.loadingboxRef.current) this.loadingboxRef.current.onHide();
          return;
        }
        if (this.loadingboxRef.current) this.loadingboxRef.current.onHide();
        this.messageboxRef.current.onShow(
          "Success",
          "The data have been updated."
        );
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {});
  };

  //
  componentDidMount() {
    if (localStorage.getItem("token") === null) {
      return;
    }
    this.requestData();
  }

  menu = () => {
    if (auth.allowAdmin()) {
      return <MenuAdmin />;
    } else if (auth.allowAdminSubcon()) {
      return <MenuAdminSubcon />;
    } else if (auth.allowReview()) {
      return <MenuReviewer />;
    } else {
      return <MenuTechnician />;
    }
  };

  //
  view_desktop = () => {
    return (
      <>
        {this.menu()}
        <MessageBox ref={this.messageboxRef} />
        <LoadingBox ref={this.loadingboxRef} />
        <ConfirmBox ref={this.confirmboxRef} />
        <div className="App-body">
          {/* Page 10 : 2.7 */}
          <div style={{ marginTop: "10px" }}>
            <table width="100%" border="1">
              <tbody>
                <tr>
                  <td align="center">
                    <h4>2.7 Photo Master - Slave Install Completed</h4>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span style={{ fontSize: "10px" }}>Vender/Sub</span>
                    <br />
                    {this.state.data !== null
                      ? this.state.data.cover.surveyAndInstallation
                      : ""}
                  </td>
                </tr>
                <tr>
                  <td>
                    <table width="100%">
                      <tbody>
                        <tr>
                          <td width="33%" valign="top">
                            <span style={{ fontSize: "10px" }}>
                              Doc response/Approved
                            </span>
                            <br />
                            AWN
                          </td>
                          <td width="33%" valign="top">
                            <span style={{ fontSize: "10px" }}>Checked</span>
                            <br />
                            AWN
                          </td>
                          <td width="33%" valign="top">
                            <span style={{ fontSize: "10px" }}>File</span>
                            <br />
                            PAT Document Smart Site
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td>
                    <table width="100%">
                      <tbody>
                        <tr>
                          <td width="33%" valign="top">
                            <span style={{ fontSize: "10px" }}>
                              Project Name
                            </span>
                            <br />
                            {this.state.data !== null
                              ? this.state.data.cover.projectName
                              : ""}
                          </td>
                          <td width="33%" valign="top">
                            <span style={{ fontSize: "10px" }}>Site Name</span>
                            <br />
                            {this.state.data !== null
                              ? this.state.data.cover.siteNameEn
                              : ""}
                          </td>
                          <td width="33%" valign="top">
                            <span style={{ fontSize: "10px" }}>Site Code</span>
                            <br />
                            {this.state.data !== null
                              ? this.state.data.cover.siteCode
                              : ""}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td align="center">
                    <div style={{ marginTop: 5 }}>
                      <table>
                        <tbody>
                          <tr>
                            <td align="center" colSpan="3">
                              <Image
                                src={
                                  this.state.data !== null &&
                                  this.state.data.content.p271 !== undefined &&
                                  this.state.data.content.p271 !== ""
                                    ? Config.service.url +
                                      "/ssr/image/" +
                                      this.state.data.content.p271
                                    : "/images/na-vertical.jpg"
                                }
                                style={{ width: 300 }}
                                rounded
                                fluid
                              />
                            </td>
                          </tr>
                          <tr>
                            <td align="center" colSpan="3">
                              Smart Site Config &amp; On service
                            </td>
                          </tr>
                          {/* <tr>
                                                        <td align="center" colSpan="2">
                                                            <div className="hidden-print" style={{margin: 5}}>
                                                                <Button variant="danger" onClick={() => this.onDelete('p271')}>Delete</Button>
                                                            </div>
                                                        </td>
                                                    </tr> */}
                          <tr>
                            <td align="center" valign="top">
                              <Image
                                src={
                                  this.state.data !== null &&
                                  this.state.data.content.p272 !== undefined &&
                                  this.state.data.content.p272 !== ""
                                    ? Config.service.url +
                                      "/ssr/image/" +
                                      this.state.data.content.p272
                                    : "/images/na-vertical.jpg"
                                }
                                style={{ width: 300 }}
                                rounded
                                fluid
                              />
                            </td>
                            <td align="center" valign="top">
                              <Image
                                src={
                                  this.state.data !== null &&
                                  this.state.data.content.p273 !== undefined &&
                                  this.state.data.content.p273 !== ""
                                    ? Config.service.url +
                                      "/ssr/image/" +
                                      this.state.data.content.p273
                                    : "/images/na-vertical.jpg"
                                }
                                style={{ width: 300 }}
                                rounded
                                fluid
                              />
                            </td>
                            <td align="center" valign="top">
                              <Image
                                src={
                                  this.state.data !== null &&
                                  this.state.data.content.p274 !== undefined &&
                                  this.state.data.content.p274 !== ""
                                    ? Config.service.url +
                                      "/ssr/image/" +
                                      this.state.data.content.p274
                                    : "/images/na-vertical.jpg"
                                }
                                style={{ width: 300 }}
                                rounded
                                fluid
                              />
                            </td>
                          </tr>
                          <tr>
                            <td align="center">Master 1 Installed</td>
                            <td align="center">Slave 2 Installed</td>
                            <td align="center">Slave 3 Installed</td>
                          </tr>
                          {/* <tr>
                            <td align="center">
                              <div className="hidden-print" style={{margin: 5}}>
                                                                <Button variant="danger" onClick={() => this.onDelete('p272')}>Delete</Button>
                                                            </div>
                            </td>
                            <td align="center">
                              <div
                                className="hidden-print"
                                style={{ margin: 5 }}
                              >
                                <Button
                                  variant="danger"
                                  onClick={() => this.onDelete("p273")}
                                >
                                  Delete
                                </Button>
                              </div>
                            </td>
                          </tr> */}
                        </tbody>
                      </table>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
  };

  //
  render() {
    if (localStorage.getItem("token") === null) {
      return <Redirect push to="/login" />;
    }

    if (this.state.redirect) {
      return <Redirect push to={this.state.redirect} />;
    }

    return this.view_desktop();
  }
}

export default withRouter(PATAdminReviewerShowPage27);

import React, { Component } from "react";
import { Redirect, withRouter } from "react-router-dom";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import PieChart from "highcharts-react-official";

import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";

import MessageBox from "../components/messagebox/messagebox";
import LoadingBox from "../components/messagebox/loadingbox";
import MenuAdmin from "../components/menu/menuAdmin";
import MenuAdminSubcon from "../components/menu/menuAdminSubcon";
import MenuReviewer from "../components/menu/menuReviewer";
import MenuTechnician from "../components/menu/menuTechnician";

import auth from "../helper/authHelper";

import Axios from "axios";

var Config = require("../config/config.json");

class DashboardSSR extends Component {
  //
  constructor(props) {
    // Base contructor.
    super(props);
    //
    this.state = {
      regionSelected: "all",
      regions: [],
      optionsZone: {},
      optoinsStatus: {},
      optionsJobSttatus: {},
      progressPlanInstall: []
    };
  }

  //
  //
  requestRegions = () => {
    // Prevent access empty object.
    if (localStorage.getItem("token") !== null) {
    }

    // Request all regions for combobox
    Axios.get(Config.service.url + "/dashboard/ssr/regions/", {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    })
      .then(res => {
        if (res.data.success === false) {
          this.messageboxRef.current.onShow("Error", res.data.message);
          return;
        }

        // console.log("response regions = ", res);
        this.setState({ regions: res.data.data });

        // Request all regions data.
        this.requestRegionsData("all");
      })
      .catch(err => {
        console.log(err);
      });
  };

  //
  componentDidMount() {
    if (localStorage.getItem("token") === null) {
      return;
    }
    this.requestRegions();
  }

  requestRegionsData = region => {
    //
    //
    //
    setTimeout(() => {
      Axios.get(Config.service.url + "/dashboard/ssr/regionsStatus/" + region, {
        headers: {
          Authorization: localStorage.getItem("token")
        }
      })
        .then(res => {
          if (res.data.success === false) {
            this.messageboxRef.current.onShow("Error", res.data.message);
            return;
          }

          console.log("response zone status = ", res);

          let items = [];
          let listInstalled = [];
          let listRejected = [];
          let listCompleted = [];

          if (region === "all") {
            items = res.data.data.regions;
          } else {
            items = res.data.data.zones;
          }

          for (let i = 0; i < items.length; i++) {
            listInstalled.push(items[i].installed.amount);
            listRejected.push(items[i].rejected.amount);
            listCompleted.push(items[i].completed.amount);
          }

          const optionsZone = {
            chart: {
              type: "column"
            },
            title: {
              text:
                (region === "all" ? "Regions" : "Zones") +
                " - " +
                this.state.regionSelected.toUpperCase() +
                " Installed",
              align: "left"
            },
            xAxis: {
              categories: items.map(i => {
                return i.name;
              })
            },
            yAxis: {
              min: 0,
              max: 2000,
              title: {
                text: "Sites"
              },
              stackLabels: {
                enabled: true
              }
            },
            legend: {
              align: "center",
              x: 70,
              verticalAlign: "top",
              y: 70,
              floating: true,
              backgroundColor:
                Highcharts.defaultOptions.legend.backgroundColor || "white",
              borderColor: "#CCC",
              borderWidth: 1,
              shadow: false
            },
            colors: ["#3498DB", "#E74C3C", "#2ECC71"],
            plotOptions: {
              column: {
                stacking: "normal",
                dataLabels: {
                  enabled: true
                }
              }
            },
            series: [
              {
                name: "Installed",
                data: listInstalled
              },
              {
                name: "Rejected",
                data: listRejected
              },
              {
                name: "Completed",
                data: listCompleted
              }
            ]
          };

          this.setState({ optionsZone: optionsZone });
        })
        .catch(err => {
          console.log(err);
        });
    }, 1000);

    //
    //
    //
    setTimeout(() => {
      Axios.get(Config.service.url + "/dashboard/ssr/overallStatus/" + region, {
        headers: {
          Authorization: localStorage.getItem("token")
        }
      })
        .then(res => {
          if (res.data.success === false) {
            this.messageboxRef.current.onShow("Error", res.data.message);
            return;
          }

          console.log("response overall status = ", res);

          const status = res.data.data;

          const optionsStatus = {
            chart: {
              type: "pie",
              plotBackgroundColor: null,
              plotBorderWidth: null,
              plotShadow: false
            },
            title: {
              text: "Status - " + this.state.regionSelected.toUpperCase(),
              align: "left"
            },
            credits: {
              enabled: false
            },
            plotOptions: {
              pie: {
                allowPointSelect: true,
                cursor: "pointer",
                dataLabels: {
                  enabled: true,
                  format:
                    "<b>{point.name}</b>: {point.percentage:.1f} %, {point.y} Sites"
                },
                showInLegend: false,
                colors: ["#3498DB", "#E74C3C", "#2ECC71"]
              }
            },
            series: [
              {
                name: "",
                color: "#006600",
                lineWidth: 1,
                marker: {
                  enabled: false,
                  symbol: "circle",
                  radius: 3,
                  states: {
                    hover: {
                      enabled: true,
                      lineWidth: 1
                    }
                  }
                },
                data: [
                  {
                    name: status.installed.label,
                    y: status.installed.amount
                  },
                  {
                    name: status.rejected.label,
                    y: status.rejected.amount
                  },
                  {
                    name: status.completed.label,
                    y: status.completed.amount
                  }
                ]
              }
            ]
          };

          this.setState({ optionsStatus: optionsStatus });
        })
        .catch(err => {
          console.log(err);
        });
    }, 2000);

    //
    //
    //
    setTimeout(() => {
      Axios.get(Config.service.url + "/dashboard/plan/" + region, {
        headers: {
          Authorization: localStorage.getItem("token")
        }
      })
        .then(res => {
          console.log("plan = ", res.data.data);

          let plans = [];
          for (let i = 0; i < res.data.data.length; i++) {
            plans.push(res.data.data[i].amount);
          }

          this.setState({ progressPlanInstall: plans });
        })
        .catch(err => {
          console.log(err);
        });
    }, 3000);

    // //
    // //
    // //
    // setTimeout(() => {
    //   Axios.get(Config.service.url + "/dashboard/burnDown/ssr/" + region, {
    //     headers: {
    //       Authorization: localStorage.getItem("token")
    //     }
    //   })
    //     .then(res => {
    //       console.log("plan = ", res.data.data);

    //       let plans = [];
    //       for (let i = 0; i < res.data.data.length; i++) {
    //         plans.push(res.data.data[i].amount);
    //       }

    //       this.setState({ progressBurnDown: plans });
    //     })
    //     .catch(err => {
    //       console.log(err);
    //     });
    // }, 1000);

    //
    //
    //
    setTimeout(() => {
      Axios.get(
        Config.service.url + "/dashboard/ssr/progressStatus/" + region,
        {
          headers: {
            Authorization: localStorage.getItem("token")
          }
        }
      )

        .then(res => {
          if (res.data.success === false) {
            this.messageboxRef.current.onShow("Error", res.data.message);
            return;
          }

          console.log("response progress status = ", res);

          const months = res.data.data.months;
          let listProgressInstalled = [];
          let listProgressRejected = [];
          let listProgressCompleted = [];

          months.forEach(i => {
            listProgressInstalled.push(i.installed.amount);
            listProgressRejected.push(i.rejected.amount);
            listProgressCompleted.push(i.completed.amount);
          });

          console.log("listProgressInstalled", listProgressInstalled);
          console.log("listProgressRejected", listProgressRejected);
          console.log("listProgressCompleted", listProgressCompleted);

          const optionsJobStatus = {
            chart: {},
            title: {
              text: "Progress - " + this.state.regionSelected.toUpperCase(),
              align: "left"
            },
            xAxis: {
              categories: months.map(i => {
                return i.month;
              })
            },
            yAxis: [
              {
                min: 0,
                title: {
                  text: "Sites"
                },
                stackLabels: {
                  enabled: true
                }
              },
              {
                min: 0,
                title: {
                  text: "Plans"
                },
                opposite: true
              }
            ],
            colors: ["#3498DB", "#E74C3C", "#2ECC71", "#8E44AD"],
            legend: {
              align: "center",
              x: 70,
              verticalAlign: "top",
              y: 70,
              floating: true,
              backgroundColor:
                Highcharts.defaultOptions.legend.backgroundColor || "white",
              borderColor: "#CCC",
              borderWidth: 1,
              shadow: false
            },
            plotOptions: {
              column: {
                stacking: "normal",
                dataLabels: {
                  enabled: true
                }
              }
            },
            series: [
              {
                name: "Installed",
                type: "column",
                data: listProgressInstalled
              },
              {
                name: "Rejected",
                type: "column",
                data: listProgressRejected
              },
              {
                name: "Completed",
                type: "column",
                data: listProgressCompleted
              },
              {
                name: "Plan Install",
                data: this.state.progressPlanInstall
              }
              // {
              //   name: "Burn Down",
              //   data: this.state.progressBurnDown
              // }
            ]
          };

          this.setState({ optionsJobStatus: optionsJobStatus });
        })
        .catch(err => {
          console.log(err);
        });
    }, 5000);
  };

  //
  onChange = e => {
    const { name, value } = e.target;
    let state = { ...this.state };

    // console.log("e.target = ", e.target);
    // console.log("name = ", name);
    // console.log("value = ", value);

    state[name] = value;
    this.setState(state);

    // Request chart data
    this.requestRegionsData(value);
  };

  menu = () => {
    if (auth.allowAdmin()) {
      return <MenuAdmin />;
    } else if (auth.allowAdminSubcon()) {
      return <MenuAdminSubcon />;
    } else if (auth.allowReview()) {
      return <MenuReviewer />;
    } else {
      return <MenuTechnician />;
    }
  };

  //
  render() {
    //
    if (localStorage.getItem("token") === null) {
      return <Redirect push to="/login" />;
    }

    if (auth.allowAdminReview() === false) {
      return <Redirect push to="/noPermission" />;
    }

    return (
      <>
        <MessageBox ref={this.messageboxRef} />
        <LoadingBox ref={this.loadingboxRef} />
        {this.menu()}
        <div className="App-body">
          {/* Top */}
          <Row>
            <Col md={2}>
              <h2>SSR Dashboard</h2>
            </Col>
            <Col md={8}></Col>
            <Col md={2}>
              <select
                name="regionSelected"
                className="custom-select"
                onChange={this.onChange}
                value={this.state.regionSelected}
              >
                <option key="all" value="all">
                  All
                </option>
                {this.state.regions.map(i => {
                  return (
                    <option key={i} value={i}>
                      {i}
                    </option>
                  );
                })}
              </select>
            </Col>
          </Row>
          {/* HR */}
          <Row>
            <Col md={12}>
              <hr />
            </Col>
          </Row>
          {/* Region + Status */}
          <Row>
            <Col md={6}>
              <HighchartsReact
                highcharts={Highcharts}
                options={this.state.optionsZone}
              />
            </Col>
            <Col md={6}>
              <PieChart
                highcharts={Highcharts}
                options={this.state.optionsStatus}
              />
            </Col>
          </Row>
          {/* HR */}
          <Row>
            <Col md={12}>
              <hr />
            </Col>
          </Row>
          {/* Job status */}
          <Row>
            <Col md={12}>
              <HighchartsReact
                highcharts={Highcharts}
                options={this.state.optionsJobStatus}
              />
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default withRouter(DashboardSSR);

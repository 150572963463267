import React from "react";
import { Navbar } from "react-bootstrap";
import { Button } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPowerOff } from "@fortawesome/free-solid-svg-icons";

import Menu from "./menu";

class MenuTechnician extends Menu {
  //
  constructor(props) {
    // Base contructor.
    super(props);
    //
    this.state = {};
  }

  //
  menu = () => {
    window.location.href = "/dashboardTechnician";
  };

  //
  componentDidMount = async () => {};

  //
  render() {
    return (
      <>
        <Navbar bg="dark" variant="dark">
          <table width="100%">
            <tbody>
              <tr>
                <td align="left">
                  <Button variant="success" onClick={this.menu}>
                    Menu
                  </Button>
                </td>
                <td width="20%" align="right">
                  {/* <Button variant="danger" onClick={this.logout}>Logout</Button> */}
                  <Button variant="danger" onClick={this.logout}>
                    <FontAwesomeIcon icon={faPowerOff} />
                  </Button>
                </td>
              </tr>
            </tbody>
          </table>
        </Navbar>
      </>
    );
  }
}

export default MenuTechnician;

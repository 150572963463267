import React, { Component } from "react";
import { Redirect, withRouter } from "react-router-dom";
import { Image } from "react-bootstrap";
import { Table } from "react-bootstrap";

import Axios from "axios";

import MessageBox from "../components/messagebox/messagebox";
import LoadingBox from "../components/messagebox/loadingbox";
import ConfirmBox from "../components/messagebox/confirmbox";

import MenuAdmin from "../components/menu/menuAdmin";
import MenuAdminSubcon from "../components/menu/menuAdminSubcon";
import MenuReviewer from "../components/menu/menuReviewer";
import MenuTechnician from "../components/menu/menuTechnician";

import SSRDesignMasterImage from "../components/image/SSRDesignMasterImage";
import SSRDesignSlaveImage from "../components/image/SSRDesignSlaveImage";
import ImageUploadPreviewWithRect from "../components/image/ImageUploadPreviewWithRect";

import { getValue } from "../helper/utilityHelper";
import auth from "../helper/authHelper";

var Config = require("../config/config.json");

class SSRAdminReviewerShow extends Component {
  //
  constructor(props) {
    // Base contructor.
    super(props);
    // Ref.
    this.messageboxRef = React.createRef();
    this.loadingboxRef = React.createRef();
    this.confirmboxRef = React.createRef();
    //
    this.designMaster1ImageRef = React.createRef();
    this.imageM11Ref = React.createRef();
    this.imageM12Ref = React.createRef();
    this.imageM13Ref = React.createRef();
    this.imageM14Ref = React.createRef();
    this.imageM15Ref = React.createRef();
    this.imageM16Ref = React.createRef();
    this.imageM17Ref = React.createRef();
    this.imageM18Ref = React.createRef();
    this.imageM19Ref = React.createRef();
    this.imageM110Ref = React.createRef();
    this.imageM111Ref = React.createRef();
    //
    this.designSlave2ImageRef = React.createRef();
    this.imageS21Ref = React.createRef();
    this.imageS22Ref = React.createRef();
    this.imageS23Ref = React.createRef();
    this.imageS24Ref = React.createRef();
    this.imageS25Ref = React.createRef();
    this.imageS26Ref = React.createRef();
    this.imageS27Ref = React.createRef();
    //
    this.imageS31Ref = React.createRef();
    this.imageS32Ref = React.createRef();
    this.imageS33Ref = React.createRef();
    this.imageS34Ref = React.createRef();
    this.imageS35Ref = React.createRef();
    this.imageS36Ref = React.createRef();
    this.imageS37Ref = React.createRef();
    // State.
    this.state = {
      redirect: "",
      data: null
    };
  }

  //
  requestData = () => {
    if (this.loadingboxRef.current) this.loadingboxRef.current.onShow();
    let params = new URLSearchParams(window.location.search);
    let id = params.get("id");
    let mode = params.get("mode");
    Axios.get(Config.service.url + "/ssr/get/" + id, {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    })
      .then(res => {
        if (res.data.success === false) {
          this.messageboxRef.current.onShow("Error", res.data.message);
          return;
        }
        console.log(res.data.data);
        this.setState({ data: res.data.data });
        document.title =
          "SSR-Doc " +
          res.data.data.cover.siteCode +
          " No-" +
          res.data.data.documentNumber;
        if (mode === "print") {
          setTimeout(() => {
            this.designMaster1ImageRef.current.refresh(
              this.state.data.master1.imageDesignMaster,
              this.state.data.master1.imageDesignMaster2,
              this.state.data.master1.markers
            );
            this.imageM11Ref.current.refresh(
              this.state.data.master1.imageM11,
              this.state.data.master1.markersM11
            );
            this.imageM12Ref.current.refresh(
              this.state.data.master1.imageM12,
              this.state.data.master1.markersM12
            );
            this.imageM13Ref.current.refresh(
              this.state.data.master1.imageM13,
              this.state.data.master1.markersM13
            );
            this.imageM14Ref.current.refresh(
              this.state.data.master1.imageM14,
              this.state.data.master1.markersM14
            );
            this.imageM15Ref.current.refresh(
              this.state.data.master1.imageM15,
              this.state.data.master1.markersM15
            );
            this.imageM16Ref.current.refresh(
              this.state.data.master1.imageM16,
              this.state.data.master1.markersM16
            );
            this.imageM17Ref.current.refresh(
              this.state.data.master1.imageM17,
              this.state.data.master1.markersM17
            );
            this.imageM18Ref.current.refresh(
              this.state.data.master1.imageM18,
              this.state.data.master1.markersM18
            );
            this.imageM19Ref.current.refresh(
              this.state.data.master1.imageM19,
              this.state.data.master1.markersM19
            );
            this.imageM110Ref.current.refresh(
              this.state.data.master1.imageM110,
              this.state.data.master1.markersM110
            );
            this.imageM111Ref.current.refresh(
              this.state.data.master1.imageM111,
              this.state.data.master1.markersM111
            );
            //
            this.designSlave2ImageRef.current.refresh(
              this.state.data.slave2.imageDesignSlave,
              this.state.data.slave2.markers
            );
            this.imageS21Ref.current.refresh(
              this.state.data.slave2.imageS21,
              this.state.data.slave2.markersS21
            );
            this.imageS22Ref.current.refresh(
              this.state.data.slave2.imageS22,
              this.state.data.slave2.markersS22
            );
            this.imageS23Ref.current.refresh(
              this.state.data.slave2.imageS23,
              this.state.data.slave2.markersS23
            );
            this.imageS24Ref.current.refresh(
              this.state.data.slave2.imageS24,
              this.state.data.slave2.markersS24
            );
            this.imageS25Ref.current.refresh(
              this.state.data.slave2.imageS25,
              this.state.data.slave2.markersS25
            );
            this.imageS26Ref.current.refresh(
              this.state.data.slave2.imageS26,
              this.state.data.slave2.markersS26
            );
            this.imageS27Ref.current.refresh(
              this.state.data.slave2.imageS27,
              this.state.data.slave2.markersS27
            );
            //
            this.imageS31Ref.current.refresh(
              this.state.data.slave3.imageS31,
              this.state.data.slave3.markersS31
            );
            this.imageS32Ref.current.refresh(
              this.state.data.slave3.imageS32,
              this.state.data.slave3.markersS32
            );
            this.imageS33Ref.current.refresh(
              this.state.data.slave3.imageS33,
              this.state.data.slave3.markersS33
            );
            this.imageS34Ref.current.refresh(
              this.state.data.slave3.imageS34,
              this.state.data.slave3.markersS34
            );
            this.imageS35Ref.current.refresh(
              this.state.data.slave3.imageS35,
              this.state.data.slave3.markersS35
            );
            this.imageS36Ref.current.refresh(
              this.state.data.slave3.imageS36,
              this.state.data.slave3.markersS36
            );
            this.imageS37Ref.current.refresh(
              this.state.data.slave3.imageS37,
              this.state.data.slave3.markersS37
            );
            setTimeout(() => {
              if (this.loadingboxRef.current)
                this.loadingboxRef.current.onHide();
              setTimeout(() => {
                window.print();
              }, 1000);
            }, 15 * 1000);
          }, 1000);
        } else {
          setTimeout(() => {
            if (this.loadingboxRef.current) this.loadingboxRef.current.onHide();
            this.designMaster1ImageRef.current.refresh(
              this.state.data.master1.imageDesignMaster,
              this.state.data.master1.imageDesignMaster2,
              this.state.data.master1.markers
            );
            this.imageM11Ref.current.refresh(
              this.state.data.master1.imageM11,
              this.state.data.master1.markersM11
            );
            this.imageM12Ref.current.refresh(
              this.state.data.master1.imageM12,
              this.state.data.master1.markersM12
            );
            this.imageM13Ref.current.refresh(
              this.state.data.master1.imageM13,
              this.state.data.master1.markersM13
            );
            this.imageM14Ref.current.refresh(
              this.state.data.master1.imageM14,
              this.state.data.master1.markersM14
            );
            this.imageM15Ref.current.refresh(
              this.state.data.master1.imageM15,
              this.state.data.master1.markersM15
            );
            this.imageM16Ref.current.refresh(
              this.state.data.master1.imageM16,
              this.state.data.master1.markersM16
            );
            this.imageM17Ref.current.refresh(
              this.state.data.master1.imageM17,
              this.state.data.master1.markersM17
            );
            this.imageM18Ref.current.refresh(
              this.state.data.master1.imageM18,
              this.state.data.master1.markersM18
            );
            this.imageM19Ref.current.refresh(
              this.state.data.master1.imageM19,
              this.state.data.master1.markersM19
            );
            this.imageM110Ref.current.refresh(
              this.state.data.master1.imageM110,
              this.state.data.master1.markersM110
            );
            this.imageM111Ref.current.refresh(
              this.state.data.master1.imageM111,
              this.state.data.master1.markersM111
            );
            //
            this.designSlave2ImageRef.current.refresh(
              this.state.data.slave2.imageDesignSlave,
              this.state.data.slave2.markers
            );
            this.imageS21Ref.current.refresh(
              this.state.data.slave2.imageS21,
              this.state.data.slave2.markersS21
            );
            this.imageS22Ref.current.refresh(
              this.state.data.slave2.imageS22,
              this.state.data.slave2.markersS22
            );
            this.imageS23Ref.current.refresh(
              this.state.data.slave2.imageS23,
              this.state.data.slave2.markersS23
            );
            this.imageS24Ref.current.refresh(
              this.state.data.slave2.imageS24,
              this.state.data.slave2.markersS24
            );
            this.imageS25Ref.current.refresh(
              this.state.data.slave2.imageS25,
              this.state.data.slave2.markersS25
            );
            this.imageS26Ref.current.refresh(
              this.state.data.slave2.imageS26,
              this.state.data.slave2.markersS26
            );
            this.imageS27Ref.current.refresh(
              this.state.data.slave2.imageS27,
              this.state.data.slave2.markersS27
            );
            //
            this.imageS31Ref.current.refresh(
              this.state.data.slave3.imageS31,
              this.state.data.slave3.markersS31
            );
            this.imageS32Ref.current.refresh(
              this.state.data.slave3.imageS32,
              this.state.data.slave3.markersS32
            );
            this.imageS33Ref.current.refresh(
              this.state.data.slave3.imageS33,
              this.state.data.slave3.markersS33
            );
            this.imageS34Ref.current.refresh(
              this.state.data.slave3.imageS34,
              this.state.data.slave3.markersS34
            );
            this.imageS35Ref.current.refresh(
              this.state.data.slave3.imageS35,
              this.state.data.slave3.markersS35
            );
            this.imageS36Ref.current.refresh(
              this.state.data.slave3.imageS36,
              this.state.data.slave3.markersS36
            );
            this.imageS37Ref.current.refresh(
              this.state.data.slave3.imageS37,
              this.state.data.slave3.markersS37
            );
          }, 1000);
        }
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        if (this.loadingboxRef.current) this.loadingboxRef.current.onHide();
      });
  };

  //
  componentDidMount() {
    if (localStorage.getItem("token") === null) {
      return;
    }
    this.requestData();
  }

  //
  uiSiteDetail() {
    return (
      <div style={{ margin: "15px" }}>
        <table width="100%">
          <tbody>
            <tr>
              <td colSpan="2">
                Project :{" "}
                {this.state.data !== null
                  ? this.state.data.cover.projectName
                  : ""}
              </td>
            </tr>
            <tr>
              <td>
                Site Name En :{" "}
                {this.state.data !== null
                  ? this.state.data.cover.siteNameEn
                  : ""}
              </td>
              <td>
                Site Name Th :{" "}
                {this.state.data !== null
                  ? this.state.data.cover.siteNameTh
                  : ""}
              </td>
            </tr>
            <tr>
              <td>
                Site Code :{" "}
                {this.state.data !== null ? this.state.data.cover.siteCode : ""}
              </td>
              <td>
                Region :{" "}
                {this.state.data !== null ? this.state.data.cover.region : ""}
              </td>
            </tr>
            <tr>
              <td>
                Zone :{" "}
                {this.state.data !== null ? this.state.data.cover.zone : ""}
              </td>
              <td></td>
            </tr>
            <tr>
              <td>
                IP : {this.state.data !== null ? this.state.data.cover.ip : ""}
              </td>
              <td>
                Gateway :{" "}
                {this.state.data !== null ? this.state.data.cover.gateway : ""}
              </td>
            </tr>
            <tr>
              <td>
                Subnet Mask :{" "}
                {this.state.data !== null
                  ? this.state.data.cover.subnetMask
                  : ""}
              </td>
              <td>
                IPRAN :{" "}
                {this.state.data !== null ? this.state.data.cover.ipRan : ""}
              </td>
            </tr>
            <tr>
              <td>
                QR Code Master 1 :{" "}
                {this.state.data !== null
                  ? this.state.data.cover.qrCodeMaster1
                  : ""}
              </td>
              <td>
                QR Code Slave 2 :{" "}
                {this.state.data !== null
                  ? this.state.data.cover.qrCodeSlave2
                  : ""}
              </td>
            </tr>
            <tr>
              <td>
                QR Code Slave 3 :{" "}
                {this.state.data !== null
                  ? this.state.data.cover.qrCodeSlave3
                  : ""}
              </td>
              <td></td>
            </tr>
            <tr>
              <td>
                <span style={{ fontWeight: "bold", color: "#ff0000" }}>
                  Remark : {getValue(this.state.data?.cover.remark, "", "")}
                </span>
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  menu = () => {
    if (auth.allowAdmin()) {
      return <MenuAdmin />;
    } else if (auth.allowAdminSubcon()) {
      return <MenuAdminSubcon />;
    } else if (auth.allowReview()) {
      return <MenuReviewer />;
    } else {
      return <MenuTechnician />;
    }
  };

  //
  view_desktop = () => {
    return (
      <>
        {this.menu()}
        <MessageBox ref={this.messageboxRef} />
        <LoadingBox ref={this.loadingboxRef} />
        <ConfirmBox ref={this.confirmboxRef} />
        <div className="App-body">
          {/* Page 1 : Cover */}
          <div style={{ marginTop: "10px" }}>
            <table width="100%" border="1">
              <tbody>
                <tr>
                  <td align="center">
                    <h3>Technical Site Survey Report</h3>
                    {this.state.data !== null
                      ? "Doc No: " + this.state.data.documentNumber
                      : ""}
                  </td>
                </tr>
                <tr>
                  <td>{this.uiSiteDetail()}</td>
                </tr>
                <tr>
                  <td align="center">
                    <div style={{ margin: "15px" }}>
                      <Image
                        src={
                          this.state.data !== null &&
                          this.state.data.cover.imageCover !== ""
                            ? Config.service.url +
                              "/ssr/image/" +
                              this.state.data.cover.imageCover
                            : "/images/na-vertical.jpg"
                        }
                        height="1000"
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div style={{ margin: "15px" }}>
                      <table width="100%">
                        <tbody>
                          <tr>
                            <td colSpan="2">
                              Surver & Installaion by : SSK DECHA ENGINEERING
                              LTD.,PART.
                            </td>
                          </tr>
                          <tr>
                            <td>Telephone No: 092-328-2997</td>
                            <td>
                              Date:{" "}
                              {this.state.data !== null
                                ? this.state.data.cover.date
                                : ""}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Technician Name :{" "}
                              {this.state.data !== null
                                ? this.state.data.cover.technicianName +
                                  (this.state.data.cover.technicianName2 !==
                                  undefined
                                    ? ", " +
                                      this.state.data.cover.technicianName2
                                    : "")
                                : ""}
                            </td>
                            <td>
                              Telephone No :{" "}
                              {this.state.data !== null
                                ? this.state.data.cover.technicianPhone +
                                  (this.state.data.cover.technicianPhone2 !==
                                  undefined
                                    ? ", " +
                                      this.state.data.cover.technicianPhone2
                                    : "")
                                : ""}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <p style={{ pageBreakBefore: "always" }} />
          {/* Page 2 : Visual Check */}
          <div style={{ marginTop: "10px" }}>
            <table width="100%" border="1">
              <tbody>
                <tr>
                  <td align="center">
                    <h3>Technical Site Survey Report</h3>
                  </td>
                </tr>
                <tr>
                  <td>{this.uiSiteDetail()}</td>
                </tr>
                <tr>
                  <td align="center">
                    <div style={{ margin: "15px" }}>
                      <h4>Visual Check</h4>
                      <br />
                      <Table striped bordered hover size="sm">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Detail</th>
                            <th>Result</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>1</td>
                            <td>
                              ตรวจสอบตู้ ได้รับความเสียหายหรือไม่ เช่น บุบ
                              ซีลยางไม่ฉีกขาด
                            </td>
                            <td>
                              {this.state.data !== null
                                ? this.state.data.check.visualCheck1
                                : ""}
                            </td>
                          </tr>
                          {this.state.data !== null &&
                          this.state.data.check.visualCheck1 === "Fail" ? (
                            <tr>
                              <td colSpan="3" align="center">
                                <Image
                                  src={
                                    this.state.data !== null &&
                                    this.state.data.check.imageVisualCheck11 !==
                                      ""
                                      ? Config.service.url +
                                        "/ssr/image/" +
                                        this.state.data.check.imageVisualCheck11
                                      : "/images/na-horizontal.jpg"
                                  }
                                  rounded
                                  fluid
                                  style={{
                                    marginBottom: "5px",
                                    marginRight: "5px",
                                    width: "370px"
                                  }}
                                />
                                <Image
                                  src={
                                    this.state.data !== null &&
                                    this.state.data.check.imageVisualCheck12 !==
                                      ""
                                      ? Config.service.url +
                                        "/ssr/image/" +
                                        this.state.data.check.imageVisualCheck12
                                      : "/images/na-horizontal.jpg"
                                  }
                                  rounded
                                  fluid
                                  style={{
                                    marginBottom: "5px",
                                    marginRight: "5px",
                                    width: "370px"
                                  }}
                                />
                                <Image
                                  src={
                                    this.state.data !== null &&
                                    this.state.data.check.imageVisualCheck13 !==
                                      ""
                                      ? Config.service.url +
                                        "/ssr/image/" +
                                        this.state.data.check.imageVisualCheck13
                                      : "/images/na-horizontal.jpg"
                                  }
                                  rounded
                                  fluid
                                  style={{
                                    marginBottom: "5px",
                                    marginRight: "5px",
                                    width: "370px"
                                  }}
                                />
                                <Image
                                  src={
                                    this.state.data !== null &&
                                    this.state.data.check.imageVisualCheck14 !==
                                      ""
                                      ? Config.service.url +
                                        "/ssr/image/" +
                                        this.state.data.check.imageVisualCheck14
                                      : "/images/na-horizontal.jpg"
                                  }
                                  rounded
                                  fluid
                                  style={{
                                    marginBottom: "5px",
                                    marginRight: "5px",
                                    width: "370px"
                                  }}
                                />
                              </td>
                            </tr>
                          ) : null}
                          <tr>
                            <td>2</td>
                            <td>
                              ตรวจสอบว่าประตูและตัวล๊อคเดิมของตู้
                              สามารถทำงานได้อย่างสมบรูณ์ไม่ติดขัด
                            </td>
                            <td>
                              {this.state.data !== null
                                ? this.state.data.check.visualCheck2
                                : ""}
                            </td>
                          </tr>
                          {this.state.data !== null &&
                          this.state.data.check.visualCheck2 === "Fail" ? (
                            <tr>
                              <td colSpan="3" align="center">
                                <Image
                                  src={
                                    this.state.data !== null &&
                                    this.state.data.check.imageVisualCheck21 !==
                                      ""
                                      ? Config.service.url +
                                        "/ssr/image/" +
                                        this.state.data.check.imageVisualCheck21
                                      : "/images/na-horizontal.jpg"
                                  }
                                  rounded
                                  fluid
                                  style={{
                                    marginBottom: "5px",
                                    marginRight: "5px",
                                    width: "370px"
                                  }}
                                />
                                <Image
                                  src={
                                    this.state.data !== null &&
                                    this.state.data.check.imageVisualCheck22 !==
                                      ""
                                      ? Config.service.url +
                                        "/ssr/image/" +
                                        this.state.data.check.imageVisualCheck22
                                      : "/images/na-horizontal.jpg"
                                  }
                                  rounded
                                  fluid
                                  style={{
                                    marginBottom: "5px",
                                    marginRight: "5px",
                                    width: "370px"
                                  }}
                                />
                                <Image
                                  src={
                                    this.state.data !== null &&
                                    this.state.data.check.imageVisualCheck23 !==
                                      ""
                                      ? Config.service.url +
                                        "/ssr/image/" +
                                        this.state.data.check.imageVisualCheck23
                                      : "/images/na-horizontal.jpg"
                                  }
                                  rounded
                                  fluid
                                  style={{
                                    marginBottom: "5px",
                                    marginRight: "5px",
                                    width: "370px"
                                  }}
                                />
                                <Image
                                  src={
                                    this.state.data !== null &&
                                    this.state.data.check.imageVisualCheck24 !==
                                      ""
                                      ? Config.service.url +
                                        "/ssr/image/" +
                                        this.state.data.check.imageVisualCheck24
                                      : "/images/na-horizontal.jpg"
                                  }
                                  rounded
                                  fluid
                                  style={{
                                    marginBottom: "5px",
                                    marginRight: "5px",
                                    width: "370px"
                                  }}
                                />
                              </td>
                            </tr>
                          ) : null}
                          <tr>
                            <td>3</td>
                            <td>
                              ตรวจสอบทรัพย์สินและอุปกรณ์เกิดการสูญหายหรือไม่
                            </td>
                            <td>
                              {this.state.data !== null
                                ? this.state.data.check.visualCheck3
                                : ""}
                            </td>
                          </tr>
                          {this.state.data !== null &&
                          this.state.data.check.visualCheck3 === "Fail" ? (
                            <tr>
                              <td colSpan="3" align="center">
                                <Image
                                  src={
                                    this.state.data !== null &&
                                    this.state.data.check.imageVisualCheck31 !==
                                      ""
                                      ? Config.service.url +
                                        "/ssr/image/" +
                                        this.state.data.check.imageVisualCheck31
                                      : "/images/na-horizontal.jpg"
                                  }
                                  rounded
                                  fluid
                                  style={{
                                    marginBottom: "5px",
                                    marginRight: "5px",
                                    width: "370px"
                                  }}
                                />
                                <Image
                                  src={
                                    this.state.data !== null &&
                                    this.state.data.check.imageVisualCheck32 !==
                                      ""
                                      ? Config.service.url +
                                        "/ssr/image/" +
                                        this.state.data.check.imageVisualCheck32
                                      : "/images/na-horizontal.jpg"
                                  }
                                  rounded
                                  fluid
                                  style={{
                                    marginBottom: "5px",
                                    marginRight: "5px",
                                    width: "370px"
                                  }}
                                />
                                <Image
                                  src={
                                    this.state.data !== null &&
                                    this.state.data.check.imageVisualCheck33 !==
                                      ""
                                      ? Config.service.url +
                                        "/ssr/image/" +
                                        this.state.data.check.imageVisualCheck33
                                      : "/images/na-horizontal.jpg"
                                  }
                                  rounded
                                  fluid
                                  style={{
                                    marginBottom: "5px",
                                    marginRight: "5px",
                                    width: "370px"
                                  }}
                                />
                                <Image
                                  src={
                                    this.state.data !== null &&
                                    this.state.data.check.imageVisualCheck34 !==
                                      ""
                                      ? Config.service.url +
                                        "/ssr/image/" +
                                        this.state.data.check.imageVisualCheck34
                                      : "/images/na-horizontal.jpg"
                                  }
                                  rounded
                                  fluid
                                  style={{
                                    marginBottom: "5px",
                                    marginRight: "5px",
                                    width: "370px"
                                  }}
                                />
                              </td>
                            </tr>
                          ) : null}
                        </tbody>
                      </Table>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <p style={{ pageBreakBefore: "always" }} />
            {/* Page 3 : Design Master */}
            <div style={{ marginTop: "10px" }}>
              <table width="100%" border="1">
                <tbody>
                  <tr>
                    <td align="center">
                      <h3>Technical Site Survey Report</h3>
                    </td>
                  </tr>
                  <tr>
                    <td>{this.uiSiteDetail()}</td>
                  </tr>
                  <tr>
                    <td align="center">
                      <div style={{ margin: "15px" }}>
                        <h4>Design Master</h4>
                        <br />
                        <SSRDesignMasterImage
                          ref={this.designMaster1ImageRef}
                          src="/images/na-vertical.jpg"
                          src2="/images/na-horizontal.jpg"
                          draggable={false}
                          readOnly={true}
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p style={{ pageBreakBefore: "always" }} />
            {/* Page 4 : Master 1 */}
            <div style={{ marginTop: "10px" }}>
              <table width="100%" border="1">
                <tbody>
                  <tr>
                    <td align="center">
                      <h3>Technical Site Survey Report</h3>
                    </td>
                  </tr>
                  <tr>
                    <td>{this.uiSiteDetail()}</td>
                  </tr>
                  <tr>
                    <td align="center">
                      <div style={{ margin: "15px" }}>
                        <h4>Master 1</h4>
                        <br />
                        <table width="100%" border="1" cellPadding="10">
                          <tbody>
                            {/*  */}
                            <tr>
                              <td width="50%" align="center">
                                <ImageUploadPreviewWithRect
                                  ref={this.imageM11Ref}
                                  src={
                                    this.state.data !== null &&
                                    this.state.data.master1.imageM11 !== ""
                                      ? Config.service.url +
                                        "/ssr/image/" +
                                        this.state.data.master1.imageM11
                                      : "/images/na-horizontal.jpg"
                                  }
                                  draggable={false}
                                  readOnly={true}
                                />
                              </td>
                              <td width="50%" align="center">
                                <ImageUploadPreviewWithRect
                                  ref={this.imageM12Ref}
                                  src={
                                    this.state.data !== null &&
                                    this.state.data.master1.imageM12 !== ""
                                      ? Config.service.url +
                                        "/ssr/image/" +
                                        this.state.data.master1.imageM12
                                      : "/images/na-horizontal.jpg"
                                  }
                                  draggable={false}
                                  readOnly={true}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td width="50%" align="center">
                                CONNECT DC POWER TO SMART SITE
                              </td>
                              <td width="50%" align="center">
                                USB CAMERA
                              </td>
                            </tr>
                            {/*  */}
                            <tr>
                              <td width="50%" align="center">
                                <ImageUploadPreviewWithRect
                                  ref={this.imageM13Ref}
                                  src={
                                    this.state.data !== null &&
                                    this.state.data.master1.imageM13 !== ""
                                      ? Config.service.url +
                                        "/ssr/image/" +
                                        this.state.data.master1.imageM13
                                      : "/images/na-horizontal.jpg"
                                  }
                                  draggable={false}
                                  readOnly={true}
                                />
                              </td>
                              <td width="50%" align="center">
                                <ImageUploadPreviewWithRect
                                  ref={this.imageM14Ref}
                                  src={
                                    this.state.data !== null &&
                                    this.state.data.master1.imageM14 !== ""
                                      ? Config.service.url +
                                        "/ssr/image/" +
                                        this.state.data.master1.imageM14
                                      : "/images/na-horizontal.jpg"
                                  }
                                  draggable={false}
                                  readOnly={true}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td width="50%" align="center">
                                ELECTRIC LOCK
                              </td>
                              <td width="50%" align="center">
                                DOOR LOCK
                              </td>
                            </tr>
                            {/*  */}
                            <tr>
                              <td width="50%" align="center">
                                <ImageUploadPreviewWithRect
                                  ref={this.imageM15Ref}
                                  src={
                                    this.state.data !== null &&
                                    this.state.data.master1.imageM15 !== ""
                                      ? Config.service.url +
                                        "/ssr/image/" +
                                        this.state.data.master1.imageM15
                                      : "/images/na-horizontal.jpg"
                                  }
                                  draggable={false}
                                  readOnly={true}
                                />
                              </td>
                              <td width="50%" align="center">
                                <ImageUploadPreviewWithRect
                                  ref={this.imageM16Ref}
                                  src={
                                    this.state.data !== null &&
                                    this.state.data.master1.imageM16 !== ""
                                      ? Config.service.url +
                                        "/ssr/image/" +
                                        this.state.data.master1.imageM16
                                      : "/images/na-horizontal.jpg"
                                  }
                                  draggable={false}
                                  readOnly={true}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td width="50%" align="center">
                                IPRAN PORT ASSIGN
                              </td>
                              <td width="50%" align="center">
                                MASTER CONTROLLER
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p style={{ pageBreakBefore: "always" }} />
            {/* Page 5 : Master 1 */}
            <div style={{ marginTop: "10px" }}>
              <table width="100%" border="1">
                <tbody>
                  <tr>
                    <td align="center">
                      <h3>Technical Site Survey Report</h3>
                    </td>
                  </tr>
                  <tr>
                    <td>{this.uiSiteDetail()}</td>
                  </tr>
                  <tr>
                    <td align="center">
                      <div style={{ margin: "15px" }}>
                        <h4>Master 1</h4>
                        <br />
                        <table width="100%" border="1" cellPadding="10">
                          <tbody>
                            {/*  */}
                            <tr>
                              <td width="50%" align="center">
                                <ImageUploadPreviewWithRect
                                  ref={this.imageM17Ref}
                                  src={
                                    this.state.data !== null &&
                                    this.state.data.master1.imageM17 !== ""
                                      ? Config.service.url +
                                        "/ssr/image/" +
                                        this.state.data.master1.imageM17
                                      : "/images/na-horizontal.jpg"
                                  }
                                  draggable={false}
                                  readOnly={true}
                                />
                              </td>
                              <td width="50%" align="center">
                                <ImageUploadPreviewWithRect
                                  ref={this.imageM18Ref}
                                  src={
                                    this.state.data !== null &&
                                    this.state.data.master1.imageM18 !== ""
                                      ? Config.service.url +
                                        "/ssr/image/" +
                                        this.state.data.master1.imageM18
                                      : "/images/na-horizontal.jpg"
                                  }
                                  draggable={false}
                                  readOnly={true}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td width="50%" align="center">
                                SIREN
                              </td>
                              <td width="50%" align="center">
                                MOTION SENSOR
                              </td>
                            </tr>
                            {/*  */}
                            <tr>
                              <td width="50%" align="center">
                                <ImageUploadPreviewWithRect
                                  ref={this.imageM19Ref}
                                  src={
                                    this.state.data !== null &&
                                    this.state.data.master1.imageM19 !== ""
                                      ? Config.service.url +
                                        "/ssr/image/" +
                                        this.state.data.master1.imageM19
                                      : "/images/na-horizontal.jpg"
                                  }
                                  draggable={false}
                                  readOnly={true}
                                />
                              </td>
                              <td width="50%" align="center">
                                <ImageUploadPreviewWithRect
                                  ref={this.imageM110Ref}
                                  src={
                                    this.state.data !== null &&
                                    this.state.data.master1.imageM110 !== ""
                                      ? Config.service.url +
                                        "/ssr/image/" +
                                        this.state.data.master1.imageM110
                                      : "/images/na-horizontal.jpg"
                                  }
                                  draggable={false}
                                  readOnly={true}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td width="50%" align="center">
                                Cable Inlet
                              </td>
                              <td width="50%" align="center">
                                Ground Connection
                              </td>
                            </tr>
                            {/*  */}
                            <tr>
                              <td width="50%" align="center">
                                <ImageUploadPreviewWithRect
                                  ref={this.imageM111Ref}
                                  src={
                                    this.state.data !== null &&
                                    this.state.data.master1.imageM111 !== ""
                                      ? Config.service.url +
                                        "/ssr/image/" +
                                        this.state.data.master1.imageM111
                                      : "/images/na-horizontal.jpg"
                                  }
                                  draggable={false}
                                  readOnly={true}
                                />
                              </td>
                              <td width="50%" align="center"></td>
                            </tr>
                            <tr>
                              <td width="50%" align="center">
                                Ground Bar Connection
                              </td>
                              <td width="50%" align="center"></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p style={{ pageBreakBefore: "always" }} />
            {/* Page 6 : Design Slave */}
            <div style={{ marginTop: "10px" }}>
              <table width="100%" border="1">
                <tbody>
                  <tr>
                    <td align="center">
                      <h3>Technical Site Survey Report</h3>
                    </td>
                  </tr>
                  <tr>
                    <td>{this.uiSiteDetail()}</td>
                  </tr>
                  <tr>
                    <td align="center">
                      <div style={{ margin: "15px" }}>
                        <h4>Design Slave</h4>
                        <br />
                        <SSRDesignSlaveImage
                          ref={this.designSlave2ImageRef}
                          draggable={false}
                          src="/images/na-vertical.jpg"
                          readOnly={true}
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p style={{ pageBreakBefore: "always" }} />
            {/* Page 7 : Slave 2 */}
            <div style={{ marginTop: "10px" }}>
              <table width="100%" border="1">
                <tbody>
                  <tr>
                    <td align="center">
                      <h3>Technical Site Survey Report</h3>
                    </td>
                  </tr>
                  <tr>
                    <td>{this.uiSiteDetail()}</td>
                  </tr>
                  <tr>
                    <td align="center">
                      <div style={{ margin: "15px" }}>
                        <h4>Slave 2</h4>
                        <br />
                        <table width="100%" border="1" cellPadding="10">
                          <tbody>
                            {/*  */}
                            <tr>
                              <td width="50%" align="center">
                                <ImageUploadPreviewWithRect
                                  ref={this.imageS21Ref}
                                  src={
                                    this.state.data !== null &&
                                    this.state.data.slave2.imageS21 !== ""
                                      ? Config.service.url +
                                        "/ssr/image/" +
                                        this.state.data.slave2.imageS21
                                      : "/images/na-horizontal.jpg"
                                  }
                                  draggable={false}
                                  readOnly={true}
                                />
                              </td>
                              <td width="50%" align="center">
                                <ImageUploadPreviewWithRect
                                  ref={this.imageS22Ref}
                                  src={
                                    this.state.data !== null &&
                                    this.state.data.slave2.imageS22 !== ""
                                      ? Config.service.url +
                                        "/ssr/image/" +
                                        this.state.data.slave2.imageS22
                                      : "/images/na-horizontal.jpg"
                                  }
                                  draggable={false}
                                  readOnly={true}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td width="50%" align="center">
                                CONNECT DC POWER TO SMART SITE
                              </td>
                              <td width="50%" align="center">
                                SLAVE CONTROLLER
                              </td>
                            </tr>
                            {/*  */}
                            <tr>
                              <td width="50%" align="center">
                                <ImageUploadPreviewWithRect
                                  ref={this.imageS23Ref}
                                  src={
                                    this.state.data !== null &&
                                    this.state.data.slave2.imageS23 !== ""
                                      ? Config.service.url +
                                        "/ssr/image/" +
                                        this.state.data.slave2.imageS23
                                      : "/images/na-horizontal.jpg"
                                  }
                                  draggable={false}
                                  readOnly={true}
                                />
                              </td>
                              <td width="50%" align="center">
                                <ImageUploadPreviewWithRect
                                  ref={this.imageS24Ref}
                                  src={
                                    this.state.data !== null &&
                                    this.state.data.slave2.imageS24 !== ""
                                      ? Config.service.url +
                                        "/ssr/image/" +
                                        this.state.data.slave2.imageS24
                                      : "/images/na-horizontal.jpg"
                                  }
                                  draggable={false}
                                  readOnly={true}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td width="50%" align="center">
                                ELECTRIC LOCK
                              </td>
                              <td width="50%" align="center">
                                DOOR LOCK
                              </td>
                            </tr>
                            {/*  */}
                            <tr>
                              <td width="50%" align="center">
                                <ImageUploadPreviewWithRect
                                  ref={this.imageS25Ref}
                                  src={
                                    this.state.data !== null &&
                                    this.state.data.slave2.imageS25 !== ""
                                      ? Config.service.url +
                                        "/ssr/image/" +
                                        this.state.data.slave2.imageS25
                                      : "/images/na-horizontal.jpg"
                                  }
                                  draggable={false}
                                  readOnly={true}
                                />
                              </td>
                              <td width="50%" align="center">
                                <ImageUploadPreviewWithRect
                                  ref={this.imageS26Ref}
                                  src={
                                    this.state.data !== null &&
                                    this.state.data.slave2.imageS26 !== ""
                                      ? Config.service.url +
                                        "/ssr/image/" +
                                        this.state.data.slave2.imageS26
                                      : "/images/na-horizontal.jpg"
                                  }
                                  draggable={false}
                                  readOnly={true}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td width="50%" align="center">
                                Cable Inlet
                              </td>
                              <td width="50%" align="center">
                                Ground Bar in Cab. Connection
                              </td>
                            </tr>
                            {/*  */}
                            <tr>
                              <td width="50%" align="center">
                                <ImageUploadPreviewWithRect
                                  ref={this.imageS27Ref}
                                  src={
                                    this.state.data !== null &&
                                    this.state.data.slave2.imageS27 !== ""
                                      ? Config.service.url +
                                        "/ssr/image/" +
                                        this.state.data.slave2.imageS27
                                      : "/images/na-horizontal.jpg"
                                  }
                                  draggable={false}
                                  readOnly={true}
                                />
                              </td>
                              <td width="50%" align="center"></td>
                            </tr>
                            <tr>
                              <td width="50%" align="center">
                                Ground Bar Connection
                              </td>
                              <td width="50%" align="center"></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            {/* Page 7 : Slave 3 */}
            <div style={{ marginTop: "10px" }}>
              <table width="100%" border="1">
                <tbody>
                  <tr>
                    <td align="center">
                      <h3>Technical Site Survey Report</h3>
                    </td>
                  </tr>
                  <tr>
                    <td>{this.uiSiteDetail()}</td>
                  </tr>
                  <tr>
                    <td align="center">
                      <div style={{ margin: "15px" }}>
                        <h4>Slave 3</h4>
                        <br />
                        <table width="100%" border="1" cellPadding="10">
                          <tbody>
                            {/*  */}
                            <tr>
                              <td width="50%" align="center">
                                <ImageUploadPreviewWithRect
                                  ref={this.imageS31Ref}
                                  src={
                                    this.state.data !== null &&
                                    this.state.data.slave3.imageS31 !== ""
                                      ? Config.service.url +
                                        "/ssr/image/" +
                                        this.state.data.slave3.imageS31
                                      : "/images/na-horizontal.jpg"
                                  }
                                  draggable={false}
                                  readOnly={true}
                                />
                              </td>
                              <td width="50%" align="center">
                                <ImageUploadPreviewWithRect
                                  ref={this.imageS32Ref}
                                  src={
                                    this.state.data !== null &&
                                    this.state.data.slave3.imageS32 !== ""
                                      ? Config.service.url +
                                        "/ssr/image/" +
                                        this.state.data.slave3.imageS32
                                      : "/images/na-horizontal.jpg"
                                  }
                                  draggable={false}
                                  readOnly={true}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td width="50%" align="center">
                                CONNECT DC POWER TO SMART SITE
                              </td>
                              <td width="50%" align="center">
                                SLAVE CONTROLLER
                              </td>
                            </tr>
                            {/*  */}
                            <tr>
                              <td width="50%" align="center">
                                <ImageUploadPreviewWithRect
                                  ref={this.imageS33Ref}
                                  src={
                                    this.state.data !== null &&
                                    this.state.data.slave3.imageS33 !== ""
                                      ? Config.service.url +
                                        "/ssr/image/" +
                                        this.state.data.slave3.imageS33
                                      : "/images/na-horizontal.jpg"
                                  }
                                  draggable={false}
                                  readOnly={true}
                                />
                              </td>
                              <td width="50%" align="center">
                                <ImageUploadPreviewWithRect
                                  ref={this.imageS34Ref}
                                  src={
                                    this.state.data !== null &&
                                    this.state.data.slave3.imageS34 !== ""
                                      ? Config.service.url +
                                        "/ssr/image/" +
                                        this.state.data.slave3.imageS34
                                      : "/images/na-horizontal.jpg"
                                  }
                                  draggable={false}
                                  readOnly={true}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td width="50%" align="center">
                                ELECTRIC LOCK
                              </td>
                              <td width="50%" align="center">
                                DOOR LOCK
                              </td>
                            </tr>
                            {/*  */}
                            <tr>
                              <td width="50%" align="center">
                                <ImageUploadPreviewWithRect
                                  ref={this.imageS35Ref}
                                  src={
                                    this.state.data !== null &&
                                    this.state.data.slave3.imageS35 !== ""
                                      ? Config.service.url +
                                        "/ssr/image/" +
                                        this.state.data.slave3.imageS35
                                      : "/images/na-horizontal.jpg"
                                  }
                                  draggable={false}
                                  readOnly={true}
                                />
                              </td>
                              <td width="50%" align="center">
                                <ImageUploadPreviewWithRect
                                  ref={this.imageS36Ref}
                                  src={
                                    this.state.data !== null &&
                                    this.state.data.slave3.imageS36 !== ""
                                      ? Config.service.url +
                                        "/ssr/image/" +
                                        this.state.data.slave3.imageS36
                                      : "/images/na-horizontal.jpg"
                                  }
                                  draggable={false}
                                  readOnly={true}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td width="50%" align="center">
                                Cable Inlet
                              </td>
                              <td width="50%" align="center">
                                Ground Bar in Cab. Connection
                              </td>
                            </tr>
                            {/*  */}
                            <tr>
                              <td width="50%" align="center">
                                <ImageUploadPreviewWithRect
                                  ref={this.imageS37Ref}
                                  src={
                                    this.state.data !== null &&
                                    this.state.data.slave3.imageS37 !== ""
                                      ? Config.service.url +
                                        "/ssr/image/" +
                                        this.state.data.slave3.imageS37
                                      : "/images/na-horizontal.jpg"
                                  }
                                  draggable={false}
                                  readOnly={true}
                                />
                              </td>
                              <td width="50%" align="center"></td>
                            </tr>
                            <tr>
                              <td width="50%" align="center">
                                Ground Bar Connection
                              </td>
                              <td width="50%" align="center"></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </>
    );
  };

  //
  render() {
    if (localStorage.getItem("token") === null) {
      return <Redirect push to="/login" />;
    }

    if (this.state.redirect) {
      return <Redirect push to={this.state.redirect} />;
    }

    return this.view_desktop();
  }
}

export default withRouter(SSRAdminReviewerShow);

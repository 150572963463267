import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { Image } from 'react-bootstrap';

import MenuAdmin from '../components/menu/menuAdmin'
import MenuTechnician from '../components/menu/menuTechnician'

class noPermission extends Component {
    //
    render() {
        //
        if(localStorage.getItem('token') === null) {
            return <Redirect push to="/login" />;
        }

        return (
            <>
                { (localStorage.getItem('role') === 'Admin' || localStorage.getItem('role') === 'Super Admin') ? <MenuAdmin /> : <MenuTechnician /> }
                <div className="App-upder-construction">
                    <Image src="/images/permission.png" rounded fluid />
                </div>
            </>
        );
    }
}

export default withRouter(noPermission);
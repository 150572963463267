import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import { Stage } from 'react-konva';
import { Layer } from 'react-konva';
import { Image } from 'react-konva';
import { Line } from 'react-konva';
import Resizer from 'react-image-file-resizer';

var Config = require('../../config/config.json');

class ImageUploadPreviewWithRect extends Component {

    //
    constructor(props) {
        // Base contructor.
        super(props);
        // Ref
        this.refRect = React.createRef();
        // State
        this.state = {
            blob: null,
            file: null,
            path: this.props.src,
            draggable: this.props.draggable,
            width: 280,
            height: 191,
            config: {
                color: "#FF0000",
                borderColor: '#FF0000',
                opacity: 0.2,
                width: 50,
                height: 40,
            },
            markers: {
                r1: { x: 50, y: 50 },
            }
        }
    }

    resizeFile = (file) => new Promise(resolve => {
        Resizer.imageFileResizer(file, 408, 306, 'JPEG', 100, 0, uri => { resolve(uri) }, 'blob');
    });

    //
    getState = () => {
        return this.state;
    }

    //
    loadImage = (path, file = null) => {
        this.image = new window.Image();
        this.image.src = path;
        this.image.addEventListener('load', () => {
            this.setState({
                blob: this.image,
                path: this.image.src,
            });
            if(file !== null) {
                this.setState({ file: file });
            }
        });
    }

    //
    refresh = (path = '', markers = null) => {
        if(path === '') {
            this.loadImage(this.state.path);
        } else {
            this.loadImage(Config.service.url + "/ssr/image/" + path);
        }
        //
        if(markers !== null) {
            this.setState({
                markers: markers
            });
        }
    }

    //
    componentDidMount = () => {
        
    }

    //
    onFileChange = async (e) => {
        // Check in case user doesn't input file.
        if(e.target.files[0] === undefined)
            return;
        // Resize.
        let fileName = e.target.files[0].name;
        let fileResize = new File([await this.resizeFile(e.target.files[0])], fileName);
        this.loadImage(URL.createObjectURL(fileResize), fileResize);
    }

    //
    onDragMove = (e) => {
        let m = {...this.state.markers};
        m[e.target.attrs.name].x = e.target.attrs.x;
        m[e.target.attrs.name].y = e.target.attrs.y;
        this.setState({
            markers: m
        });
    }

    //
    render = () => {
        return (<>
            <div style={{width: '100%', display: 'block'}}>
                {
                    (this.props.readOnly === undefined || this.props.readOnly === false) ? 
                    <Form.File label="Select an image" custom onChange={this.onFileChange} /> : 
                    null 
                }
                <center>
                    <div style={{marginTop: '10px', width: this.state.width, height: this.state.height, display: 'block'}}>
                        <Stage width={this.state.width} height={this.state.height}>
                            <Layer>
                                <Image image={this.state.blob} width={this.state.width} height={this.state.height} />
                                <Line 
                                    name='r1'
                                    x={this.state.markers.r1.x} y={this.state.markers.r1.y}
                                    stroke={this.state.config.borderColor}
                                    points={[
                                        0, 0, 
                                        this.state.config.width, 0, 
                                        this.state.config.width, this.state.config.height, 
                                        0, this.state.config.height
                                    ]}
                                    closed
                                    draggable={this.state.draggable}
                                    onDragMove={this.onDragMove} 
                                    />
                            </Layer>
                        </Stage>
                    </div>
                </center>
            </div>
        </>);
    }
}

export default ImageUploadPreviewWithRect
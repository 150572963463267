import React, { useState, useEffect } from "react";

import "./App.css";

// Std.
import { Switch, Route, withRouter } from "react-router-dom";

// Customer.
import Login from "./views/login";
import MA from "./views/ma";
import NoPermission from "./views/noPermission";

import User from "./views/user";
import BaseStation from "./views/baseStation";

import DashboardAdmin from "./views/dashboardAdmin";
import DashboardReviewer from "./views/dashboardReviewer";
import DashboardTechnician from "./views/dashboardTechnician";

import DashboardSSR from "./views/dashboardSSR";
import DashboardPAT from "./views/dashboardPAT";
import DashboardSSRByDate from "./views/dashboardSSRByDate";
import DashboardPATByDate from "./views/dashboardPATByDate";
import DashboardBurnDown from "./views/dashboardBurnDown";

import SSRsAdminReviewer from "./views/ssrsAdminReviewer";
import SSRsTechnician from "./views/ssrsTechnician";

import SSRTechnician from "./views/ssrTechnician";
import SSRTechnicianShow from "./views/ssrTechnicianShow";
import SSRTechnicianEdit from "./views/ssrTechnicianEdit";
import SSRAdminReviewerShow from "./views/ssrAdminReviewerShow";

import PATsAdminReviewer from "./views/patsAdminReviewer";
import PATsTechnician from "./views/patsTechnician";
import ConfigSmartSite from "./views/configSmartSite";
import ConfigSmartSiteAdminReviewer from "./views/configSmartSiteAdminReviewer";

import PATTechnician from "./views/patTechnician";
import PATTechnicianEdit from "./views/patTechnicianEdit";
import PATTechnicianEdit2 from "./views/patTechnicianEdit2";
import PATTechnicianShow from "./views/patTechnicianShow";
import PATAdminReviewerShow from "./views/patAdminReviewerShow";
import PATAdminReviewerShowPage27 from "./views/patAdminReviewerShowPage27";
import PATAdminReviewerShowPage27All from "./views/patAdminReviewerShowPage27All";

import Store from "./views/store";

import QR from "./views/qr";
import Axios from "axios";

import ReportByDetail from "./views/reportByDetail";
import ReportByAdmin from "./views/reportByAdmin";

var Config = require("./config/config.json");

function App() {
  const [isMA, setIsMA] = useState(false);

  useEffect(() => {
    Axios.get(Config.service.url + "/setting/getMA/", {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    })
      .then(res => {
        if (res.data.success === false) {
          console.log(res.data);
          return;
        }
        setIsMA(res.data.data.value);
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {});
  }, []);

  if (isMA) {
    return <MA />;
  }

  return (
    <div className="App">
      <Switch>
        {/* Test */}
        <Route exact path="/qr" component={() => <QR />}></Route>
        {/* Main */}
        <Route
          exact
          path="/"
          component={() => <Login title={"Site Survey Report"} />}
        ></Route>
        <Route
          exact
          path="/login"
          component={() => <Login title={"Site Survey Report"} />}
        ></Route>
        <Route exact path="/ma" component={() => <MA />}></Route>
        <Route
          exact
          path="/noPermission"
          component={() => <NoPermission />}
        ></Route>
        {/* Dashbaord */}
        <Route
          exact
          path="/dashboardAdmin"
          component={() => <DashboardAdmin />}
        ></Route>
        <Route
          exact
          path="/dashboardReviewer"
          component={() => <DashboardReviewer />}
        ></Route>
        <Route
          exact
          path="/dashboardTechnician"
          component={() => <DashboardTechnician />}
        ></Route>
        {/* Dashboard SSR + PAT */}
        <Route
          exact
          path="/dashboardSSR"
          component={() => <DashboardSSR />}
        ></Route>
        <Route
          exact
          path="/dashboardSSRByDate"
          component={() => <DashboardSSRByDate />}
        ></Route>
        <Route
          exact
          path="/dashboardPAT"
          component={() => <DashboardPAT />}
        ></Route>
        <Route
          exact
          path="/dashboardPATByDate"
          component={() => <DashboardPATByDate />}
        ></Route>
        <Route
          exact
          path="/dashboardBurnDown"
          component={() => <DashboardBurnDown />}
        ></Route>
        {/* Admin operation */}
        <Route exact path="/user" component={() => <User />}></Route>
        <Route
          exact
          path="/baseStation"
          component={() => <BaseStation />}
        ></Route>
        {/* SSR Admin and Reviewer */}
        <Route
          exact
          path="/ssrsAdmin"
          component={() => <SSRsAdminReviewer />}
        ></Route>
        <Route
          exact
          path="/ssrsReviewer"
          component={() => <SSRsAdminReviewer />}
        ></Route>
        <Route
          exact
          path="/ssrsTechnician"
          component={() => <SSRsTechnician />}
        ></Route>
        {/* SSR Technician */}
        <Route
          exact
          path="/ssrTechnician"
          component={() => <SSRTechnician />}
        ></Route>
        <Route
          exact
          path="/ssrTechnicianShow"
          component={() => <SSRTechnicianShow />}
        ></Route>
        <Route
          exact
          path="/ssrTechnicianEdit"
          component={() => <SSRTechnicianEdit />}
        ></Route>
        {/* SSR document admin and reviewer show view, to print. */}
        <Route
          exact
          path="/ssrAdminReviewerShow"
          component={() => <SSRAdminReviewerShow />}
        ></Route>
        {/* PAT Admin and Reviewer */}
        <Route
          exact
          path="/patsAdmin"
          component={() => <PATsAdminReviewer />}
        ></Route>
        <Route
          exact
          path="/patsReviewer"
          component={() => <PATsAdminReviewer />}
        ></Route>
        <Route
          exact
          path="/patsTechnician"
          component={() => <PATsTechnician />}
        ></Route>
        <Route
          exact
          path="/configSmartSite"
          component={() => <ConfigSmartSite />}
        ></Route>
        <Route
          exact
          path="/configSmartSiteAdminReviewer"
          component={() => <ConfigSmartSiteAdminReviewer />}
        ></Route>
        {/* PAT Technician */}
        <Route
          exact
          path="/patTechnician"
          component={() => <PATTechnician />}
        ></Route>
        <Route
          exact
          path="/patTechnicianShow"
          component={() => <PATTechnicianShow />}
        ></Route>
        <Route
          exact
          path="/patTechnicianEdit"
          component={() => <PATTechnicianEdit />}
        ></Route>
        <Route
          exact
          path="/patTechnicianEdit2"
          component={() => <PATTechnicianEdit2 />}
        ></Route>
        {/* For PAT document admin and reviewer show view, to print. */}
        <Route
          exact
          path="/patAdminReviewerShow"
          component={() => <PATAdminReviewerShow />}
        ></Route>
        <Route
          exact
          path="/patAdminReviewerShowPage27"
          component={() => <PATAdminReviewerShowPage27 />}
        ></Route>
        <Route
          exact
          path="/patAdminReviewerShowPage27All"
          component={() => <PATAdminReviewerShowPage27All />}
        ></Route>
        {/* Report */}
        <Route
          exact
          path="/report/byDetail"
          component={() => <ReportByDetail />}
        ></Route>
        <Route
          exact
          path="/report/byAdmin"
          component={() => <ReportByAdmin />}
        ></Route>
        {/*  */}
        <Route exact path="/store" component={() => <Store />}></Route>
      </Switch>
    </div>
  );
}

export default withRouter(App);

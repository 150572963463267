import React, { Component } from "react";
import { Redirect, withRouter } from "react-router-dom";
import { Button } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import { Table } from "react-bootstrap";
import { Image } from "react-bootstrap";

import MenuTechnician from "../components/menu/menuTechnician";

class DashboardTechnician extends Component {
  //
  constructor(props) {
    // Base contructor.
    super(props);
    //
    this.state = {
      redirect: "",
      isShowMyProfile: false
    };
  }

  //
  navigateToSSR = () => {
    this.setState({ redirect: "/ssrTechnician" });
  };

  navigateToSSRs = () => {
    this.setState({ redirect: "/ssrsTechnician" });
  };

  navigateToPAT = () => {
    this.setState({ redirect: "/patTechnician" });
  };

  navigateToPATs = () => {
    this.setState({ redirect: "/patsTechnician" });
  };

  navigateToConfigSMartSite = () => {
    this.setState({ redirect: "/configSmartSite" });
  };

  navigateToMyProfile = () => {
    this.setState({ isShowMyProfile: true });
  };

  onHideMyProfile = () => {
    this.setState({ isShowMyProfile: false });
  };

  //
  componentDidMount() {}

  //
  render() {
    //
    if (localStorage.getItem("token") === null) {
      return <Redirect push to="/login" />;
    }

    if (this.state.redirect) {
      return <Redirect push to={this.state.redirect} />;
    }

    return (
      <>
        <MenuTechnician />
        <Modal show={this.state.isShowMyProfile} onHide={this.onHideMyProfile}>
          <Modal.Header closeButton>
            <Modal.Title>My Profile</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Table striped>
              <tbody>
                <tr>
                  <td width="120">Name</td>
                  <td>{localStorage.getItem("name")}</td>
                </tr>
                <tr>
                  <td width="120">Phone</td>
                  <td>{localStorage.getItem("phone")}</td>
                </tr>
                <tr>
                  <td width="120">Role</td>
                  <td>{localStorage.getItem("role")}</td>
                </tr>
                <tr>
                  <td width="120">Username</td>
                  <td>{localStorage.getItem("username")}</td>
                </tr>
              </tbody>
            </Table>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={this.onHideMyProfile}>
              OK
            </Button>
          </Modal.Footer>
        </Modal>
        <div className="App-body">
          <div style={{ marginBottom: "10px" }}>
            <Image src="/logo2.png" style={{ width: "100%" }} />
            <div style={{ width: "100%", marginBottom: "5px" }}>
              <Button
                variant="primary"
                style={{ height: "100px", width: "100%" }}
                onClick={this.navigateToSSR}
              >
                New SSR Document
              </Button>
            </div>
            <div style={{ width: "100%", marginBottom: "5px" }}>
              <Button
                variant="info"
                style={{ height: "100px", width: "100%" }}
                onClick={this.navigateToSSRs}
              >
                Show All SSR
              </Button>
            </div>
            <div style={{ width: "100%", marginBottom: "5px" }}>
              <Button
                variant="success"
                style={{ height: "100px", width: "100%" }}
                onClick={this.navigateToPATs}
              >
                Show All PAT
              </Button>
            </div>
            <div style={{ width: "100%", marginBottom: "5px" }}>
              <Button
                variant="warning"
                style={{ height: "100px", width: "100%" }}
                onClick={this.navigateToConfigSMartSite}
              >
                Config Smart Site
              </Button>
            </div>
            <div style={{ width: "100%", marginBottom: "5px" }}>
              <Button
                variant="secondary"
                style={{ height: "100px", width: "100%" }}
                onClick={this.navigateToMyProfile}
              >
                My Profile
              </Button>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(DashboardTechnician);

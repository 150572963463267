import React, { Component } from "react";
import { Redirect, withRouter } from "react-router-dom";
import { Form } from "react-bootstrap";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import { Card } from "react-bootstrap";
import { Button } from "react-bootstrap";

import Resizer from "react-image-file-resizer";
import Axios from "axios";

import MenuTechnician from "../components/menu/menuTechnician";
import MenuAdmin from "../components/menu/menuAdmin";
import MenuAdminSubcon from "../components/menu/menuAdminSubcon";
import MenuReviewer from "../components/menu/menuReviewer";

import ImageUploadPreview from "../components/image/ImageUploadPreview";

import MessageBox from "../components/messagebox/messagebox";
import LoadingBox from "../components/messagebox/loadingbox";
import ConfirmBox from "../components/messagebox/confirmbox";

import auth from "../helper/authHelper";

var Config = require("../config/config.json");

class PATTechnicianEdit2 extends Component {
  //
  constructor(props) {
    // Base contructor.
    super(props);
    // Ref.
    this.messageboxRef = React.createRef();
    this.loadingboxRef = React.createRef();
    this.confirmboxRef = React.createRef();
    //
    this.imageP211Ref = React.createRef();
    this.imageP212Ref = React.createRef();
    // this.imageP213Ref = React.createRef();
    // this.imageP214Ref = React.createRef();
    this.imageP215Ref = React.createRef();
    this.imageP216Ref = React.createRef();
    this.imageP217Ref = React.createRef();
    this.imageP218Ref = React.createRef();
    //
    this.imageP221Ref = React.createRef();
    this.imageP222Ref = React.createRef();
    this.imageP223Ref = React.createRef();
    this.imageP224Ref = React.createRef();
    this.imageP225Ref = React.createRef();
    this.imageP226Ref = React.createRef();
    this.imageP227Ref = React.createRef();
    this.imageP228Ref = React.createRef();
    this.imageP229Ref = React.createRef();
    this.imageP2210Ref = React.createRef();
    this.imageP2211Ref = React.createRef();
    this.imageP2212Ref = React.createRef();
    //
    this.imageP232Ref = React.createRef();
    this.imageP234Ref = React.createRef();
    this.imageP236Ref = React.createRef();
    this.imageP238Ref = React.createRef();
    //
    this.imageP242Ref = React.createRef();
    this.imageP244Ref = React.createRef();
    this.imageP246Ref = React.createRef();
    this.imageP248Ref = React.createRef();
    //
    this.imageP252Ref = React.createRef();
    this.imageP254Ref = React.createRef();
    this.imageP256Ref = React.createRef();
    this.imageP258Ref = React.createRef();
    //
    this.imageP262Ref = React.createRef();
    this.imageP264Ref = React.createRef();
    this.imageP266Ref = React.createRef();
    this.imageP268Ref = React.createRef();
    //
    this.imageP271Ref = React.createRef();
    this.imageP272Ref = React.createRef();
    this.imageP273Ref = React.createRef();
    this.imageP274Ref = React.createRef();
    //
    this.imageP281Ref = React.createRef();
    this.imageP282Ref = React.createRef();
    this.imageP283Ref = React.createRef();
    this.imageP284Ref = React.createRef();
    this.imageP285Ref = React.createRef();
    this.imageP286Ref = React.createRef();
    this.imageP287Ref = React.createRef();
    // State
    this.state = {
      redirect: "",
      pat: null,
      owners: null
    };
  }

  resizeFile = (file, mode) =>
    new Promise(resolve => {
      if (mode === "v") {
        Resizer.imageFileResizer(
          file,
          306,
          408,
          "JPEG",
          70,
          0,
          uri => {
            resolve(uri);
          },
          "blob"
        );
      } else {
        Resizer.imageFileResizer(
          file,
          408,
          306,
          "JPEG",
          70,
          0,
          uri => {
            resolve(uri);
          },
          "blob"
        );
      }
    });

  //
  requestData = () => {
    if (this.loadingboxRef.current) this.loadingboxRef.current.onShow();
    //
    Axios.get(Config.service.url + "/user/getOwner", {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    }).then(res => {
      this.setState({ owners: res.data.data });
    });
    //
    let params = new URLSearchParams(window.location.search);
    let ssrId = params.get("ssrId");
    let patId = params.get("id");
    let endPoint = "";

    if (patId !== null) {
      endPoint = Config.service.url + "/pat/get/" + patId;
    } else {
      endPoint = Config.service.url + "/pat/getBySSRId/" + ssrId;
    }

    Axios.get(endPoint, {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    })
      .then(res => {
        if (res.data.success === false) {
          this.messageboxRef.current.onShow("Error", res.data.message);
          return;
        }
        console.log(res.data);
        //
        this.setState({ pat: res.data.data });
        //
        this.imageP271Ref.current.refresh(this.state.pat.content.p271);
        this.imageP272Ref.current.refresh(this.state.pat.content.p272);
        this.imageP273Ref.current.refresh(this.state.pat.content.p273);
        this.imageP274Ref.current.refresh(this.state.pat.content.p274);
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        if (this.loadingboxRef.current) this.loadingboxRef.current.onHide();
      });
  };

  //
  onCoverChange = e => {
    const { name, value } = e.target;
    let pat = { ...this.state.pat };
    pat.cover[name] = value;
    this.setState({ pat: pat });
  };

  //
  onCoverChangeToLocalStorage = e => {
    const { name, value } = e.target;
    localStorage.setItem(name, value);
    let pat = { ...this.state.pat };
    pat.cover[name] = value;
    this.setState({ pat: pat });
  };

  //
  onContentChange = e => {
    const { name, value } = e.target;
    let pat = { ...this.state.pat };
    pat.content[name] = value;
    this.setState({ pat: pat });
  };

  //
  onChangeOwner = e => {
    const { name, value } = e.target;
    let pat = { ...this.state.pat };
    pat[name] = value;
    this.setState({ pat: pat });
  };

  //
  onSubmit = () => {
    if (this.loadingboxRef.current) this.loadingboxRef.current.onShow();
    //
    let data = new FormData();
    data.append("data", JSON.stringify(this.state.pat));
    //
    data.append("p271", this.imageP271Ref.current.getState().file);
    data.append("p272", this.imageP272Ref.current.getState().file);
    data.append("p273", this.imageP273Ref.current.getState().file);
    data.append("p274", this.imageP274Ref.current.getState().file);

    //
    Axios.put(Config.service.url + "/pat/update/" + this.state.pat._id, data, {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    })
      .then(res => {
        if (res.data.success === false) {
          this.messageboxRef.current.onShow("Error", res.data.message);
          this.loadingboxRef.current.onHide();
          return;
        }
        if (this.loadingboxRef.current) this.loadingboxRef.current.onHide();
        this.messageboxRef.current.onShow(
          "Success",
          "The data have been updated."
        );
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {});
  };

  //
  componentDidMount = () => {
    if (localStorage.getItem("token") === null) {
      return;
    }
    this.requestData();
  };

  menu = () => {
    if (auth.allowAdmin()) {
      return <MenuAdmin />;
    } else if (auth.allowAdminSubcon()) {
      return <MenuAdminSubcon />;
    } else if (auth.allowReview()) {
      return <MenuReviewer />;
    } else {
      return <MenuTechnician />;
    }
  };

  //
  render = () => {
    //
    if (localStorage.getItem("token") === null) {
      return <Redirect push to="/login" />;
    }

    // Redirect
    if (this.state.redirect) {
      return <Redirect push to={this.state.redirect} />;
    }

    return (
      <>
        {this.menu()}
        <MessageBox ref={this.messageboxRef} />
        <LoadingBox ref={this.loadingboxRef} />
        <ConfirmBox ref={this.confirmboxRef} />
        <div className="App-body">
          <Row>
            <Col>
              <div>
                <table width="100%">
                  <tbody>
                    <tr>
                      <td>
                        <h5>PAT Config Smart Site</h5>
                      </td>
                      <td width="100" align="right">
                        <Button variant="success" onClick={this.onSubmit}>
                          Update
                        </Button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <hr />
              <div style={{ textAlign: "center", marginBottom: "10px" }}>
                <h4>
                  {this.state.pat !== null
                    ? this.state.pat.cover.projectName
                    : ""}
                </h4>
                {this.state.pat !== null
                  ? "Doc No: " + this.state.pat.documentNumber
                  : ""}
              </div>
              <Form>
                <div style={{ textAlign: "center", margin: "10px" }}>
                  <h4>Config Smart Site</h4>
                </div>
                <div style={{ marginTop: "10px" }}>
                  <Card border="primary">
                    <Card.Header>
                      Smart Site Config &amp; On service
                    </Card.Header>
                    <Card.Body>
                      <ImageUploadPreview
                        ref={this.imageP271Ref}
                        src={
                          this.state.pat !== null &&
                          this.state.pat.content.p271 !== undefined &&
                          this.state.pat.content.p271 !== ""
                            ? Config.service.url +
                              "/ssr/image/" +
                              this.state.pat.content.p271
                            : "/images/na-vertical.jpg"
                        }
                        mode="v"
                      />
                    </Card.Body>
                  </Card>
                </div>
                <div style={{ marginTop: "10px" }}>
                  <Card border="success">
                    <Card.Header>Master 1 Installed</Card.Header>
                    <Card.Body>
                      <ImageUploadPreview
                        ref={this.imageP272Ref}
                        src={
                          this.state.pat !== null &&
                          this.state.pat.content.p272 !== undefined &&
                          this.state.pat.content.p272 !== ""
                            ? Config.service.url +
                              "/ssr/image/" +
                              this.state.pat.content.p272
                            : "/images/na-vertical.jpg"
                        }
                        mode="v"
                      />
                    </Card.Body>
                  </Card>
                </div>
                <div style={{ marginTop: "10px" }}>
                  <Card border="primary">
                    <Card.Header>Slave 2 Installed</Card.Header>
                    <Card.Body>
                      <ImageUploadPreview
                        ref={this.imageP273Ref}
                        src={
                          this.state.pat !== null &&
                          this.state.pat.content.p273 !== undefined &&
                          this.state.pat.content.p273 !== ""
                            ? Config.service.url +
                              "/ssr/image/" +
                              this.state.pat.content.p273
                            : "/images/na-vertical.jpg"
                        }
                        mode="v"
                      />
                    </Card.Body>
                  </Card>
                </div>
                <div style={{ marginTop: "10px" }}>
                  <Card border="primary">
                    <Card.Header>Slave 3 Installed</Card.Header>
                    <Card.Body>
                      <ImageUploadPreview
                        ref={this.imageP274Ref}
                        src={
                          this.state.pat !== null &&
                          this.state.pat.content.p274 !== undefined &&
                          this.state.pat.content.p274 !== ""
                            ? Config.service.url +
                              "/ssr/image/" +
                              this.state.pat.content.p274
                            : "/images/na-vertical.jpg"
                        }
                        mode="v"
                      />
                    </Card.Body>
                  </Card>
                </div>
              </Form>
            </Col>
          </Row>
        </div>
      </>
    );
  };
}

export default withRouter(PATTechnicianEdit2);

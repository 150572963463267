import React, { Component } from "react";
import { Redirect, withRouter } from "react-router-dom";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import PieChart from "highcharts-react-official";

import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import { Form } from "react-bootstrap";

import MessageBox from "../components/messagebox/messagebox";
import LoadingBox from "../components/messagebox/loadingbox";
import MenuAdmin from "../components/menu/menuAdmin";
import MenuAdminSubcon from "../components/menu/menuAdminSubcon";
import MenuReviewer from "../components/menu/menuReviewer";
import MenuTechnician from "../components/menu/menuTechnician";

import auth from "../helper/authHelper";

import Axios from "axios";

var Config = require("../config/config.json");
var Moment = require("moment");

class DashboardPATByDate extends Component {
  //
  constructor(props) {
    // Base contructor.
    super(props);
    //
    this.state = {
      regionSelected: "all",
      dateSelected: Moment().format("YYYY-MM-DD"),
      regions: [],
      optionsZone: {},
      optoinsStatus: {},
      optionsJobSttatus: {}
    };
  }

  //
  //
  requestRegions = () => {
    // Prevent access empty object.
    if (localStorage.getItem("token") !== null) {
    }

    // Request all regions for combobox
    Axios.get(Config.service.url + "/dashboard/pat/regions/", {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    })
      .then(res => {
        if (res.data.success === false) {
          this.messageboxRef.current.onShow("Error", res.data.message);
          return;
        }

        // console.log("response regions = ", res);
        this.setState({ regions: res.data.data });

        // Request all regions data.
        this.requestRegionsData(Moment().format("YYYY-MM-DD"), "all");
      })
      .catch(err => {
        console.log(err);
      });
  };

  //
  componentDidMount() {
    if (localStorage.getItem("token") === null) {
      return;
    }
    this.requestRegions();
  }

  requestRegionsData = (date, region) => {
    //
    //
    //
    setTimeout(() => {
      Axios.get(
        Config.service.url +
          "/dashboard/pat/regionsStatusByDate/" +
          region +
          "/" +
          date,
        {
          headers: {
            Authorization: localStorage.getItem("token")
          }
        }
      )
        .then(res => {
          if (res.data.success === false) {
            this.messageboxRef.current.onShow("Error", res.data.message);
            return;
          }

          console.log("response zone status = ", res);

          let items = [];
          let listInstalled = [];
          let listRejected = [];
          let listCompleted = [];

          if (region === "all") {
            items = res.data.data.regions;
          } else {
            items = res.data.data.zones;
          }

          for (let i = 0; i < items.length; i++) {
            listInstalled.push(items[i].installed.amount);
            listRejected.push(items[i].rejected.amount);
            listCompleted.push(items[i].completed.amount);
          }

          const optionsZone = {
            chart: {
              type: "column"
            },
            title: {
              text:
                (region === "all" ? "Regions" : "Zones") +
                " - " +
                this.state.regionSelected.toUpperCase() +
                " Installed",
              align: "left"
            },
            xAxis: {
              categories: items.map(i => {
                return i.name;
              })
            },
            yAxis: {
              min: 0,
              max: 100,
              title: {
                text: "Sites"
              },
              stackLabels: {
                enabled: true
              }
            },
            legend: {
              align: "left",
              x: 70,
              verticalAlign: "top",
              y: 70,
              floating: true,
              backgroundColor:
                Highcharts.defaultOptions.legend.backgroundColor || "white",
              borderColor: "#CCC",
              borderWidth: 1,
              shadow: false
            },
            colors: ["#3498DB", "#E74C3C", "#2ECC71"],
            plotOptions: {
              column: {
                stacking: "normal",
                dataLabels: {
                  enabled: true
                }
              }
            },
            series: [
              {
                name: "Installed",
                data: listInstalled
              },
              {
                name: "Rejected",
                data: listRejected
              },
              {
                name: "Completed",
                data: listCompleted
              }
            ]
          };

          this.setState({ optionsZone: optionsZone });
        })
        .catch(err => {
          console.log(err);
        });
    }, 1000);

    //
    //
    //
    setTimeout(() => {
      Axios.get(
        Config.service.url +
          "/dashboard/pat/overallStatusByDate/" +
          region +
          "/" +
          date,
        {
          headers: {
            Authorization: localStorage.getItem("token")
          }
        }
      )
        .then(res => {
          if (res.data.success === false) {
            this.messageboxRef.current.onShow("Error", res.data.message);
            return;
          }

          console.log("response overall status = ", res);

          const status = res.data.data;

          const optionsStatus = {
            chart: {
              type: "pie",
              plotBackgroundColor: null,
              plotBorderWidth: null,
              plotShadow: false
            },
            title: {
              text: "Status - " + this.state.regionSelected.toUpperCase(),
              align: "left"
            },
            credits: {
              enabled: false
            },
            plotOptions: {
              pie: {
                allowPointSelect: true,
                cursor: "pointer",
                dataLabels: {
                  enabled: true,
                  format:
                    "<b>{point.name}</b>: {point.percentage:.1f} %, {point.y} Sites"
                },
                showInLegend: false,
                colors: ["#3498DB", "#E74C3C", "#2ECC71"]
              }
            },
            series: [
              {
                name: "",
                color: "#006600",
                lineWidth: 1,
                marker: {
                  enabled: false,
                  symbol: "circle",
                  radius: 3,
                  states: {
                    hover: {
                      enabled: true,
                      lineWidth: 1
                    }
                  }
                },
                data: [
                  {
                    name: status.installed.label,
                    y: status.installed.amount
                  },
                  {
                    name: status.rejected.label,
                    y: status.rejected.amount
                  },
                  {
                    name: status.completed.label,
                    y: status.completed.amount
                  }
                ]
              }
            ]
          };

          this.setState({ optionsStatus: optionsStatus });
        })
        .catch(err => {
          console.log(err);
        });
    }, 2000);
  };

  //
  onRegionChange = e => {
    const { name, value } = e.target;
    let state = { ...this.state };

    console.log("e.target = ", e.target);
    console.log("name = ", name);
    console.log("value = ", value);

    state[name] = value;
    this.setState(state);

    // Request chart data
    this.requestRegionsData(this.state.dateSelected, value);
  };

  //
  onDateChange = e => {
    const { name, value } = e.target;
    let state = { ...this.state };

    console.log("e.target = ", e.target);
    console.log("name = ", name);
    console.log("value = ", value);

    state[name] = value;
    this.setState(state);

    // Request chart data
    this.requestRegionsData(value, this.state.regionSelected);
  };

  menu = () => {
    if (auth.allowAdmin()) {
      return <MenuAdmin />;
    } else if (auth.allowAdminSubcon()) {
      return <MenuAdminSubcon />;
    } else if (auth.allowReview()) {
      return <MenuReviewer />;
    } else {
      return <MenuTechnician />;
    }
  };

  //
  render() {
    //
    if (localStorage.getItem("token") === null) {
      return <Redirect push to="/login" />;
    }

    if (auth.allowAdminReview() === false) {
      return <Redirect push to="/noPermission" />;
    }

    return (
      <>
        <MessageBox ref={this.messageboxRef} />
        <LoadingBox ref={this.loadingboxRef} />
        {this.menu()}
        <div className="App-body">
          {/* Top */}
          <Row>
            <Col md={4}>
              <h2>PAT Dashboard By Date</h2>
            </Col>
            <Col md={4}></Col>
            <Col md={2}>
              <Form>
                <Form.Control
                  type="date"
                  name="dateSelected"
                  onChange={this.onDateChange}
                  defaultValue={Moment().format("YYYY-MM-DD")}
                />
              </Form>
            </Col>
            <Col md={2}>
              <select
                name="regionSelected"
                className="custom-select"
                onChange={this.onRegionChange}
                value={this.state.regionSelected}
              >
                <option key="all" value="all">
                  All
                </option>
                {this.state.regions.map(i => {
                  return (
                    <option key={i} value={i}>
                      {i}
                    </option>
                  );
                })}
              </select>
            </Col>
          </Row>
          {/* HR */}
          <Row>
            <Col md={12}>
              <hr />
            </Col>
          </Row>
          {/* Region + Status */}
          <Row>
            <Col md={6}>
              <HighchartsReact
                highcharts={Highcharts}
                options={this.state.optionsZone}
              />
            </Col>
            <Col md={6}>
              <PieChart
                highcharts={Highcharts}
                options={this.state.optionsStatus}
              />
            </Col>
          </Row>
          {/* HR */}
          <Row>
            <Col md={12}>
              <hr />
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default withRouter(DashboardPATByDate);

import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Spinner } from 'react-bootstrap';

class LoadingBox extends Component {
    //
    constructor(props) {
        super(props);
        this.state = {
            title: 'กำลังโหลด',
            isShow: false,
        };
    }
    //
    onShow = () => {
        this.setState({ isShow: true, });
    }

    //
    onHide = () => {
        this.setState({ isShow: false });
    }

    //
    render() {
        return (
            <>
                <Modal show={this.state.isShow}>
                    <Modal.Header>
                        <Modal.Title>{this.state.title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            <Spinner animation="grow" variant="primary" />
                            <Spinner animation="grow" variant="success" />
                            <Spinner animation="grow" variant="danger" />
                            <Spinner animation="grow" variant="warning" />
                            <Spinner animation="grow" variant="info" />
                        </div>
                    </Modal.Body>
                </Modal>
            </>
        );
    }
}

export default LoadingBox
import React, { Component } from "react";
import { Redirect, withRouter } from "react-router-dom";
import { Card } from "react-bootstrap";
import { Image } from "react-bootstrap";
import { Tabs } from "react-bootstrap";
import { Tab } from "react-bootstrap";
import { Table } from "react-bootstrap";

import Axios from "axios";

import MessageBox from "../components/messagebox/messagebox";
import LoadingBox from "../components/messagebox/loadingbox";
import ConfirmBox from "../components/messagebox/confirmbox";
import MenuTechnician from "../components/menu/menuTechnician";

import { getValue } from "../helper/utilityHelper";

var Config = require("../config/config.json");

class PATTechnicianShow extends Component {
  //
  constructor(props) {
    // Base contructor.
    super(props);
    // Ref.
    this.messageboxRef = React.createRef();
    this.loadingboxRef = React.createRef();
    this.confirmboxRef = React.createRef();
    this.imageDesignMaster1Ref = React.createRef();
    this.imageDesignSlave2Ref = React.createRef();
    // State.
    this.state = {
      redirect: "",
      data: null
    };
  }

  //
  requestData = () => {
    if (this.loadingboxRef.current) this.loadingboxRef.current.onShow();
    let params = new URLSearchParams(window.location.search);
    let id = params.get("id");
    Axios.get(Config.service.url + "/pat/get/" + id, {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    })
      .then(res => {
        if (res.data.success === false) {
          this.messageboxRef.current.onShow("Error", res.data.message);
          return;
        }
        console.log("data = ", res.data.data);
        this.setState({ data: res.data.data });
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        if (this.loadingboxRef.current) this.loadingboxRef.current.onHide();
      });
  };

  //
  componentDidMount() {
    if (localStorage.getItem("token") === null) {
      return;
    }
    this.requestData();
  }

  getP8Value = item => {
    if (item === undefined || item === null) {
      return "";
    } else {
      return item;
    }
  };

  getP8Image = item => {
    if (item === undefined || item === null || item === "") {
      return "/images/na-vertical.jpg";
    } else {
      return Config.service.url + "/ssr/image/" + item;
    }
  };

  //
  render() {
    if (localStorage.getItem("token") === null) {
      return <Redirect push to="/login" />;
    }

    if (this.state.redirect) {
      return <Redirect push to={this.state.redirect} />;
    }

    return (
      <>
        <MenuTechnician />
        <MessageBox ref={this.messageboxRef} />
        <LoadingBox ref={this.loadingboxRef} />
        <ConfirmBox ref={this.confirmboxRef} />
        <div className="App-body">
          <div style={{ textAlign: "center", marginBottom: "15px" }}>
            <h3>PAT Document</h3>
            {this.state.data !== null
              ? "Doc No: " + this.state.data.documentNumber
              : ""}
          </div>
          <Tabs id={React.createRef()} defaultActiveKey="cover">
            {/*  */}
            <Tab eventKey="cover" title="Cover">
              <div style={{ marginTop: "5px" }}>
                <Card border="primary">
                  <Card.Header>
                    {this.state.data !== null
                      ? this.state.data.cover.projectName
                      : null}
                  </Card.Header>
                  <Card.Body>
                    <table width="100%">
                      <tbody>
                        <tr>
                          <td width="150">Status</td>
                          <td>
                            {this.state.data !== null
                              ? this.state.data.status
                              : null}
                          </td>
                        </tr>
                        <tr>
                          <td>Date</td>
                          <td>
                            {this.state.data !== null
                              ? this.state.data.cover.date
                              : null}
                          </td>
                        </tr>
                        <tr>
                          <td>Site</td>
                          <td>
                            {this.state.data !== null
                              ? this.state.data.cover.siteCode
                              : null}
                          </td>
                        </tr>
                        <tr>
                          <td>Site En</td>
                          <td>
                            {this.state.data !== null
                              ? this.state.data.cover.siteNameEn
                              : null}
                          </td>
                        </tr>
                        <tr>
                          <td>Site Th</td>
                          <td>
                            {this.state.data !== null
                              ? this.state.data.cover.siteNameTh
                              : null}
                          </td>
                        </tr>
                        <tr>
                          <td>Region</td>
                          <td>
                            {this.state.data !== null
                              ? this.state.data.cover.region
                              : null}
                          </td>
                        </tr>
                        <tr>
                          <td>Zone</td>
                          <td>
                            {this.state.data !== null
                              ? this.state.data.cover.zone
                              : null}
                          </td>
                        </tr>
                        <tr>
                          <td>IP</td>
                          <td>
                            {this.state.data !== null
                              ? this.state.data.cover.ip
                              : null}
                          </td>
                        </tr>
                        <tr>
                          <td>Gateway</td>
                          <td>
                            {this.state.data !== null
                              ? this.state.data.cover.gateway
                              : null}
                          </td>
                        </tr>
                        <tr>
                          <td>Subnet Mask</td>
                          <td>
                            {this.state.data !== null
                              ? this.state.data.cover.subnetMask
                              : null}
                          </td>
                        </tr>
                        <tr>
                          <td>IPRAN</td>
                          <td>
                            {this.state.data !== null
                              ? this.state.data.cover.ipRan
                              : null}
                          </td>
                        </tr>
                        <tr>
                          <td>Tech Name 1</td>
                          <td>
                            {this.state.data !== null
                              ? this.state.data.cover.technicianName
                              : null}
                          </td>
                        </tr>
                        <tr>
                          <td>Tech Phone 1</td>
                          <td>
                            {this.state.data !== null
                              ? this.state.data.cover.technicianPhone
                              : null}
                          </td>
                        </tr>
                        <tr>
                          <td>Tech Name 2</td>
                          <td>
                            {this.state.data !== null
                              ? this.state.data.cover.technicianName2
                              : null}
                          </td>
                        </tr>
                        <tr>
                          <td>Tech Phone 2</td>
                          <td>
                            {this.state.data !== null
                              ? this.state.data.cover.technicianPhone2
                              : null}
                          </td>
                        </tr>
                        <tr>
                          <td>Contact Person</td>
                          <td>
                            {this.state.data !== null
                              ? this.state.data.cover.contactPerson
                              : null}
                          </td>
                        </tr>
                        <tr>
                          <td>Contact Address</td>
                          <td>
                            {this.state.data !== null
                              ? this.state.data.cover.address
                              : null}
                          </td>
                        </tr>
                        <tr style={{ color: "#ff0000", fontWeight: "bold" }}>
                          <td>Remark</td>
                          <td>
                            {this.state.data !== null
                              ? this.state.data?.cover.remark
                              : null}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Card.Body>
                </Card>
              </div>
              <div style={{ marginTop: "5px" }}>
                <Card border="primary">
                  <Card.Header>Cover's Image</Card.Header>
                  <Card.Body>
                    <Image
                      src={
                        this.state.data !== null &&
                        this.state.data.cover.imageCover !== ""
                          ? Config.service.url +
                            "/ssr/image/" +
                            this.state.data.cover.imageCover
                          : "/images/na-vertical.jpg"
                      }
                      style={{ width: "100%" }}
                      rounded
                      fluid
                    />
                  </Card.Body>
                </Card>
                <div style={{ marginTop: "5px" }}>
                  <Card border="primary">
                    <Card.Header>QR Code Master 1</Card.Header>
                    <Card.Body>
                      QR Code Master :{" "}
                      {this.state.data !== null
                        ? this.state.data.cover.qrCodeMaster1
                        : null}
                      <br />
                      <br />
                      <Image
                        src={
                          this.state.data !== null &&
                          this.state.data.cover.imageQRCodeMaster1 !== ""
                            ? Config.service.url +
                              "/ssr/image/" +
                              this.state.data.cover.imageQRCodeMaster1
                            : "/images/na-vertical.jpg"
                        }
                        style={{ width: "100%" }}
                        rounded
                        fluid
                      />
                    </Card.Body>
                  </Card>
                </div>
                <div style={{ marginTop: "5px" }}>
                  <Card border="primary">
                    <Card.Header>QR Code Slave 2</Card.Header>
                    <Card.Body>
                      QR Code Slave :{" "}
                      {this.state.data !== null
                        ? this.state.data.cover.qrCodeSlave2
                        : null}
                      <br />
                      <br />
                      <Image
                        src={
                          this.state.data !== null &&
                          this.state.data.cover.imageQRCodeSlave2 !== ""
                            ? Config.service.url +
                              "/ssr/image/" +
                              this.state.data.cover.imageQRCodeSlave2
                            : "/images/na-vertical.jpg"
                        }
                        style={{ width: "100%" }}
                        rounded
                        fluid
                      />
                    </Card.Body>
                  </Card>
                </div>
                <div style={{ marginTop: "5px" }}>
                  <Card border="primary">
                    <Card.Header>QR Code Slave 3</Card.Header>
                    <Card.Body>
                      QR Code Slave :{" "}
                      {this.state.data !== null
                        ? this.state.data.cover.qrCodeSlave3
                        : null}
                      <br />
                      <br />
                      <Image
                        src={
                          this.state.data !== null &&
                          this.state.data.cover.imageQRCodeSlave3 !== ""
                            ? Config.service.url +
                              "/ssr/image/" +
                              this.state.data.cover.imageQRCodeSlave3
                            : "/images/na-vertical.jpg"
                        }
                        style={{ width: "100%" }}
                        rounded
                        fluid
                      />
                    </Card.Body>
                  </Card>
                </div>
              </div>
            </Tab>
            {/*  */}
            <Tab eventKey="content" title="Content">
              <div style={{ marginTop: "10px" }}>
                <table width="100%">
                  <tbody>
                    <tr>
                      <td align="center" colSpan="2">
                        <h4>Contents</h4>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2">1. Equipment check</td>
                    </tr>
                    <tr>
                      <td width="50"></td>
                      <td>1.1 Installation check list</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>1.2 Alarm Test Report</td>
                    </tr>
                    <tr>
                      <td colSpan="2">2. Photo</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>2.1 Physical Photo</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>2.2 Logical Test Photo</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>2.3 Photo Master 1 After - Before</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>2.4 Photo Master 1 After - Before</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>2.5 Photo Slave 2 After - Before</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>2.6 Photo Slave 3 After - Before</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>2.7 Photo Master - Slave Install Completed</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>2.8 Photo Key</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Tab>
            {/*  */}
            <Tab eventKey="1.1" title="1.1">
              <div style={{ padding: "5px" }}>
                <div style={{ textAlign: "center", margin: "10px" }}>
                  <h4>Installation check list</h4>
                </div>
                <div style={{ textAlign: "center" }}>
                  <h5>Physical Check</h5>
                </div>
                <Table striped bordered hover size="sm">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Detail</th>
                      <th>Result</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>
                        ติดตั้ง Master Control ที่บริเวณข้าง Rack
                        และไม่กีดขวางจุดยึดอุปกรณ์อื่น
                      </td>
                      <td width="100px">
                        {this.state.data !== null
                          ? this.state.data.content.p111
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>
                        ติตตั้ง Motion Sensor ที่คานใต้ตู้ Rack , Wiring
                        สายต่อเข้ากล่องและติด Label{" "}
                      </td>
                      <td width="100">
                        {this.state.data !== null
                          ? this.state.data.content.p112
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>
                        Wiring LAN Cable เสียบเข้า IPRAN เช็ค Port Up
                        พร้อมใช้งานและติด Label
                      </td>
                      <td width="100">
                        {this.state.data !== null
                          ? this.state.data.content.p113
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>
                        ติตตั้ง Siren ที่ตำแหน่งข้าง Rack , Wiring
                        สายต่อเข้ากล่องและติด Label{" "}
                      </td>
                      <td width="100">
                        {this.state.data !== null
                          ? this.state.data.content.p114
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>
                        รูปการใช้ JIG ในการติตตั้ง Door lock ตู้ Master , Wiring
                        สายต่อเข้ากล่องและติด Label{" "}
                      </td>
                      <td width="100">
                        {this.state.data !== null
                          ? this.state.data.content.p115
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>
                        รูปติดตั้ง Electric Door Lock ที่ขอบประตูตู้ Master
                      </td>
                      <td width="100">
                        {this.state.data !== null
                          ? this.state.data.content.p116
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <td>7</td>
                      <td>รูปติดตั้งหูช้างที่บานประตู Master</td>
                      <td width="100">
                        {this.state.data !== null
                          ? this.state.data.content.p117
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <td>8</td>
                      <td>
                        ติดตั้ง DC Power Cable จาก Isolate Batt Wiring
                        มาที่กล่อง Master และติด Label
                      </td>
                      <td width="100">
                        {this.state.data !== null
                          ? this.state.data.content.p118
                          : ""}
                      </td>
                    </tr>
                    {/* <tr>
                                            <td>9</td>
                                            <td>รูปถ่ายประแจ Torgue ที่ขั้วแบตเตอรี่  (ค่า Torgue = ............Nm )</td>
                                            <td width="100">
                                                {(this.state.data !== null) ? this.state.data.content.p119 : ''}
                                            </td>
                                        </tr> */}
                    <tr>
                      <td>9</td>
                      <td>
                        รูปถ่ายจุดเชื่อมต่อสาย DC Power ที่ CB Batt Isolate และ
                        On Breaker Master
                      </td>
                      <td width="100">
                        {this.state.data !== null
                          ? this.state.data.content.p1110
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <td>10</td>
                      <td>รูปถ่ายตำแหน่งติดตั้งกล้อง USB</td>
                      <td width="100">
                        {this.state.data !== null
                          ? this.state.data.content.p1111
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <td>11</td>
                      <td>
                        รูปแสดงจุด Inlet ภายนอกตู้ RS485 Cable (ช่องร้อยสาย
                        RS485 อุดด้วย PU) Master
                      </td>
                      <td width="100">
                        {this.state.data !== null
                          ? this.state.data.content.p1112
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <td>12</td>
                      <td>
                        ติดตั้ง Slave Control ที่บริเวณข้าง Rack
                        และไม่กีดขวางจุดยึดอุปกรณ์อื่น
                      </td>
                      <td width="100">
                        {this.state.data !== null
                          ? this.state.data.content.p1113
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <td>13</td>
                      <td>
                        รูปการใช้ JIG ในการติตตั้ง Door lock ตู้ Slave , Wiring
                        สายต่อเข้ากล่องและติด Label{" "}
                      </td>
                      <td width="100">
                        {this.state.data !== null
                          ? this.state.data.content.p1114
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <td>14</td>
                      <td>
                        รูปติดตั้ง Electric Door Lock ที่ขอบประตูตู้ Slave
                      </td>
                      <td width="100">
                        {this.state.data !== null
                          ? this.state.data.content.p1115
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <td>15</td>
                      <td>รูปติดตั้งหูช้างที่บานประตู Slave</td>
                      <td width="100">
                        {this.state.data !== null
                          ? this.state.data.content.p1116
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <td>16</td>
                      <td>
                        ติดตั้ง DC Power Cable จาก Isolate Batt Wiring
                        มาที่กล่อง Slave และติด Label
                      </td>
                      <td width="100">
                        {this.state.data !== null
                          ? this.state.data.content.p1117
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <td>17</td>
                      <td>
                        รูปถ่ายจุดเชื่อมต่อสาย DC Power ที่ CB Batt Isolate และ
                        On Breaker Slave
                      </td>
                      <td width="100">
                        {this.state.data !== null
                          ? this.state.data.content.p1118
                          : ""}
                      </td>
                    </tr>
                    {/* <tr>
                                            <td>19</td>
                                            <td>รูปถ่ายประแจ Torgue ที่ขั้วแบตเตอรี่  (ค่า Torgue =    5  Nm? )</td>
                                            <td width="100">
                                                {(this.state.data !== null) ? this.state.data.content.p1119 : ''}
                                            </td>
                                        </tr> */}
                    <tr>
                      <td>18</td>
                      <td>
                        รูปแสดงจุด Inlet ภายนอกตู้ RS485 Cable (ช่องร้อยสาย
                        RS485 อุดด้วย PU) Slave
                      </td>
                      <td width="100">
                        {this.state.data !== null
                          ? this.state.data.content.p1120
                          : ""}
                      </td>
                    </tr>
                  </tbody>
                </Table>
                <div style={{ textAlign: "center" }}>
                  <h5>Logical Test</h5>
                </div>
                <Table striped bordered hover size="sm">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Detail</th>
                      <th>Result</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>รูป Capture จาก App Smart Site Setup (Local Test)</td>
                      <td width="100px"></td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>IP Address</td>
                      <td>
                        {this.state.data !== null
                          ? this.state.data.content.p11l11
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>การเชื่อมต่อ Sensor</td>
                      <td>
                        {this.state.data !== null
                          ? this.state.data.content.p11l12
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>
                        Connection ping to server 10.242.15.21 , 10.242.15.22
                      </td>
                      <td>
                        {this.state.data !== null
                          ? this.state.data.content.p11l13
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>
                        รูปถ่ายจากกล้อง Smart Site
                        สามารถมองเห็นภาพของคนที่เข้าทำงานได้ชัดเจน
                      </td>
                      <td>
                        {this.state.data !== null
                          ? this.state.data.content.p11l14
                          : ""}
                      </td>
                    </tr>
                    {/*  */}
                    <tr>
                      <td>2</td>
                      <td>รูป Capture จาก TTSM App</td>
                      <td width="100px"></td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>Normal Case</td>
                      <td>
                        {this.state.data !== null
                          ? this.state.data.content.p11l21
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>Lock</td>
                      <td>
                        {this.state.data !== null
                          ? this.state.data.content.p11l22
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>Unlock</td>
                      <td>
                        {this.state.data !== null
                          ? this.state.data.content.p11l23
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>ผล Alarm Test</td>
                      <td>
                        {this.state.data !== null
                          ? this.state.data.content.p11l24
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>Emergency Case</td>
                      <td>
                        {this.state.data !== null
                          ? this.state.data.content.p11l25
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>รูป Pair Bluetooth สำเร็จ</td>
                      <td>
                        {this.state.data !== null
                          ? this.state.data.content.p11l26
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>Lock</td>
                      <td>
                        {this.state.data !== null
                          ? this.state.data.content.p11l27
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>Unlock</td>
                      <td>
                        {this.state.data !== null
                          ? this.state.data.content.p11l28
                          : ""}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Tab>
            {/*  */}
            <Tab eventKey="1.2" title="1.2">
              <div style={{ padding: "5px" }}>
                <div style={{ textAlign: "center", margin: "10px" }}>
                  <h4>Alarm Test Report</h4>
                </div>
                <Table striped bordered hover size="sm">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Detail</th>
                      <th>Result</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>Smart Lock Failed</td>
                      <td>
                        {this.state.data !== null
                          ? this.state.data.content.p121
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>Camera Failed</td>
                      <td width="100">
                        {this.state.data !== null
                          ? this.state.data.content.p122
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>Motion Sensor Failed</td>
                      <td width="100">
                        {this.state.data !== null
                          ? this.state.data.content.p123
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>Slave Down</td>
                      <td width="100">
                        {this.state.data !== null
                          ? this.state.data.content.p124
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>Unauthorized Open</td>
                      <td width="100">
                        {this.state.data !== null
                          ? this.state.data.content.p125
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>Vibration Detected</td>
                      <td width="100">
                        {this.state.data !== null
                          ? this.state.data.content.p126
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <td>7</td>
                      <td>Communication Lost</td>
                      <td width="100">
                        {this.state.data !== null
                          ? this.state.data.content.p127
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <td>8</td>
                      <td>Motion Detected</td>
                      <td width="100">
                        {this.state.data !== null
                          ? this.state.data.content.p128
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <td>9</td>
                      <td>Test AUX Input Port1</td>
                      <td width="100">
                        {this.state.data !== null
                          ? this.state.data.content.p129
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <td>10</td>
                      <td>Test AUX Input Port2</td>
                      <td width="100">
                        {this.state.data !== null
                          ? this.state.data.content.p1210
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <td>11</td>
                      <td>Test AUX Input Port3</td>
                      <td width="100">
                        {this.state.data !== null
                          ? this.state.data.content.p1211
                          : ""}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Tab>
            {/*  */}
            <Tab eventKey="2.1" title="2.1">
              <div style={{ textAlign: "center", margin: "10px" }}>
                <h4>Physical Photo</h4>
              </div>
              <div style={{ marginTop: "10px" }}>
                <Card border="primary">
                  <Card.Header>รูปใช้ JIG ในการเจาะตู้ Master 1</Card.Header>
                  <Card.Body>
                    <Image
                      src={
                        this.state.data !== null &&
                        this.state.data.content.p211 !== ""
                          ? Config.service.url +
                            "/ssr/image/" +
                            this.state.data.content.p211
                          : "/images/na-horizontal.jpg"
                      }
                      style={{ width: "100%" }}
                      rounded
                      fluid
                    />
                  </Card.Body>
                </Card>
              </div>
              <div style={{ marginTop: "10px" }}>
                <Card border="primary">
                  <Card.Header>รูปใช้ JIG ในการเจาะตู้ Slave 2</Card.Header>
                  <Card.Body>
                    <Image
                      src={
                        this.state.data !== null &&
                        this.state.data.content.p212 !== ""
                          ? Config.service.url +
                            "/ssr/image/" +
                            this.state.data.content.p212
                          : "/images/na-horizontal.jpg"
                      }
                      style={{ width: "100%" }}
                      rounded
                      fluid
                    />
                  </Card.Body>
                </Card>
              </div>
              <div style={{ marginTop: "10px" }}>
                <Card border="primary">
                  <Card.Header>รูปใช้ JIG ในการเจาะตู้ Slave 3</Card.Header>
                  <Card.Body>
                    <Image
                      src={getValue(
                        this.state.data?.content.p219,
                        "/images/na-horizontal.jpg",
                        Config.service.url + "/ssr/image/"
                      )}
                      style={{ width: "100%" }}
                      rounded
                      fluid
                    />
                  </Card.Body>
                </Card>
              </div>
              <div style={{ marginTop: "10px" }}>
                <Card border="primary">
                  <Card.Header>รูป On CB Battery Isolate Master 1</Card.Header>
                  <Card.Body>
                    <Image
                      src={
                        this.state.data !== null &&
                        this.state.data.content.p215 !== ""
                          ? Config.service.url +
                            "/ssr/image/" +
                            this.state.data.content.p215
                          : "/images/na-horizontal.jpg"
                      }
                      style={{ width: "100%" }}
                      rounded
                      fluid
                    />
                  </Card.Body>
                </Card>
              </div>
              <div style={{ marginTop: "10px" }}>
                <Card border="primary">
                  <Card.Header>รูป On CB Battery Isolate Slave 2</Card.Header>
                  <Card.Body>
                    <Image
                      src={
                        this.state.data !== null &&
                        this.state.data.content.p216 !== ""
                          ? Config.service.url +
                            "/ssr/image/" +
                            this.state.data.content.p216
                          : "/images/na-horizontal.jpg"
                      }
                      style={{ width: "100%" }}
                      rounded
                      fluid
                    />
                  </Card.Body>
                </Card>
              </div>
              <div style={{ marginTop: "10px" }}>
                <Card border="primary">
                  <Card.Header>รูป On CB Battery Isolate Slave 3</Card.Header>
                  <Card.Body>
                    <Image
                      src={getValue(
                        this.state.data?.content.p2110,
                        "/images/na-horizontal.jpg",
                        Config.service.url + "/ssr/image/"
                      )}
                      style={{ width: "100%" }}
                      rounded
                      fluid
                    />
                  </Card.Body>
                </Card>
              </div>
              <div style={{ marginTop: "10px" }}>
                <Card border="primary">
                  <Card.Header>รูป Cable Inlet อุด PU Master 1</Card.Header>
                  <Card.Body>
                    <Image
                      src={
                        this.state.data !== null &&
                        this.state.data.content.p217 !== ""
                          ? Config.service.url +
                            "/ssr/image/" +
                            this.state.data.content.p217
                          : "/images/na-horizontal.jpg"
                      }
                      style={{ width: "100%" }}
                      rounded
                      fluid
                    />
                  </Card.Body>
                </Card>
              </div>
              <div style={{ marginTop: "10px" }}>
                <Card border="primary">
                  <Card.Header>รูป Cable Inlet อุด ซิลิโคน Slave 2</Card.Header>
                  <Card.Body>
                    <Image
                      src={
                        this.state.data !== null &&
                        this.state.data.content.p218 !== ""
                          ? Config.service.url +
                            "/ssr/image/" +
                            this.state.data.content.p218
                          : "/images/na-horizontal.jpg"
                      }
                      style={{ width: "100%" }}
                      rounded
                      fluid
                    />
                  </Card.Body>
                </Card>
              </div>
              <div style={{ marginTop: "10px" }}>
                <Card border="primary">
                  <Card.Header>รูป Cable Inlet อุด ซิลิโคน Slave 3</Card.Header>
                  <Card.Body>
                    <Image
                      src={getValue(
                        this.state.data?.content.p2111,
                        "/images/na-horizontal.jpg",
                        Config.service.url + "/ssr/image/"
                      )}
                      style={{ width: "100%" }}
                      rounded
                      fluid
                    />
                  </Card.Body>
                </Card>
              </div>
            </Tab>
            {/*  */}
            <Tab eventKey="2.2" title="2.2">
              <div style={{ textAlign: "center", margin: "10px" }}>
                <h4>Logical Test Photo</h4>
              </div>
              <div style={{ marginTop: "10px" }}>
                <Card border="primary">
                  <Card.Header>รูปการตั้งค่า IP Address</Card.Header>
                  <Card.Body>
                    <Image
                      src={getValue(
                        this.state.data?.content.p221,
                        "/images/na-vertical.jpg",
                        Config.service.url + "/ssr/image/"
                      )}
                      style={{ width: "100%" }}
                      rounded
                      fluid
                    />
                  </Card.Body>
                </Card>
              </div>
              <div style={{ marginTop: "10px" }}>
                <Card border="primary">
                  <Card.Header>รูปถ่ายจากกล้อง Smart Site</Card.Header>
                  <Card.Body>
                    <Image
                      src={getValue(
                        this.state.data?.content.p222,
                        "/images/na-vertical.jpg",
                        Config.service.url + "/ssr/image/"
                      )}
                      style={{ width: "100%" }}
                      rounded
                      fluid
                    />
                  </Card.Body>
                </Card>
              </div>
              <div style={{ marginTop: "10px" }}>
                <Card border="primary">
                  <Card.Header>รูป Normal Case : Close</Card.Header>
                  <Card.Body>
                    <Image
                      src={getValue(
                        this.state.data?.content.p225,
                        "/images/na-vertical.jpg",
                        Config.service.url + "/ssr/image/"
                      )}
                      style={{ width: "100%" }}
                      rounded
                      fluid
                    />
                  </Card.Body>
                </Card>
              </div>
              <div style={{ marginTop: "10px" }}>
                <Card border="primary">
                  <Card.Header>รูป Normal Case : Open</Card.Header>
                  <Card.Body>
                    <Image
                      src={getValue(
                        this.state.data?.content.p226,
                        "/images/na-vertical.jpg",
                        Config.service.url + "/ssr/image/"
                      )}
                      style={{ width: "100%" }}
                      rounded
                      fluid
                    />
                  </Card.Body>
                </Card>
              </div>
              <div style={{ marginTop: "10px" }}>
                <Card border="primary">
                  <Card.Header>
                    รูป Emergency Case (เชื่อมต่อ blutooth)
                  </Card.Header>
                  <Card.Body>
                    <Image
                      src={getValue(
                        this.state.data?.content.p223,
                        "/images/na-vertical.jpg",
                        Config.service.url + "/ssr/image/"
                      )}
                      style={{ width: "100%" }}
                      rounded
                      fluid
                    />
                  </Card.Body>
                </Card>
              </div>
              <div style={{ marginTop: "10px" }}>
                <Card border="primary">
                  <Card.Header>รูป Emer Case : Close</Card.Header>
                  <Card.Body>
                    <Image
                      src={getValue(
                        this.state.data?.content.p228,
                        "/images/na-vertical.jpg",
                        Config.service.url + "/ssr/image/"
                      )}
                      style={{ width: "100%" }}
                      rounded
                      fluid
                    />
                  </Card.Body>
                </Card>
              </div>
              <div style={{ marginTop: "10px" }}>
                <Card border="primary">
                  <Card.Header>รูป Emer Case : Open</Card.Header>
                  <Card.Body>
                    <Image
                      src={getValue(
                        this.state.data?.content.p229,
                        "/images/na-vertical.jpg",
                        Config.service.url + "/ssr/image/"
                      )}
                      style={{ width: "100%" }}
                      rounded
                      fluid
                    />
                  </Card.Body>
                </Card>
              </div>
              <div style={{ marginTop: "10px" }}>
                <Card border="primary">
                  <Card.Header>รูปกลอนล็อคตู้ Master 1</Card.Header>
                  <Card.Body>
                    <Image
                      src={getValue(
                        this.state.data?.content.p2210,
                        "/images/na-vertical.jpg",
                        Config.service.url + "/ssr/image/"
                      )}
                      style={{ width: "100%" }}
                      rounded
                      fluid
                    />
                  </Card.Body>
                </Card>
              </div>
              <div style={{ marginTop: "10px" }}>
                <Card border="primary">
                  <Card.Header>รูปกลอนล็อคตู้ Slave 2</Card.Header>
                  <Card.Body>
                    <Image
                      src={getValue(
                        this.state.data?.content.p2211,
                        "/images/na-vertical.jpg",
                        Config.service.url + "/ssr/image/"
                      )}
                      style={{ width: "100%" }}
                      rounded
                      fluid
                    />
                  </Card.Body>
                </Card>
              </div>
              <div style={{ marginTop: "10px" }}>
                <Card border="primary">
                  <Card.Header>รูปกลอนล็อคตู้ Slave 3</Card.Header>
                  <Card.Body>
                    <Image
                      src={getValue(
                        this.state.data?.content.p2212,
                        "/images/na-vertical.jpg",
                        Config.service.url + "/ssr/image/"
                      )}
                      style={{ width: "100%" }}
                      rounded
                      fluid
                    />
                  </Card.Body>
                </Card>
              </div>
            </Tab>
            {/*  */}
            <Tab eventKey="2.3" title="2.3">
              <div style={{ textAlign: "center", margin: "10px" }}>
                <h4>Photo Master 1 After - Before</h4>
              </div>
              <div style={{ marginTop: "10px" }}>
                <Card border="primary">
                  <Card.Header>
                    Before : จุดติดตั้ง Master Controller
                  </Card.Header>
                  <Card.Body>
                    <Image
                      src={
                        this.state.data !== null &&
                        this.state.data.content.p231 !== ""
                          ? Config.service.url +
                            "/ssr/image/" +
                            this.state.data.content.p231
                          : "/images/na-horizontal.jpg"
                      }
                      style={{ width: "100%" }}
                      rounded
                      fluid
                    />
                  </Card.Body>
                </Card>
              </div>
              <div style={{ marginTop: "10px" }}>
                <Card border="success">
                  <Card.Header>After : ตำแหน่ง Master Controller</Card.Header>
                  <Card.Body>
                    <Image
                      src={
                        this.state.data !== null &&
                        this.state.data.content.p232 !== ""
                          ? Config.service.url +
                            "/ssr/image/" +
                            this.state.data.content.p232
                          : "/images/na-horizontal.jpg"
                      }
                      style={{ width: "100%" }}
                      rounded
                      fluid
                    />
                  </Card.Body>
                </Card>
              </div>
              <div style={{ marginTop: "10px" }}>
                <Card border="primary">
                  <Card.Header>Before : จุดติดตั้ง Motion Sensor</Card.Header>
                  <Card.Body>
                    <Image
                      src={
                        this.state.data !== null &&
                        this.state.data.content.p233 !== ""
                          ? Config.service.url +
                            "/ssr/image/" +
                            this.state.data.content.p233
                          : "/images/na-horizontal.jpg"
                      }
                      style={{ width: "100%" }}
                      rounded
                      fluid
                    />
                  </Card.Body>
                </Card>
              </div>
              <div style={{ marginTop: "10px" }}>
                <Card border="success">
                  <Card.Header>After : จุดติดตั้ง Motion Sensor</Card.Header>
                  <Card.Body>
                    <Image
                      src={
                        this.state.data !== null &&
                        this.state.data.content.p234 !== ""
                          ? Config.service.url +
                            "/ssr/image/" +
                            this.state.data.content.p234
                          : "/images/na-horizontal.jpg"
                      }
                      style={{ width: "100%" }}
                      rounded
                      fluid
                    />
                  </Card.Body>
                </Card>
              </div>
              <div style={{ marginTop: "10px" }}>
                <Card border="primary">
                  <Card.Header>Before : ตำแหน่ง IPRAN</Card.Header>
                  <Card.Body>
                    <Image
                      src={
                        this.state.data !== null &&
                        this.state.data.content.p235 !== ""
                          ? Config.service.url +
                            "/ssr/image/" +
                            this.state.data.content.p235
                          : "/images/na-horizontal.jpg"
                      }
                      style={{ width: "100%" }}
                      rounded
                      fluid
                    />
                  </Card.Body>
                </Card>
              </div>
              <div style={{ marginTop: "10px" }}>
                <Card border="success">
                  <Card.Header>After : ตำแหน่ง IPRAN</Card.Header>
                  <Card.Body>
                    <Image
                      src={
                        this.state.data !== null &&
                        this.state.data.content.p236 !== ""
                          ? Config.service.url +
                            "/ssr/image/" +
                            this.state.data.content.p236
                          : "/images/na-horizontal.jpg"
                      }
                      style={{ width: "100%" }}
                      rounded
                      fluid
                    />
                  </Card.Body>
                </Card>
              </div>
              <div style={{ marginTop: "10px" }}>
                <Card border="primary">
                  <Card.Header>Before : จุดติดตั้ง Siren</Card.Header>
                  <Card.Body>
                    <Image
                      src={
                        this.state.data !== null &&
                        this.state.data.content.p237 !== ""
                          ? Config.service.url +
                            "/ssr/image/" +
                            this.state.data.content.p237
                          : "/images/na-horizontal.jpg"
                      }
                      style={{ width: "100%" }}
                      rounded
                      fluid
                    />
                  </Card.Body>
                </Card>
              </div>
              <div style={{ marginTop: "10px" }}>
                <Card border="success">
                  <Card.Header>After : จุดติดตั้ง Siren</Card.Header>
                  <Card.Body>
                    <Image
                      src={
                        this.state.data !== null &&
                        this.state.data.content.p238 !== ""
                          ? Config.service.url +
                            "/ssr/image/" +
                            this.state.data.content.p238
                          : "/images/na-horizontal.jpg"
                      }
                      style={{ width: "100%" }}
                      rounded
                      fluid
                    />
                  </Card.Body>
                </Card>
              </div>
            </Tab>
            {/*  */}
            <Tab eventKey="2.4" title="2.4">
              <div style={{ textAlign: "center", margin: "10px" }}>
                <h4>Photo Master 1 After - Before</h4>
              </div>
              <div style={{ marginTop: "10px" }}>
                <Card border="primary">
                  <Card.Header>
                    Before : ตำแหน่ง Master Electric Lock
                  </Card.Header>
                  <Card.Body>
                    <Image
                      src={
                        this.state.data !== null &&
                        this.state.data.content.p241 !== ""
                          ? Config.service.url +
                            "/ssr/image/" +
                            this.state.data.content.p241
                          : "/images/na-horizontal.jpg"
                      }
                      style={{ width: "100%" }}
                      rounded
                      fluid
                    />
                  </Card.Body>
                </Card>
              </div>
              <div style={{ marginTop: "10px" }}>
                <Card border="success">
                  <Card.Header>
                    After : ตำแหน่ง Master Electric Lock
                  </Card.Header>
                  <Card.Body>
                    <Image
                      src={
                        this.state.data !== null &&
                        this.state.data.content.p242 !== ""
                          ? Config.service.url +
                            "/ssr/image/" +
                            this.state.data.content.p242
                          : "/images/na-horizontal.jpg"
                      }
                      style={{ width: "100%" }}
                      rounded
                      fluid
                    />
                  </Card.Body>
                </Card>
              </div>
              <div style={{ marginTop: "10px" }}>
                <Card border="primary">
                  <Card.Header>Before : ตำแหน่งติดตั้งหูช้าง</Card.Header>
                  <Card.Body>
                    <Image
                      src={
                        this.state.data !== null &&
                        this.state.data.content.p243 !== ""
                          ? Config.service.url +
                            "/ssr/image/" +
                            this.state.data.content.p243
                          : "/images/na-horizontal.jpg"
                      }
                      style={{ width: "100%" }}
                      rounded
                      fluid
                    />
                  </Card.Body>
                </Card>
              </div>
              <div style={{ marginTop: "10px" }}>
                <Card border="success">
                  <Card.Header>After : ตำแหน่งติดตั้งหูช้าง</Card.Header>
                  <Card.Body>
                    <Image
                      src={
                        this.state.data !== null &&
                        this.state.data.content.p244 !== ""
                          ? Config.service.url +
                            "/ssr/image/" +
                            this.state.data.content.p244
                          : "/images/na-horizontal.jpg"
                      }
                      style={{ width: "100%" }}
                      rounded
                      fluid
                    />
                  </Card.Body>
                </Card>
              </div>
              <div style={{ marginTop: "10px" }}>
                <Card border="primary">
                  <Card.Header>Before : DC Power</Card.Header>
                  <Card.Body>
                    <Image
                      src={
                        this.state.data !== null &&
                        this.state.data.content.p245 !== ""
                          ? Config.service.url +
                            "/ssr/image/" +
                            this.state.data.content.p245
                          : "/images/na-horizontal.jpg"
                      }
                      style={{ width: "100%" }}
                      rounded
                      fluid
                    />
                  </Card.Body>
                </Card>
              </div>
              <div style={{ marginTop: "10px" }}>
                <Card border="success">
                  <Card.Header>After : DC Power</Card.Header>
                  <Card.Body>
                    <Image
                      src={
                        this.state.data !== null &&
                        this.state.data.content.p246 !== ""
                          ? Config.service.url +
                            "/ssr/image/" +
                            this.state.data.content.p246
                          : "/images/na-horizontal.jpg"
                      }
                      style={{ width: "100%" }}
                      rounded
                      fluid
                    />
                  </Card.Body>
                </Card>
              </div>
              <div style={{ marginTop: "10px" }}>
                <Card border="primary">
                  <Card.Header>Before : USB Camera</Card.Header>
                  <Card.Body>
                    <Image
                      src={
                        this.state.data !== null &&
                        this.state.data.content.p247 !== ""
                          ? Config.service.url +
                            "/ssr/image/" +
                            this.state.data.content.p247
                          : "/images/na-horizontal.jpg"
                      }
                      style={{ width: "100%" }}
                      rounded
                      fluid
                    />
                  </Card.Body>
                </Card>
              </div>
              <div style={{ marginTop: "10px" }}>
                <Card border="success">
                  <Card.Header>After : USB Camera</Card.Header>
                  <Card.Body>
                    <Image
                      src={
                        this.state.data !== null &&
                        this.state.data.content.p248 !== ""
                          ? Config.service.url +
                            "/ssr/image/" +
                            this.state.data.content.p248
                          : "/images/na-horizontal.jpg"
                      }
                      style={{ width: "100%" }}
                      rounded
                      fluid
                    />
                  </Card.Body>
                </Card>
              </div>
            </Tab>
            {/*  */}
            <Tab eventKey="2.5" title="2.5">
              <div style={{ textAlign: "center", margin: "10px" }}>
                <h4>Photo Slave 2 After - Before</h4>
              </div>
              <div style={{ marginTop: "10px" }}>
                <Card border="primary">
                  <Card.Header>Before : ตำแหน่ง Slave Controller</Card.Header>
                  <Card.Body>
                    <Image
                      src={
                        this.state.data !== null &&
                        this.state.data.content.p251 !== ""
                          ? Config.service.url +
                            "/ssr/image/" +
                            this.state.data.content.p251
                          : "/images/na-horizontal.jpg"
                      }
                      style={{ width: "100%" }}
                      rounded
                      fluid
                    />
                  </Card.Body>
                </Card>
              </div>
              <div style={{ marginTop: "10px" }}>
                <Card border="success">
                  <Card.Header>After : ตำแหน่ง Slave Controller</Card.Header>
                  <Card.Body>
                    <Image
                      src={
                        this.state.data !== null &&
                        this.state.data.content.p252 !== ""
                          ? Config.service.url +
                            "/ssr/image/" +
                            this.state.data.content.p252
                          : "/images/na-horizontal.jpg"
                      }
                      style={{ width: "100%" }}
                      rounded
                      fluid
                    />
                  </Card.Body>
                </Card>
              </div>
              <div style={{ marginTop: "10px" }}>
                <Card border="primary">
                  <Card.Header>Before : ตำแหน่ง Slave Door Lock</Card.Header>
                  <Card.Body>
                    <Image
                      src={
                        this.state.data !== null &&
                        this.state.data.content.p253 !== ""
                          ? Config.service.url +
                            "/ssr/image/" +
                            this.state.data.content.p253
                          : "/images/na-horizontal.jpg"
                      }
                      style={{ width: "100%" }}
                      rounded
                      fluid
                    />
                  </Card.Body>
                </Card>
              </div>
              <div style={{ marginTop: "10px" }}>
                <Card border="success">
                  <Card.Header>After : ตำแหน่ง Slave Door Lock</Card.Header>
                  <Card.Body>
                    <Image
                      src={
                        this.state.data !== null &&
                        this.state.data.content.p254 !== ""
                          ? Config.service.url +
                            "/ssr/image/" +
                            this.state.data.content.p254
                          : "/images/na-horizontal.jpg"
                      }
                      style={{ width: "100%" }}
                      rounded
                      fluid
                    />
                  </Card.Body>
                </Card>
              </div>
              <div style={{ marginTop: "10px" }}>
                <Card border="primary">
                  <Card.Header>Before : ตำแหน่งติดตั้งหูช้าง</Card.Header>
                  <Card.Body>
                    <Image
                      src={
                        this.state.data !== null &&
                        this.state.data.content.p255 !== ""
                          ? Config.service.url +
                            "/ssr/image/" +
                            this.state.data.content.p255
                          : "/images/na-horizontal.jpg"
                      }
                      style={{ width: "100%" }}
                      rounded
                      fluid
                    />
                  </Card.Body>
                </Card>
              </div>
              <div style={{ marginTop: "10px" }}>
                <Card border="success">
                  <Card.Header>After : ตำแหน่งติดตั้งหูช้าง</Card.Header>
                  <Card.Body>
                    <Image
                      src={
                        this.state.data !== null &&
                        this.state.data.content.p256 !== ""
                          ? Config.service.url +
                            "/ssr/image/" +
                            this.state.data.content.p256
                          : "/images/na-horizontal.jpg"
                      }
                      style={{ width: "100%" }}
                      rounded
                      fluid
                    />
                  </Card.Body>
                </Card>
              </div>
              <div style={{ marginTop: "10px" }}>
                <Card border="primary">
                  <Card.Header>Before : DC Power</Card.Header>
                  <Card.Body>
                    <Image
                      src={
                        this.state.data !== null &&
                        this.state.data.content.p257 !== ""
                          ? Config.service.url +
                            "/ssr/image/" +
                            this.state.data.content.p257
                          : "/images/na-horizontal.jpg"
                      }
                      style={{ width: "100%" }}
                      rounded
                      fluid
                    />
                  </Card.Body>
                </Card>
              </div>
              <div style={{ marginTop: "10px" }}>
                <Card border="success">
                  <Card.Header>After : DC Power</Card.Header>
                  <Card.Body>
                    <Image
                      src={
                        this.state.data !== null &&
                        this.state.data.content.p258 !== ""
                          ? Config.service.url +
                            "/ssr/image/" +
                            this.state.data.content.p258
                          : "/images/na-horizontal.jpg"
                      }
                      style={{ width: "100%" }}
                      rounded
                      fluid
                    />
                  </Card.Body>
                </Card>
              </div>
            </Tab>
            {/*  */}
            <Tab eventKey="2.6" title="2.6">
              <div style={{ textAlign: "center", margin: "10px" }}>
                <h4>Photo Slave 3 After - Before</h4>
              </div>
              <div style={{ marginTop: "10px" }}>
                <Card border="success">
                  <Card.Header>After : ตำแหน่ง Slave Controller</Card.Header>
                  <Card.Body>
                    <Image
                      src={
                        this.state.data !== null &&
                        this.state.data.content.p262 !== ""
                          ? Config.service.url +
                            "/ssr/image/" +
                            this.state.data.content.p262
                          : "/images/na-horizontal.jpg"
                      }
                      style={{ width: "100%" }}
                      rounded
                      fluid
                    />
                  </Card.Body>
                </Card>
              </div>
              <div style={{ marginTop: "10px" }}>
                <Card border="primary">
                  <Card.Header>Before : ตำแหน่ง Slave Door Lock</Card.Header>
                  <Card.Body>
                    <Image
                      src={
                        this.state.data !== null &&
                        this.state.data.content.p263 !== ""
                          ? Config.service.url +
                            "/ssr/image/" +
                            this.state.data.content.p263
                          : "/images/na-horizontal.jpg"
                      }
                      style={{ width: "100%" }}
                      rounded
                      fluid
                    />
                  </Card.Body>
                </Card>
              </div>
              <div style={{ marginTop: "10px" }}>
                <Card border="success">
                  <Card.Header>After : ตำแหน่ง Slave Door Lock</Card.Header>
                  <Card.Body>
                    <Image
                      src={
                        this.state.data !== null &&
                        this.state.data.content.p264 !== ""
                          ? Config.service.url +
                            "/ssr/image/" +
                            this.state.data.content.p264
                          : "/images/na-horizontal.jpg"
                      }
                      style={{ width: "100%" }}
                      rounded
                      fluid
                    />
                  </Card.Body>
                </Card>
              </div>
              <div style={{ marginTop: "10px" }}>
                <Card border="primary">
                  <Card.Header>Before : ตำแหน่งติดตั้งหูช้าง</Card.Header>
                  <Card.Body>
                    <Image
                      src={
                        this.state.data !== null &&
                        this.state.data.content.p265 !== ""
                          ? Config.service.url +
                            "/ssr/image/" +
                            this.state.data.content.p265
                          : "/images/na-horizontal.jpg"
                      }
                      style={{ width: "100%" }}
                      rounded
                      fluid
                    />
                  </Card.Body>
                </Card>
              </div>
              <div style={{ marginTop: "10px" }}>
                <Card border="success">
                  <Card.Header>After : ตำแหน่งติดตั้งหูช้าง</Card.Header>
                  <Card.Body>
                    <Image
                      src={
                        this.state.data !== null &&
                        this.state.data.content.p266 !== ""
                          ? Config.service.url +
                            "/ssr/image/" +
                            this.state.data.content.p266
                          : "/images/na-horizontal.jpg"
                      }
                      style={{ width: "100%" }}
                      rounded
                      fluid
                    />
                  </Card.Body>
                </Card>
              </div>
              <div style={{ marginTop: "10px" }}>
                <Card border="primary">
                  <Card.Header>Before : DC Power</Card.Header>
                  <Card.Body>
                    <Image
                      src={
                        this.state.data !== null &&
                        this.state.data.content.p267 !== ""
                          ? Config.service.url +
                            "/ssr/image/" +
                            this.state.data.content.p267
                          : "/images/na-horizontal.jpg"
                      }
                      style={{ width: "100%" }}
                      rounded
                      fluid
                    />
                  </Card.Body>
                </Card>
              </div>
              <div style={{ marginTop: "10px" }}>
                <Card border="success">
                  <Card.Header>After : DC Power</Card.Header>
                  <Card.Body>
                    <Image
                      src={
                        this.state.data !== null &&
                        this.state.data.content.p268 !== ""
                          ? Config.service.url +
                            "/ssr/image/" +
                            this.state.data.content.p268
                          : "/images/na-horizontal.jpg"
                      }
                      style={{ width: "100%" }}
                      rounded
                      fluid
                    />
                  </Card.Body>
                </Card>
              </div>
            </Tab>
            {/*  */}
            <Tab eventKey="2.7" title="2.7">
              <div style={{ textAlign: "center", margin: "10px" }}>
                <h4>Photo Master - Slave Install Completed</h4>
              </div>
              <div style={{ marginTop: "10px" }}>
                <Card border="primary">
                  <Card.Header>Smart Site Config &amp; On service</Card.Header>
                  <Card.Body>
                    <Image
                      src={
                        this.state.data !== null &&
                        this.state.data.content.p271 !== ""
                          ? Config.service.url +
                            "/ssr/image/" +
                            this.state.data.content.p271
                          : "/images/na-horizontal.jpg"
                      }
                      style={{ width: "100%" }}
                      rounded
                      fluid
                    />
                  </Card.Body>
                </Card>
              </div>
              <div style={{ marginTop: "10px" }}>
                <Card border="success">
                  <Card.Header>Master 1 Installed</Card.Header>
                  <Card.Body>
                    <Image
                      src={
                        this.state.data !== null &&
                        this.state.data.content.p272 !== ""
                          ? Config.service.url +
                            "/ssr/image/" +
                            this.state.data.content.p272
                          : "/images/na-horizontal.jpg"
                      }
                      style={{ width: "100%" }}
                      rounded
                      fluid
                    />
                  </Card.Body>
                </Card>
              </div>
              <div style={{ marginTop: "10px" }}>
                <Card border="primary">
                  <Card.Header>Slave 2 Installed</Card.Header>
                  <Card.Body>
                    <Image
                      src={
                        this.state.data !== null &&
                        this.state.data.content.p273 !== ""
                          ? Config.service.url +
                            "/ssr/image/" +
                            this.state.data.content.p273
                          : "/images/na-horizontal.jpg"
                      }
                      style={{ width: "100%" }}
                      rounded
                      fluid
                    />
                  </Card.Body>
                </Card>
              </div>
              <div style={{ marginTop: "10px" }}>
                <Card border="primary">
                  <Card.Header>Slave 3 Installed</Card.Header>
                  <Card.Body>
                    <Image
                      src={
                        this.state.data !== null &&
                        this.state.data.content.p274 !== ""
                          ? Config.service.url +
                            "/ssr/image/" +
                            this.state.data.content.p274
                          : "/images/na-horizontal.jpg"
                      }
                      style={{ width: "100%" }}
                      rounded
                      fluid
                    />
                  </Card.Body>
                </Card>
              </div>
            </Tab>
            {/*  */}
            <Tab eventKey="2.8" title="2.8">
              <div style={{ textAlign: "center", margin: "10px" }}>
                <h4>Photo Key</h4>
              </div>
              <div style={{ marginTop: "10px" }}>
                <Card border="primary">
                  <Card.Header>Lock C1</Card.Header>
                  <Card.Body>
                    <div style={{ marginBottom: "10px" }}>
                      Lock C1 No. :{" "}
                      {this.state.data !== null
                        ? this.getP8Value(this.state.data.content.p281Text)
                        : ""}
                    </div>
                    <Image
                      src={
                        this.state.data !== null
                          ? this.getP8Image(this.state.data.content.p281)
                          : "/images/na-horizontal.jpg"
                      }
                      style={{ width: "100%" }}
                      rounded
                      fluid
                    />
                  </Card.Body>
                </Card>
              </div>
              <div style={{ marginTop: "10px" }}>
                <Card border="success">
                  <Card.Header>BATT C1</Card.Header>
                  <Card.Body>
                    <div style={{ marginBottom: "10px" }}>
                      BATT C1 No. :{" "}
                      {this.state.data !== null
                        ? this.getP8Value(this.state.data.content.p282Text)
                        : ""}
                    </div>
                    <Image
                      src={
                        this.state.data !== null
                          ? this.getP8Image(this.state.data.content.p282)
                          : "/images/na-horizontal.jpg"
                      }
                      style={{ width: "100%" }}
                      rounded
                      fluid
                    />
                  </Card.Body>
                </Card>
              </div>
              <div style={{ marginTop: "10px" }}>
                <Card border="primary">
                  <Card.Header>Lock C2</Card.Header>
                  <Card.Body>
                    <div style={{ marginBottom: "10px" }}>
                      Lock C2 No. :{" "}
                      {this.state.data !== null
                        ? this.getP8Value(this.state.data.content.p283Text)
                        : ""}
                    </div>
                    <Image
                      src={
                        this.state.data !== null
                          ? this.getP8Image(this.state.data.content.p283)
                          : "/images/na-horizontal.jpg"
                      }
                      style={{ width: "100%" }}
                      rounded
                      fluid
                    />
                  </Card.Body>
                </Card>
              </div>
              <div style={{ marginTop: "10px" }}>
                <Card border="primary">
                  <Card.Header>BATT C2</Card.Header>
                  <Card.Body>
                    <div style={{ marginBottom: "10px" }}>
                      BATT C2 No. :{" "}
                      {this.state.data !== null
                        ? this.getP8Value(this.state.data.content.p284Text)
                        : ""}
                    </div>
                    <Image
                      src={
                        this.state.data !== null
                          ? this.getP8Image(this.state.data.content.p284)
                          : "/images/na-horizontal.jpg"
                      }
                      style={{ width: "100%" }}
                      rounded
                      fluid
                    />
                  </Card.Body>
                </Card>
              </div>
              <div style={{ marginTop: "10px" }}>
                <Card border="primary">
                  <Card.Header>Lock C3</Card.Header>
                  <Card.Body>
                    <div style={{ marginBottom: "10px" }}>
                      Lock C3 No. :{" "}
                      {this.state.data !== null
                        ? this.getP8Value(this.state.data.content.p285Text)
                        : ""}
                    </div>
                    <Image
                      src={
                        this.state.data !== null
                          ? this.getP8Image(this.state.data.content.p285)
                          : "/images/na-horizontal.jpg"
                      }
                      style={{ width: "100%" }}
                      rounded
                      fluid
                    />
                  </Card.Body>
                </Card>
              </div>
              <div style={{ marginTop: "10px" }}>
                <Card border="primary">
                  <Card.Header>BATT C3</Card.Header>
                  <Card.Body>
                    <div style={{ marginBottom: "10px" }}>
                      BATT C3 No. :{" "}
                      {this.state.data !== null
                        ? this.getP8Value(this.state.data.content.p286Text)
                        : ""}
                    </div>
                    <Image
                      src={
                        this.state.data !== null
                          ? this.getP8Image(this.state.data.content.p286)
                          : "/images/na-horizontal.jpg"
                      }
                      style={{ width: "100%" }}
                      rounded
                      fluid
                    />
                  </Card.Body>
                </Card>
              </div>
              <div style={{ marginTop: "10px" }}>
                <Card border="primary">
                  <Card.Header>รั้ว</Card.Header>
                  <Card.Body>
                    <div style={{ marginBottom: "10px" }}>
                      รั้ว No. :{" "}
                      {this.state.data !== null
                        ? this.getP8Value(this.state.data.content.p287Text)
                        : ""}
                    </div>
                    <Image
                      src={
                        this.state.data !== null
                          ? this.getP8Image(this.state.data.content.p287)
                          : "/images/na-horizontal.jpg"
                      }
                      style={{ width: "100%" }}
                      rounded
                      fluid
                    />
                  </Card.Body>
                </Card>
              </div>
            </Tab>
          </Tabs>
        </div>
      </>
    );
  }
}

export default withRouter(PATTechnicianShow);

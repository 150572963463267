import React, { Component } from "react";
import { Form } from "react-bootstrap";
import { Image } from "react-bootstrap";
import Resizer from "react-image-file-resizer";

var Config = require("../../config/config.json");

class ImageUploadPreview extends Component {
  //
  constructor(props) {
    // Base contructor.
    super(props);
    // State
    this.state = {
      mode: this.props.mode,
      src: this.props.src,
      file: ""
    };
  }

  resizeFile = file =>
    new Promise(resolve => {
      if (this.state.mode === "v") {
        //Resizer.imageFileResizer(file, 306, 408, 'JPEG', 70, 0, uri => { resolve(uri) }, 'blob');
        Resizer.imageFileResizer(
          file,
          918,
          1224,
          "JPEG",
          100,
          0,
          uri => {
            resolve(uri);
          },
          "blob"
        );
      } else {
        //Resizer.imageFileResizer(file, 408, 306, 'JPEG', 70, 0, uri => { resolve(uri) }, 'blob');
        Resizer.imageFileResizer(
          file,
          918,
          1224,
          "JPEG",
          100,
          0,
          uri => {
            resolve(uri);
          },
          "blob"
        );
      }
    });

  //
  getState = () => {
    return this.state;
  };

  //
  refresh = (src, defaultSrc = "") => {
    if ((src === "" || src === undefined) && defaultSrc === "") {
      if (this.state.mode === "v") {
        this.setState({ src: "/images/na-vertical.jpg" });
      } else {
        this.setState({ src: "/images/na-horizontal.jpg" });
      }
    } else if ((src === "" || src === undefined) && defaultSrc !== "") {
      this.setState({ src: defaultSrc });
    } else {
      this.setState({ src: Config.service.url + "/ssr/image/" + src });
    }
  };

  //
  refresh2 = src => {
    if (src === undefined) {
      if (this.state.mode === "v") {
        this.setState({ src: "/images/na-vertical.jpg" });
      } else {
        this.setState({ src: "/images/na-horizontal.jpg" });
      }
    } else if (src.indexOf("images/patExample") !== -1) {
      this.setState({ src: src });
    } else {
      this.setState({ src: Config.service.url + "/ssr/image/" + src });
    }
  };

  //
  componentDidMount = () => {
    this.image = new window.Image();
    this.image.src = this.props.src;
    this.image.addEventListener("load", () => {
      this.setState({
        file: this.image
      });
    });
  };

  //
  componentWillUnmount() {
    this.image.removeEventListener("load", () => {
      this.setState({
        file: this.image
      });
    });
  }

  //
  onFileChange = async e => {
    // Check in case user doesn't input file.
    if (e.target.files[0] === undefined) return;
    // Get property.
    let fileName = e.target.files[0].name;
    let fileOriginal = e.target.files[0];
    let fileResize = null;
    // Resize.
    fileResize = new File(
      [await this.resizeFile(fileOriginal, "h")],
      fileName,
      fileOriginal
    );
    // Save state.
    this.setState({
      src: URL.createObjectURL(fileResize),
      file: fileResize
    });
  };

  //
  render = () => {
    return (
      <>
        <Form.Group>
          <Form.File
            label="Select an image"
            custom
            onChange={this.onFileChange}
          />
          <br />
          <Image
            src={this.state.src}
            style={{ width: "100%", marginTop: "5px" }}
          />
        </Form.Group>
      </>
    );
  };
}

export default ImageUploadPreview;

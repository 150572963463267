import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { Form } from "react-bootstrap";

import { getValue } from "../../helper/utilityHelper";

class RemarkBox extends Component {
  //
  constructor(props) {
    super(props);
    this.state = {
      remark: "",
      isShow: false,
      //
      callback: null
    };
  }
  //
  onShow = (remark, callback = null) => {
    this.setState({
      isShow: true,
      remark: remark,
      callback: callback
    });
  };

  //
  onHide = () => {
    this.setState({ isShow: false });
  };

  //
  onAction = () => {
    this.onHide();

    if (this.props.onAction) {
      this.props.onAction();
    } else if (this.state.callback) {
      this.state.callback();
    }
  };

  //
  onChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  //
  getState = () => {
    return this.state;
  };

  //
  render() {
    return (
      <>
        <Modal show={this.state.isShow} onHide={this.onHide}>
          <Modal.Header closeButton>
            <Modal.Title>Remark</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              <Form.Label>Message</Form.Label>
              <Form.Control
                as="textarea"
                name="remark"
                rows={10}
                defaultValue={getValue(this.state.remark, "", "")}
                onChange={this.onChange}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={this.onAction}>
              OK
            </Button>
            <Button variant="danger" onClick={this.onHide}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default RemarkBox;

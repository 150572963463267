import React, { Component } from "react";
import { Redirect, withRouter } from "react-router-dom";
import { Image } from "react-bootstrap";

import Axios from "axios";

import MessageBox from "../components/messagebox/messagebox";
import LoadingBox from "../components/messagebox/loadingbox";
import ConfirmBox from "../components/messagebox/confirmbox";

import MenuAdmin from "../components/menu/menuAdmin";
import MenuAdminSubcon from "../components/menu/menuAdminSubcon";
import MenuReviewer from "../components/menu/menuReviewer";
import MenuTechnician from "../components/menu/menuTechnician";

import auth from "../helper/authHelper";

var Config = require("../config/config.json");

class PATAdminReviewerShowPage27 extends Component {
  //
  constructor(props) {
    // Base contructor.
    super(props);
    // Ref.
    this.messageboxRef = React.createRef();
    this.loadingboxRef = React.createRef();
    this.confirmboxRef = React.createRef();
    this.designMaster1ImageRef = React.createRef();
    this.designSlaveImageRef = React.createRef();
    // State.
    this.state = {
      redirect: "",
      data: []
    };
  }

  //
  requestData = () => {
    if (this.loadingboxRef.current) this.loadingboxRef.current.onShow();
    let params = new URLSearchParams(window.location.search);
    let type = params.get("type");
    let month = params.get("month");
    let siteCode = params.get("siteCode");
    let region = params.get("region");
    let zone = params.get("zone");
    let contractor = params.get("contractor");
    let status = params.get("status");
    let endPoint = Config.service.url + "/pat/search";
    let body = {};

    if (type === "custom") {
      body = {
        month: month
      };

      if (siteCode !== "") {
        body = {
          ...body,
          siteCode: siteCode
        };
      }

      if (region !== "") {
        body = {
          ...body,
          region: region
        };
      }

      if (zone !== "") {
        body = {
          ...body,
          zone: zone
        };
      }

      if (contractor !== "-") {
        body = {
          ...body,
          contractor: contractor
        };
      }

      if (status !== "-") {
        body = {
          ...body,
          status: status
        };
      }
    }

    Axios.post(endPoint, body, {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    })
      .then(res => {
        if (res.data.success === false) {
          this.messageboxRef.current.onShow("Error", res.data.message);
          return;
        }

        console.log(res.data);

        if (res.data.data.data !== undefined) {
          this.setState({ data: res.data.data.data });
          document.title = "PAT-Doc";
          if (this.loadingboxRef.current) this.loadingboxRef.current.onHide();
        } else {
          this.messageboxRef.current.onShow(
            "Error",
            "PAT document does not create yet."
          );
        }
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        if (this.loadingboxRef.current) this.loadingboxRef.current.onHide();
      });
  };

  //
  componentDidMount() {
    if (localStorage.getItem("token") === null) {
      return;
    }
    this.requestData();
  }

  menu = () => {
    if (auth.allowAdmin()) {
      return <MenuAdmin />;
    } else if (auth.allowAdminSubcon()) {
      return <MenuAdminSubcon />;
    } else if (auth.allowReview()) {
      return <MenuReviewer />;
    } else {
      return <MenuTechnician />;
    }
  };

  //
  render() {
    if (localStorage.getItem("token") === null) {
      return <Redirect push to="/login" />;
    }

    if (this.state.redirect) {
      return <Redirect push to={this.state.redirect} />;
    }

    return (
      <div>
        {this.menu()}
        <MessageBox ref={this.messageboxRef} />
        <LoadingBox ref={this.loadingboxRef} />
        <ConfirmBox ref={this.confirmboxRef} />
        <div className="App-body">
          {this.state.data !== null
            ? this.state.data.map((data, index) => {
                return (
                  <div id={index}>
                    <div style={{ marginTop: "10px" }}>
                      <table width="100%" border="1">
                        <tbody>
                          <tr>
                            <td align="center">
                              <h4>
                                2.7 Photo Master - Slave Install Completed
                              </h4>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <span style={{ fontSize: "10px" }}>
                                Vender/Sub
                              </span>
                              <br />
                              {data.cover.surveyAndInstallation}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <table width="100%">
                                <tbody>
                                  <tr>
                                    <td width="33%">
                                      <span style={{ fontSize: "10px" }}>
                                        Doc response/Approved
                                      </span>
                                      <br />
                                      AWN
                                    </td>
                                    <td width="33%">
                                      <span style={{ fontSize: "10px" }}>
                                        Checked
                                      </span>
                                      <br />
                                      AWN
                                    </td>
                                    <td width="33%">
                                      <span style={{ fontSize: "10px" }}>
                                        File
                                      </span>
                                      <br />
                                      PAT Document Smart Site
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <table width="100%">
                                <tbody>
                                  <tr>
                                    <td width="33%">
                                      <span style={{ fontSize: "10px" }}>
                                        Project Name
                                      </span>
                                      <br />
                                      {data.cover.projectName}
                                    </td>
                                    <td width="33%">
                                      <span style={{ fontSize: "10px" }}>
                                        Site Name
                                      </span>
                                      <br />
                                      {data.cover.siteNameEn}
                                    </td>
                                    <td width="33%">
                                      <span style={{ fontSize: "10px" }}>
                                        Site Code
                                      </span>
                                      <br />
                                      {data.cover.siteCode}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                          <tr>
                            <td align="center">
                              <div style={{ marginTop: 5 }}>
                                <table>
                                  <tbody>
                                    <tr>
                                      <td align="center" colSpan="3">
                                        <Image
                                          src={
                                            data.content.p271 !== undefined &&
                                            data.content.p271 !== ""
                                              ? Config.service.url +
                                                "/ssr/image/" +
                                                data.content.p271
                                              : "/images/na-vertical.jpg"
                                          }
                                          style={{ width: 300 }}
                                          rounded
                                          fluid
                                        />
                                        <br />
                                        Smart Site Config &amp; On service
                                      </td>
                                    </tr>
                                    <tr>
                                      <td align="center">
                                        <table>
                                          <tbody>
                                            <tr>
                                              <td align="center" valign="top">
                                                <Image
                                                  src={
                                                    data.content.p272 !==
                                                      undefined &&
                                                    data.content.p272 !== ""
                                                      ? Config.service.url +
                                                        "/ssr/image/" +
                                                        data.content.p272
                                                      : "/images/na-vertical.jpg"
                                                  }
                                                  style={{ width: 300 }}
                                                  rounded
                                                  fluid
                                                />
                                              </td>
                                              <td align="center" valign="top">
                                                <Image
                                                  src={
                                                    data.content.p273 !==
                                                      undefined &&
                                                    data.content.p273 !== ""
                                                      ? Config.service.url +
                                                        "/ssr/image/" +
                                                        data.content.p273
                                                      : "/images/na-vertical.jpg"
                                                  }
                                                  style={{ width: 300 }}
                                                  rounded
                                                  fluid
                                                />
                                              </td>
                                              <td align="center" valign="top">
                                                <Image
                                                  src={
                                                    data.content.p274 !==
                                                      undefined &&
                                                    data.content.p274 !== ""
                                                      ? Config.service.url +
                                                        "/ssr/image/" +
                                                        data.content.p274
                                                      : "/images/na-vertical.jpg"
                                                  }
                                                  style={{ width: 300 }}
                                                  rounded
                                                  fluid
                                                />
                                              </td>
                                            </tr>
                                            <tr>
                                              <td align="center">
                                                Master 1 Installed
                                              </td>
                                              <td align="center">
                                                Slave 2 Installed
                                              </td>
                                              <td align="center">
                                                Slave 3 Installed
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <p style={{ pageBreakBefore: "always" }} />
                  </div>
                );
              })
            : ""}
        </div>
      </div>
    );
  }
}

export default withRouter(PATAdminReviewerShowPage27);

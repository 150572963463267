import React, { Component } from "react";
import { Redirect, withRouter } from "react-router-dom";
import { Button } from "react-bootstrap";
import { Card } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { Container } from "react-bootstrap";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import { Badge } from "react-bootstrap";
import { processStatusText, processStatus } from "../helper/utilityHelper";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { faFile } from "@fortawesome/free-solid-svg-icons";
import { faPen } from "@fortawesome/free-solid-svg-icons";
// import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { faSignInAlt } from "@fortawesome/free-solid-svg-icons";

import Axios from "axios";

import MessageBox from "../components/messagebox/messagebox";
import LoadingBox from "../components/messagebox/loadingbox";
import ConfirmBox from "../components/messagebox/confirmbox";
import MenuTechnician from "../components/menu/menuTechnician";

// var Moment = require('moment');
var Config = require("../config/config.json");

class SSRsTechnician extends Component {
  //
  constructor(props) {
    // Base contructor.
    super(props);
    // Ref.
    this.messageboxRef = React.createRef();
    this.loadingboxRef = React.createRef();
    this.confirmboxRef = React.createRef();
    // State.
    this.state = {
      isShowSearch: false,
      isShowEnterSite: false,
      search: {
        documentNumber: "",
        //month: Moment().format('YYYY-MM'),
        siteCode: "",
        siteNameEn: "",
        region: "",
        zone: "",
        status: ""
      },
      enterSite: {
        siteCode: "",
        region: ""
      },
      redirect: "",
      data: []
    };
  }

  //
  requestData = () => {
    if (this.loadingboxRef.current) this.loadingboxRef.current.onShow();
    Axios.post(
      Config.service.url + "/ssr/search",
      {
        technicianName: localStorage.getItem("name")
        //month: Moment().format('YYYY-MM')
      },
      {
        headers: {
          Authorization: localStorage.getItem("token")
        }
      }
    )
      .then(res => {
        if (res.data.success === false) {
          this.messageboxRef.current.onShow("Error", res.data.message);
          return;
        }
        this.setState({ data: res.data.data.data });
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        if (this.loadingboxRef.current) this.loadingboxRef.current.onHide();
      });
  };

  //
  onNewPAT = data => {
    // Need to check, Does the PAT document already created?
    Axios.get(Config.service.url + "/pat/isCreated/" + data._id, {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    })
      .then(res => {
        if (this.loadingboxRef.current) this.loadingboxRef.current.onHide();
        if (res.data.success === true) {
          console.log("Open PAT document.");
          this.setState({ redirect: "/patTechnicianEdit?ssrId=" + data._id });
        } else {
          console.log("New PAT document.");
          this.setState({ redirect: "/patTechnician?id=" + data._id });
        }
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {});
  };

  //
  onShow = data => {
    this.setState({ redirect: "/ssrTechnicianShow?id=" + data._id });
  };

  //
  onEdit = data => {
    this.setState({ redirect: "/ssrTechnicianEdit?id=" + data._id });
  };

  //
  onDelete = data => {
    this.confirmboxRef.current.onShow(
      "Delete",
      "Do you want to delete this record?",
      () => {
        if (this.loadingboxRef.current) this.loadingboxRef.current.onShow();
        Axios.delete(Config.service.url + "/ssr/delete/" + data._id, {
          headers: {
            Authorization: localStorage.getItem("token")
          }
        })
          .then(res => {
            if (res.data.success === false) {
              this.messageboxRef.current.onShow("Error", res.data.message);
              return;
            }
            if (this.loadingboxRef.current) this.loadingboxRef.current.onHide();
            this.requestData();
          })
          .catch(err => {
            console.log(err);
          });
      }
    );
  };

  //
  onChangeEnterSite = e => {
    const { name, value } = e.target;
    let enterSite = { ...this.state.enterSite };
    enterSite[name] = value;
    this.setState({ enterSite: enterSite });
  };

  //
  onChangeSearch = e => {
    const { name, value } = e.target;
    let search = { ...this.state.search };
    search[name] = value;
    this.setState({ search: search });
  };

  //
  onHide = () => {
    this.setState({ isShowSearch: false });
  };

  //
  onHideEnterSite = () => {
    this.setState({ isShowEnterSite: false });
  };

  //
  onSearch = () => {
    this.setState({ isShowSearch: true });
  };

  //
  onEnterSite = () => {
    this.setState({ isShowEnterSite: true });
  };

  //
  onSearchSubmit = () => {
    this.setState({ isShowSearch: false });
    if (this.loadingboxRef.current) this.loadingboxRef.current.onShow();
    Axios.post(
      Config.service.url + "/ssr/search",
      {
        documentNumber: this.state.search.documentNumber,
        //month: this.state.search.month,
        siteCode: this.state.search.siteCode,
        status: this.state.search.status,
        technicianName: localStorage.getItem("name")
      },
      {
        headers: {
          Authorization: localStorage.getItem("token")
        }
      }
    )
      .then(res => {
        if (res.data.success === false) {
          this.messageboxRef.current.onShow("Error", res.data.message);
          return;
        }
        this.setState({ data: res.data.data.data });
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        if (this.loadingboxRef.current) this.loadingboxRef.current.onHide();
      });
  };

  //
  onEnterSiteSubmit = () => {
    this.setState({ isShowEnterSite: false });
    if (this.loadingboxRef.current) this.loadingboxRef.current.onShow();
    Axios.post(
      Config.service.url + "/ssr/enterSiteSecondRound",
      {
        siteCode: this.state.enterSite.siteCode,
        region: this.state.enterSite.region,
        technicianName: localStorage.getItem("name"),
        technicianPhone: localStorage.getItem("phone"),
        technicianContractor: localStorage.getItem("contractor")
      },
      {
        headers: {
          Authorization: localStorage.getItem("token")
        }
      }
    )
      .then(res => {
        console.log(res.data.success === false);
        if (res.data.success === false) {
          this.messageboxRef.current.onShow("Error", res.data.message);
          return;
        }
        window.location.reload();
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        if (this.loadingboxRef.current) this.loadingboxRef.current.onHide();
      });
  };

  //
  componentDidMount() {
    if (localStorage.getItem("token") === null) {
      return;
    }
    this.requestData();
  }

  //
  render() {
    if (localStorage.getItem("token") === null) {
      return <Redirect push to="/login" />;
    }

    if (this.state.redirect) {
      return <Redirect push to={this.state.redirect} />;
    }

    return (
      <>
        <MenuTechnician />
        <MessageBox ref={this.messageboxRef} />
        <LoadingBox ref={this.loadingboxRef} />
        <ConfirmBox ref={this.confirmboxRef} />
        {/* Modal enter site in */}
        <Modal show={this.state.isShowEnterSite} onHide={this.onHideEnterSite}>
          <Modal.Header closeButton>
            <Modal.Title>Enter Site Second Round</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Container>
                <Form.Group>
                  <Row>
                    <Col md={3}>Site Code</Col>
                    <Col md={9}>
                      <Form.Control
                        type="text"
                        name="siteCode"
                        onChange={this.onChangeEnterSite}
                        value={this.state.enterSite.siteCode}
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row>
                    <Col md={3}>Region</Col>
                    <Col md={9}>
                      <Form.Control
                        type="text"
                        name="region"
                        onChange={this.onChangeEnterSite}
                        value={this.state.enterSite.region}
                      />
                    </Col>
                  </Row>
                </Form.Group>
              </Container>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={this.onEnterSiteSubmit}>
              OK
            </Button>
            <Button variant="danger" onClick={this.onHideEnterSite}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Modal search */}
        <Modal show={this.state.isShowSearch} onHide={this.onHide}>
          <Modal.Header closeButton>
            <Modal.Title>Search</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Container>
                <Form.Group>
                  <Row>
                    <Col md={3}>Doc No</Col>
                    <Col md={9}>
                      <Form.Control
                        type="documentNumber"
                        name="documentNumber"
                        onChange={this.onChangeSearch}
                        defaultValue={this.state.search.documentNumber}
                      />
                    </Col>
                  </Row>
                </Form.Group>
                {/* <Form.Group>
                                    <Row>
                                        <Col md={3}>Date</Col>
                                        <Col md={9}><Form.Control type="month" name="month" onChange={this.onChangeSearch} defaultValue={this.state.search.month} /></Col>
                                    </Row>
                                </Form.Group> */}
                <Form.Group>
                  <Row>
                    <Col md={3}>Site Code</Col>
                    <Col md={9}>
                      <Form.Control
                        type="text"
                        name="siteCode"
                        onChange={this.onChangeSearch}
                        value={this.state.search.siteCode}
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row>
                    <Col md={3}>Site Name En</Col>
                    <Col md={9}>
                      <Form.Control
                        type="text"
                        name="siteNameEn"
                        onChange={this.onChangeSearch}
                        value={this.state.search.siteNameEn}
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row>
                    <Col md={3}>Region</Col>
                    <Col md={9}>
                      <Form.Control
                        type="text"
                        name="region"
                        onChange={this.onChangeSearch}
                        value={this.state.search.region}
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row>
                    <Col md={3}>Zone</Col>
                    <Col md={9}>
                      <Form.Control
                        type="text"
                        name="zone"
                        onChange={this.onChangeSearch}
                        value={this.state.search.zone}
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row>
                    <Col md={3}>Status</Col>
                    <Col md={9}>
                      <select
                        name="status"
                        className="custom-select"
                        onChange={this.onChangeSearch}
                        value={this.state.search.status}
                      >
                        <option value="-" defaultValue>
                          ---
                        </option>
                        <option value="New">New</option>
                        <option value="Waiting-Review">
                          Waiting-SSK-Review
                        </option>
                        <option value="Reject-From-SSK">Reject-From-SSK</option>
                        <option value="Waiting-Approve">
                          Waiting-AIS-Approve
                        </option>
                        <option value="Reject-From-AIS">Reject-From-AIS</option>
                        <option value="Approved">Approved</option>
                      </select>
                    </Col>
                  </Row>
                </Form.Group>
              </Container>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={this.onSearchSubmit}>
              OK
            </Button>
            <Button variant="danger" onClick={this.onHide}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
        <div className="App-body">
          <table width="100%">
            <tbody>
              <tr>
                <td>
                  <h5>SSR Document</h5>
                </td>
                <td align="right">
                  <Button
                    variant="secondary"
                    onClick={this.onEnterSite}
                    title="Enter Site Second Round"
                  >
                    <FontAwesomeIcon icon={faSignInAlt} /> Round 2
                  </Button>
                  &nbsp;
                  <Button
                    variant="secondary"
                    onClick={this.onSearch}
                    title="Search"
                  >
                    <FontAwesomeIcon icon={faSearch} />
                  </Button>
                </td>
              </tr>
            </tbody>
          </table>
          <hr />
          {this.state.data.length === 0 ? (
            <center>
              <span>No data</span>
            </center>
          ) : (
            this.state.data.map((data, index) => {
              let allowEdit = "false";
              if (
                data.status === "Waiting Review" ||
                data.status === "Reject Review"
              ) {
                allowEdit = "true";
              }
              return (
                <div key={data._id} style={{ marginBottom: "10px" }}>
                  <Card border="primary">
                    <Card.Header>
                      No: {data.documentNumber}, Site: {data.cover.siteCode}
                    </Card.Header>
                    <Card.Body>
                      <table width="100%">
                        <tbody>
                          <tr>
                            <td width="120">Status</td>
                            <td>
                              <Badge variant={processStatus(data.status)}>
                                {processStatusText(data.status)}
                              </Badge>
                            </td>
                          </tr>
                          <tr>
                            <td>Date</td>
                            <td>{data.cover.date}</td>
                          </tr>
                          <tr>
                            <td>Doc No</td>
                            <td>{data.documentNumber}</td>
                          </tr>
                          <tr>
                            <td>Project</td>
                            <td>{data.cover.projectName}</td>
                          </tr>
                          <tr>
                            <td>Site</td>
                            <td>{data.cover.siteCode}</td>
                          </tr>
                          <tr>
                            <td>Site En</td>
                            <td>{data.cover.siteNameEn}</td>
                          </tr>
                          <tr>
                            <td>Site Th</td>
                            <td>{data.cover.siteNameTh}</td>
                          </tr>
                          <tr>
                            <td>Region</td>
                            <td>{data.cover.region}</td>
                          </tr>
                          <tr>
                            <td>Zone</td>
                            <td>{data.cover.zone}</td>
                          </tr>
                          <tr>
                            <td>Tech Name 1</td>
                            <td>{data.cover.technicianName}</td>
                          </tr>
                          <tr>
                            <td>Tech Phone 1</td>
                            <td>{data.cover.technicianPhone}</td>
                          </tr>
                          <tr>
                            <td>Tech Name 2</td>
                            <td>{data.cover.technicianName2}</td>
                          </tr>
                          <tr>
                            <td>Tech Phone 2</td>
                            <td>{data.cover.technicianPhone2}</td>
                          </tr>
                          <tr style={{ color: "#ff0000", fontWeight: "bold" }}>
                            <td>Remark</td>
                            <td>{data.cover.remark}</td>
                          </tr>
                          <tr style={{ color: "#ff0000", fontWeight: "bold" }}>
                            <td valign="top">Reject</td>
                            <td>
                              <pre>
                                <span
                                  style={{
                                    color: "#ff0000",
                                    fontWeight: "bold"
                                  }}
                                >
                                  {data.remark}
                                </span>
                              </pre>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <hr />
                      <table width="100%">
                        <tbody>
                          <tr>
                            <td>
                              <Button
                                variant="primary"
                                onClick={() => this.onNewPAT(data)}
                                title="PAT"
                              >
                                <FontAwesomeIcon icon={faFile} /> PAT
                              </Button>{" "}
                            </td>
                            <td align="right">
                              <Button
                                variant="success"
                                onClick={() => this.onShow(data)}
                                title="Show"
                              >
                                <FontAwesomeIcon icon={faFile} />
                              </Button>{" "}
                              {data.status === "New" ||
                              data.status === "Reject-From-SSK" ||
                              data.status === "Reject-From-AIS" ? (
                                <Button
                                  variant="primary"
                                  onClick={() => this.onEdit(data)}
                                  title="Edit"
                                >
                                  <FontAwesomeIcon
                                    icon={faPen}
                                    disable={allowEdit}
                                  />
                                </Button>
                              ) : null}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </Card.Body>
                  </Card>
                </div>
              );
            })
          )}
        </div>
      </>
    );
  }
}

export default withRouter(SSRsTechnician);

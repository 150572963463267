import { Component } from 'react';
import Axios from 'axios';

var Config = require('../../config/config.json');

class Menu extends Component {
    //
    logout = () => {
        Axios.get(Config.service.url + "/auth/logout", { 
            headers: {
                "Authorization" : localStorage.getItem('token')
            } 
        })
        .then(() => {
            localStorage.removeItem('token');
            localStorage.removeItem('userId');
            localStorage.removeItem('name');
            localStorage.removeItem('phone');
            localStorage.removeItem('username');
            localStorage.removeItem('role');
            window.location.href = "/login";
        })
        .catch(err => {
            console.log(err);
        });
    }
}

export default Menu
import React, { Component } from "react";
import Axios from "axios";
import { Redirect } from "react-router-dom";
import { Container } from "react-bootstrap";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import { Card } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { Form } from "react-bootstrap";
// import { Image } from 'react-bootstrap';
import Hash from "hash.js";

import MessageBox from "../components/messagebox/messagebox";

var Config = require("../config/config.json");

class Login extends Component {
  //
  constructor(props) {
    super(props);
    // Ref.
    this.messageboxRef = React.createRef();
    // State.
    this.state = {
      redirect: "",
      username: "",
      password: "",
      message: "",
      isShow: false
    };
  }

  //
  onChange = e => {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    });
  };

  //
  componentDidMount = () => {
    // Redirect.
    if (localStorage.getItem("role") === "Admin") {
      this.setState({ redirect: "/dashboardBurnDown" });
    } else if (localStorage.getItem("role") === "Reviewer") {
      this.setState({ redirect: "/dashboardBurnDown" });
    } else if (localStorage.getItem("role") === "Technician") {
      this.setState({ redirect: "/dashboardTechnician" });
    }
  };

  //
  onSubmit = e => {
    //e.preventDefault()
    Axios.post(Config.service.url + "/auth/login", {
      username: this.state.username,
      password: Hash.sha256().update(this.state.password).digest("hex")
    })
      .then(res => {
        if (res.data.success === false) {
          this.messageboxRef.current.onShow("Error", res.data.message);
          return;
        }
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("userId", res.data.userId);
        localStorage.setItem("name", res.data.name);
        localStorage.setItem("phone", res.data.phone);
        localStorage.setItem("contractor", res.data.contractor);
        localStorage.setItem("username", res.data.username);
        localStorage.setItem("role", res.data.role);
        // Redirect.
        if (
          localStorage.getItem("role") === "Admin" ||
          localStorage.getItem("role") === "Admin SSK" ||
          localStorage.getItem("role") === "Admin Subcon" ||
          localStorage.getItem("role") === "Super Admin"
        ) {
          this.setState({ redirect: "/patsAdmin" });
        } else if (localStorage.getItem("role") === "Reviewer") {
          this.setState({ redirect: "/patsAdmin" });
        } else if (localStorage.getItem("role") === "Technician") {
          this.setState({ redirect: "/dashboardTechnician" });
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  //
  render() {
    // Redirect
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }

    return (
      <>
        <MessageBox ref={this.messageboxRef} />
        <Container>
          <Row>
            <Col md={3}></Col>
            <Col md={6}>
              <div style={{ marginTop: "50px" }}>
                <Card>
                  <Card.Header className="text-center">
                    <h2>{this.props.title}</h2>
                  </Card.Header>
                  <Card.Body>
                    {/* <div style={{textAlign: "center"}}>
                                            <Image src="/images/logo.jpg" width="100"></Image>
                                        </div> */}
                    <Form>
                      <Container>
                        <Form.Group>
                          <Row>
                            <Col md={4}>Username</Col>
                            <Col md={8}>
                              <Form.Control
                                type="text"
                                name="username"
                                onChange={this.onChange}
                              />
                            </Col>
                          </Row>
                        </Form.Group>
                        <Form.Group>
                          <Row>
                            <Col md={4}>Password</Col>
                            <Col md={8}>
                              <Form.Control
                                type="password"
                                name="password"
                                onChange={this.onChange}
                              />
                            </Col>
                          </Row>
                        </Form.Group>
                        <Row>
                          <Col md={3}></Col>
                          <Col md={9}>
                            <div style={{ textAlign: "right" }}>
                              <Button variant="success" onClick={this.onSubmit}>
                                Login
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </Container>
                    </Form>
                  </Card.Body>
                </Card>
              </div>
            </Col>
            <Col md={3}></Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Login;

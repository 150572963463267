import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';

class ConfirmBox extends Component {
    //
    constructor(props) {
        super(props);
        this.state = {
            title: '',
            message: '',
            isShow: false,
            //
            callback: null,
        };
    }
    //
    onShow = (title, message, callback = null) => {
        this.setState({ isShow: true, title: title, message: message, callback: callback});
    }

    //
    onHide = () => {
        this.setState({ isShow: false });
    }

    //
    onAction = () => {
        this.onHide(); 
        
        if(this.props.onAction) {
            this.props.onAction();
        }
        else if(this.state.callback) {
            this.state.callback();
        }
    }

    //
    render() {
        return (
            <>
                <Modal show={this.state.isShow} onHide={this.onHide}>
                    <Modal.Header closeButton>
                        <Modal.Title>{this.state.title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.message}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="success" onClick={this.onAction}>OK</Button>
                        <Button variant="danger" onClick={this.onHide}>Cancel</Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

export default ConfirmBox
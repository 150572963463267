import React from "react";
import { Navbar } from "react-bootstrap";
import { Nav } from "react-bootstrap";
import { NavDropdown } from "react-bootstrap";
import { Button } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileInvoice } from "@fortawesome/free-solid-svg-icons";
import { faDatabase } from "@fortawesome/free-solid-svg-icons";
import { faPowerOff } from "@fortawesome/free-solid-svg-icons";
import { faChartPie } from "@fortawesome/free-solid-svg-icons";

import Menu from "./menu";

class MenuAdmin extends Menu {
  //
  constructor(props) {
    // Base contructor.
    super(props);
    //
    this.state = {};
  }

  //
  componentDidMount = async () => {};

  //
  view_desktop = () => {
    return (
      <>
        <Navbar bg="dark" variant="dark">
          <Nav className="mr-auto">
            {/* <Nav.Link href="/">Dashboard</Nav.Link> */}
            <Navbar.Brand href="/">SSK</Navbar.Brand>
            {/*  */}
            <NavDropdown title="Dashboard" id="nav-dropdown">
              <NavDropdown.Item href="/dashboardSSR">
                <FontAwesomeIcon icon={faChartPie} /> SSR
              </NavDropdown.Item>
              <NavDropdown.Item href="/dashboardSSRByDate">
                <FontAwesomeIcon icon={faChartPie} /> SSR by Date
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/dashboardPAT">
                <FontAwesomeIcon icon={faChartPie} /> PAT
              </NavDropdown.Item>
              <NavDropdown.Item href="/dashboardPATByDate">
                <FontAwesomeIcon icon={faChartPie} /> PAT by Date
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/dashboardBurnDown">
                <FontAwesomeIcon icon={faChartPie} /> Burn Down
              </NavDropdown.Item>
            </NavDropdown>
            {/*  */}
            <NavDropdown title="Docs" id="nav-dropdown">
              <NavDropdown.Item href="/ssrsAdmin">
                <FontAwesomeIcon icon={faFileInvoice} /> SSR Doc
              </NavDropdown.Item>
              <NavDropdown.Item href="/patsAdmin">
                <FontAwesomeIcon icon={faFileInvoice} /> PAT Doc
              </NavDropdown.Item>
              <NavDropdown.Item href="/configSmartSiteAdminReviewer">
                <FontAwesomeIcon icon={faFileInvoice} /> Config Smart Site
              </NavDropdown.Item>
            </NavDropdown>
            {/*  */}
            {/* <NavDropdown title="Store" id="nav-dropdown">
              <NavDropdown.Item href="/store">
                <FontAwesomeIcon icon={faFileInvoice} /> Code In-Out
              </NavDropdown.Item>
            </NavDropdown> */}
            {/* <NavDropdown title="Report" id="nav-dropdown">
                        <NavDropdown.Item href="/report/byAdmin"><FontAwesomeIcon icon={faChartBar} /> By Admin</NavDropdown.Item>
                        <NavDropdown.Item href="/report/byDetail"><FontAwesomeIcon icon={faChartBar} /> By Detail</NavDropdown.Item>
                    </NavDropdown> */}
            <NavDropdown title="Data" id="nav-dropdown">
              <NavDropdown.Item href="/user">
                <FontAwesomeIcon icon={faDatabase} /> User
              </NavDropdown.Item>
              <NavDropdown.Item href="/baseStation">
                <FontAwesomeIcon icon={faDatabase} /> Base Station
              </NavDropdown.Item>
            </NavDropdown>
            {/* <NavDropdown title="Help" id="nav-dropdown">
                        <NavDropdown.Item href=""><FontAwesomeIcon icon={faQuestionCircle} /> Manual</NavDropdown.Item>
                    </NavDropdown> */}
          </Nav>
          <Button variant="danger" onClick={this.logout}>
            <FontAwesomeIcon icon={faPowerOff} />
          </Button>
        </Navbar>
      </>
    );
  };

  //
  render() {
    return this.view_desktop();
  }
}

export default MenuAdmin;

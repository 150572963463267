import React, { Component } from "react";
import { Redirect, withRouter } from "react-router-dom";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import { Table } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import auth from "../helper/authHelper";

import Axios from "axios";

import MenuAdmin from "../components/menu/menuAdmin";

import MessageBox from "../components/messagebox/messagebox";
import LoadingBox from "../components/messagebox/loadingbox";
import ConfirmBox from "../components/messagebox/confirmbox";

var Config = require("../config/config.json");

class ReportByAdmin extends Component {
  //
  constructor(props) {
    // Base contructor.
    super(props);
    // Ref.
    this.messageboxRef = React.createRef();
    this.loadingboxRef = React.createRef();
    this.confirmboxRef = React.createRef();
    this.linkDownloadRef = React.createRef();
    // State.
    this.state = {
      isShow: false,
      data: null
    };
  }

  //
  requestData = () => {
    if (this.loadingboxRef.current) this.loadingboxRef.current.onShow();
    Axios.get(Config.service.url + "/report/byAdmin", {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    })
      .then(res => {
        if (res.data.success === false) {
          this.messageboxRef.current.onShow("Error", res.data.message);
          return;
        }
        console.log(res.data.data);
        this.setState({ data: res.data.data });
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        if (this.loadingboxRef.current) this.loadingboxRef.current.onHide();
      });
  };

  //
  onExportToExcel = () => {
    if (this.loadingboxRef.current) this.loadingboxRef.current.onShow();
    Axios.get(Config.service.url + "/report/exportAdminToExcel", {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    })
      .then(res => {
        if (res.data.success === false) {
          this.messageboxRef.current.onShow("Error", res.data.message);
          return;
        }
        console.log(res.data);
        if (this.loadingboxRef.current) this.loadingboxRef.current.onHide();
        this.setState({ isShow: true });
        this.linkDownloadRef.current.href =
          Config.service.url + "/report/download/" + res.data.file;
        console.log(this.linkDownloadRef.current.href);
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {});
  };

  //
  onHide = () => {
    this.setState({ isShow: false });
  };

  //
  componentDidMount() {
    if (localStorage.getItem("token") === null) {
      return;
    }
    this.requestData();
  }

  //
  render() {
    if (localStorage.getItem("token") === null) {
      return <Redirect push to="/login" />;
    }

    if (auth.allowAdminReview() === false) {
      return <Redirect push to="/noPermission" />;
    }

    return (
      <>
        <MenuAdmin />
        <MessageBox ref={this.messageboxRef} />
        <LoadingBox ref={this.loadingboxRef} />
        <ConfirmBox ref={this.confirmboxRef} />
        <Modal show={this.state.isShow} onHide={this.onHide}>
          <Modal.Header closeButton>
            <Modal.Title>Download</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <a
              ref={this.linkDownloadRef}
              href="http://localhost"
              target="_blank"
              download
              rel="noopener noreferrer"
            >
              Click here to download
            </a>
          </Modal.Body>
        </Modal>
        <div className="App-body">
          <Row>
            <Col md={12}>
              <table width="100%">
                <tbody>
                  <tr>
                    <td>
                      <h3>Report by Admin</h3>
                    </td>
                    <td align="right">
                      <Button
                        variant="success"
                        onClick={this.onExportToExcel}
                        title="Search"
                      >
                        Export to Excel
                      </Button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Col>
          </Row>
          <Row>
            <Col>
              <Table width="100%">
                <tbody>
                  <tr align="center">
                    <td width="">Owner</td>
                    <td width="10   0px">Type</td>
                    <td width="150px">New</td>
                    <td width="150px">Waiting-Review</td>
                    <td width="150px">Reject-From-SSK</td>
                    <td width="150px">Waiting-Approve</td>
                    <td width="150px">Reject-From-AIS</td>
                    <td width="150px">Approved</td>
                  </tr>
                  {this.state.data !== null
                    ? this.state.data.map((item, index) => {
                        return (
                          <>
                            <tr>
                              <td rowSpan="2">{item.key}</td>
                              <td align="center">SSR</td>
                              <td align="center">{item.value.data.ssr.new}</td>
                              <td align="center">
                                {item.value.data.ssr.waitingReview}
                              </td>
                              <td align="center">
                                {item.value.data.ssr.rejectFromSSK}
                              </td>
                              <td align="center">
                                {item.value.data.ssr.waitingApprove}
                              </td>
                              <td align="center">
                                {item.value.data.ssr.rejectFromAIS}
                              </td>
                              <td align="center">
                                {item.value.data.ssr.approved}
                              </td>
                            </tr>
                            <tr>
                              <td align="center">PAT</td>
                              <td align="center">{item.value.data.pat.new}</td>
                              <td align="center">
                                {item.value.data.pat.waitingReview}
                              </td>
                              <td align="center">
                                {item.value.data.pat.rejectFromSSK}
                              </td>
                              <td align="center">
                                {item.value.data.pat.waitingApprove}
                              </td>
                              <td align="center">
                                {item.value.data.pat.rejectFromAIS}
                              </td>
                              <td align="center">
                                {item.value.data.pat.approved}
                              </td>
                            </tr>
                          </>
                        );
                      })
                    : null}
                </tbody>
              </Table>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default withRouter(ReportByAdmin);

import React, { Component } from "react";
import { Redirect, withRouter } from "react-router-dom";

import MenuReviewer from "../components/menu/menuReviewer";
import auth from "../helper/authHelper";

class DashboardReviewer extends Component {
  //
  constructor(props) {
    // Base contructor.
    super(props);
    //
    this.state = {};
  }

  //
  requestData = () => {
    // Prevent access empty object.
    if (localStorage.getItem("token") !== null) {
    }
  };

  //
  componentDidMount() {
    if (localStorage.getItem("token") === null) {
      return;
    }
    this.requestData();
  }

  //
  render() {
    //
    if (localStorage.getItem("token") === null) {
      return <Redirect push to="/login" />;
    }

    if (auth.allowAdminReview() === false) {
      return <Redirect push to="/noPermission" />;
    }

    return (
      <>
        <MenuReviewer />
      </>
    );
  }
}

export default withRouter(DashboardReviewer);

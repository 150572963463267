import React, { Component } from "react";
import { Form } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { Stage } from "react-konva";
import { Layer } from "react-konva";
import { Image } from "react-konva";
import { Circle } from "react-konva";
import { Text } from "react-konva";
import { Rect } from "react-konva";
import Resizer from "react-image-file-resizer";

var Config = require("../../config/config.json");

class SSRDesignMasterImage extends Component {
  //
  constructor(props) {
    // Base contructor.
    super(props);
    // DC Power
    this.refM1Circle = React.createRef();
    this.refM1Rect = React.createRef();
    this.refM1Text = React.createRef();
    // Camera
    this.refM2Circle = React.createRef();
    this.refM2Rect = React.createRef();
    this.refM2Text = React.createRef();
    // Electric Lock
    this.refM3Circle = React.createRef();
    this.refM3Rect = React.createRef();
    this.refM3Text = React.createRef();
    // Door Lock
    this.refM4Circle = React.createRef();
    this.refM4Rect = React.createRef();
    this.refM4Text = React.createRef();
    // IPRAN
    this.refM5Circle = React.createRef();
    this.refM5Rect = React.createRef();
    this.refM5Text = React.createRef();
    // Master Controller
    this.refM6Circle = React.createRef();
    this.refM6Rect = React.createRef();
    this.refM6Text = React.createRef();
    // Siren
    this.refM7Circle = React.createRef();
    this.refM7Rect = React.createRef();
    this.refM7Text = React.createRef();
    // Motion Sensor
    this.refM8Circle = React.createRef();
    this.refM8Rect = React.createRef();
    this.refM8Text = React.createRef();
    // Cable Inlet
    this.refM9Circle = React.createRef();
    this.refM9Rect = React.createRef();
    this.refM9Text = React.createRef();
    // State
    this.state = {
      config: {
        radius: 10,
        circleColor: "#FF0000",
        opacity: 1.0,
        fontSize: 12,
        fontColor: "#FF0000",
        backgroundColor: "#FFFFFF"
      },
      // Set 1
      blob: null,
      file: null,
      path: this.props.src,
      // Set 1
      blob2: null,
      file2: null,
      path2: this.props.src2,
      markers: {
        m1: { text: "DC Power", x: 75, y: 75, xT: 95, yT: 70 },
        m2: { text: "Camera", x: 180, y: 75, xT: 200, yT: 70 },
        m3: { text: "Electric Lock", x: 75, y: 200, xT: 95, yT: 195 },
        m4: { text: "Door Lock", x: 180, y: 200, xT: 200, yT: 195 },
        m5: { text: "IPRAN", x: 180, y: 150, xT: 200, yT: 145 },
        m6: { text: "Master Controller", x: 180, y: 250, xT: 200, yT: 245 },
        m7: { text: "Siren", x: 180, y: 300, xT: 200, yT: 295 },
        m8: { text: "Motion Sensor", x: 180, y: 110, xT: 200, yT: 105 },
        m9: { text: "Cable Inlet", x: 180, y: 170, xT: 200, yT: 165 }
      },
      draggable: this.props.draggable,
      width: 306,
      height: 408
    };
  }

  getState = () => {
    return this.state;
  };

  //
  loadImage = (path, file = null) => {
    this.image = new window.Image();
    this.image.src = path;
    this.image.addEventListener("load", () => {
      this.setState({
        blob: this.image,
        path: this.image.src
      });
      if (file !== null) {
        this.setState({ file: file });
      }
    });
  };

  //
  loadImage2 = (path, file = null) => {
    this.image2 = new window.Image();
    this.image2.src = path;
    this.image2.addEventListener("load", () => {
      this.setState({
        blob2: this.image2,
        path2: this.image2.src
      });
      if (file !== null) {
        this.setState({ file2: file });
      }
    });
  };

  //
  refresh = (path = "", path2 = "", markers = null) => {
    if (path === "" && path2 === "") {
      this.loadImage(this.state.path);
      this.loadImage2(this.state.path2);
    } else {
      this.loadImage(Config.service.url + "/ssr/image/" + path);
      this.loadImage2(Config.service.url + "/ssr/image/" + path2);
    }
    //
    if (markers !== null) {
      this.setState({
        markers: markers
      });
    }
  };

  //
  componentDidMount = () => {};

  //
  onDragMoveCircle = e => {
    let m = { ...this.state.markers };
    m[e.target.attrs.name].x = e.target.attrs.x;
    m[e.target.attrs.name].y = e.target.attrs.y;
    this.setState({
      markers: m
    });
  };

  //
  onDragMoveText = e => {
    let m = { ...this.state.markers };
    m[e.target.attrs.name].xT = e.target.attrs.x;
    m[e.target.attrs.name].yT = e.target.attrs.y;
    this.setState({
      markers: m
    });
  };

  //
  resizeFile = file =>
    new Promise(resolve => {
      Resizer.imageFileResizer(
        file,
        306,
        408,
        "JPEG",
        100,
        0,
        uri => {
          resolve(uri);
        },
        "blob"
      );
    });

  //
  onFileChange = async e => {
    // Check in case user doesn't input file.
    if (e.target.files[0] === undefined) return;
    // Resize.
    let fileName = e.target.files[0].name;
    let fileResize = new File(
      [await this.resizeFile(e.target.files[0])],
      fileName
    );
    this.loadImage(URL.createObjectURL(fileResize), fileResize);
  };

  //
  onFileChange2 = async e => {
    // Check in case user doesn't input file.
    if (e.target.files[0] === undefined) return;
    // Resize.
    let fileName = e.target.files[0].name;
    let fileResize = new File(
      [await this.resizeFile(e.target.files[0])],
      fileName
    );
    this.loadImage2(URL.createObjectURL(fileResize), fileResize);
  };

  //
  onResetMarkers = () => {
    this.setState({
      markers: {
        m1: { text: "DC Power", x: 75, y: 75, xT: 95, yT: 70 },
        m2: { text: "Camera", x: 180, y: 75, xT: 200, yT: 70 },
        m3: { text: "Electric Lock", x: 75, y: 200, xT: 95, yT: 195 },
        m4: { text: "Door Lock", x: 180, y: 200, xT: 200, yT: 195 },
        m5: { text: "IPRAN", x: 180, y: 150, xT: 200, yT: 145 },
        m6: { text: "Master Controller", x: 180, y: 250, xT: 200, yT: 245 },
        m7: { text: "Siren", x: 180, y: 300, xT: 200, yT: 295 },
        m8: { text: "Motion Sensor", x: 180, y: 110, xT: 200, yT: 105 },
        m9: { text: "Cable Inlet", x: 180, y: 170, xT: 200, yT: 165 }
      }
    });
  };

  //
  render = () => {
    return (
      <>
        <div style={{ width: "100%", display: "block" }}>
          {this.props.readOnly === undefined ||
          this.props.readOnly === false ? (
            <Form.File
              label="Select an image"
              custom
              onChange={this.onFileChange}
            />
          ) : null}
          <center>
            <div
              style={{
                marginTop: "10px",
                width: this.state.width,
                height: this.state.height,
                display: "block"
              }}
            >
              <Stage width={this.state.width} height={this.state.height}>
                <Layer>
                  <Image
                    image={this.state.blob}
                    width={this.state.width}
                    height={this.state.height}
                  />
                  {/* DC Power */}
                  <Circle
                    ref={this.refM1Circle}
                    name="m1"
                    x={this.state.markers.m1.x}
                    y={this.state.markers.m1.y}
                    radius={this.state.config.radius}
                    fill={this.state.config.circleColor}
                    opacity={this.state.config.opacity}
                    draggable={this.state.draggable}
                    onDragMove={this.onDragMoveCircle}
                  />
                  <Rect
                    ref={this.refM1Rect}
                    name="m1"
                    width={60}
                    height={16}
                    fill={this.state.config.backgroundColor}
                    x={this.state.markers.m1.xT - 3}
                    y={this.state.markers.m1.yT - 3}
                  />
                  <Text
                    ref={this.refM1Text}
                    name="m1"
                    text={this.state.markers.m1.text}
                    x={this.state.markers.m1.xT}
                    y={this.state.markers.m1.yT}
                    draggable={this.state.draggable}
                    onDragMove={this.onDragMoveText}
                    fontSize={this.state.config.fontSize}
                    fill={this.state.config.fontColor}
                  />
                  {/* Camera */}
                  <Circle
                    ref={this.refM2Circle}
                    x={this.state.markers.m2.x}
                    y={this.state.markers.m2.y}
                    name="m2"
                    radius={this.state.config.radius}
                    fill={this.state.config.circleColor}
                    opacity={this.state.config.opacity}
                    draggable={this.state.draggable}
                    onDragMove={this.onDragMoveCircle}
                  />
                  <Rect
                    ref={this.refM2Rect}
                    name="m2"
                    width={50}
                    height={16}
                    fill={this.state.config.backgroundColor}
                    x={this.state.markers.m2.xT - 3}
                    y={this.state.markers.m2.yT - 3}
                  />
                  <Text
                    ref={this.refM2Text}
                    name="m2"
                    text={this.state.markers.m2.text}
                    x={this.state.markers.m2.xT}
                    y={this.state.markers.m2.yT}
                    draggable={this.state.draggable}
                    onDragMove={this.onDragMoveText}
                    fontSize={this.state.config.fontSize}
                    fill={this.state.config.fontColor}
                  />
                  {/* Electric Lock */}
                  <Circle
                    ref={this.refM3Circle}
                    x={this.state.markers.m3.x}
                    y={this.state.markers.m3.y}
                    name="m3"
                    radius={this.state.config.radius}
                    fill={this.state.config.circleColor}
                    opacity={this.state.config.opacity}
                    draggable={this.state.draggable}
                    onDragMove={this.onDragMoveCircle}
                  />
                  <Rect
                    ref={this.refM3Rect}
                    name="m3"
                    width={75}
                    height={16}
                    fill={this.state.config.backgroundColor}
                    x={this.state.markers.m3.xT - 3}
                    y={this.state.markers.m3.yT - 3}
                  />
                  <Text
                    ref={this.refM3Text}
                    name="m3"
                    text={this.state.markers.m3.text}
                    x={this.state.markers.m3.xT}
                    y={this.state.markers.m3.yT}
                    draggable={this.state.draggable}
                    onDragMove={this.onDragMoveText}
                    fontSize={this.state.config.fontSize}
                    fill={this.state.config.fontColor}
                  />
                  {/* Door Lock */}
                  <Circle
                    ref={this.refM4Circle}
                    x={this.state.markers.m4.x}
                    y={this.state.markers.m4.y}
                    name="m4"
                    radius={this.state.config.radius}
                    fill={this.state.config.circleColor}
                    opacity={this.state.config.opacity}
                    draggable={this.state.draggable}
                    onDragMove={this.onDragMoveCircle}
                  />
                  <Rect
                    ref={this.refM4Rect}
                    name="m4"
                    width={60}
                    height={16}
                    fill={this.state.config.backgroundColor}
                    x={this.state.markers.m4.xT - 3}
                    y={this.state.markers.m4.yT - 3}
                  />
                  <Text
                    ref={this.refM4Text}
                    name="m4"
                    text={this.state.markers.m4.text}
                    x={this.state.markers.m4.xT}
                    y={this.state.markers.m4.yT}
                    draggable={this.state.draggable}
                    onDragMove={this.onDragMoveText}
                    fontSize={this.state.config.fontSize}
                    fill={this.state.config.fontColor}
                  />
                  {/* IPRAN */}
                  <Circle
                    ref={this.refM5Circle}
                    x={this.state.markers.m5.x}
                    y={this.state.markers.m5.y}
                    name="m5"
                    radius={this.state.config.radius}
                    fill={this.state.config.circleColor}
                    opacity={this.state.config.opacity}
                    draggable={this.state.draggable}
                    onDragMove={this.onDragMoveCircle}
                  />
                  <Rect
                    ref={this.refM5Rect}
                    name="m5"
                    width={45}
                    height={16}
                    fill={this.state.config.backgroundColor}
                    x={this.state.markers.m5.xT - 3}
                    y={this.state.markers.m5.yT - 3}
                  />
                  <Text
                    ref={this.refM5Text}
                    name="m5"
                    text={this.state.markers.m5.text}
                    x={this.state.markers.m5.xT}
                    y={this.state.markers.m5.yT}
                    draggable={this.state.draggable}
                    onDragMove={this.onDragMoveText}
                    fontSize={this.state.config.fontSize}
                    fill={this.state.config.fontColor}
                  />
                  {/* Master Controller */}
                  <Circle
                    ref={this.refM6Circle}
                    x={this.state.markers.m6.x}
                    y={this.state.markers.m6.y}
                    name="m6"
                    radius={this.state.config.radius}
                    fill={this.state.config.circleColor}
                    opacity={this.state.config.opacity}
                    draggable={this.state.draggable}
                    onDragMove={this.onDragMoveCircle}
                  />
                  <Rect
                    ref={this.refM6Rect}
                    name="m6"
                    width={100}
                    height={16}
                    fill="#FFFFFF"
                    x={this.state.markers.m6.xT - 3}
                    y={this.state.markers.m6.yT - 3}
                  />
                  <Text
                    ref={this.refM6Text}
                    name="m6"
                    text={this.state.markers.m6.text}
                    x={this.state.markers.m6.xT}
                    y={this.state.markers.m6.yT}
                    draggable={this.state.draggable}
                    onDragMove={this.onDragMoveText}
                    fontSize={this.state.config.fontSize}
                    fill={this.state.config.fontColor}
                  />
                  {/* Siren */}
                  <Circle
                    ref={this.refM7Circle}
                    x={this.state.markers.m7.x}
                    y={this.state.markers.m7.y}
                    name="m7"
                    radius={this.state.config.radius}
                    fill={this.state.config.circleColor}
                    opacity={this.state.config.opacity}
                    draggable={this.state.draggable}
                    onDragMove={this.onDragMoveCircle}
                  />
                  <Rect
                    ref={this.refM7Rect}
                    name="m7"
                    width={35}
                    height={16}
                    fill={this.state.config.backgroundColor}
                    x={this.state.markers.m7.xT - 3}
                    y={this.state.markers.m7.yT - 3}
                  />
                  <Text
                    ref={this.refM7Text}
                    name="m7"
                    text={this.state.markers.m7.text}
                    x={this.state.markers.m7.xT}
                    y={this.state.markers.m7.yT}
                    draggable={this.state.draggable}
                    onDragMove={this.onDragMoveText}
                    fontSize={this.state.config.fontSize}
                    fill={this.state.config.fontColor}
                  />
                </Layer>
              </Stage>
            </div>
          </center>
          <br />
          {this.props.readOnly === undefined ||
          this.props.readOnly === false ? (
            <Form.File
              label="Select an image"
              custom
              onChange={this.onFileChange2}
            />
          ) : null}
          <center>
            <div
              style={{
                marginTop: "10px",
                width: this.state.width,
                height: 230,
                display: "block"
              }}
            >
              <Stage width={this.state.width} height={230}>
                <Layer>
                  <Image
                    image={this.state.blob2}
                    width={this.state.width}
                    height={230}
                  />
                  {/* Motion Sensor */}
                  <Circle
                    ref={this.refM8Circle}
                    x={this.state.markers.m8.x}
                    y={this.state.markers.m8.y}
                    name="m8"
                    radius={this.state.config.radius}
                    fill={this.state.config.circleColor}
                    opacity={this.state.config.opacity}
                    draggable={this.state.draggable}
                    onDragMove={this.onDragMoveCircle}
                  />
                  <Rect
                    ref={this.refM8Rect}
                    name="m8"
                    width={85}
                    height={16}
                    fill={this.state.config.backgroundColor}
                    x={this.state.markers.m8.xT - 3}
                    y={this.state.markers.m8.yT - 3}
                  />
                  <Text
                    ref={this.refM8Text}
                    name="m8"
                    text={this.state.markers.m8.text}
                    x={this.state.markers.m8.xT}
                    y={this.state.markers.m8.yT}
                    draggable={this.state.draggable}
                    onDragMove={this.onDragMoveText}
                    fontSize={this.state.config.fontSize}
                    fill={this.state.config.fontColor}
                  />
                  {/* Cable Inlet */}
                  <Circle
                    ref={this.refM9Circle}
                    x={this.state.markers.m9.x}
                    y={this.state.markers.m9.y}
                    name="m9"
                    radius={this.state.config.radius}
                    fill={this.state.config.circleColor}
                    opacity={this.state.config.opacity}
                    draggable={this.state.draggable}
                    onDragMove={this.onDragMoveCircle}
                  />
                  <Rect
                    ref={this.refM9Rect}
                    name="m9"
                    width={105}
                    height={16}
                    fill={this.state.config.backgroundColor}
                    x={this.state.markers.m9.xT - 3}
                    y={this.state.markers.m9.yT - 3}
                  />
                  <Text
                    ref={this.refM9Text}
                    name="m9"
                    text={this.state.markers.m9.text}
                    x={this.state.markers.m9.xT}
                    y={this.state.markers.m9.yT}
                    draggable={this.state.draggable}
                    onDragMove={this.onDragMoveText}
                    fontSize={this.state.config.fontSize}
                    fill={this.state.config.fontColor}
                  />
                </Layer>
              </Stage>
            </div>
          </center>
          <br />
          <center>
            {this.props.readOnly === undefined ||
            this.props.readOnly === false ? (
              <Button
                variant="secondary"
                onClick={() => this.onResetMarkers()}
                title="Reset all markers"
              >
                Reset All Marker
              </Button>
            ) : null}
          </center>
        </div>
      </>
    );
  };
}

export default SSRDesignMasterImage;
